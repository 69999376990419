import React from "react";
import Drawers from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Form from "./Form";
import AddButton from "./AddButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DeleteButton from "./DeleteButton";

const Drawer = (props) => {
  let {
    DrawerBody,
    DrawerFooter,
    initialValues,
    enableReinitialize,
    onSubmit,
    handleOpenModal,
    handleCloseModal,
    handleDrawerClose,
    isModalOpen,
    buttonLabel,
    showButton,
    hideAddButton,
    modelTitle,
    showDeleteButton,
    handleDelete,
    showAddButton,
    className,
    showFooter = true,
    disableSubmitOnEnter, // New prop to control form submission on Enter
  } = props;

  // Handle key press to prevent submission on Enter key
  const handleKeyDown = (event) => {
    if (disableSubmitOnEnter && event.key === "Enter") {
      event.preventDefault(); // Prevent form submission only if the prop is true
    }
  };

  const handleCancel = () => {
    handleCloseModal();
  };

  return (
    <>
      {showAddButton && (
        <AddButton
          label={buttonLabel}
          onClick={handleOpenModal}
          variant="contained"
          color="var(--drawer-add-button-text-color)"
        />
      )}
      {showButton && !hideAddButton && (
        <div className="">
          <div
            onClick={handleOpenModal}
            className="d-flex justify-content-center align-items-center me-1"
            style={{ width: "35px", height: "35px" }}
          >
            <button
              style={{
                border: "none",
                width: "35px",
                height: "35px",
                borderRadius: "5px",
              }}
              className="bg-primary"
            >
              <FontAwesomeIcon icon={faPlus} color="var(--drawer-FontAwesomeIcon-color)" />
            </button>
          </div>
        </div>
      )}
      <Drawers
        className={className?className:"MuiDrawer-paper"}
        anchor="right"
        open={isModalOpen}
        style={{
          width: "100%",
          height: "100vh",  
          overflow: "hidden",
        }}
        PaperProps={{
          className: className ? className : ""
        }}
      >
        <div className="d-flex flex-column h-100">
          {/* Header */}
          <div
            style={{
              height: "70px", 
              padding: "3px 10px",
              borderBottom: "1px solid var(--drawer-header-border-bottom-color)",
              top: 0,
            }}
            className="d-flex justify-content-between align-items-center position-sticky bg-white z-2"
          >
            <h2 className="fw-normal">{modelTitle ? modelTitle : ""}</h2>
            <div className="d-flex">
              {showDeleteButton && (
                <div className="mt-2">
                  <DeleteButton onClick={() => handleDelete()} />
                </div>
              )}
              <Button
                onClick={handleDrawerClose}
                color="var(--drawer-cancel-button-bg-color)"
                style={{ fontSize: "24px" }}
              >
                ×
              </Button>
            </div>
          </div>
          {/* Content */}
          <div className="flex-fill overflow-auto">
            <Form
              initialValues={initialValues}
              enableReinitialize={enableReinitialize}
              onSubmit={onSubmit}
              className="w-100"
              width="100%"
            >
              <div 
                style={{ minHeight: "calc(100vh - 80px)" }} 
                className="p-4"
                onKeyDown={handleKeyDown}  // Attach keydown event handler here
              >
                {DrawerBody}
              </div>
              {showFooter && (
                <div
                  style={{
                    height: "70px",
                    padding: "3px 10px",
                    borderTop: "1px solid var(--drawer-footer-border-tap-color)",
                    backgroundColor: "var(--drawer-footer-bg-color)",
                  }}
                  className="d-flex justify-content-center text-center position-sticky bottom-0 bg-white z-2"
                >
                  <div className="mt-3">{DrawerFooter}</div>

                  {initialValues && (
                    <div className="mt-3">
                      <Button
                        onClick={handleCancel}
                        style={{
                          border: "1px solid var(--drawer-cancel-button-border-color)",
                          marginLeft: "15px",
                          borderRadius: "7px",
                          textTransform: "none",
                        }}
                      >
                        Cancel
                      </Button>
                    </div>
                  )}
                </div>
              )}
            </Form>
          </div>
        </div>
      </Drawers>
    </>
  );
};

export default Drawer;
