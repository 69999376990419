import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DropdownItem } from "reactstrap";
import { fetchList } from "../../actions/table";
import { endpoints } from "../../api/endPoints";
import DateSelector from "../../components/Date";
import DeleteModal from "../../components/DeleteModal";
import Drawer from "../../components/Drawer";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import StatusText from "../../components/StatusText";
import MoreDropdown from "../../components/authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import ObjectName from "../../helpers/ObjectName";
import DateTime from "../../lib/DateTime";
import LocationAllocationService from "../../services/LocationAllocationService";
import StatusService from "../../services/StatusService";
import { Link } from "react-router-dom";
import Url from "../../lib/Url";

const locationAllocation = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [list, setList] = useState();

  let dispatch = useDispatch();

  useEffect(() => {
    getStatusList();
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const deleteToggle = () => {
    setIsModelOpen(!isModelOpen);
    setRowData(null);
  };

  const getStatusList = async (statusId) => {
    const data = await StatusService.nextStatusSearch(
      ObjectName.LOCATION_ALLOCATION,
      statusId
    );
    if (data && data.length > 0) {
      setList(data);
    }
  };

  let DrawerBody = (
    <>
      <DateSelector name="date" placeholder="Date" isClearable />
    </>
  );

  let DrawerFooter = (
    <>
      <SaveButton label="Add" />
    </>
  );

  let initialValues = {
    date: new Date() || "",
  };

  const handleAdd = async (values) => {
    let data = new FormData();
    data.append("date", values && values?.date ? values?.date : "");
    dispatch(
      await LocationAllocationService.create(data, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "locationAllocation",
              `${endpoints().LocationAllocationAPI}/search`,
              Url.GetParam("page") ? Url.GetParam("page") : 1,
              Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
              {
                startDate: Url.GetParam("startDate"),
                endDate: Url.GetParam("endDate"),
                sort: Url.GetParam("sort"),
                sortDir: Url.GetParam("sortDir"),
                date: Url.GetParam("date") ? Url.GetParam("date") : "",
              }
            )
          );
          toggle();
        }
      })
    );
  };

  const handleDelete = async () => {
    dispatch(
      await LocationAllocationService.delete(rowData?.id, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "locationAllocation",
              `${endpoints().LocationAllocationAPI}/search`,
              Url.GetParam("page") ? Url.GetParam("page") : 1,
              Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
              {
                startDate: Url.GetParam("startDate"),
                endDate: Url.GetParam("endDate"),
                sort: Url.GetParam("sort"),
                sortDir: Url.GetParam("sortDir"),
                date: Url.GetParam("date") ? Url.GetParam("date") : "",
              }
            )
          );
          deleteToggle();
        }
      })
    );
  };

  const handleStatusChange = async (id, statusId) => {
    dispatch(
      await LocationAllocationService.updateStatus(
        id,
        { status: statusId },
        (res) => {
          if (res) {
            setList("");
            getStatusList();
          dispatch(
            fetchList(
              "locationAllocation",
               `${endpoints().LocationAllocationAPI}/search`,
               Url.GetParam("page") ? Url.GetParam("page") : 1,
               Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
              {
                ...Url.GetAllParams()
              }
            )
          )
          }
        }
      )
    );
  };

  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
    {
      value: "date:ASC",
      label: "Date",
    },
  ];

  return (
    <div>
      <Drawer
        DrawerBody={DrawerBody}
        DrawerFooter={DrawerFooter}
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleAdd}
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggle}
        isModalOpen={isOpen}
        hideAddButton
        modelTitle="Add Location Allocation"
      />
      <DeleteModal
        title="Delete Location Allocation"
        isOpen={isModelOpen}
        toggle={deleteToggle}
        deleteFunction={handleDelete}
        label={DateTime.getDate(rowData?.date)}
      />

      <PageTitle
        label="Location Allocation"
        buttonHandler={() => {
          toggle();
        }}
        buttonLabel="Add"
      />

      <ReduxTable
        id="locationAllocation"
        showHeader
        newTableHeading
        params={{}}
        searchPlaceholder="Search"
        apiURL={`${endpoints().LocationAllocationAPI}/search`}
        history={props.history}
        paramsToUrl={true}
        showCustomDateFilter
        showSearch
        sortByOptions={sortByOption}
      >
        <ReduxColumn
          field="date"
          sortBy="date"
          className="text-center"
          renderField={(row) => (
            <Link to={`/locationAllocation/${row.id}`} className="link-opacity-75 text-decoration-none"><span>{DateTime.getDate(row.date)}</span></Link>
          )}
        >
          Date
        </ReduxColumn>
        <ReduxColumn
          field="statusName"
          sortBy="status"
          width="150px"
          className="text-center"
          renderField={(row) => (
            <StatusText
              backgroundColor={row.statusColor}
              status={row.statusName}
            />
          )}
        >
          Status
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          disableOnClick
          renderField={(row) => (
            <div className="text-center action-group-dropdown">
              <MoreDropdown
                onClick={(e) => {
                  getStatusList(row && row?.statusId);
                }}
              >
                {list &&
                  list.map((value) => (
                    <DropdownItem
                      onClick={async () => {
                        handleStatusChange(row?.id, value && value?.value);
                      }}
                    >
                      {value?.label}
                    </DropdownItem>
                  ))}
                <DropdownItem
                  className="text-danger"
                  onClick={() => {
                    setRowData(row);
                    setIsModelOpen(true);
                  }}
                >
                  Delete
                </DropdownItem>
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </div>
  );
};

export default locationAllocation;
