import React, { useState, useEffect } from "react";

// API Client
import { apiClient } from "../apiClient";

// Configs
import { DEFAULT_API_KEY } from "../configs";
import { endpoints } from "../api/endPoints";

// Cookie
import { COOKIE_SESSION_TOKEN } from "../lib/Cookie";
import Cookie from "../lib/Helper";

// Components
import Select from "./Select";

const StateDropdown = ({
  name,
  label,
  placeholder,
  error,
  required,
  defaultValue,
  selectedCountryName,
  fontBolded,
  notRequired,
  id,
  selectedCountry,
}) => {
  const [options, setOptions] = useState([]);
  // Fetch State options
  const fetchStateDropdownOptions = async () => {
    if (Cookie.get(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization =
        Cookie.get(COOKIE_SESSION_TOKEN);
    } else {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }

    if (selectedCountry) {
      const params = "?stateList=true";
      try {
        const response = await apiClient.get(
          `${endpoints().countryAPI}/${selectedCountry}${params}`
        );
        const states = response.data?.data || [];
        const optionsData = states.map((state) => ({
          value: state.name,
          label: state.name,
        }));
        setOptions(optionsData);
      } catch (error) {
        console.error("Error fetching state options:", error);
      }
    }
  };

  useEffect(() => {
    fetchStateDropdownOptions();
  }, [selectedCountry]); // Refetch options whenever selectedCountry changes

  return (
    <Select
      name={name}
      label={label}
      options={selectedCountryName ? options : []}
      placeholder={placeholder}
      error={error}
      defaultValue={defaultValue || ""}
      isSearchable={true}
      required={required}
    />
  );
};

export default StateDropdown;
