import React, { useState } from "react";

// Components
import Select from "../../../components/Select";
import StatusSelect from "../../../components/SelectStatus";
import Text from "../../../components/Text";
import URL from "../../../components/Url";
import App from "../../../helpers/App";
import { portalTemplate } from "../../../helpers/Support";

const AppSettingForm = (props) => {
  const {
    handleTemplateChange,
    handleHostUrlChange,
    handleStatusChange,
    handleNameSpaceChange,
    handleNameChange,
    editable,
  } = props;

  const customStatusOption = [
    {
      label: App.STATUS_ACTIVE_TEXT,
      value: App.STATUS_ACTIVE,
    },
    {
      label: App.STATUS_INACTIVE_TEXT,
      value: App.STATUS_INACTIVE,
    },
  ];

  return (
    <>
      <Text
        name="name"
        label="Name"
        onChange={handleNameChange}
        required
        disabled={editable}
      />
      <Text
        name="nameSpace"
        label="Name Space"
        onChange={handleNameSpaceChange}
        disabled={editable}
      />
      <StatusSelect
        name="status"
        label="Status"
        customStatusOption={customStatusOption}
        required
        handleStatusChange={handleStatusChange}
        isDisabled={editable}
      />
      <div className="row field-wrapper">
        <div className="col-lg-12 col-sm-12">
          <URL
            className="w-100"
            id="host_url"
            name="host_url"
            label="Host Url"
            onChange={handleHostUrlChange}
            disabled={editable}
          />
        </div>
      </div>

      <div className="row field-wrapper">
        <div className="col-lg-12 col-sm-12">
          <Select
            name="template"
            label="Template"
            placeholder="Select Template..."
            options={portalTemplate}
            onInputChange={handleTemplateChange}
            isDisabled={editable}
          />
        </div>
      </div>
    </>
  );
};

export default AppSettingForm;
