import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import QRCode from "qrcode.react";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import { bindActionCreators } from "redux";

// Components
import BreadCrumb from "../../components/Breadcrumb";
import DateSelector from "../../components/Date";
import DeleteModal from "../../components/DeleteModal";
import Form from "../../components/Form";
import HorizontalSpace from "../../components/HorizontalSpace";
import PageTitle from "../../components/PageTitle";
import Password from "../../components/Password";
import SaveButton from "../../components/SaveButton";
import Select from "../../components/Select";
import Text from "../../components/Text";
import toast from "../../components/Toast";
import AddButton from "../../components/AddButton";
import ActivityList from "../../components/ActivityList";
import CurrencyComponent from "../../components/Currency";
import IPAddress from "../../components/IpAddress";
import ProductSelectModal from "../../components/ProductSelectModal";
import UserSelect from "../../components/UserSelect";
import Team from "./components/Team";
import ProductTab from "./components/productTab";
import ImageList from "../../components/MediaCarousel";
import CancelButton from "../../components/CancelButton";
import AddModal from "../../components/Modal";
import QRCodePrint from "./components/qrPrint";
import Action from "../../components/Action";
import Drawer from "../../components/Drawer";
import SettingsTab from "./components/settingTab";
import MoreDropdown from "../../components/authentication/moreDropdown";
import CustomForm from "../../components/customFieldsList/CustomForm";
import URL from "../../components/Url";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import AddressDetailTab from "../portalDetail/AddressTab";
import AttendanceList from "../attendance/list";
import ContactList from "../contact/components/ContactList";
import LocationRackTab from "./components/LocationRackTab";
import SelectStore from "../../components/SelectStore";
import ShiftSelect from "../../components/ShiftSelect";
import NumberComponent from "../../components/Number";

//Configs
import { apiClient } from "../../apiClient";

// API
import { endpoints } from "../../api/endPoints";

// Lib
import Currency from "../../lib/Currency";
import { isBadRequest } from "../../lib/Http";
import String from "../../lib/String";
import Url from "../../lib/Url";

// Services
import AddressService from "../../services/AddressService";
import LocationSalesGoalService from "../../services/LocationSalesGoalService";
import { hasPermission } from "../../services/UserRolePermissionService";

// Action
import {
  addTeam,
  deleteStore,
  updateStore,
  updateStoreStatus,
} from "../../actions/storeList";
import { addStore } from "../../actions/storeProductDetail";
import { fetchList } from "../../actions/table";

// Helpers
import * as Store from "../../helpers/Store";
import ObjectName from "../../helpers/ObjectName";
import Permission from "../../helpers/Permission";
import { locationTypeOptions } from "../../helpers/locationTypes";

const StoreDetail = (props) => {
  const param = new URLSearchParams(props.history.location.search);
  const selectedTab = param.get("tab");

  const { history, CurrentPage, CurrentPageSize } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(
    selectedTab ? selectedTab : Store.GENERAL_TAB
  );
  const [productData, setProductData] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [shiftList, setShiftList] = useState("");
  const [selectedOption, setSelectedOption] = useState();
  const [selectedCountryName, setSelectedCountryName] = useState();
  const [addStoreModal, setAddProductModal] = useState(false);
  const [MultiSelectProduct, setMultiSelectProduct] = useState([]);
  const [isToggle, setIsToggle] = useState(false);
  const [isSalesGoalToggle, setIsSalesGoalToggle] = useState(false);
  const [storeList, setStoreList] = useState([]);
  const [isAddressOpen, setIsAdressModelOpen] = useState(false);
  const [addressDetails, setAddressDetails] = useState();
  const [isAddressDeleteModel, setIsAddressDeleteModel] = useState(false);
  const [isContactModelOpen, setIsContactModelOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [status, setImageStatus] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [file, setFile] = useState();
  const [isToggleOpen, setIsToggleOpen] = useState(false);
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [amount, setAmount] = useState("");
  const [shiftValue, setShiftValue] = useState();
  const [row, setRow] = useState();
  const [salesGoalDeleteModal, setSalesGoalDeleteModal] = useState(false);
  const [isDrawerModelOpen, setIsDrawerModelOpen] = useState(false);
  const [editable, setEditable] = useState(true);

  let showHistory = hasPermission(Permission.LOCATION_HISTORY_VIEW);

  let showEditButton = hasPermission(Permission.LOCATION_EDIT);

  // Store id

  let storeId = props.match.params.tab;

  const dispatch = useDispatch();

  const statusOptions = [
    {
      value: Store.STATUS_ACTIVE_TEXT,
      label: "Active",
    },
    {
      value: Store.STATUS_INACTIVE_TEXT,
      label: "InActive",
    },
  ];

  useEffect(() => {
    getStoreDetail();
  }, []);

  // adress tab
  const addressOpenToggle = () => {
    setIsAdressModelOpen(!isAddressOpen);
    setAddressDetails("");
  };

  const EditModal = () => {
    setIsAdressModelOpen(!isAddressOpen);
  };

  const addContactToggle = () => {
    setIsContactModelOpen(!isContactModelOpen)
  };

  const addressInitialValues = {
    title: addressDetails?.title ? addressDetails?.title : "",
    name: addressDetails?.name ? addressDetails?.name : "",
    address1: addressDetails?.address1 ? addressDetails?.address1 : "",
    address2: addressDetails?.address2 ? addressDetails?.address2 : "",
    city: addressDetails?.city ? addressDetails?.city : "",
    gst_number: addressDetails?.gst_number ? addressDetails?.gst_number : "",
    pin_code: addressDetails?.pin_code ? addressDetails?.pin_code : "",
    phone_number: addressDetails?.phone_number
      ? addressDetails?.phone_number
      : "",
    state: {
      label: addressDetails?.state ? addressDetails?.state : "",
      value: addressDetails?.state ? addressDetails?.state : "",
    },
    country: {
      label: addressDetails?.country ? addressDetails?.country : "",
      value: addressDetails?.country ? addressDetails?.country : "",
    },
    latitude: latitude
      ? latitude
      : addressDetails?.latitude
        ? addressDetails?.latitude
        : "",
    longitude: longitude
      ? longitude
      : addressDetails?.longitude
        ? addressDetails?.longitude
        : "",
  };

  const handleAdressUpdate = async (values) => {
    const data = new FormData();
    const addressId = addressDetails?.id;
    let params = {
      object_id: storeId,
      objectName: ObjectName.LOCATION,
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
      search: Url.GetParam("search"),
    };
    if (!addressId) {
      data.append("name", values && values.name ? values.name : "");
      data.append(
        "phone_number",
        values && values.phone_number ? values.phone_number : ""
      );
      data.append("address1", values && values.address1 ? values.address1 : "");
      data.append("title", values && values.title ? values.title : "");
      data.append("store_id", storeId);
      data.append("object_name", ObjectName.LOCATION);
      data.append("address2", values && values.address2 ? values.address2 : "");
      data.append("pin_code", values && values.pin_code ? values.pin_code : "");
      data.append(
        "latitude",
        latitude ? latitude : values && values.latitude ? values.latitude : ""
      );
      data.append(
        "longitude",
        longitude
          ? longitude
          : values && values.longitude
            ? values.longitude
            : ""
      );

      data.append(
        "country",
        values && values.country.value ? values.country.value : ""
      );
      data.append("state", values && values.state ? values.state.value : "");
      data.append("city", values && values.city ? values.city : "");
      data.append(
        "gst_number",
        values && values.gst_number ? values.gst_number : ""
      );
      dispatch(
        AddressService.add(data, params, addressOpenToggle, setAddressDetails)
      );
    } else {
      data.append("name", values && values.name ? values.name : "");
      data.append(
        "phone_number",
        values && values.phone_number ? values.phone_number : ""
      );
      data.append("address1", values && values.address1 ? values.address1 : "");
      data.append("title", values && values.title ? values.title : "");
      data.append("store_id", storeId);
      data.append("object_name", ObjectName.LOCATION);
      data.append("address2", values && values.address2 ? values.address2 : "");
      data.append("pin_code", values && values.pin_code ? values.pin_code : "");
      data.append(
        "country",
        values && values.country.value ? values.country.value : ""
      );
      data.append(
        "latitude",
        latitude ? latitude : values && values.latitude ? values.latitude : ""
      );
      data.append(
        "longitude",
        longitude
          ? longitude
          : values && values.longitude
            ? values.longitude
            : ""
      );
      data.append("state", values && values.state ? values.state.value : "");
      data.append("city", values && values.city ? values.city : "");
      data.append(
        "gst_number",
        values && values.gst_number ? values.gst_number : ""
      );
      data.append("id", addressDetails?.id);

      dispatch(
        await AddressService.update(
          addressDetails?.id,
          data,
          params,
          setIsAdressModelOpen
        )
      );
      setAddressDetails("");
    }
  };

  const addUrlForm = (
    <>
      <URL name="url" label="Location Url" placeholder="Enter Location Url" />
    </>
  );

  const roleFooter = (
    <>
      <SaveButton onClick={() => setIsToggleOpen(false)} />
    </>
  );

  const handleAddressDelete = async () => {
    dispatch(
      await AddressService.Delete(addressDetails?.id, {
        pagination: true,
        search: Url.GetParam("search"),
        page: Url.GetParam("page"),
        pageSize: Url.GetParam("pageSize"),
        object_id: storeId,
        objectName: ObjectName.LOCATION,
        sort: "name",
        sortDir: "ASC",
      })
    );
    setAddressDetails("");
    setIsAddressDeleteModel(false);
  };

  //Get Store Detail
  const getStoreDetail = () => {
    let id = props.match.params.tab;
    return apiClient
      .get(`${endpoints().locationAPI}/${id}`)
      .then((response) => {
        const data = response.data;
        setProductData(data);
      })
      .catch((error) => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  const _toggle = (id) => {
    setIsToggle(!isToggle);
  };

  const salesGoaltoggle = () => {
    setIsSalesGoalToggle(!isSalesGoalToggle);
  };

  const toggleOpen = () => {
    setIsToggleOpen(!isToggleOpen);
  };

  const viewInMap = () => {
    let Latitude = latitude ? latitude : addressDetails?.latitude;
    let Longitude = longitude ? longitude : addressDetails?.longitude;

    let url = `https://www.google.com/maps/search/?api=1&query=${Latitude},${Longitude}`;

    // You can then use the URL, for example:
    window.open(url, "_blank"); // To open the URL in a new tab
  };

  // Add store modal toggling
  const addProductToggle = () => {
    setAddProductModal(false);
    history.push(`/location/${storeId}?pageSize=${25}`);
  };

  const multiselect = (values) => {
    setMultiSelectProduct(values);
  };

  const handleSubmitValue = (values) => {
    const url = values?.url;
    const regex = /@(-?\d+\.\d{4,}),(-?\d+\.\d{4,})/;

    const match = url.match(regex);

    if (match) {
      const latitude = parseFloat(match[1]);
      const longitude = parseFloat(match[2]);
      setLatitude(latitude);
      setLongitude(longitude);
    } else {
      console.log("Latitude and longitude not found in the URL.");
    }
  };

  const handleSubmit = async (values) => {
    const createDate = new FormData();
    createDate.append("productIds", MultiSelectProduct);
    createDate.append("storeId", storeId);

    try {
      const response = await apiClient.post(
        `${endpoints().storeProductAPI}/productAdd`,
        createDate
      );
      if (response) {
        toast.success(response.data.message);
        setAddProductModal(false);
        setMultiSelectProduct("");
        history.push(`/location/${storeId}?pageSize=${25}`);
      }
      dispatch(
        fetchList(
          "storeProduct",
          `${endpoints().storeProductAPI}/search`,
          1,
          25,
          {
            store_id: props.match.params.tab,
            sort: Url.GetParam("sort") ? Url.GetParam("sort") : "product_name",
            sortDir: Url.GetParam("sortDir") ? Url.GetParam("sortDir") : "ASC",
          }
        )
      );
      dispatch(
        fetchList("product", `${endpoints().productAPI}/search`, 1, 25, {
          locationId: storeId,
        })
      );
      const data = {
        productIds: values.product.value,
        quantity: values.quantity,
        storeId: storeId,
      };

      dispatch(addStore(data));
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  };

  const toggle = (tab) => {
    setIsOpen(!isOpen);
    setActiveTab(tab);

    history.push(`/location/${storeId}?pageSize=${25}`);
  };

  const handleCountryChange = (values) => {
    const selectedOption = values && values.id;
    const selectedCountryName = values && values.label;
    setSelectedOption(selectedOption);
    setSelectedCountryName(selectedCountryName);
  };

  const _handleTabChange = (tab) => {
    if (tab === Store.CONTACT_TAB) {
      props.history.push(`?tab=${tab}`);
    } else {
      props.history.push(`?tab=${tab}&location=${props.match.params.tab}`);
    }
  };

  /**
   * Delete Store
   *
   * @param data
   */
  const storeDelete = (id) => {
    dispatch(deleteStore(id, {}));
    history.push("/location");
  };

  const handleAmountChange = (e) => {
    let value = e.values?.amount ? e.values?.amount : "";
    setAmount(value);
  };

  const handleShiftChange = (x) => {
    let data = x;
    setShiftValue(data);
  };

  const addTeamEntry = (
    <>
      <UserSelect label="User" name="userName" required />
      <ShiftSelect
        name="shift"
        label="Shift"
        placeholder="Select Shift"
        shiftOption={setShiftList}
        handleShiftChange={handleShiftChange}
        required
      />
    </>
  );

  const addSalesGoal = (
    <>
      <ShiftSelect
        name="shift"
        label="Shift"
        placeholder="Select Shift"
        shiftOption={setShiftList}
        handleShiftChange={handleShiftChange}
        required
      />
      <CurrencyComponent
        label="Amount"
        name="amount"
        onInputChange={handleAmountChange}
        required
      />
    </>
  );

  const salesGoalFooter = (
    <SaveButton type="submit" label={row?.id ? "Save" : "Add"} />
  );

  //Handle Update Store Details
  const handleUpdate = (id, values) => {
    let bodyData = new Object();

    if (values?.name) {
      bodyData.name = String.Get(values.name);
    }
    if (values?.route_number) {
      bodyData.route_number = values.route_number;
    }
    if (values?.status?.value) {
      bodyData.status = values?.status && String.Get(values.status.value);
    }
    if (values?.shopify_shop_name) {
      bodyData.shopify_shop_name = String.Get(values?.shopify_shop_name);
    }
    if (values?.shopify_api_key) {
      bodyData.shopify_api_key = String.Get(values.shopify_api_key);
    }
    if (values?.shopify_admin_api_version) {
      bodyData.shopify_admin_api_version = String.Get(
        values.shopify_admin_api_version
      );
    }
    if (values?.shopify_password) {
      bodyData.shopify_password = String.Get(values.shopify_password);
    }
    if (values?.address1) {
      bodyData.address1 = String.Get(values.address1);
    }
    if (values?.address2) {
      bodyData.address2 = String.Get(values.address2);
    }
    if (values?.state) {
      bodyData.state = String.Get(values.state.value);
    }
    if (values?.city) {
      bodyData.city = String.Get(values.city);
    }
    if (values?.country) {
      bodyData.country = String.Get(values.country.value);
    }
    if (values?.pin_code) {
      bodyData.pin_code = String.Get(values.pin_code);
    }
    if (values?.email) {
      bodyData.email = String.Get(values.email);
    }
    if (values?.mobile_number1) {
      bodyData.mobile_number1 = String.Get(values.mobile_number1);
    }
    if (values?.mobile_number2) {
      bodyData.mobile_number2 = String.Get(values.mobile_number2);
    }

    if (values && values.printName !== undefined) {
      bodyData.printName = String.Get(values.printName);
    }
    if (values && values.color) {
      bodyData.color = String.Get(values.color);
    }
    if (values && values.longitude) {
      bodyData.longitude = String.Get(longitude)
        ? longitude
        : String.Get(values.longitude);
    }
    if (values && values.latitude) {
      bodyData.latitude = latitude ? latitude : String.Get(values.latitude);
    }

    if (values && values.ip_address) {
      bodyData.ip_address = String.Get(values.ip_address);
    }

    if (values && values.minimum_cash_in_store) {
      bodyData.minimum_cash_in_store =
        values &&
        Currency.Get(
          values.minimum_cash_in_store ? values.minimum_cash_in_store : null
        );
    }

    if (values && values.cash_in_location) {
      bodyData.cash_in_location =
        values &&
        Currency.Get(values.cash_in_location ? values.cash_in_location : null);
    }


    bodyData.start_date = values && values.start_date ? values.start_date : "";

    bodyData.end_date = values && values.end_date ? values.end_date : "";

    if (productData?.data?.open_time !== values?.open_time) {
      bodyData.open_time = values && values.open_time ? values.open_time : null;
    }

    if (productData?.data?.close_time !== values?.close_time) {
      bodyData.close_time =
        values && values?.close_time ? values?.close_time : null;
    }

    if (values?.distributionCenter !== undefined) {
      bodyData.distributionCenter =
        values?.distributionCenter && values?.distributionCenter.value;
    }

    if (values?.type !== undefined) {
      bodyData.type = values?.type ? values?.type.value : null;
    }

    bodyData.location_code =
      values && values?.location_code ? values?.location_code : "";

    bodyData.last_order_number =
      values && values?.last_order_number ? values?.last_order_number : null;

    dispatch(updateStore(id, bodyData, {}));
    getStoreDetail();
    setEditable(true);
  };

  const deleteSalesGoal = async () => {
    dispatch(
      await LocationSalesGoalService.delete(row?.id, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "salesGoal",
              `${endpoints().LocationSalesGoalApi}/search`,
              1,
              25,
              { location_id: storeId }
            )
          );
        }
      })
    );
  };

  const handleSalesGoalAdd = async (id, values) => {
    const data = new FormData();
    if (values?.amount) {
      data.append("amount", values?.amount);
    }
    if (values?.shift) {
      data.append("shift_id", String.Get(values.shift.id));
    }
    data.append("location_id", storeId);
    if (row?.id) {
      dispatch(
        await LocationSalesGoalService.update(row?.id, data, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "salesGoal",
                `${endpoints().LocationSalesGoalApi}/search`,
                1,
                25,
                { location_id: storeId }
              )
            );
          }
          salesGoaltoggle();
        })
      );
    } else {
      dispatch(
        await LocationSalesGoalService.create(data, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "salesGoal",
                `${endpoints().LocationSalesGoalApi}/search`,
                1,
                25,
                { location_id: storeId }
              )
            );
          }
          salesGoaltoggle();
        })
      );
    }
  };

  const handleTeamAdd = (id, values) => {
    const data = new FormData();
    if (values?.userName) {
      data.append("user", String.Get(values.userName.id));
    }
    if (values?.shift) {
      data.append("shift", String.Get(values.shift.id));
    }

    if (values?.status) {
      data.append("status", String.Get(values.status.value));
    }

    dispatch(addTeam(id, data, _toggle));
  };

  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    {
      label: "Location",
      link: `/location?status=${Store.Store.STATUS_ACTIVE}`,
    },
    {
      label: activeTab,
      link: "",
    },
  ];

  const teamFooter = <SaveButton type="submit" label="Add" />;

  const downloadQRCode = (
    <QRCode
      id="qr-gen"
      value={productData?.data?.encryptedStoreID}
      size={290}
      level={"H"}
      includeMargin={true}
    />
  );

  const imagetoggle = (id) => {
    setImageStatus("");
    setSelectedFile("");
    setErrorMessage("");
    setSelectedFile("");
    setFile("");
    setCurrentData(null);
    setModalOpen(!modalOpen);
  };

  const imageOpentoggle = (id) => {
    setModalOpen(!modalOpen);
  };

  const onStatusChange = async (value) => {
    const id = props.match.params.tab;
    dispatch(updateStoreStatus(id, value, {}));
  };

  const initialValues = {
    name: productData?.data?.name,
    start_date: productData?.data?.start_date,
    end_date: productData?.data?.end_date,
    ip_address: productData?.data?.ip_address,
    color: productData?.data?.color,
    printName: productData?.data?.printName,
    status: productData?.data?.status
      ? statusOptions.find((data) => data.label === productData?.data?.status)
      : "",
    minimum_cash_in_store:
      productData?.data?.minimum_cash_in_store == 0
        ? ""
        : productData?.data?.minimum_cash_in_store,
    cash_in_location:
      productData?.data?.cash_in_location == 0
        ? ""
        : productData?.data?.cash_in_location,
    distributionCenter:
      storeList &&
      storeList.find(
        (data) => data.value == productData?.data?.distributionCenter
      ),
    location_code: productData?.data?.location_code,
    last_order_number: productData?.data?.last_order_number,
    latitude: latitude ? latitude : productData?.data?.latitude || "",
    longitude: longitude ? longitude : productData?.data?.longitude || "",
    type: productData?.data?.type
      ? locationTypeOptions.find(
        (data) => data.value === productData?.data?.type
      )
      : "",
    close_time: productData?.data?.close_time
      ? productData?.data?.close_time
      : "",
    open_time: productData?.data?.open_time ? productData?.data?.open_time : "",
    route_number: productData?.data?.route_number ? productData?.data?.route_number : "",

  };

  const actionsMenuList = [
    {
      value: "delete",
      label: "Delete",
    },
  ];

  if (showEditButton && activeTab === Store.GENERAL_TAB) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  const handleActionChange = (e) => {
    if (e == "delete") {
      setDeleteModal(true);
    }
    if (e == "edit") {
      setEditable(false);
    }
  };

  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  return (
    <>
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Store"
        id={productData?.data?.id}
        label={productData?.data?.name}
        deleteFunction={storeDelete}
      />
      <DeleteModal
        isOpen={isAddressDeleteModel}
        title="Delete Address"
        label={addressDetails?.title}
        toggle={() => {
          setIsAddressDeleteModel(false);
        }}
        deleteFunction={handleAddressDelete}
      />
      <DeleteModal
        isOpen={salesGoalDeleteModal}
        toggle={() => {
          setSalesGoalDeleteModal(false);
        }}
        title="Delete Sales Goal"
        id={row?.id}
        label={row?.id}
        deleteFunction={deleteSalesGoal}
      />

      <ProductSelectModal
        modalOpen={addStoreModal}
        toggle={toggle}
        toggleModalClose={addProductToggle}
        handleSubmit={handleSubmit}
        BulkSelect={multiselect}
        history={history}
        params={{ locationId: storeId }}
      />
      {activeTab == Store.SALES_GOAL_TAB && (
        <Drawer
          modelTitle={row?.id ? "Edit Sales Goal" : "Add Sales Goal"}
          DrawerBody={addSalesGoal}
          DrawerFooter={salesGoalFooter}
          onSubmit={(values) => {
            let id = props.match.params.tab;

            handleSalesGoalAdd(id, values);
          }}
          initialValues={{
            amount: amount ? amount : row ? row.amount : "",
            shift: shiftValue
              ? shiftList &&
              shiftList.find((option) => option.value == shiftValue?.value)
              : shiftList &&
              shiftList.find((option) => option.value == row?.shift_id),
          }}
          handleOpenModal={salesGoaltoggle}
          handleCloseModal={salesGoaltoggle}
          handleDrawerClose={salesGoaltoggle}
          isModalOpen={isSalesGoalToggle}
          enableReinitialize={true}
        />
      )}
      <Drawer
        modelTitle="Add Member"
        DrawerBody={addTeamEntry}
        DrawerFooter={teamFooter}
        onSubmit={(values) => {
          let id = props.match.params.tab;

          handleTeamAdd(id, values);
        }}
        initialValues={{ userName: "", shift: "", status: "" }}
        handleOpenModal={_toggle}
        handleCloseModal={_toggle}
        handleDrawerClose={_toggle}
        isModalOpen={isToggle}
        enableReinitialize={true}
      />

      {/* BreadCrumb */}
      <BreadCrumb list={breadcrumbList} />
      {/* Page Title */}
      <div className="d-flex justify-content-between">
        <PageTitle label={productData?.data?.name} />

        <div className="d-flex">
          {activeTab == Store.PRODUCT_TAB ? (
            <div className="mt-0 mt-sm-1 me-1">
              <AddButton
                label={"Add Product"}
                onClick={() => {
                  setAddProductModal(true);
                }}
              />
            </div>
          ) : (
            ""
          )}
          {activeTab == Store.TEAM_TAB ? (
            <div className="mt-0 mt-sm-1 me-2">
              <AddButton
                label={"Add User"}
                onClick={() => {
                  _toggle(true);
                }}
              />
            </div>
          ) : (
            ""
          )}
          {activeTab == Store.SALES_GOAL_TAB ? (
            <AddButton
              label={"Add Sales Goal"}
              className="mt-0 mt-sm-1 me-2"
              onClick={() => {
                salesGoaltoggle(true);
                setRow("");
                setAmount("");
                setShiftValue("");
              }}
            />
          ) : (
            ""
          )}
          {activeTab == Store.ADDRESS_TAB ? (
            <div className="mt-0 mt-sm-1 me-2">
              <AddButton
                label={"Add Address"}
                onClick={() => {
                  addressOpenToggle(true);
                }}
              />
            </div>
          ) : (
            ""
          )}
          {activeTab == Store.CONTACT_TAB ? (
            <div className="mt-0 mt-sm-1 me-1">
              <AddButton
                label={"Add Contact"}
                onClick={(e) => {
                  addContactToggle(true);
                }}
              />
            </div>
          ) : (
            ""
          )}
          {activeTab == Store.FILES_TAB && (
            <AddButton
              className="mt-0 mt-sm-1 me-2"
              label="Add File"
              onClick={(e) => {
                imagetoggle();
              }}
            />
          )}
          {activeTab == Store.RACK_TAB ? (
            <div className="mt-0 mt-sm-1 me-2 mr-2">
              <AddButton
                label={"Add Location Rack"}
                onClick={() => {
                  setIsDrawerModelOpen(!isDrawerModelOpen);
                }}
              />
            </div>
          ) : (
            ""
          )}
          <div className="me-2">
            <Action
              buttonLabel={productData?.data?.status}
              dropdownLinks={statusOptions}
              handleChange={onStatusChange}
            />
          </div>
          <div className="ps-2">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        </div>
      </div>

      <Nav tabs className="admin-tabs">
        {/* General Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Store.GENERAL_TAB,
            })}
            onClick={() => {
              toggle(Store.GENERAL_TAB);
              _handleTabChange(Store.GENERAL_TAB);
            }}
          >
            {Store.GENERAL_TAB}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Store.FILES_TAB,
            })}
            onClick={() => {
              toggle(Store.FILES_TAB);
              _handleTabChange(Store.FILES_TAB);
            }}
          >
            {Store.FILES_TAB}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Store.SALES_GOAL_TAB,
            })}
            onClick={() => {
              toggle(Store.SALES_GOAL_TAB);
              _handleTabChange(Store.SALES_GOAL_TAB);
            }}
          >
            {Store.SALES_GOAL_TAB}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Store.ADDRESS_TAB,
            })}
            onClick={() => {
              toggle(Store.ADDRESS_TAB);
              _handleTabChange(Store.ADDRESS_TAB);
            }}
          >
            {Store.ADDRESS_TAB}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Store.CONTACT_TAB,
            })}
            onClick={() => {
              toggle(Store.CONTACT_TAB);
              _handleTabChange(Store.CONTACT_TAB);
            }}
          >
            {Store.CONTACT_TAB}
          </NavLink>
        </NavItem>

        {/* Email Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Store.SHOPIFY_TAB,
            })}
            onClick={() => {
              toggle(Store.SHOPIFY_TAB);
              _handleTabChange(Store.SHOPIFY_TAB);
            }}
          >
            {Store.SHOPIFY_TAB}
          </NavLink>
        </NavItem>

        {/* Prouduct Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Store.PRODUCT_TAB,
            })}
            onClick={() => {
              toggle(Store.PRODUCT_TAB);
              _handleTabChange(Store.PRODUCT_TAB);
            }}
          >
            {Store.PRODUCT_TAB}
          </NavLink>
        </NavItem>
        {/* ✴---CustomFields Tab---✴ */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Store.CUSTOMFIELDS_TAB,
            })}
            onClick={() => {
              toggle(Store.CUSTOMFIELDS_TAB);
              _handleTabChange(Store.CUSTOMFIELDS_TAB);
            }}
          >
            {Store.CUSTOMFIELDS_TAB}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Store.TEAM_TAB,
            })}
            onClick={() => {
              toggle(Store.TEAM_TAB);
              _handleTabChange(Store.TEAM_TAB);
            }}
          >
            {Store.TEAM_TAB}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Store.ATTENDANCE_TAB,
            })}
            onClick={() => {
              toggle(Store.ATTENDANCE_TAB);
              _handleTabChange(Store.ATTENDANCE_TAB);
            }}
          >
            {Store.ATTENDANCE_TAB}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Store.RACK_TAB,
            })}
            onClick={() => {
              toggle(Store.RACK_TAB);
              _handleTabChange(Store.RACK_TAB);
            }}
          >
            {Store.RACK_TAB}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Store.SETTINGS_TAB,
            })}
            onClick={() => {
              toggle(Store.SETTINGS_TAB);
              _handleTabChange(Store.SETTINGS_TAB);
            }}
          >
            {Store.SETTINGS_TAB}
          </NavLink>
        </NavItem>
        <NavItem>
          {showHistory && (
            <NavLink
              className={classnames({
                active: activeTab === Store.HISTORY_TAB,
              })}
              onClick={() => {
                toggle(Store.HISTORY_TAB);
                _handleTabChange(Store.HISTORY_TAB);
              }}
            >
              {Store.HISTORY_TAB}
            </NavLink>
          )}
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        {activeTab === Store.GENERAL_TAB && (
          <TabPane tabId={Store.GENERAL_TAB}>
            <AddModal
              isOpen={isToggleOpen}
              toggle={toggleOpen}
              toggleModalClose={toggleOpen}
              modalTitle="Add Location URL"
              modalBody={addUrlForm}
              modalFooter={roleFooter}
              initialValues={{
                name: "",
              }}
              onSubmit={handleSubmitValue}
              hideDefaultButtons
            />
            <Form
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={(values) => {
                let id = props.match.params.tab;
                handleUpdate(id, values);
              }}
            >
              <div className="card bg-white mt-3 col-lg-12">
                <div className="card-body">
                  <div className=" field-wrapper row pb-3 mb-3">
                    <div className="col-sm-6">
                      <Text
                        className="w-100"
                        name="name"
                        label="Name"
                        required={true}
                        disabled={editable}
                      />
                      <NumberComponent
                        name="route_number"
                        label="Route Number"
                        disabled={editable}
                      />
                      <div className="row">
                        <div className="col">
                          <Text
                            className="w-100"
                            name="printName"
                            label="Print Name"
                            disabled={editable}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <Text
                            className="w-100"
                            name="location_code"
                            label="Location Code"
                            disabled={editable}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <Text
                            className="w-100"
                            name="last_order_number"
                            label="Last Order Number"
                            disabled={editable}
                          />
                        </div>
                      </div>
                      <IPAddress
                        name="ip_address"
                        label="Ip Address"
                        disabled={editable}
                      />
                      <div>
                        {(addressDetails?.latitude &&
                          addressDetails?.longitude) && (
                            <Link onClick={viewInMap}>View In Map</Link>
                          )}
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <DateSelector
                            label="Open Time"
                            name="open_time"
                            isClearable={!editable}
                            showTimeSelect
                            showTimeSelectOnly
                            format="h:mm aa"
                            disabled={editable}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <DateSelector
                            isClearable={!editable}
                            showTimeSelect
                            showTimeSelectOnly
                            format="h:mm aa"
                            label="Close Time"
                            name="close_time"
                            disabled={editable}
                          />
                        </div>
                      </div>
                      <div className="row mt-1">
                        <div className="col-12 col-sm-6">
                          <CurrencyComponent
                            name="minimum_cash_in_store"
                            label="Minimum Cash In Location"
                            disabled={editable}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <CurrencyComponent
                            name="cash_in_location"
                            label="Cash In Location"
                            disabled={editable}
                          />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <SelectStore
                            label="Distribution Center"
                            name="distributionCenter"
                            StoreList={setStoreList}
                            isDisabled={editable}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <Select
                            name="type"
                            label="Type"
                            options={locationTypeOptions}
                            isDisabled={editable}
                          />
                        </div>
                      </div>
                      <Text
                        className="w-100"
                        name="color"
                        label="Color"
                        disabled={editable}
                      />
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <DateSelector
                            label="Start Date"
                            name="start_date"
                            disabled={editable}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <DateSelector
                            label="End Date"
                            name="end_date"
                            disabled={editable}
                          />
                        </div>
                      </div>
                      {!editable && (
                        <HorizontalSpace>
                          <SaveButton label="Save" />
                          <CancelButton
                            onClick={() => {
                              history.push("/location");
                            }}
                          />
                        </HorizontalSpace>
                      )}
                    </div>
                    <div className="col-lg-6 col-sm-6 mb-3">
                      <QRCodePrint
                        image={downloadQRCode}
                        storeDetail={productData?.data?.name}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </TabPane>
        )}
        {activeTab === Store.FILES_TAB && (
          <TabPane tabId={Store.FILES_TAB}>
            {/* Image List Table Component */}
            <ImageList
              title="Location"
              objectName="LOCATION"
              objectId={props.match.params.tab}
              showFeature={true}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              selectedFile={selectedFile}
              setSelectedFile={selectedFile}
              file={file}
              currentData={currentData}
              setCurrentData={setCurrentData}
              status={status}
              setImageStatus={setImageStatus}
              setFile={setFile}
              modalOpen={modalOpen}
              setModalOpen={setModalOpen}
              toggleClose={imagetoggle}
              userView
              hideAttachments
              toggle={imageOpentoggle}
            />
          </TabPane>
        )}
        {activeTab === Store.SALES_GOAL_TAB && (
          <TabPane tabId={Store.SALES_GOAL_TAB}>
            <div className="mt-4">
              <ReduxTable
                id="salesGoal"
                apiURL={`${endpoints().LocationSalesGoalApi}/search`}
                showHeader
                params={{
                  location_id: storeId,
                  sort: Url.GetParam("sort"),
                  sortDir: Url.GetParam("sortDir"),
                }}
                searchPlaceholder="Search"
                newTableHeading
                onRowClick
                history={history}
                sortByOptions={sortByOption}
                paramsToUrl={true}
                hideQueryParams={{
                  location: true,
                }}
              >
                <ReduxColumn
                  field="location_id"
                  sortBy="location_id"
                  className="text-center"
                  width="190px"
                  minWidth="190px"
                  maxWidth="190px"
                  renderField={(row) => <span>{row.locationName}</span>}
                >
                  Location
                </ReduxColumn>
                <ReduxColumn
                  field="shift_id"
                  sortBy="shift_id"
                  className="text-center"
                  width="110px"
                  minWidth="110px"
                  maxWidth="110px"
                  renderField={(row) => <span>{row.shiftName}</span>}
                >
                  Shift
                </ReduxColumn>

                <ReduxColumn
                  field="amount"
                  sortBy="amount"
                  className="text-end"
                  width="110px"
                  minWidth="110px"
                  maxWidth="110px"
                  renderField={(row) => (
                    <span>{Currency.Format(row.amount)}</span>
                  )}
                >
                  Amount
                </ReduxColumn>

                <ReduxColumn
                  field="Action"
                  disableOnClick
                  width="120px"
                  minWidth="80px"
                  maxWidth="80px"
                  renderField={(row) => (
                    <div className=" text-center action-group-dropdown">
                      <MoreDropdown>
                        {
                          <DropdownItem
                            onClick={() => {
                              setRow(row);
                              return salesGoaltoggle(row.id);
                            }}
                          >
                            Quick View
                          </DropdownItem>
                        }
                        <DropdownItem
                          className=" text-danger cursor-pointer"
                          onClick={() => {
                            setRow(row);
                            setSalesGoalDeleteModal(true);
                          }}
                        >
                          Delete
                        </DropdownItem>
                      </MoreDropdown>
                    </div>
                  )}
                >
                  Action
                </ReduxColumn>
              </ReduxTable>
            </div>
          </TabPane>
        )}
        {activeTab === Store.ADDRESS_TAB && (
          <TabPane tabId={Store.ADDRESS_TAB}>
            <AddressDetailTab
              AddressOpen={isAddressOpen}
              AddressSelectModal={addressOpenToggle}
              toggleOpen={toggleOpen}
              handleSubmit={(e) => handleAdressUpdate(e)}
              initialValue={addressInitialValues}
              history={history}
              object_id={storeId}
              objectName={ObjectName.LOCATION}
              EditModal={EditModal}
              id={addressDetails?.id}
              setIsDeleteModel={setIsAddressDeleteModel}
              setRow={setAddressDetails}
              setDeleteRow={setAddressDetails}
              handleCountryChange={handleCountryChange}
              selectedOption={selectedOption}
              selectedCountryName={selectedCountryName}
              newTableHeading
              hideQueryParams={{
                location: true,
              }}
            />
          </TabPane>
        )}

        {activeTab === Store.CONTACT_TAB && (
          <TabPane tabId={Store.CONTACT_TAB}>
            <ContactList
              objectName={ObjectName.LOCATION}
              objectId={storeId}
              history={history}
              isModalOpen={isContactModelOpen}
              setIsModalOpen={setIsContactModelOpen}
            />
          </TabPane>
        )}
        {activeTab === Store.SHOPIFY_TAB && (
          <TabPane tabId={Store.SHOPIFY_TAB}>
            <Form
              enableReinitialize={true}
              initialValues={{
                ...initialValues,
                name: productData?.data?.name,
                start_date: productData?.data?.start_date,
                end_date: productData?.data?.end_date,
                ip_address: productData?.data?.ip_address,
                allow_sale: productData?.data?.allow_sale,
                status: productData?.data?.status
                  ? statusOptions.find(
                    (data) => data.label === productData?.data?.status
                  )
                  : "",
                address1:
                  productData && productData.data && productData.data.address1,
                address2: productData?.data?.address2,
                state: {
                  label: productData?.data?.state,
                  value: productData?.data?.state,
                },
                country: {
                  label: productData?.data?.country,
                  value: productData?.data?.country,
                },
                pin_code: productData?.data?.pin_code || "",
                city: productData?.data?.city,
                shopify_shop_name: productData?.data?.shopifyStoreName,
                shopify_api_key: productData?.data?.shopifyApiKey,
                shopify_password: productData?.data?.shopifyPassword,
                shopify_admin_api_version:
                  productData?.data?.shopifyAdminApiVersion,
              }}
              onSubmit={(values) => {
                let id = props.match.params.tab;
                handleUpdate(id, values);
              }}
            >
              <div className="card bg-white mt-3 col-lg-12">
                <div className="card-body">
                  <div className=" field-wrapper row">
                    <Text
                      className="w-100"
                      name="shopify_shop_name"
                      label="Shopify Store Name"
                    />
                    <Text
                      className="w-100"
                      name="shopify_admin_api_version"
                      label="Shopify Admin API Version"
                    />
                    <Text
                      className="w-100"
                      name="shopify_api_key"
                      label="Shopify API Key"
                    />
                    <div className="w-100">
                      <Password
                        name="shopify_password"
                        label="Shopify Password"
                      />
                    </div>
                  </div>
                </div>
                <HorizontalSpace paddingleft="3" paddingtop="0">
                  <SaveButton label="Save" />
                  <CancelButton
                    onClick={() => {
                      history.push("/location");
                    }}
                  />
                </HorizontalSpace>
              </div>
            </Form>
          </TabPane>
        )}

        {/* Prouduct Tab */}
        {activeTab === Store.PRODUCT_TAB && (
          <TabPane tabId={Store.PRODUCT_TAB}>
            {activeTab === Store.PRODUCT_TAB && (
              <ProductTab
                storeId={props.match.params.tab}
                CurrentPage={CurrentPage}
                CurrentPageSize={CurrentPageSize}
                history={history}
              />
            )}
          </TabPane>
        )}
        {activeTab === Store.CUSTOMFIELDS_TAB && (
          <TabPane tabId={Store.CUSTOMFIELDS_TAB} className="w-100">
            <CustomForm
              objectName={ObjectName.LOCATION}
              objectId={props.match.params.tab}
              history={history}
              cancelButtonOnClick={() => {
                history.push("/location");
              }}
            />
          </TabPane>
        )}
        {activeTab === Store.TEAM_TAB && props.match.params.tab && (
          <TabPane tabId={Store.TEAM_TAB} className="w-100">
            <Team
              storeId={props.match.params.tab}
              setIsDeleteModel={setDeleteModal}
              history={history}
            />
          </TabPane>
        )}
        {showHistory && activeTab === Store.HISTORY_TAB && (
          <TabPane tabId={Store.HISTORY_TAB} className="w-100">
            <ActivityList
              id={props.match.params.tab}
              objectId={props.match.params.tab}
              object_name={ObjectName.LOCATION}
              history={props.history}
            />
          </TabPane>
        )}

        {activeTab === Store.SETTINGS_TAB && (
          <TabPane tabId={Store.SETTINGS_TAB}>
            <SettingsTab
              storeId={storeId}
              history={history}
              productData={productData}
            />
          </TabPane>
        )}
        {activeTab === Store.ATTENDANCE_TAB && (
          <TabPane tabId={Store.ATTENDANCE_TAB}>
            <AttendanceList
              showStoreFilter={false}
              showSearch
              showPageSize={false}
              sortByDropdown
              refreshButton
              history={history}
              bulkSelect={false}
              hideQueryParams={{
                location: true,
              }}
              showActionColumn={false}
              userView
            />
          </TabPane>
        )}
        {activeTab === Store.RACK_TAB && (
          <TabPane tabId={Store.RACK_TAB}>
            <LocationRackTab
              history={history}
              setIsDrawerModelOpen={setIsDrawerModelOpen}
              isDrawerModelOpen={isDrawerModelOpen}
              locationId={props?.match?.params?.tab}
            />
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

function mapStateToProps(state) {
  const reduxTable = state.table;

  const CurrentPage =
    reduxTable["storeProduct"] && !reduxTable["storeProduct"].isFetching
      ? reduxTable["storeProduct"].currentPage
      : 1;

  const CurrentPageSize =
    reduxTable["storeProduct"] && !reduxTable["storeProduct"].isFetching
      ? reduxTable["storeProduct"].pageSize
      : 25;

  return {
    CurrentPage,
    CurrentPageSize,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(StoreDetail);
