import React from "react";

// Components
import Currency from "../../../components/Currency.js";
import DateSelector from "../../../components/Date";
import Text from "../../../components/Text";
import TextArea from "../../../components/TextArea";

// Helpers && Api
import AccountSelect from "../../../components/AccountSelect";
import MediaCarousel from "../../../components/MediaCarousel";
import Notes from "../../../components/Notes.js";
import SelectStore from "../../../components/SelectStore";
import UserSelect from "../../../components/UserSelect.js";
import Account from "../../../helpers/Account.js";
import ObjectName from "../../../helpers/ObjectName";
import Permission from "../../../helpers/Permission.js";
import { hasPermission } from "../../../services/UserRolePermissionService.js";

const purchaseForm = (props) => {
  let { rowValue, isEditPage } = props;
  let enableDueDateEdit = hasPermission(Permission.PURCHASE_ALLOW_TO_CHANGE_DUE_DATE);
  let enableOwnerEdit = hasPermission(Permission.PURCHASE_ALLOW_TO_CHANGE_OWNER);

  // Props
  const {
    className,
    history,
    onVendorChange,
    onDescriptionChange,
    onStoreChange,
    onPurchaseNumberChange,
    id,
    vendorData,
    handleNotesChange,
    showNotes,
    onVendorInvoiceDateChange,
    onDueDateChange,
    owner_id,
    handleInvoiceAmount,
    handleReturnedAmount,
    handlePurchaseChange,
    handleUserChange,
    editable,
    showAccountDetailsPageLink,
    accountId,
    showUserDetailsPageLink,
    userId,
    userList,
    handleReviewerChange,
    handleImageRemove,
    onDropImage,
    imageurl,
    reviewerId,
    setLocationList,
    locationId,
    purchaseData,
    showReviewerDetailsPageLink,
    showOwnerDetailsPageLink
  } = props;
  return (
    <>
      {/* Form */}
      <div className="row">
        <div className={`${className ? className : "col-sm-12"} "card-body"`}>
          <div className="row d-flex">
            <div className="col">
              <DateSelector
                label="Purchase Date"
                required={true}
                disabled={editable}
                name="date"
                onChange={handlePurchaseChange}
                dateOnly
              />
            </div>
          </div>
          <AccountSelect
            name="vendor_name"
            handleVendorChange={onVendorChange}
            label="Vendor"
            required
            isDisabled={editable}
            showAccountDetailsPageLink={showAccountDetailsPageLink}
            accountId={accountId}
            accountCategory={Account.CATEGORY_VENDOR}
          />
          <div className="row">
            <div className="col-lg-6 col-sm-6 col-md">
              <Text
                label="Vendor Invoice Number"
                name="vendor_invoice_number"
                onChange={onPurchaseNumberChange}
                disabled={editable}
                required
              />
            </div>
            <div className="col-lg-6 col-sm-6 col-md-6">
              <DateSelector
                label="Vendor Invoice Date"
                name="vendor_invoice_date"
                onChange={onVendorInvoiceDateChange}
                isClearable={!editable ? true : false}
                disabled={editable}
                dateOnly
              />
            </div>
          </div>
          <Currency
            name="invoice_amount"
            label="Invoice Amount"
            onChange={handleInvoiceAmount}
            disabled={editable}
            required
          />
          <Currency
            label="Rejected Items Amount "
            name="returnedItemAmount"
            onChange={handleReturnedAmount}
            disabled={editable}
          />

          <Currency
            label="Net Amount"
            name="net_amount"
            required={true}
            disabled={true}
          />
          <div className="row">
            <div className="col-lg-6 col-sm-6 col-md-6">
              <SelectStore
                name="location"
                label="Location"
                handleStoreChange={onStoreChange}
                required
                isDisabled={editable}
                StoreList={setLocationList}
                defaultValue={locationId}
              />
            </div>
            <div className="col-lg-6 col-sm-6 col-md-6">
              <UserSelect
                name="reviewer"
                label="Reviewer"
                handleUserChange={handleReviewerChange}
                isDisabled={editable}
                showUserDetailsPageLink={showReviewerDetailsPageLink}
                userList={userList}
                userId={reviewerId ? reviewerId : purchaseData?.reviewer_id}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-sm-6 col-md-6">
              <UserSelect
                name="owner"
                label="Owner"
                selectedUserId={owner_id ? owner_id : null}
                showLoggedInUser={owner_id ? false : true}
                handleUserChange={handleUserChange}
                isDisabled={isEditPage ? (editable ? true : !enableOwnerEdit) : rowValue ? !enableOwnerEdit : false}
                showUserDetailsPageLink={showOwnerDetailsPageLink}
                userId={userId}
                userList={userList}
              />
            </div>
            <div className="col-lg-6 col-sm-6 col-md-6">
              <DateSelector
                label="Due Date"
                name="due_date"
                onChange={onDueDateChange}
                isClearable={isEditPage ? (editable ? false : enableDueDateEdit) : rowValue ? enableDueDateEdit : false}
                disabled={isEditPage ? (editable ? true : !enableDueDateEdit) : rowValue ? !enableDueDateEdit : false}
                dateOnly
              />
            </div>
          </div>
          <TextArea
            name="description"
            label="Description"
            onChange={onDescriptionChange}
            placeholder="Enter Description..."
            disabled={editable}
          />
          {showNotes && (
            <Notes
              name="notes"
              label="Notes"
              disabled={editable}
              onChange={handleNotesChange}
            />
          )}
        </div>

        {(!rowValue && !vendorData) && (
          <div className="col-12">
            <MediaCarousel
              showCarasoul
              Attachments
              onDropImage={onDropImage}
              imageUrl={imageurl}
              handleImageRemove={handleImageRemove}
            />
          </div>
        )}

        {(vendorData?.vendor_name || rowValue) && (
          <div className={rowValue ? "col" : "col-lg-5 mb-4 pb-5 pb-sm-0 mb-sm-0"}>
            <MediaCarousel
              showCarasoul
              objectName={ObjectName.PURCHASE}
              objectId={rowValue ? rowValue?.id : vendorData?.data?.id}
              history={history}
              attachmentsList={true}
              Attachments={"Attachments"}
              editable={editable}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default purchaseForm;
