import React, { useEffect, useState } from "react";
import ShiftService from "../services/ShiftService";
import Select from "./Select";

const ShiftSelect = (props) => {
  let { name, handleShiftChange, shiftOption, label,isMulti, required, defaultValue=null,isDisabled } = props;
  const [shiftList, setShiftList] = useState([]);

  useEffect(() => {
    if(shiftOption){
      getShifts()
    }
  }, [defaultValue])
  

  const getShifts = async () => {
    let params ={
      ...(defaultValue? {defaultValue: defaultValue} :{})
    }
      const list = await ShiftService.list(params);
      if (list?.data?.data.length > 0) {
        setShiftList(list?.data?.data);
        shiftOption && shiftOption(list?.data?.data)
      }
  };

  return (
    <>
      <Select
        name={name ? name : "shift"}
        placeholder="Select Shift"
        options={shiftList}
        handleChange={handleShiftChange}
        label={label ? label : ""}
        autoFocus={getShifts}
        isMulti={isMulti}
        required={required}
        isDisabled={isDisabled}
      />
    </>
  );
};

export default ShiftSelect;
