import React, { useState } from "react";
import Hint from "../../components/Hint";
import "../../scss/_custom.scss";

const PortalLogo = (props) => {
  const {
    portalLogoWithBaseUrl,
    faviconWithBaseUrl,
    loginBackgroundImageWithBaseUrl,
    onPortalLogoChange,
    portalLogoRef,
    onPortalLogoRemove,
    onPortalFavIconChange,
    portalFavIconRef,
    handlePortalFavIconRemove,
    onLoginBackgroundImageChange,
    loginBackgroundImageRef,
    onLoginBackgroundImageRemove,
  } = props;

  const [isExpanded, setIsExpanded] = useState(true);

  const portalLogoColor = isExpanded ? "#ECF5FF" : "#FFFFFF";

  return (
    <>
      <div className="accordion mt-3" id="accordionPortalLogo">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingPortalLogo">
            <button
              className={`accordion-button ${isExpanded ? "" : "collapsed"}`}
              style={{ backgroundColor: portalLogoColor }}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapsePortalLogo"
              aria-expanded={isExpanded ? "true" : "false"}
              aria-controls="collapsePortalLogo"
              onClick={() => setIsExpanded(!isExpanded)}
            >
              <h5 className="text-primary">App Logo</h5>
            </button>
          </h2>
          <div
            id="collapsePortalLogo"
            className={`accordion-collapse collapse ${isExpanded ? "show" : ""}`}
            aria-labelledby="headingPortalLogo"
            data-bs-parent="#accordionPortalLogo"
          >
            <div className="accordion-body p-3">
              {/* App Logo upload */}
              <div className="field-wrapper d-flex mt-3">
                <div>
                  <div className="d-inline-block">
                    <input
                      name="file"
                      className="form-control d-none"
                      type="file"
                      id="portalLogo"
                      placeholder="Banner Image"
                      accept="image/png,image/gif,image/jpeg"
                      onChange={(e) => {
                        onPortalLogoChange(e);
                      }}
                      ref={portalLogoRef}
                    />
                    <span className="d-block">
                      <label htmlFor="portalLogo" className="profile-img-sm mb-0">
                        <span className="image-upload-text-decoration-none cursor-pointer text-primary">
                          Upload App Logo
                        </span>
                      </label>
                    </span>
                  </div>
                  {/* Remove image */}
                  {portalLogoWithBaseUrl && (
                    <span
                      className="image-upload-text-decoration-none cursor-pointer text-primary text-danger ms-md-1"
                      onClick={onPortalLogoRemove}
                    >
                      (Remove)
                    </span>
                  )}
                  <div className="field-wrapper">
                    <small className={`text-danger mt-3 ${"d-none"}`}>
                      Please select an image file
                    </small>
                    <Hint
                      id="bannerRequirements"
                      hintText="We recommend using a 400 x 600 image"
                    />
                  </div>
                </div>
                <div className="ms-auto">
                  {portalLogoWithBaseUrl && (
                    <img src={portalLogoWithBaseUrl} className="portal-logo" alt="App Logo" />
                  )}
                </div>
              </div>

              {/* App Favicon upload */}
              <h5 className="">App Favicon</h5>
              <div className="field-wrapper d-flex">
                <div>
                  <div className="d-inline-block">
                    <input
                      name="file"
                      className="form-control d-none"
                      type="file"
                      id="portalFavIconImage"
                      placeholder="Favicon"
                      onChange={(e) => {
                        onPortalFavIconChange(e);
                      }}
                      accept="image/png,image/gif,image/jpeg"
                      ref={portalFavIconRef}
                    />
                    <span className="d-block">
                      <label
                        htmlFor="portalFavIconImage"
                        className="profile-img-sm mb-0"
                      >
                        <span className="text-decoration-none cursor-pointer text-primary">
                          Upload App Favicon
                        </span>
                      </label>
                    </span>
                  </div>
                  {/* Remove image */}
                  {faviconWithBaseUrl && (
                    <span
                      className="text-decoration-none cursor-pointer text-primary text-danger ms-md-1"
                      onClick={handlePortalFavIconRemove}
                    >
                      (Remove)
                    </span>
                  )}
                  <div className="field-wrapper">
                    <small className={`text-danger mt-3 ${"d-none"}`}>
                      Please select an image file
                    </small>
                    <Hint
                      id="faviconRequirements"
                      hintText="We recommend using a 32 x 32 image"
                    />
                  </div>
                </div>
                <div className="ms-auto">
                  {faviconWithBaseUrl && (
                    <img src={faviconWithBaseUrl} className="portal-logo" alt="App Favicon" />
                  )}
                </div>
              </div>
              {/* Background image upload */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortalLogo;
