import { endpoints } from "../api/endPoints";
import apiClient from "../apiClient";
import Toast from "../components/Toast";
import ArrayList from "../lib/ArrayList";
import { isBadRequest } from "../lib/Http";
import Url from "../lib/Url";

class DailySummaryReportService {
  static async getReport(params) {
    try {
      const queryString = await ArrayList.toQueryString(params);

      const response = await Url.get(`${endpoints().DailySummaryReportAPI}`, queryString);

      // Log the response only once
      if (response && response.data) {
        return response.data;
      } else {
        throw new Error("No data found in response");
      }

    } catch (err) {
      // Log the full error object for better context
      console.error("DailySummaryReportService error: ", err);

      // Handle specific bad request error
      if (isBadRequest(err)) {
        let errorMessage = "An unknown error occurred";
        const errorRequest = err.response?.request;

        if (errorRequest && errorRequest.response) {
          try {
            errorMessage = JSON.parse(errorRequest.response).message || errorMessage;
          } catch (parseError) {
            console.error("Error parsing response: ", parseError);
          }
        }

        Toast.error(errorMessage);
      }
    }
  }
}

export default DailySummaryReportService;
