import React from 'react'
import Text from '../../../components/Text';
import Select from '../../../components/Select';
import { statusOptions } from '../../../helpers/Lead';

const LeadTypeForm = (props) => {
    return (
        <>
            <Text
                name="name"
                placeholder="Enter Name"
                label="Name"
                required
            />
            <Select
                name="status"
                label="Status"
                placeholder="Select Status"
                options={statusOptions}
                required
            />
        </>
    );
}

export default LeadTypeForm;
