import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Components
import Form from "../../components/Form";
import Text from "../../components/Text";
import Email from "../../components/Email";
import Phone from "../../components/Phone";
import SaveButton from "../../components/SaveButton";
import PageTitle from "../../components/PageTitle";
import DeleteModal from "../../components/DeleteModal";
import BreadCrumb from "../../components/Breadcrumb";
import Action from "../../components/Action";
import HorizontalSpace from "../../components/HorizontalSpace";
import CancelButton from "../../components/CancelButton";

// Services
import ContactService from "../../services/ContactService";
import { hasPermission } from "../../services/UserRolePermissionService";

// Helpers
import Permission from "../../helpers/Permission";
import { Nav, NavItem, NavLink, TabPane } from "reactstrap";
import classNames from "classnames";
import ActivityList from "../../components/ActivityList";
import ObjectName from "../../helpers/ObjectName";

export const ContactsTab = {
  SUMMARY: "Summary",
  HISTORY: "History",
};

const contactDetailPage = (props) => {
  let { history } = props;
  const [detail, setDetail] = useState({});
  const [isContactDeleteModel, setDeleteContactModal] = useState(false);
  const [editable, setEditable] = useState(true);
  const [activeTab, setActiveTab] = useState(ContactsTab.SUMMARY);

  let showEditButton = hasPermission(Permission.CONTACT_EDIT);
  let showHistory = hasPermission(Permission.CONTACT_HISTORY_VIEW);


  let dispatch = useDispatch();
  useEffect(() => {
    getContactDetail();
  }, []);

  const toggle = (tab) => {
    setActiveTab(tab);
  };

  const _handleTabChange = (tab) => {
    props.history.push(`?tab=${tab}`);
  };

  const actionsMenuList = [
    {
      value: "Delete",
      label: "Delete",
    },
  ];

  if (showEditButton && editable) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  let getContactDetail = async () => {
    let { data } = await ContactService.get(props?.match?.params?.id);
    setDetail(data);
  };

  const handleActionChange = (e) => {
    if (e == "edit") {
      setEditable(false);
    }
    if (e === "Delete") {
      setDeleteContactModal(true);
    }
  };

  const initialValue = {
    first_name: detail?.first_name ? detail?.first_name : "",
    last_name: detail?.last_name ? detail?.last_name : "",
    email: detail?.email ? detail?.email : "",
    mobile: detail?.mobile ? detail?.mobile : "",
    work_phone: detail?.work_phone ? detail?.work_phone : "",
    designation: detail?.designation ? detail?.designation : "",
  };

  const handleContact = async (values) => {
    const updateData = new FormData();
    setEditable(true);
    updateData.append("first_name", values.first_name ? values.first_name : "");
    updateData.append("last_name", values.last_name ? values.last_name : "");
    updateData.append("email", values.email ? values.email : "");
    updateData.append("mobile", values.mobile ? values.mobile : "");
    updateData.append(
      "designation",
      values.designation ? values.designation : ""
    );
    updateData.append("work_phone", values.work_phone ? values.work_phone : "");

    updateData.append("id", props?.match?.params?.id);

    dispatch(
      await ContactService.update(
        props?.match?.params?.id,
        updateData,
        (res) => {
          getContactDetail();
        }
      )
    );
    setEditable(false);
  };

  const closeDeleteModal = () => {
    setDeleteContactModal(false);
  };

  const handleContactDelete = async () => {
    dispatch(
      await ContactService.del(props?.match?.params?.id, () => {
        history.goBack();
      })
    );
  };

  const breadcrumbList = [
    { label: "Home", link: "people/dashboard" },
    { label: "Contacts", link: "/contacts" },
    { label: "Contact Detail", link: "" },
  ];

  return (
    <div>
      <BreadCrumb list={breadcrumbList} />
      <DeleteModal
        isOpen={isContactDeleteModel}
        title="Delete Contact"
        label={`${detail?.first_name} ${detail?.last_name}`}
        toggle={closeDeleteModal}
        deleteFunction={handleContactDelete}
      />
      <div className="d-flex justify-content-between mb-3">
        <PageTitle label="Contact Detail Page" />
        <div className="d-flex">
          <Action
            dropdownLinks={actionsMenuList}
            handleChange={handleActionChange}
          />
        </div>
      </div>
      <Nav tabs className="admin-tabs mt-2">
        {/* General Tab */}
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === ContactsTab.SUMMARY,
            })}
            onClick={() => {
              toggle(ContactsTab.SUMMARY);
              _handleTabChange(ContactsTab.SUMMARY);
            }}
          >
            {ContactsTab.SUMMARY}
          </NavLink>
        </NavItem>
        {showHistory && (
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === ContactsTab.HISTORY,
              })}
              onClick={() => {
                toggle(ContactsTab.HISTORY);
                _handleTabChange(ContactsTab.HISTORY);
              }}
            >
              {ContactsTab.HISTORY}
            </NavLink>
          </NavItem>
        )}
      </Nav>
      {activeTab === ContactsTab.SUMMARY && (
        <TabPane>
          <div className="card card-body">
            <Form
              initialValues={initialValue}
              enableReinitialize
              onSubmit={handleContact}
            >
              <Text
                label="First Name"
                name="first_name"
                className="fw-bold-text"
                placeholder="Enter First Name"
                required
                error=""
                disabled={editable}
              />
              <Text
                label="Last Name"
                name="last_name"
                className="fw-bold-text"
                placeholder="Enter Last Name"
                error=""
                disabled={editable}
              />
              <Email
                label="Email Address"
                name="email"
                placeholder="Enter Email Address"
                notify="error"
                disabled={editable}
              />
              <Phone
                label="Mobile Number"
                name="mobile"
                placeholder="Enter Mobile Number"
                error=""
                disabled={editable}
              />
              <Phone
                label="Work Phone"
                name="work_phone"
                placeholder="Enter Work Number"
                error=""
                disabled={editable}
              />
              <Text
                label="Designation"
                name="designation"
                placeholder="Enter Designation"
                disabled={editable}
              />
              <div>
                <HorizontalSpace bottom="2">
                  {!editable && (
                    <>
                      <SaveButton />
                      <CancelButton
                        onClick={() => {
                          history.push("/contacts");
                        }}
                      />
                    </>
                  )}
                </HorizontalSpace>
              </div>
            </Form>
          </div>
        </TabPane>
      )}
      {activeTab === ContactsTab.HISTORY && (
        <ActivityList
          id={props?.match?.params?.id}
          objectId={props?.match?.params?.id}
          object_name={ObjectName.CONTACT}
          history={history}
        />
      )}
    </div>
  );
};

export default contactDetailPage;
