import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

//components
import CancelButton from "../../components/CancelButton";
import Form from "../../components/Form";
import HorizontalSpace from "../../components/HorizontalSpace";
import SaveButton from "../../components/SaveButton";
import Text from "../../components/Text";
import DeleteModal from "../../components/DeleteModal";
import Action from "../../components/Action";
import BreadCrumb from "../../components/Breadcrumb";
import DateSelector from "../../components/Date";
import PageTitle from "../../components/PageTitle";

// API
import { endpoints } from "../../api/endPoints";

// Config
import { apiClient } from "../../apiClient";

// Helpers
import Customer from "../../helpers/Customer";

// Lib
import { isBadRequest } from "../../lib/Http";
import DateTime from "../../lib/DateTime";

// Services
import HolidayService from "../../services/HolidayService";
import Holiday from "../../helpers/Holiday";
import Select from "../../components/Select";
import { Nav, NavItem, NavLink } from "reactstrap";
import classNames from "classnames";
import ActivityList from "../../components/ActivityList";
import ObjectName from "../../helpers/ObjectName";
import Url from "../../lib/Url";

const Tab = {
  SUMMARY: "Summary",
  HISTORY: "History",
};

const HolidayDetail = (props) => {
  const { match, history } = props;
  const [holidayDetails, setHolidayDetails] = useState();
  const [holidayDate, setHolidayDate] = useState();
  const [holidayName, setHolidayName] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [editable, setEditable] = useState(false);
  const [type, setType] = useState("");
  const [activeTab, setActiveTab] = useState(
    Url.GetParam("tab") ? Url.GetParam("tab") : Tab.SUMMARY
  );
  const holidayId = props.match.params.id;

  let dispatch = useDispatch();

  useEffect(() => {
    getHolidayDetails(match.params.id);
  }, [props]);

  //get the holiday details
  const getHolidayDetails = () => {
    let id = props.match.params.id;

    try {
      return apiClient
        .get(`${endpoints().holidayAPI}/${id}`)
        .then((response) => {
          const data = response?.data?.data;
          setHolidayDetails(data);
        })
        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    } catch (error) {
      console.log(err);
    }
  };

  //Handle Update userRole Details
  const handleUpdate = async (id, values) => {
    const data = new FormData();
    data.append("id", id);
    data.append("name", values && values.name ? values.name : "");
    data.append("date", values && values.date ? values.date : "");
    data.append("type", values && values.type.value ? values.type.value : "");
    try {
      await HolidayService.update(id, data, () => {
        getHolidayDetails();
        setEditable(false);
      });
    } catch (error) {
      console.error("Error updating holiday:", error);
    }
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    props.history.push(`?tab=${tab}`);
  };

  const handleHolidayDelete = async (value) => {
    dispatch(
      await HolidayService.delete(
        { data: { id: props?.match?.params?.id } },
        (res) => {
          props.history.push("/setting/Holiday");
        }
      )
    );
  };

  const breadcrumbList = [
    { label: "Settings", link: "/setting/Holiday" },
    {
      label: "Holiday",
      link: "/setting/Holiday",
    },
    {
      label: Customer.GetDisplayName(holidayDetails?.name),
      link: "",
    },
  ];

  const holidayTypeOptions = [
    {
      value: Holiday.COMPULSORY_VALUE,
      label: Holiday.COMPULSORY_TEXT,
    },
    {
      value: Holiday.OPTIONAL_VALUE,
      label: Holiday.OPTIONAL_TEXT,
    },
  ];

  // Initial values
  const initialValues = {
    name: holidayName ? holidayName : holidayDetails?.name,
    date: holidayDate ? holidayDate : holidayDetails?.date,
    type: type
      ? holidayTypeOptions.find((data) => data.value === type)
      : holidayTypeOptions.find((data) => data.value === holidayDetails?.type),
  };

  const actionsMenuList = [
    {
      value: "Delete",
      label: "Delete",
    },
  ];

  if (!editable) {
    actionsMenuList.unshift({
      value: "Edit",
      label: "Edit",
    });
  }

  const handleActionChange = (e) => {
    if (e == "Delete") {
      setDeleteModal(true);
    }

    if (e == "Edit") {
      setEditable(true);
    }
  };

  const holidayNameChange = (e) => {
    let value = e.target.value;
    setHolidayName(value);
  };

  const handleDate = async (date) => {
    const value = date ? DateTime.toISOStringDate(date) : "";
    setHolidayDate(value);
  };

  const handleTypeChange = (e) => {
    setType(e?.value ? e.value : "");
  };

  return (
    <>
      <DeleteModal
        id={holidayId}
        label={holidayDetails?.name}
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Holiday"
        deleteFunction={handleHolidayDelete}
      />
      <div>
        <BreadCrumb list={breadcrumbList} />
        <div className="d-flex justify-content-between mb-3">
          <PageTitle label={holidayDetails?.name} />

          <div className="ps-2">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        </div>

        <Nav tabs className="admin-tabs">
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.SUMMARY,
              })}
              onClick={() => handleTabChange(Tab.SUMMARY)}
            >
              {Tab.SUMMARY}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.HISTORY,
              })}
              onClick={() => handleTabChange(Tab.HISTORY)}
            >
              {Tab.HISTORY}
            </NavLink>
          </NavItem>
        </Nav>

        {activeTab === Tab.SUMMARY && (
          <div className="card card-body">
            <Form
              enableReinitialize={true}
              initialValues={initialValues}
              onSubmit={(values) => {
                let id = props.match.params.id;
                handleUpdate(id, values);
              }}
            >
              <div className="card bg-white">
                <div className="card-body">
                  <Text
                    name="name"
                    label="Name"
                    onChange={holidayNameChange}
                    required
                    disabled={!editable}
                  />
                  <Select
                    fullWidth={true}
                    label="Type"
                    name="type"
                    isClearable
                    options={holidayTypeOptions}
                    handleChange={handleTypeChange}
                    required
                    isDisabled={!editable}
                  />
                  <DateSelector
                    name="date"
                    label="Date"
                    onChange={handleDate}
                    required
                    isClearable={editable}
                    disabled={!editable}
                  />
                  <div>
                    <HorizontalSpace bottom="3">
                      {editable && <SaveButton label="Save" />}
                      <CancelButton
                        onClick={() => {
                          history.push({ pathname: "/setting/Holiday" });
                        }}
                      />
                    </HorizontalSpace>
                  </div>
                </div>
              </div>
            </Form>
          </div>
        )}

        {activeTab === Tab.HISTORY && (
          <ActivityList
            id={holidayId}
            objectId={holidayId}
            object_name={ObjectName.HOLIDAY}
            history={history}
          />
        )}
      </div>
    </>
  );
};

export default HolidayDetail;
