import { useEffect, useState } from "react";
import Form from "../../../components/Form";
import Number from "../../../components/Number";
import SaveButton from "../../../components/SaveButton";
import Select from "../../../components/Select";
import Text from "../../../components/Text";
import { Projects } from "../../../helpers/Project";
import {
  default as NumberInput,
  default as Numbers,
} from "../../../lib/Number";
import String from "../../../lib/String";
import ProjectService from "../../../services/ProjectService";
import SlackService from "../../../services/SlackService";
import Url from "../../../lib/Url";
import CancelButton from "../../../components/CancelButton";

const General = (props) => {
  const [channelList, setChannelList] = useState([]);

  const { projectData } = props;

  useEffect(() => {
    slackChannelList();
  }, []);

  const slackChannelList = async () => {
    const response = await SlackService.getChannelList();

    let channelList = response && response.channels;

    let ChannelList = [];
    if (channelList && channelList.length > 0) {
      for (let i in channelList) {
        let { id, name } = channelList[i];
        ChannelList.push({
          label: name,
          value: id,
          id: id,
        });
      }
    }
    setChannelList(ChannelList);
  };

  const allowManualTicketOptions = [
    {
      label: "Yes",
      value: "Yes",
    },
    {
      label: "No",
      value: "No",
    },
  ];

  const updateData = (values) => {
    const id = Url.GetParam("projectId")
      ? Url.GetParam("projectId")
      : props.match.params.id;
    const data = new FormData();
    data.append("name", String.Get(values.name));
    data.append("slug", String.Get(values.slug));
    data.append("code", String.Get(values.code));
    data.append("status", values?.status?.value ? values?.status?.value : "");
    data.append("sort", NumberInput.Get(values.sort));
    data.append("allowmanualticket", values?.allowmanualticket?.value);
    data.append("last_ticket_number", Numbers.Get(values.last_ticket_number));
    data.append(
      "slack_channel_id",
      values && values?.slack_channel_id ? values?.slack_channel_id?.id : ""
    );
    data.append(
      "slack_channel_name",
      values && values?.slack_channel_id ? values?.slack_channel_id?.label : ""
    );

    ProjectService.updateProject(id, data);
    props.setEditable(true);
    // history.push("/projetct");/
  };

  const statusOption = [
    {
      label: "Active",
      value: 1,
    },
    {
      label: "InActive",
      value: 2,
    },
  ];

  return (
    <div className="mb-4 pb-4 mb-sm-2 pb-sm-2">
      <div className="card p-3">
        <div className="field-wrapper mb-0 form-wrapper">
          <Form
            initialValues={{
              name: projectData?.projectData?.name,
              code: projectData?.projectData?.code,
              slug: projectData?.projectData?.slug,
              sort: projectData?.projectData?.sort,
              status: statusOption.find(
                (data) => data?.value == projectData?.projectData?.status
              ),
              allowmanualticket: {
                value: projectData?.projectData?.allow_manual_id,
                label: projectData?.projectData?.allow_manual_id,
              },
              last_ticket_number: projectData?.projectData?.last_ticket_number,
              slack_channel_id: channelList.find(
                (data) => data?.id == projectData?.slack_channel_id
              ),
            }}
            enableReinitialize={true}
            onSubmit={(values) => {
              updateData(values);
            }}
          >
            <Text
              name="name"
              label="Name"
              placeholder="Enter Name"
              disabled={props?.editable}
            />
            <Text
              name="code"
              label="Code"
              placeholder="Code"
              fontBolded
              disabled={props?.editable}
            />

            <Text
              name="slug"
              label="Slug"
              placeholder="slug"
              fontBolded
              disabled={props?.editable}
            />

            <Select
              name="status"
              label="Status"
              options={statusOption}
              isDisabled={props?.editable}
            />

            <Number
              label="Sort"
              name="sort"
              fontBolded
              disabled={props?.editable}
            />

            <Select
              name="allowmanualticket"
              label="Allow Manual Ticket #"
              options={allowManualTicketOptions}
              fontBolded
              isDisabled={props?.editable}
            />

            <Number
              name="last_ticket_number"
              label="Last Ticket #"
              fontBolded
              disabled={props.editable}
            />

            <Select
              name="slack_channel_id"
              label="Slack Channel"
              options={channelList}
              isDisabled={props.editable}
            />

            {!props?.editable && (
              <div className="mt-2">
                <SaveButton type="submit" label="Save" />
                <CancelButton
                  onClick={() => {
                    props.history.push({ pathname: "/project" });
                  }}
                />
              </div>
            )}
          </Form>
        </div>
      </div>
    </div>
  );
};

export default General;
