import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";
import AddButton from "../../components/AddButton";
import PageTitle from "../../components/PageTitle";
import DragAndDropTable from "../../components/StatusTable/StatusDragAndDropTable";
import ObjectName from "../../helpers/ObjectName";
import { Tabs } from "../../helpers/Setting";
import Url from "../../lib/Url";

const CandidateStatus = (props) => {
  let { history } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(
    Url.GetParam("tab") ? Url.GetParam("tab") : Tabs.STATUS
  );
  const [isTypeModelOpen, setIsTypeModelOpen] = useState(false);
  const [row, setRow] = useState();
  const _toggle = () => {
    setIsOpen(!isOpen);
  };

  const _toggles = () => {
    setIsTypeModelOpen(!isTypeModelOpen);
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    props.history.push(`?tab=${tab}`);
  };

  const handleAddButtonClick = () => {
    _toggle();
    setRow("");
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <PageTitle label="Candidate" />
        {activeTab === Tabs.STATUS && (
          <AddButton
            className="ms-2 me-1"
            label="Add Status"
            onClick={handleAddButtonClick}
          />
        )}
      </div>
      <Nav tabs className="admin-tabs mb-3">
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tabs.STATUS,
            })}
            onClick={() => {
              handleTabClick(Tabs.STATUS);
            }}
          >
            {Tabs.STATUS}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        {activeTab === Tabs.STATUS && (
          <TabPane tabId={Tabs.STATUS}>
            <DragAndDropTable
              history={history}
              objectName={ObjectName.CANDIDATE}
              showUrl
              _toggle={_toggle}
              isOpen={isOpen}
              row={row}
              setRow={setRow}
            />
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

export default CandidateStatus;
