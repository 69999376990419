import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Button, DropdownItem } from "reactstrap";
import Text from "../../components/Text";
import DeleteButton from "../../components/DeleteButton";
import PageTitle from "../../components/PageTitle";
import { connect } from "react-redux";
import { fetchList } from "../../actions/table";

// Components
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";

//Config
import { endpoints } from "../../api/endPoints";

// Action
import AddModal from "../../components/Modal";
import DeleteModal from "../../components/DeleteModal";
import { sprint } from "../../helpers/Sprint";
import DateSelector from "../../components/Date";
import DateTime from "../../lib/DateTime";
import Link from "../../components/Link";
import ObjectName from "../../helpers/ObjectName";

// Services
import SprintService from "../../services/SprintService";
import StatusService from "../../services/StatusService";
import Url from "../../lib/Url";
import { bindActionCreators } from "redux";
import Drawer from "../../components/Drawer";
import MoreDropdown from "../../components/authentication/moreDropdown";
import SaveButton from "../../components/SaveButton";

const Sprint = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [sprintName, setSprintName] = useState();
  const [rowValue, setRowValue] = useState("");

  const sortByOption = [
    {
      value: "name:ASC",
      label: "Name",
    },
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  const dispatch = useDispatch();

  const toggle = () => {
    setIsOpen(!isOpen);
    setCurrentData("");
    setSprintName("");
    setEndDate("");
    setRowValue("");
  };

  /**
   * Create Creation
   *
   * @param data
   */
  const sprintCreate = async (data) => {
    data.name = data?.name;
    data.start_time = data?.start_time;
    data.end_time = data?.end_time;
    let id = rowValue?.id;

    let params = {
      search: Url.GetParam("search"),
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
    };

    if (rowValue && rowValue?.id) {
      dispatch(
        await SprintService.update(id, data, (res) => {
          dispatch(
            fetchList(
              "sprint",
              `${endpoints().sprintAPI}/search`,
              1,
              25,
              params
            )
          );
          toggle();
          setRowValue("");
        })
      );
    } else {
      dispatch(await SprintService.create(data, params));
      toggle();
    }
  };

  const startDateChange = (e) => {
    setStartDate(e);
  };

  const endDateChange = (e) => {
    setEndDate(e);
  };

  const handleOnChange = (e) => {
    let value = e.target.value;
    setSprintName(value);
  };

  /**
   * Update Creation
   *
   * @param data
   */

  const sprintDelete = async () => {
    let params = {
      search: Url.GetParam("search"),
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
    };
    dispatch(
      await SprintService.delete(rowValue?.id, () => {
        dispatch(
          fetchList("sprint", `${endpoints().sprintAPI}/search`, 1, 25, params)
        );
        setDeleteModal(false);
        setRowValue("");
      })
    );
  };

  const addSprintForm = (
    <>
      <Text
        name="name"
        label="Sprint Name"
        placeholder="Enter Sprint Name..."
        error=""
        fontBolded
        onChange={handleOnChange}
        required={true}
      />
      <div className="row">
        <div className="col-12 col-sm-6">
          <DateSelector
            label="Start Date"
            name="start_date"
            format="dd-MMM-yyyy"
            required
            onChange={startDateChange}
          />
        </div>
        <div className="col-12 col-sm-6">
          <DateSelector
            label="End Date"
            name="end_date"
            format="dd-MMM-yyyy"
            required
            onChange={endDateChange}
          />
        </div>
      </div>
    </>
  );

  const sprintFooter = (
    <SaveButton
      type="submit"
      label={rowValue?.id ? "Save" : "Add"}
    />
  );

  const initialValues = {
    name: rowValue?.name ? rowValue?.name : sprintName,
    start_date: rowValue?.start_date
      ? rowValue?.start_date
      : startDate
      ? startDate
      : new Date(),
    end_date: rowValue?.end_date ? rowValue?.end_date : endDate ? endDate : "",
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Sprint"
        label={rowValue?.name}
        deleteFunction={sprintDelete}
      />
      <Drawer
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggle}
        isModalOpen={isOpen}
        enableReinitialize
        initialValues={initialValues}
        DrawerBody={addSprintForm}
        DrawerFooter={sprintFooter}
        modelTitle={rowValue?.id ? "Edit Sprint " : "Add New Sprint"}
        onSubmit={(values) => {
          sprintCreate(values);
        }}
      />

      <PageTitle
        label="Sprints"
        buttonHandler={(e) => {
          toggle();
        }}
        buttonLabel="Add New"
        className={"pt-3"}
      />
      <div className="mt-4">
        <ReduxTable
          id="sprint"
          showHeader
          searchPlaceholder="Search"
          apiURL={`${endpoints().sprintAPI}/search`}
          newTableHeading
          history={props.history}
          paramsToUrl={true}
          sortByOptions={sortByOption}
        >
          <ReduxColumn
            type="link"
            isClickable="true"
            field="name"
            sortBy="name"
            width="140px"
            minWidth="140px"
            maxWidth="140px"
            renderField={(row) => (
              <Link
                text={row.name}
                url={`/Sprint/${row.id}`}
                className="link-opacity-75"
              />
            )}
          >
            Name
          </ReduxColumn>
          <ReduxColumn
            isClickable="true"
            field="start_time"
            sortBy="start_date"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
            renderField={(row) => (
              <span>{DateTime.getDate(row.start_date)}</span>
            )}
          >
            Start Time
          </ReduxColumn>
          <ReduxColumn
            isClickable="true"
            field="end_time"
            sortBy="end_date"
            width="110px"
            minWidth="110px"
            maxWidth="110px"
            renderField={(row) => <span>{DateTime.getDate(row.end_date)}</span>}
          >
            End Time
          </ReduxColumn>
          <ReduxColumn
            field="status"
            sortBy="status"
            width="120px"
            minWidth="120px"
            maxWidth="120px"
            renderField={(row) => (
              <div
                className={`status-input text-center rounded text-white text-uppercase my-3 mx-auto ${
                  row.status && row.status === sprint.STATUS_ACTIVE_TEXT
                    ? "bg-success"
                    : row.status === sprint.STATUS_INACTIVE_TEXT
                    ? "bg-dark bg-opacity-50"
                    : ""
                }`}
              >
                <p>{row.status}</p>
              </div>
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn
            field="Action"
            disableOnClick
            width="70px"
            renderField={(row) => (
              <>
                <div className="text-center action-group-dropdown">
                  <MoreDropdown>
                    <DropdownItem
                      onClick={() => {
                        toggle();
                        setRowValue(row);
                      }}
                    >
                      Quick View
                    </DropdownItem>
                    <DropdownItem
                      className="text-danger"
                      onClick={() => {
                        setRowValue(row);
                        setDeleteModal(true);
                      }}
                    >
                      Delete
                    </DropdownItem>
                  </MoreDropdown>
                </div>
              </>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    </>
  );
};

function mapStateToProps(state) {
  const reduxTable = state.table;
  const CurrentPage =
    reduxTable["sprint"] && !reduxTable["sprint"].isFetching
      ? reduxTable["sprint"].currentPage
      : 1;

  const CurrentPageSize =
    reduxTable["sprint"] && !reduxTable["sprint"].isFetching
      ? reduxTable["sprint"].pageSize
      : 25;

  return {
    CurrentPage,
    CurrentPageSize,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Sprint);
