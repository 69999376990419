import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import ArrayList from "../lib/ArrayList";
import { isBadRequest } from "../lib/Http";
import Url from "../lib/Url";

class AppSettingService {
  static save(data, callback) {
    apiClient
      .put(`${endpoints().appSettingAPI}`, data)
      .then((response) => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          Toast.success(successMessage);
        }
        return callback(response && response.data);
      })
      .catch((error) => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  }
  static async saveAppThemeSetting(data, appId, callback) {
try{
    const apiUrl = `${endpoints().appSettingAPI}/theme/${appId}`;

    // Save settings
    return apiClient
      .put(apiUrl, data)
      .then((response) => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          Toast.success(successMessage);
        }

        if (callback) {
          return callback(null, response);
        }
      })
      .catch((error) => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(error.response.data.message);
          console.error(errorMessage);
        }
        if (callback) {
          return callback(error, null);
        }
      });
    }catch(err){
      console.log(err);
    }
  }

  static async getAppThemeSetings(appId,params) {
    try{
    let queryString = await ArrayList.toQueryString(params);
    const response = await apiClient.get(
      `${endpoints().appSettingAPI}/theme/${appId}`,
      queryString
    );
    return response && response?.data;
  }catch(err){
    console.log(err);
  }
  }
  
    static get(data, callback) {

        apiClient
            .get(`${endpoints().appSettingAPI}`, data)
            .then((response) => {
                return callback(response && response.data);
            })
    }
}
export default AppSettingService;