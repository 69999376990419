import React, { useState } from "react";
import { Link } from "react-router-dom";
import "../../../views/user/portalUser.scss";

// API

// Assets
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";

// Helpers
import { User } from "../../../helpers/User";

// Lib
import { endpoints } from "../../../api/endPoints";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import UserCard from "../../../components/UserCard";
import Url from "../../../lib/Url";

export const Tabs = {
  ACTIVE: "Active",
  ALL: "All",
  INACTIVE: "InActive",
};

const ActivityUserList = (props) => {
  const { selectedCheckBox,setSelectedIds } = props;
  const sortByOption = [
    {
      value: "name:ASC",
      label: "Name",
    },
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];
  const onBulkSelect = (value) => {
    setSelectedIds(value);
  };
  return (
    <>
      <ReduxTable
        id={"roleUserList"}
        showHeader
        searchPlaceholder="Search"
        apiURL={`${endpoints().userAPI}/search`}
        newTableHeading
        icon={<FontAwesomeIcon icon={faUser} />}
        message="You can start by clicking add."
        sortByOptions={sortByOption}
        history={props.history}
        params={{...Url.GetAllParams(),status:User.STATUS_ACTIVE_VALUE}}
        selectedCheckBox={selectedCheckBox}
        showRoleFilter={true}
        paramsToUrl={true}
        bulkSelect
        showScroll
        onBulkSelect={onBulkSelect}
        showLoggerInUser
        hideQueryParams={{
          status:true
        }}
      >
        <ReduxColumn
          field="first_name"
          type="link"
          isClickable="true"
          sortBy="name"
          width="350px"
          maxWidth="250px"
          minWidth="250px"
          renderField={(row) => (
            <>
              <Link to={`/user/${row.id}`} className="link-opacity-75">
                <UserCard
                  customSize={parseInt(50, 10)}
                  firstName={row.first_name}
                  url={row.avatarUrl}
                  mobileNumber={row.mobileNumber1}
                  email={row.email}
                  lastName={row.last_name}
                  status={
                    row.status == User.STATUS_INACTIVE_VALUE
                      ? `(${User.STATUS_INACTIVE_TEXT})`
                      : ""
                  }
                />
              </Link>
            </>
          )}
        >
          Name
        </ReduxColumn>
        <ReduxColumn
          field="current_location"
          width="110px"
          maxWidth="110px"
          minWidth="110px"
          sortBy="current_location_id"
        >
          Current Location
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default ActivityUserList;
