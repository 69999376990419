import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import "../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { AtomicBlockUtils, EditorState } from "draft-js";

// Helpers
import MediaUpload from "../helpers/MediaUpload";
import ObjectName from "../helpers/ObjectName";
import Media from "../helpers/Media";

// Lib
import { getRandomId } from "../lib/Helper";

function DraftEditor(props) {
  const {
    onChange,
    editorState,
    showEditButton,
    onBlur,
    mentionList = [],
    readOnly,
    placeholder,
    hideOutLine,
    disabled,
    error,
    errorMessage,
    label,
  } = props;

  const [editable, setEditable] = useState(false);
  const [editorHeight, setEditorHeight] = useState("120px");

  useEffect(() => {
    const updateEditorHeight = () => {
      if (window.innerWidth >= 1024) {
        setEditorHeight("240px");
      } else if (window.innerWidth >= 768) {
        setEditorHeight("180px");
      } else {
        setEditorHeight("120px");
      }
    };

    updateEditorHeight();
    window.addEventListener("resize", updateEditorHeight);

    return () => window.removeEventListener("resize", updateEditorHeight);
  }, []);

  const uploadImageCallBack = async (file) => {
    let objectId = getRandomId();

    let mediaParams = {
      selectedFile: file && file,
      objectId: objectId,
      ObjectName: ObjectName.COMMENT,
      visiblity: Media.VISIBILITY_PUBLIC,
    };
    return new Promise(async (resolve, reject) => {
      await MediaUpload.uploadFile(mediaParams, (res) => {
        resolve({ data: { link: res?.mediaUrl } });
      });
    });
  };

  const handleDragAndDropFileUpload = async (file) => {
    let mediaParams = {
      selectedFile: file && file[0],
      objectId: getRandomId(),
      ObjectName: ObjectName.COMMENT,
      visiblity: Media.VISIBILITY_PUBLIC,
    };

    await MediaUpload.uploadFile(mediaParams, (res) => {
      onChange && onChange(insertImage(res?.mediaUrl));
    });
  };

  const insertImage = (imgUrl) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity(
      "IMAGE",
      "IMMUTABLE",
      { src: imgUrl }
    );
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
    const newEditorState = EditorState.set(editorState, {
      currentContent: contentStateWithEntity,
    });
    return AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ");
  };

  return (
    <div>
      {label && <h6 className="fw-bold mb-2">{label}</h6>}
      <Editor
        editorState={editorState}
        toolbarClassName="toolbar-class"
        wrapperClassName="wrapper-class"
        editorClassName="editor-class"
        toolbarHidden={!editable || disabled}
        onEditorStateChange={onChange}
        handlePastedFiles={handleDragAndDropFileUpload}
        editorStyle={{
          fontSize: "14px",
          fontFamily: "Roboto, sans-serif",
          lineHeight: "1.6",
          color: "#333333",
          minHeight: editorHeight,
          padding: "12px",
          overflowY: "auto",
          border: editable
            ? "2px solid #007bff" // Blue border when editable
            : hideOutLine
              ? "none"
              : "1px solid #dcdcdc",
          borderRadius: "8px",
          backgroundColor: editable ? "#f9f9ff" : "#ffffff", // Light blue for edit mode
        }}
        toolbar={{
          inline: { inDropdown: true },
          list: { inDropdown: true },
          textAlign: { inDropdown: true },
          link: { inDropdown: true },
          history: { inDropdown: true },
          image: {
            uploadCallback: uploadImageCallBack,
            alt: { present: true, mandatory: false },
            previewImage: true,
            inputAccept: "image/*",
          },
          options: [
            "image",
            "emoji",
            "embedded",
            "colorPicker",
            "inline",
            "blockType",
            "fontSize",
            "fontFamily",
            "list",
            "textAlign",
            "link",
            "remove",
            "history",
          ],
        }}
        customStyleMap={{
          HIGHLIGHT: {
            backgroundColor:
              "var(--page-create-ticket-description-text-bg-color)",
          },
        }}
        mention={{
          separator: " ",
          trigger: "@",
          suggestions: mentionList,
        }}
        onFocus={() => {
          if (!disabled) {
            setEditable(true);
          }
        }}
        readOnly={readOnly || disabled}
        placeholder={placeholder}
      />
      {error && (
        <div
          className="text-danger mt-2"
          style={{
            marginTop: "8px",
            fontSize: "12px",
            color: "#e74c3c",
          }}
        >
          {errorMessage || "This field is required."}
        </div>
      )}
      {showEditButton && editable && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "8px",
            marginTop: "12px",
          }}
        >
          <i
            className="fas fa-check me-2"
            onClick={() => {
              onBlur && onBlur();
              setEditable(false);
            }}
          ></i>
          <i
            className="fas fa-times"
            onClick={() => {
              setEditable(false);
            }}
          ></i>
        </div>
      )}
    </div>
  );
}

export default DraftEditor;
