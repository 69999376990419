import React, { useState } from "react";
import { useDispatch } from "react-redux";

// Components
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import ProductCard from "../product/components/productCard";
import BreadCrumb from "../../components/Breadcrumb";
import Button from "../../components/Button";
import PageTitle from "../../components/PageTitle";
import Toast from "../../components/Toast";
import ProductSelect from "./ProductSelect";

// Helpers
import { TagTypeName } from "../../helpers/Tag";

// Endpoints
import { endpoints } from "../../api/endPoints";

// Font Awesome Icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBoxOpen } from "@fortawesome/free-solid-svg-icons";

// Lib
import { isBadRequest } from "../../lib/Http";

// Services
import ProductService from "../../services/ProductService";

const ProductList = (props) => {
  const { params, history, setPage, showBrandFilter, showCategoryFilter } =
    props;

  const [productData, setProductData] = useState([]);

  const [addProductModal, setAddProductModal] = useState(false);

  const [MultiSelectProduct, setMultiSelectProduct] = useState([]);

  const [selectedCheckBox, setSelectedCheckBox] = useState(true);

  const [id, setId] = useState(true);

  const [isToggle, setIsToggle] = useState(false);

  let dispatch = useDispatch();
  // Defining dispatch from useDispatch,
  const sortByOption = [
    {
      value: "product_name:ASC",
      label: "Name",
    },
  ];

  // Bread crumb list
  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    {
      label: "Reports",
      link: "/report",
    },
    {
      label: "Product Duplicate Report",
      link: "",
    },
  ];

  // Add store modal toggling
  const addProductToggle = () => {
    setAddProductModal(false);
  };

  const multiselect = (values) => {
    setMultiSelectProduct(values);
  };

  const _toggle = (id) => {
    setIsToggle(!isToggle);
    setSelectedCheckBox(false);
    setSelectedCheckBox(true);
    setId("");
    setMultiSelectProduct("");
  };

  const handleSubmit = async () => {
    try {
      const data = new FormData();
      data.append("duplicateIds", MultiSelectProduct);
      data.append("selectedProductId", id);

      dispatch(
        await ProductService.bulkMerge(data, (res) => {
          if (res) {
            setAddProductModal(false);
            setMultiSelectProduct([]);
            setId("");
          }
        })
      );
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        Toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  };

  return (
    <>
      <BreadCrumb list={breadcrumbList} />

      <PageTitle label="Duplicate Product Report" className="mb-3" />
      <ProductSelect
        modalOpen={addProductModal}
        toggle={_toggle}
        toggleModalClose={addProductToggle}
        handleSubmit={handleSubmit}
        BulkSelect={multiselect}
        history={props.history}
        data={productData}
        selectedCheckBox={selectedCheckBox}
      />
      <ReduxTable
        id="duplicateProductReport"
        showHeader
        searchPlaceholder="Search"
        apiURL={`${endpoints().productAPI}/duplicateReport`}
        newTableHeading
        params={params}
        icon={<FontAwesomeIcon icon={faBoxOpen} />}
        history={history}
        message="You can start by clicking on Add New"
        sortByOptions={sortByOption}
        paramsToUrl={true}
        showBrandFilter={showBrandFilter ? false : true}
        showCategoryFilter={showCategoryFilter ? false : true}
        showProductFilter
        tagFilterType={{
          type: TagTypeName.PRODUCT,
        }}
        showTagFilter
        showManufactureFilter
        setPage={setPage}
        showStatusParam={false}
      >
        <ReduxColumn
          field="name"
          isClickable="true"
          sortBy="product_name"
          renderField={(row) => (
            <div className="row d-flex justify-content-start mx-3">
              <div className=" p-2 text-dark bg-opacity-10 col-10">
                <ProductCard
                  id={row.id}
                  productImageIcon
                  square
                  productName={row.name}
                  brandName={row.brand}
                  size={row.size !== "null" ? row.size : ""}
                  unit={row.unit !== "null" ? row.unit : ""}
                  salePrice={row.sale_price !== "null" ? row.sale_price : ""}
                  mrp={row.mrp !== "null" ? row.mrp : ""}
                  url={row.image}
                  packSize={row.pack_size}
                  brand_id={row.brand_id}
                  status={row.status}
                />
              </div>
              <div className="col-2 d-flex justify-content-center align-items-center">
                <Button
                  onClick={() => {
                    setAddProductModal(true);
                    setProductData(row?.remainingProducts);
                    setId(row.id);
                  }}
                  showButtonLabelName
                  label={row?.remainingProducts?.length}
                  align="center"
                  className="d-flex justify-content-center align-items-center bg-blue rounded-circle"
                  style={{
                    height: "50px",
                    width: "50px",
                    fontWeight: "bold",
                  }}
                />
              </div>
            </div>
          )}
        >
          Product
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default ProductList;
