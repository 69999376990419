import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";

// Components
import Action from "../../components/Action";
import AddButton from "../../components/AddButton";
import MoreDropdown from "../../components/authentication/moreDropdown";
import AvatarCard from "../../components/AvatarCard";
import Button from "../../components/Button";
import DateSelector from "../../components/Date";
import DeleteModal from "../../components/DeleteModal";
import AddModal from "../../components/Modal";
import PageTitle from "../../components/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import Select from "../../components/Select";
import StatusText from "../../components/StatusText";
import TextArea from "../../components/TextArea";
import UserCard from "../../components/UserCard";
import BulkUpdateModal from "./components/bulkUpdateModal";

// API
import { endpoints } from "../../api/endPoints";

// Helpers
import Cookie from "../../helpers/Cookie";
import ObjectName from "../../helpers/ObjectName";
import Permission from "../../helpers/Permission";
import { UserActivity } from "../../helpers/UserActivity";

// Lib
import DateTime from "../../lib/DateTime";
import Cookies, { setCookie } from "../../lib/Helper";
import Url from "../../lib/Url";

// Services
import { toast } from "react-toastify";
import videoImage from "../../assets/img/videoImage.png";
import CustomAttachment from "../../components/CustomAttachment";
import { isVideoURL } from "../../components/static/imageCarousel";
import UrlHelper from "../../helpers/Url";
import { COOKIE_SESSION_TOKEN } from "../../lib/Cookie";
import CookieLib from "../../lib/Helper";
import ActivityService from "../../services/ActivityService";
import ActivityTypeService from "../../services/ActivityTypeService";
import { hasPermission } from "../../services/UserRolePermissionService";
import ActivityUserList from "./components/ActivityUserList";
import MultiSelect from "../../components/MultiselectCreatable";
import String from "../../lib/String";
import ArrayList from "../../lib/ArrayList";
import Toast from "../../components/Toast";
import Text from "../../components/Text";
import Drawer from "../../components/Drawer";

export const Activity = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [arrays, setArray] = useState([]);
  const [activitiesList, setActivityList] = useState([]);
  const [activityTypeData, setActivityTypeData] = useState("");
  const [activityData, setActivityData] = useState("");
  const [isOpenDeleteModal, setIsDeleteModal] = useState("");
  const [selectedData, setSelectedData] = useState("");
  const [isOpenBulkUpdateModal, setOpenBulkUpdateModal] = useState(false);
  const [selectedActvityIds, setSelectedActivityIds] = useState(false);
  const [arrayList, setArrayList] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);
  const [isDeleteModel, setIsDeleteModel] = useState(false);

  const [photoIndex, setPhotoIndex] = useState(1);
  const [isAttachmentOpen, setIsAttachmentOpen] = useState(false);
  const [photos, setPhotos] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);

  let showAdd = hasPermission(Permission.ACTIVITY_ADD);
  let showDelete = hasPermission(Permission.ACTIVITY_DELETE);

  // Get initial data
  useEffect(() => {
    getActivityList();
    getUserDetails();
  }, []);

  useEffect(() => {
    const checkedList = Cookies.get(Cookie.ACTIVITY);
    const checkedLists = checkedList ? JSON.parse(checkedList) : checkedList;
    if (checkedLists) {
      setArrayList(checkedLists);
      setArray(checkedLists);
    }
  }, []);

  // Dispatch
  const dispatch = useDispatch();

  // Status Options
  const columnOptions = [
    {
      value: UserActivity.ENABLE_ACTIVITY_TYPE,
      label: UserActivity.ENABLE_ACTIVITY_TYPE,
    },
    {
      value: UserActivity.ENABLE_ESTIMATED_HOURS,
      label: UserActivity.ENABLE_ESTIMATED_HOURS,
    },
    {
      value: UserActivity.ENABLE_ACTUAL_HOURS,
      label: UserActivity.ENABLE_ACTUAL_HOURS,
    },
    {
      value: UserActivity.ENABLE_UPDATED_AT,
      label: UserActivity.ENABLE_UPDATED_AT,
    },
  ];

  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  // Handle Add Modal
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  // Handle Column Sort
  const handleColumnChange = async (e) => {
    const array = e;
    let arrayList = [];
    arrayList = JSON.stringify(array);
    setCookie(Cookie.ACTIVITY, arrayList);
    setArray(array);
    setArrayList(array);
  };

  // Get Column Selections
  function getKeyByValue(object, value) {
    let isSelected = false;
    for (const key in object) {
      if (key == value) {
        isSelected = object[key] == true ? true : false;
      }
    }
    return isSelected;
  }

  const enable_activity_type =
    arrays && getKeyByValue(arrays, UserActivity.ENABLE_ACTIVITY_TYPE)
      ? true
      : false;
  const enable_actual_hours =
    arrays && getKeyByValue(arrays, UserActivity.ENABLE_ACTUAL_HOURS)
      ? true
      : false;
  const enable_estimated_hours =
    arrays && getKeyByValue(arrays, UserActivity.ENABLE_ESTIMATED_HOURS)
      ? true
      : false;
  const enable_updated_at =
    arrays && getKeyByValue(arrays, UserActivity.ENABLE_UPDATED_AT)
      ? true
      : false;

  // Handle Activity Change
  const handleActivityChange = async (values) => {
    // Get the selected activity option Id
    const selectedOptionId =
      values &&
      values.values.activity_type &&
      values.values.activity_type.value;

    let value = activityTypeData.find(
      (activityData) => activityData.id == selectedOptionId
    );

    setActivityData(value);
  };

  //Get Activity Details
  const getActivityList = async () => {
    let data;
    let response = await ActivityTypeService.search();
    data = response && response.data && response.data.data;
    // Split the activity options
    if (data && data.length > 0) {
      const activityList = [];
      data
        .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
        .forEach((activityData) => {
          activityList.push({
            value: activityData.id,
            label: activityData.name,
          });
        });
      // Set Activity Options List in state
      setActivityList(activityList);
    }
    // Set Activity Data in state
    setActivityTypeData(data);
    return data;
  };

  const getUserDetails = (userImage, first_fname, last_name) => {
    return (
      <AvatarCard
        firstName={first_fname}
        lastName={last_name}
        url={userImage}
      />
    );
  };

  //Handle Add Activity
  const handleAddActivity = (values) => {
    if (String.isObjectEmpty(values)) {
      return;
    }


    if (!ArrayList.isArray(selectedIds)) {
      Toast.error("Atleast select one user");
      return;
    }

    const data = new FormData();
    if (values && values.activity !== undefined) {
      data.append("activity", values && values.activity ? values.activity : "");
    }

    data.append(
      "activity_type",
      values && values.activity_type && values.activity_type.label
        ? values.activity_type.label
        : ""
    );

    if (
      values &&
      values.activity_type !== undefined &&
      !values?.activity_type?.__isNew__
    ) {
      data.append(
        "activity_type_id",
        values && values.activity_type && values.activity_type.value
          ? values.activity_type.value
          : ""
      );
    }

    if (values && values.activity_type !== undefined) {
      data.append(
        "status",
        activityData && activityData.default_status
          ? activityData.default_status
          : ""
      );
    }

    if (values && values.user !== undefined) {
      data.append("user_id", selectedIds);
    }

    if (values && values.notes !== undefined) {
      data.append("notes", values && values.notes ? values.notes : "");
    }

    if (values && values.date !== undefined) {
      data.append("date", values && values.date ? values.date : "");
    }

    dispatch(
      ActivityService.bulkCreate(data, {
        sort: "id",
        sortDir: "DESC",
        date: Url.GetParam("date"),
      })
    );
    toggle();
  };

  const deleteActivity = () => {
    if (selectedActvityIds && selectedActvityIds.length > 0) {
      dispatch(
        ActivityService.bulkDelete(selectedActvityIds, setIsDeleteModal, {
          ...params,
        })
      );
    } else {
      dispatch(
        ActivityService.delete(selectedData.id, setIsDeleteModal, { ...params })
      );
    }
    setIsDeleteModal(true);
    setSelectedActivityIds([]);
    setSelectedCheckBox(false);
    setSelectedCheckBox(true);
    setIsDeleteModal(false);
  };

  const toggleBulkUpdateModal = () => {
    setOpenBulkUpdateModal(!isOpenBulkUpdateModal);
  };

  const onBulkSelect = (value) => {
    setSelectedActivityIds(value);
  };

  // initial values while adding an activity
  const initialValues = {
    user: "",
    activity: "",
    date: "",
    notes: "",
  };

  const actionsMenuList = [
    {
      value: "Bulk Update",
      label: "Bulk Update",
    },
    {
      value: "Bulk Delete",
      label: "Bulk Delete",
    },
  ];

  let params = {
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    search: Url.GetParam("search"),
    date: Url.GetParam("date"),
  };

  const handleActionChange = (e) => {
    if (e == "Bulk Delete") {
      if (selectedActvityIds && selectedActvityIds.length > 0) {
        setIsDeleteModel(true);
      } else {
        toast.error("Select Atleast One Item");
      }
    }
    if (e == "Bulk Update") {
      if (selectedActvityIds && selectedActvityIds.length > 0) {
        toggleBulkUpdateModal();
      }
    }
  };

  const addActivityForm = (
    <div className="mt-2 mb-3">
      <div>
        <MultiSelect
          name={"activity_type"}
          placeholder="Select Activity Type"
          label={"Activity Type"}
          options={activitiesList}
          onInputChange={handleActivityChange}
          required={true}
          isSearchable={true}
          isSingleSelect={true}
          value={"activity_type"}
          id={"activity_type"}
          allowManualSearch={false}
        />
        <Text
          name="activity"
          label="Activity Name"
          placeholder="Activity Name"
        />
        <ActivityUserList
          selectedCheckBox={selectedCheckBox}
          setSelectedIds={setSelectedIds}
          history={props.history}
        />
        {activityData && activityData.allow_date_selection == true && (
          <DateSelector label="Date" name="date" format="dd-MMM-yyyy" />
        )}
        {activityData && activityData.show_notes == true && (
          <TextArea
            name="notes"
            label={activityData.question}
            placeholder="Notes"
          />
        )}
      </div>
    </div>
  );

  const activityFooter = (
    <div className="overflow-hidden">
      <div className="col-sm-10 text-center">
        <Button type="submit" label="Add" showButtonLabelName />
      </div>
    </div>
  );

  const handleImageModelOpen = (photos, index) => {
    setIsAttachmentOpen(true);
    setPhotos(photos);
    setPhotoIndex(index);
    document.body.style.overflow = "hidden";
  };

  const RenderPaytmImage = ({ row }) => {
    const [imageSrc, setImageSrc] = useState(null);

    useEffect(() => {
      const fetchImage = async () => {
        if (row?.image_urls?.[0]?.media_url) {
          try {
            const response = await fetch(row.image_urls[0]?.media_url, {
              headers: {
                Authorization: CookieLib.get(COOKIE_SESSION_TOKEN),
              },
            });

            if (!response.ok) {
              throw new Error("Failed to fetch image");
            }

            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
            setImageSrc(blobUrl);

            return () => URL.revokeObjectURL(blobUrl);
          } catch (error) {
            console.error("Error fetching image:", error);
            setImageSrc(null);
          }
        }
      };

      fetchImage();
    }, [row]);

    const isVideo =
      row?.image_urls?.[0] && isVideoURL(row.image_urls[0]?.file_name);
    const handleClick = (e) => {
      e.preventDefault();
    };
    return (
      <div style={{ position: "relative", display: "inline-block" }}>
        <a
          href={row?.image_urls[0]?.media_url}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}
        >
          <img
            src={isVideo ? videoImage : imageSrc}
            alt="img"
            width={50}
            height={50}
            onClick={() => handleImageModelOpen(row?.image_urls, 0)}
          />
        </a>
        {row?.image_urls?.length > 1 && (
          <span
            style={{
              position: "absolute",
              top: "-9px",
              right: "-9px",
              backgroundColor: "red",
              color: "white",
              borderRadius: "50%",
              padding: "2px 6px",
              fontSize: "12px",
              fontWeight: "bold",
            }}
          >
            {row.image_urls.length}
          </span>
        )}
      </div>
    );
  };

  return (
    <>
      {isAttachmentOpen && (
        <CustomAttachment
          isOpen={isAttachmentOpen}
          setIsOpen={setIsAttachmentOpen}
          mediaArray={photos}
        />
      )}
      <DeleteModal
        isOpen={isDeleteModel}
        toggle={() => {
          setIsDeleteModel(false);
        }}
        title="Delete Activity"
        id={selectedData && selectedData.id}
        label={"Bulk Delete"}
        deleteFunction={deleteActivity}
      />
      <Drawer
        modelTitle={"Add Activity"}
        DrawerBody={addActivityForm}
        DrawerFooter={activityFooter}
        onSubmit={(values) => {
          handleAddActivity(values);
        }}
        initialValues={initialValues}
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggle}
        isModalOpen={isOpen}
        enableReinitialize
      />
      <DeleteModal
        isOpen={isOpenDeleteModal}
        toggle={() => {
          setIsDeleteModal(false);
        }}
        title="Delete Activity"
        id={selectedData && selectedData.id}
        label={`${selectedData.userName} ${selectedData.userLastName}-(${selectedData.date})`}
        deleteFunction={deleteActivity}
      />
      <div>
        <div className="d-flex justify-content-between">
          <PageTitle label="Activities" />

          <div className="d-flex justify-content-between">
            <BulkUpdateModal
              isOpen={isOpenBulkUpdateModal}
              toggle={toggleBulkUpdateModal}
              selectedActvityIds={selectedActvityIds}
              setSelectedCheckBox={setSelectedCheckBox}
              setSelectedActivityIds={setSelectedActivityIds}
              params={params}
            />
            {showAdd && (
              <AddButton
                className="ms-2 me-1 mt-1"
                label="Add"
                onClick={(e) => {
                  toggle();
                }}
              />
            )}
            {showDelete && (
              <div className="ms-2">
                <Action
                  dropdownLinks={actionsMenuList}
                  handleChange={handleActionChange}
                />
              </div>
            )}
          </div>
        </div>

        <div className="mt-4">
          <ReduxTable
            id="activity"
            searchPlaceholder="Search"
            apiURL={`${endpoints().activityAPI}/search`}
            newTableHeading
            sortByOptions={sortByOption}
            bulkSelect
            onBulkSelect={onBulkSelect}
            paramsToUrl={true}
            history={props.history}
            FieldLabel={columnOptions}
            handleColumnChange={handleColumnChange}
            arrayList={arrayList}
            DropdownWithCheckbox
            selectedCheckBox={selectedCheckBox}
            showStoreFilter
            showCustomDateFilter
            showStatusFilter
            showUserFilter
            showTypeFilter
            customTypeOption={activitiesList}
            params={{ objectName: ObjectName.ACTIVITY }}
          >
            <ReduxColumn
              width="170px"
              minWidth="170px"
              maxWidth="170px"
              field="date"
              sortBy="date"
              className="text-center"
              renderField={(row) => (
                <>
                  <Link
                    to={`/activity/detail/${row.id}`}
                    className="link-opacity-75 text-decoration-none"
                  >
                    {DateTime.getDateAndTime(row.date)}
                  </Link>
                </>
              )}
            >
              Date
            </ReduxColumn>
            {enable_activity_type && enable_activity_type == true && (
              <ReduxColumn
                width="170px"
                minWidth="170px"
                maxWidth="170px"
                field="activity_type"
                sortBy="activity_type"
                className="text-center"
              >
                Activity Type
              </ReduxColumn>
            )}
            <ReduxColumn
              width="310px"
              minWidth="310px"
              maxWidth="310px"
              field="activity"
              className="text-center"
              sortBy="activity"
            >
              Activity Name
            </ReduxColumn>
            <ReduxColumn
              width="310px"
              minWidth="310px"
              maxWidth="310px"
              field="userName"
              className="text-center"
              sortBy="owner_id"
              renderField={(row) => (
                <>
                  <UserCard
                    customSize={parseInt(50, 10)}
                    firstName={row.userName}
                    url={row.userAvatarUrl}
                    lastName={row.userLastName}
                  />
                </>
              )}
            >
              Owner
            </ReduxColumn>
            <ReduxColumn
              width="170px"
              minWidth="170px"
              maxWidth="170px"
              field="location_name"
              className="text-center"
              sortBy="location_name"
            >
              Location
            </ReduxColumn>

            {enable_estimated_hours && enable_estimated_hours == true && (
              <ReduxColumn
                width="170px"
                minWidth="170px"
                maxWidth="170px"
                field="estimated_hours"
                sortBy="estimated_hours"
                className="text-center"
              >
                Estimated Hours
              </ReduxColumn>
            )}
            {enable_actual_hours && enable_actual_hours == true && (
              <ReduxColumn
                width="170px"
                minWidth="170px"
                maxWidth="170px"
                field="actual_hours"
                sortBy="actual_hours"
                className="text-center"
              >
                Actual Hours
              </ReduxColumn>
            )}
            <ReduxColumn
              width="170px"
              minWidth="170px"
              maxWidth="170px"
              className="text-center"
              field="status"
              sortBy="status"
              renderField={(row) => (
                <StatusText
                  backgroundColor={row.statusColor}
                  status={row.status}
                />
              )}
            >
              Status
            </ReduxColumn>
            {enable_updated_at && enable_updated_at == true && (
              <ReduxColumn
                width="110px"
                minWidth="110px"
                maxWidth="110px"
                field="updatedAt"
                className="text-center"
                sortBy="updatedAt"
              >
                Updated At
              </ReduxColumn>
            )}
            <ReduxColumn
              field="image_urls"
              sortBy="image_urls"
              className="text-align-last-center justify-content-center"
              renderField={(row) =>
                row &&
                row?.image_urls.length > 0 && <RenderPaytmImage row={row} />
              }
            >
              Attachments
            </ReduxColumn>
            <ReduxColumn
              field="Action"
              width="90px"
              disableOnClick
              renderField={(row) => (
                <div className="text-center action-group-dropdown">
                  <MoreDropdown>
                    {showDelete && (
                      <DropdownItem
                        className="text-danger"
                        onClick={() => {
                          setSelectedData(row);
                          setIsDeleteModal(true);
                        }}
                      >
                        Delete
                      </DropdownItem>
                    )}
                  </MoreDropdown>
                </div>
              )}
            >
              Action
            </ReduxColumn>
          </ReduxTable>
        </div>
      </div>
    </>
  );
};
