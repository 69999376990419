import React, { useState } from "react";
import StatusService from "../services/StatusService";
import Select from "./Select";
import { useEffect } from "react";

const StatusSelect = (props) => {
  let {
    name,
    handleStatusChange,
    statusOption,
    customStatusOption=[],
    objectName,
    label,
    placeholder,
    isMulti,
    isDisabled,
    defaultValue,
    allowToView,
    objectId,
    orderTypeGroup
  } = props;

  const [statusList, setStatusList] = useState([]);


  useEffect(() => {
    if(statusOption){
      getStatus()
    }
  }, [])
  
  const getStatus = async () => {
    const response = await StatusService.getOption(objectName, "",allowToView,objectId,orderTypeGroup);
    setStatusList(
      customStatusOption && customStatusOption.length > 0
        ? customStatusOption
        : response
    );
    statusOption &&
      statusOption(
        customStatusOption.length > 0 ? customStatusOption : response
      );
  };

  return (
    <>
      <Select
        name={name ? name : "status"}
        label={label ? label : ""}
        placeholder={placeholder ? placeholder : "Select Status"}
        options={
          customStatusOption && customStatusOption.length > 0
            ? customStatusOption
            : statusList
        }
        handleChange={handleStatusChange}
        autoFocus={getStatus}
        isMulti={isMulti}
        isDisabled={isDisabled}
        defaultValue={defaultValue}
        required={props.required}
      />
    </>
  );
};

export default StatusSelect;
