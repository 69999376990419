import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { DropdownItem } from "reactstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

// Components
import PageTitle from "../../components/PageTitle";
import DragAndDropTable, {
  DragAndDropColumn,
} from "../../components/DragAndDropTable";
import Drawer from "../../components/Drawer";
import Filter from "../../components/Filter";
import SaveButton from "../../components/SaveButton";
import JobForm from "./components/jobForm";
import MoreDropdown from "../../components/authentication/moreDropdown";

// Actions
import { fetchList } from "../../actions/table";

// Helpers
import { Jobs } from "../../helpers/Job";

// API
import { endpoints } from "../../api/endPoints";
import { apiClient } from "../../apiClient";

// Libs
import Url from "../../lib/Url";

// Services
import JobService from "../../services/JobService";

const Job = (props) => {
  const { history } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [categories, setCategories] = useState();
  const [status, setStatus] = useState();
  const [pageSize, setPageSize] = useState(Url.GetParam("pageSize"));
  const [searchItem, setSearch] = useState(Url.GetParam("search"));
  const [spinValue, setSpin] = useState(false);
  const [allParams, setAllParams] = useState({ ...Url.GetAllParams() });
  const [rowValues, setRowValues] = useState([]);
  const [title, setTitle] = useState();
  const [slug, setSlug] = useState();

  const [editorDescriptionState, setEditorDescriptionState] = useState(
    EditorState.createEmpty()
  );
  const [editorResponsibilityState, setEditorResponsibilityState] = useState(
    EditorState.createEmpty()
  );
  const [editorBenefitsState, setEditorBenefitsState] = useState(
    EditorState.createEmpty()
  );
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [rowValue, setRowValue] = useState("");
  const [jobCategoryList, setJobCategoryList] = useState([]);

  const StatusOptions = [
    {
      value: Jobs.STATUS_ACTIVE,

      label: "Active",
    },

    {
      value: Jobs.STATUS_INACTIVE,

      label: "InActive",
    },
  ];

  const jobTypeOptions = [
    {
      value: "Full Time",
      label: "Full Time",
    },
    {
      value: "parttime",
      label: "part time",
    },
  ];

  const dispatch = useDispatch();

  const toggle = (isFinish = false) => {
    setIsOpen(!isOpen);
    setRowValue("");
    setTitle("");
    setSlug("");
    setEditorState(EditorState.createEmpty());
    setEditorResponsibilityState(EditorState.createEmpty());
    setEditorBenefitsState(EditorState.createEmpty());
    setEditorDescriptionState(EditorState.createEmpty());
    if (isFinish) {
      fetchData({});
    }
  };

  useEffect(() => {
    getJobCategoryList();
  }, []);

  const getJobCategoryList = async () => {
    try {
      const response = await apiClient.get(`${endpoints().tagApi}/list`);
      const tagDetails = response.data.data;

      const tagList = tagDetails
        .filter((tag) => tag.type === "Job Category")
        .map((tag) => ({
          label: tag.name,
          value: tag.id,
          id: tag.id,
          status: tag.status,
        }));

      setJobCategoryList(tagList);
    } catch (err) {
      console.log(err);
    }
  };

  const statusOptions = [
    {
      value: "Active",

      label: "Active",
    },

    {
      value: "InActive",

      label: "InActive",
    },
  ];

  const handleCategoryChange = (values) => {
    const category = values?.values?.category?.value;
    setCategories(category);
    dispatch(
      fetchList("job", `${endpoints().JobApi}/list`, 1, 25, {
        category: category ? category : "",
        status: status ? status : "",
        pagination: true,
      })
    );
  };

  const handleStatusChange = (values) => {
    const status = values?.values?.status?.value;

    setStatus(status);
    dispatch(
      fetchList("job", `${endpoints().JobApi}/list`, 1, 25, {
        status: status ? status : "",
        category: categories ? categories : "",
        pagination: true,
      })
    );
  };

  const UpdateUrl = (params) => {
    const currentPage = window.location.pathname;
    let queryString = "";
    const queryStringArray = Object.entries(params);

    if (queryStringArray.length > 0) {
      queryString = "?";
      queryStringArray.forEach(async (queryParam) => {
        if (queryParam[1] !== "")
          queryString = `${queryString}&${queryParam[0]}=${queryParam[1]}`;
      });
    }
    if (history) {
      history.push(`${currentPage}${queryString}`);
    }
  };

  const handleEditorDescriptionChange = (editorState) => {
    setEditorDescriptionState(editorState);
  };

  const handleEditorResponsibilityChange = (editorState) => {
    setEditorResponsibilityState(editorState);
  };

  const handleEditorBenefitsChange = (editorState) => {
    setEditorBenefitsState(editorState);
  };

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleTitleChange = (e) => {
    let value = e.target.value;
    setTitle(value);

    const generatedSlug = value
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^a-z0-9-]/g, "");
    setSlug(generatedSlug);
  };

  /**
   * Add job
   *
   * @param data
   */
  const jobAdd = async (values) => {
    const data = new FormData();
    data.append("title", values && values.job_title ? values.job_title : "");
    data.append(
      "status",
      values && values.status && values.status.value ? values.status.value : ""
    );
    data.append(
      "category",
      values && values.category && values.category.value
        ? values.category.value
        : ""
    );
    data.append(
      "job_type",
      values && values.job_type && values.job_type.value
        ? values.job_type.value
        : ""
    );
    data.append("location", values && values.location ? values.location : "");
    data.append(
      "experience",
      values && values.experience ? values.experience : ""
    );
    if (editorDescriptionState) {
      let rawComment = convertToRaw(editorDescriptionState.getCurrentContent());
      data.append(
        "job_description",
        JSON.stringify(rawComment) ? JSON.stringify(rawComment) : ""
      );
    }
    if (editorResponsibilityState) {
      let rawComment = convertToRaw(
        editorResponsibilityState.getCurrentContent()
      );
      data.append(
        "responsibilities",
        JSON.stringify(rawComment) ? JSON.stringify(rawComment) : ""
      );
    }
    if (editorBenefitsState) {
      let rawComment = convertToRaw(editorBenefitsState.getCurrentContent());
      data.append(
        "benefits",
        JSON.stringify(rawComment) ? JSON.stringify(rawComment) : ""
      );
    }
    if (editorState) {
      let rawComment = convertToRaw(editorState.getCurrentContent());
      data.append(
        "requirements",
        JSON.stringify(rawComment) ? JSON.stringify(rawComment) : ""
      );
    }
    data.append(
      "mandatory_skills",
      values && values.mandatory_skills && values.mandatory_skills
        ? values.mandatory_skills
        : ""
    );
    data.append(
      "minimum_experience",
      values && values.minimum_experience ? values.minimum_experience : ""
    );
    data.append(
      "maximum_experience",
      values && values.maximum_experience ? values.maximum_experience : ""
    );
    data.append(
      "maximum_salary",
      values && values.maximum_salary ? values.maximum_salary : ""
    );
    if (values && values.show_current_salary !== undefined) {
      data.append(
        "show_current_salary",
        values && values.show_current_salary === true ? true : false
      );
    }
    if (values && values.show_expected_salary !== undefined) {
      data.append(
        "show_expected_salary",
        values && values.show_expected_salary === true ? true : false
      );
    }
    if (values && values.show_skills !== undefined) {
      data.append("show_skills", values && values.show_skills);
    }
    if (values && values.show_employment_eligibility !== undefined) {
      data.append(
        "show_employment_eligibility",
        values && values.show_employment_eligibility === true ? true : false
      );
    }
    if (values && values.show_current_address !== undefined) {
      data.append(
        "show_current_address",
        values && values.show_current_address === true ? true : false
      );
    }
    if (values && values.show_home_town_address !== undefined) {
      data.append(
        "show_home_town_address",
        values && values.show_home_town_address === true ? true : false
      );
    }
    if (values && values.show_employment !== undefined) {
      data.append(
        "show_employment",
        values && values.show_employment === true ? true : false
      );
    }
    if (values && values.email_address !== undefined) {
      data.append(
        "email_address",
        values && values.email_address === true ? true : false
      );
    }
    if (values && values.current_address !== undefined) {
      data.append(
        "current_address",
        values && values.current_address === true ? true : false
      );
    }
    if (values && values.permanent_address !== undefined) {
      data.append(
        "permanent_address",
        values && values.permanent_address === true ? true : false
      );
    }
    if (values && values.staying_in !== undefined) {
      data.append(
        "staying_in",
        values && values.staying_in === true ? true : false
      );
    }
    if (values && values.highest_education !== undefined) {
      data.append(
        "highest_education",
        values && values.highest_education === true ? true : false
      );
    }
    if (values && values.resume !== undefined) {
      data.append("resume", values && values.resume === true ? true : false);
    }
    if (values && values.age !== undefined) {
      data.append("age", values && values.age === true ? true : false);
    }
    if (values && values.expected_salary !== undefined) {
      data.append(
        "expected_salary",
        values && values.expected_salary === true ? true : false
      );
    }
    if (values && values.first_name !== undefined) {
      data.append(
        "first_name",
        values && values.first_name === true ? true : false
      );
    }
    if (values && values.gender !== undefined) {
      data.append("gender", values && values.gender === true ? true : false);
    }
    if (values && values.last_name !== undefined) {
      data.append(
        "last_name",
        values && values.last_name === true ? true : false
      );
    }
    if (values && values.marital_status !== undefined) {
      data.append(
        "marital_status",
        values && values.marital_status === true ? true : false
      );
    }
    if (values && values.message !== undefined) {
      data.append("message", values && values.message === true ? true : false);
    }
    if (values && values.mobile_number !== undefined) {
      data.append(
        "mobile_number",
        values && values.mobile_number === true ? true : false
      );
    }
    if (values && values.position !== undefined) {
      data.append(
        "position",
        values && values.position === true ? true : false
      );
    }
    if (values && values.profile_picture !== undefined) {
      data.append(
        "profile_picture",
        values && values.profile_picture === true ? true : false
      );
    }
    data.append("slug", values && values.slug ? values.slug : "");
    if (values && values.current_address_area !== undefined) {
      data.append(
        "current_address_area",
        values && values.current_address_area === true ? true : false
      );
    }
    if (values && values.current_address_city !== undefined) {
      data.append(
        "current_address_city",
        values && values.current_address_city === true ? true : false
      );
    }
    if (values && values.current_address_state !== undefined) {
      data.append(
        "current_address_state",
        values && values.current_address_state === true ? true : false
      );
    }
    if (values && values.current_address_pincode !== undefined) {
      data.append(
        "current_address_pincode",
        values && values.current_address_pincode === true ? true : false
      );
    }
    if (values && values.permanent_address_area !== undefined) {
      data.append(
        "permanent_address_area",
        values && values.permanent_address_area === true ? true : false
      );
    }
    if (values && values.permanent_address_city !== undefined) {
      data.append(
        "permanent_address_city",
        values && values.permanent_address_city === true ? true : false
      );
    }
    if (values && values.permanent_address_state !== undefined) {
      data.append(
        "permanent_address_state",
        values && values.permanent_address_state === true ? true : false
      );
    }
    if (values && values.permanent_address_pincode !== undefined) {
      data.append(
        "permanent_address_pincode",
        values && values.permanent_address_pincode === true ? true : false
      );
    }
    if (values && values.educational_qualifiction_course !== undefined) {
      data.append(
        "educational_qualifiction_course",
        values && values.educational_qualifiction_course === true ? true : false
      );
    }
    if (values && values.educational_qualifiction_department !== undefined) {
      data.append(
        "educational_qualifiction_department",
        values && values.educational_qualifiction_department === true
          ? true
          : false
      );
    }
    if (values && values.educational_qualifiction_year !== undefined) {
      data.append(
        "educational_qualifiction_year",
        values && values.educational_qualifiction_year === true ? true : false
      );
    }

    if (rowValue && rowValue.id) {
      dispatch(JobService.update(rowValue.id, data, {}, dispatch, toggle));
    } else {
      dispatch(JobService.create(data, {}, toggle, dispatch));
    }
  };

  /**
   * Delete Job
   *
   * @param data
   */

  const addJobForm = (
    <>
      <JobForm
        StatusOptions={statusOptions}
        jobTypeOptions={jobTypeOptions}
        editorDescriptionState={editorDescriptionState}
        handleEditorDescriptionChange={handleEditorDescriptionChange}
        editorResponsibilityState={editorResponsibilityState}
        editorBenefitsState={editorBenefitsState}
        handleEditorResponsibilityChange={handleEditorResponsibilityChange}
        handleEditorBenefitsChange={handleEditorBenefitsChange}
        editorState={editorState}
        handleEditorChange={handleEditorChange}
        setJobCategoryList={setJobCategoryList}
        handleTitleChange={handleTitleChange}
        slug={slug}
      />
    </>
  );

  const addJobFooter = (
    <SaveButton type="submit" label={rowValue.id ? "Save" : "Add"} />
  );

  const initialValues = {
    job_title: title ? title : rowValue?.job_title ? rowValue?.job_title : "",
    slug: slug ? slug : rowValue?.slug ? rowValue?.slug : "",
    category:
      rowValue && rowValue.category
        ? jobCategoryList?.find((data) => data.value == rowValue.category)
        : "",
    job_type:
      rowValue && rowValue.job_type
        ? jobTypeOptions.find((data) => data.value == rowValue.job_type)
        : "",
    location: rowValue?.location ? rowValue?.location : "",
    experience: rowValue?.experience ? rowValue?.experience : "",
    sort: rowValue?.sort ? rowValue?.sort : "",
    minimum_experience: rowValue?.minimum_experience
      ? rowValue?.minimum_experience
      : "",
    maximum_experience: rowValue?.maximum_experience
      ? rowValue?.maximum_experience
      : "",
    maximum_salary: rowValue?.maximum_salary ? rowValue?.maximum_salary : "",
    show_current_address:
      rowValue && rowValue?.show_current_address
        ? rowValue.show_current_address
        : false,
    show_employment_eligibility:
      rowValue && rowValue.show_employment_eligibility
        ? rowValue.show_employment_eligibility
        : false,
    show_home_town_address:
      rowValue && rowValue.show_home_town_address
        ? rowValue.show_home_town_address
        : false,
    show_skills:
      rowValue && rowValue.show_skills ? rowValue.show_skills : false,
    show_expected_salary:
      rowValue && rowValue.show_expected_salary
        ? rowValue.show_expected_salary
        : false,
    show_current_salary:
      rowValue && rowValue.show_current_salary
        ? rowValue.show_current_salary
        : false,
    show_employment:
      rowValue && rowValue.show_employment ? rowValue.show_employment : false,
    status:
      rowValue && rowValue.statusName
        ? statusOptions.find((data) => rowValue.statusName == data.value)
        : "",
    mandatory_skills: rowValue?.mandatory_skills
      ? rowValue?.mandatory_skills
      : "",
    email_address:
      rowValue && rowValue?.email_address ? rowValue?.email_address : false,
    current_address:
      rowValue && rowValue?.current_address ? rowValue?.current_address : false,
    permanent_address:
      rowValue && rowValue?.permanent_address
        ? rowValue?.permanent_address
        : false,
    staying_in: rowValue && rowValue?.staying_in ? rowValue?.staying_in : false,
    highest_education:
      rowValue && rowValue?.highest_education
        ? rowValue?.highest_education
        : false,
    resume: rowValue && rowValue?.resume ? rowValue?.resume : false,
    age: rowValue && rowValue?.age ? rowValue?.age : false,
    expected_salary:
      rowValue && rowValue?.expected_salary ? rowValue?.expected_salary : false,
    first_name: rowValue && rowValue?.first_name ? rowValue?.first_name : false,
    gender: rowValue && rowValue?.gender ? rowValue?.gender : false,
    last_name: rowValue && rowValue?.last_name ? rowValue?.last_name : false,
    marital_status:
      rowValue && rowValue?.marital_status ? rowValue?.marital_status : false,
    message: rowValue && rowValue?.message ? rowValue?.message : false,
    mobile_number:
      rowValue && rowValue?.mobile_number ? rowValue?.mobile_number : false,
    position: rowValue && rowValue?.position ? rowValue?.position : false,
    profile_picture:
      rowValue && rowValue?.profile_picture ? rowValue?.profile_picture : false,
    current_address_area:
      rowValue && rowValue?.current_address_area ? rowValue?.current_address_area : false,
    current_address_city:
      rowValue && rowValue?.current_address_city ? rowValue?.current_address_city : false,
    current_address_state:
      rowValue && rowValue?.current_address_state ? rowValue?.current_address_state : false,
    current_address_pincode:
      rowValue && rowValue?.current_address_pincode ? rowValue?.current_address_pincode : false,
    permanent_address_area:
      rowValue && rowValue?.permanent_address_area ? rowValue?.permanent_address_area : false,
    permanent_address_city:
      rowValue && rowValue?.permanent_address_city ? rowValue?.permanent_address_city : false,
    permanent_address_state:
      rowValue && rowValue?.permanent_address_state ? rowValue?.permanent_address_state : false,
    permanent_address_pincode:
      rowValue && rowValue?.permanent_address_pincode ? rowValue?.permanent_address_pincode : false,
    educational_qualifiction_course:
      rowValue && rowValue?.educational_qualifiction_course ? rowValue?.educational_qualifiction_course : false,
    educational_qualifiction_department:
      rowValue && rowValue?.educational_qualifiction_department ? rowValue?.educational_qualifiction_department : false,
    educational_qualifiction_year:
      rowValue && rowValue?.educational_qualifiction_year ? rowValue?.educational_qualifiction_year : false,
  };

  let fetchData = async (params) => {
    let param = { sort: "sort_order", sortDir: "ASC", ...allParams, ...params };
    let response = await JobService.search(param);
    setRowValues(response?.data?.data || []);
    return response?.data?.data || [];
  };

  let handleUpdateRow = async (newRows) => {
    await JobService.updateOrder(newRows);
  };

  const handlePageSize = async (value) => {
    let params = {
      ...allParams,
      pageSize: value,
    };
    setPageSize(value);
    await fetchData(params);
    setAllParams((preValue) => ({
      ...preValue,
      pageSize: value,
    }));
  };

  const handleSearchTermChange = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    setAllParams((preValue) => ({
      ...preValue,
      search: searchValue,
    }));
  };

  const onSearchKeyUp = async (event) => {
    if (event.charCode === 13) {
      let params = {
        ...allParams,
        search: searchItem,
      };

      await fetchData(params);
      UpdateUrl(params);
    }
  };

  const onSearchKeyDown = async (event) => {
    if (event.key === "Enter") {
      let params = {
        ...allParams,
        search: searchItem,
      };

      await fetchData(params);
      UpdateUrl(params);
    }
  }

  const refreshButtonOnClick = async () => {
    setSpin(true);
    await fetchData(allParams);
    setSpin(false);
  };

  let filterInitialValues = {
    status: StatusOptions.find(
      (value) => value?.value == Url.GetParam("status")
    ),
    tag: jobCategoryList.find((value) => value?.value == Url.GetParam("tag")),
  };

  const handleFilter = async (fValue) => {
    let params = {
      ...allParams,
      status: fValue?.status?.value ? fValue?.status?.value : "",
      tag: fValue?.tag?.value ? fValue?.tag?.value : "",
    };
    await fetchData(params);
    setAllParams((preValue) => ({
      ...preValue,
      status: fValue?.status?.value ? fValue?.status?.value : "",
      tag: fValue?.tag?.value ? fValue?.tag?.value : "",
    }));
    UpdateUrl(params);
  };

  const handleDeleteFilter = async (removedFilter) => {
    let params = {
      ...allParams,
      ...removedFilter,
    };
    await fetchData(params);
    setAllParams((preValue) => ({
      ...preValue,
      ...removedFilter,
    }));
    UpdateUrl(params);
  };

  return (
    <>
      <Drawer
        modelTitle={rowValue.id ? "Edit Job" : "Add Job"}
        DrawerBody={addJobForm}
        DrawerFooter={addJobFooter}
        onSubmit={(values) => {
          jobAdd(values);
        }}
        initialValues={initialValues}
        handleOpenModal={toggle}
        handleCloseModal={() => toggle(false)}
        handleDrawerClose={() => toggle(false)}
        isModalOpen={isOpen}
        enableReinitialize
      />

      {/* /.page-heading */}
      <PageTitle
        label="Jobs"
        buttonLabel="Add New"
        buttonHandler={(e) => {
          toggle();
          setRowValue("");
        }}
      />

      <Filter
        showHeader
        newTableHeading
        pageSearchOnChange={(e) => {
          handleSearchTermChange(e);
        }}
        sortByDropdown
        pageSearchValue={searchItem}
        searchPlaceholder="Search"
        getPageSizeByOptions={(e) => handlePageSize(e)}
        selectedPageSize={pageSize && pageSize}
        refreshButtonOnClick={refreshButtonOnClick}
        refreshValue={spinValue}
        showPageSize
        handleDeleteFilter={handleDeleteFilter}
        initialValues={filterInitialValues}
        handleFilter={handleFilter}
        showTagFilter
        showStatusFilter
        customStatusOption={StatusOptions}
        tagParams={{ type: "Job Category" }}
        customTagOption={jobCategoryList}
        onKeyPress={onSearchKeyUp}
        onKeyDown={onSearchKeyDown}
        tagPlaceholder={"Select Job Category"}
      />

      <DragAndDropTable
        fetchData={fetchData}
        handleUpdateRow={handleUpdateRow}
        rowValues={rowValues}
        setRowValues={setRowValues}
      >
        <DragAndDropColumn
          fieldName="job_title"
          type="link"
          renderField={(row) => (
            <Link to={`/job/detail/${row.id}`}>{row.job_title}</Link>
          )}
        >
          Job Title
        </DragAndDropColumn>
        <DragAndDropColumn fieldName="categoryName" className="text-center">
          Category
        </DragAndDropColumn>
        <DragAndDropColumn fieldName="created_at" className="text-center">
          Created At
        </DragAndDropColumn>
        <DragAndDropColumn
          fieldName="status"
          renderField={(row) => (
            <div
              className={`status-input text-center rounded text-white text-uppercase mx-auto ${row.status == 1
                ? "bg-success"
                : row.status == 2
                  ? "bg-dark bg-opacity-50"
                  : ""
                }`}
            >
              <p>{row.statusName}</p>
            </div>
          )}
        >
          Status
        </DragAndDropColumn>
        <DragAndDropColumn
          fieldName="status"
          renderField={(row) => (
            <>
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      toggle();
                      setRowValue(row);
                      setEditorDescriptionState(
                        EditorState.createWithContent(
                          convertFromRaw(JSON.parse(row.job_description))
                        )
                      );
                      setEditorResponsibilityState(
                        EditorState.createWithContent(
                          convertFromRaw(JSON.parse(row.responsibilities))
                        )
                      );
                      setEditorBenefitsState(
                        EditorState.createWithContent(
                          convertFromRaw(JSON.parse(row.benefits))
                        )
                      );
                      setEditorState(
                        EditorState.createWithContent(
                          convertFromRaw(JSON.parse(row.requirements))
                        )
                      );
                    }}
                  >
                    Quick View
                  </DropdownItem>
                </MoreDropdown>
              </div>
            </>
          )}
        >
          Action
        </DragAndDropColumn>
      </DragAndDropTable>
    </>
  );
};

export default Job;
