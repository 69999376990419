import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TabContent, TabPane } from "reactstrap";

// Components
import Action from "../../components/Action";
import ActivityList from "../../components/ActivityList";
import AddButton from "../../components/AddButton";
import BreadCrumb from "../../components/Breadcrumb";
import DeleteModal from "../../components/DeleteModal";
import Form from "../../components/Form";
import MediaCarousel from "../../components/MediaCarousel";
import PageTitle from "../../components/PageTitle";
import StatusComponent from "../../components/Status";
import PaymentList from "../payment/components/paymentList";
import BillForm from "./components/billForm";
import Spinner from "../../components/Spinner";
import Comment from "../../components/comment";
import PurchaseList from "../purchase/components/purchaseList";
import StatusSelect from "../../components/SelectStatus";
import ProductForm from "../purchase/components/productForm";
import Banner from "../../components/Banner";
import TaxList from "./TaxList";
import NavTab from "../../components/NavTab";

// Helpers
import Media from "../../helpers/Media";
import MediaUpload from "../../helpers/MediaUpload";
import ObjectName from "../../helpers/ObjectName";
import Permission from "../../helpers/Permission";

// Lib
import { isBadRequest } from "../../lib/Http";
import Number from "../../lib/Number";
import Url from "../../lib/Url";

// API
import { endpoints } from "../../api/endPoints";
import { apiClient } from "../../apiClient";

// Services
import BillService from "../../services/BillService";
import PaymentService from "../../services/PaymentService";
import StatusService from "../../services/StatusService";
import AccountService from "../../services/AccountService";
import PurchaseProductService from "../../services/PurchaseProductService";
import { hasPermission } from "../../services/UserRolePermissionService";

import { convertFromRaw, EditorState } from "draft-js";

const BillDetail = (props) => {

  const { history } = props;

  //   constant Name
  const Tab = {
    SUMMARY: "Summary",
    PAYMENT: "Payments",
    HISTORY: "History",
    TAX: "Tax",
    ATTACHMENTS: "Attachments",
    COMMENTS: "Comments",
    PURCHASE: "Purchases",
    PRODUCT: "Products",
  };

  let showEditButton = hasPermission(Permission.BILL_EDIT);

  const Param = new URLSearchParams(props.history.location.search);
  const [deleteModal, setDeleteModal] = useState(false);
  const id = props.match.params.id;
  const dispatch = useDispatch();
  const tab = Param.get("tab");
  const [activeTab, setActiveTab] = useState(tab ? tab : Tab.SUMMARY);
  const [billData, setBillData] = useState("");
  const [accountData, setAccountData] = useState();
  const [gstStatus, setGstStatus] = useState();
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [editorState, setEditorState] = useState(() => {
    EditorState.createEmpty();
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(true);
  const [paymentSubmitting, paymentSetSubmitting] = useState(true);
  const [rowValue, setRowValue] = useState(null);
  let showHistory = hasPermission(Permission.BILL_HISTORY_VIEW);
  const [vendorList, setVendorList] = useState();
  const [editable, setEditable] = useState(true);
  const [productIds, setProductIds] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    const searchParams = new URLSearchParams(props.history.location.search);
    searchParams.set("tab", activeTab);
    props.history.replace({
      ...props.history.location,
      search: searchParams.toString(),
    });
  }, [activeTab, props.history]);

  useEffect(() => {
    if(activeTab == Tab.TAX){
      getStatus();
    }
    getBillDetail();
    if(activeTab == Tab.PAYMENT){
    getPaymentDetail();
    }
  }, [props]);


  const tabToggle = (tab) => {
    history.push(`/bill/detail/${id}`);
    setActiveTab(tab);
    props.history.push(`${props.match.params.id}?tab=${tab}`);
  };

  const onStatusChange = (value) => {
    let data = {};
    data.status = value;
    dispatch(
      BillService.updateStatus(id, data, {}, (res) => {
        if (res) {
          setIsLoading(true);
          getBillDetail();
          setIsLoading(false);
        }
      })
    );
  };

  const handleCloseModal = () => {
    setIsOpen(false);
    setIsSubmitting(true);
    setRowValue("");
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
  };

  const paymentsDelete = async () => {
    let params = {
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
      search: Url.GetParam("search"),
      bill_id: id,
    };
    dispatch(await PaymentService.delete(rowValue?.id, null, params));
    closeDeleteModal();
  };

  //Get Bill Detail
  const getBillDetail = async () => {
    let id = props.match.params.id;
    try {
      const response = await apiClient.get(
        `${endpoints().billAPI}/detail/${id}`
      );
      const data = response?.data?.data ? response?.data?.data : "";
      setBillData(data);
      setAccountData(data);
      setEditorState(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(response?.data?.data?.notes))
        )
      );
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  const getPaymentDetail = async () => {
    let param = {
      bill_id: id,
    };
    let response = await PaymentService.search(param);
    let data = response && response.data && response.data.data;
    setPaymentId(data && data[0]?.id);
  };

  const getStatus = async () => {
    const status = await StatusService.getOption(ObjectName.BILL_GST_STATUS);
    setGstStatus(status);
  };


  const breadcrumbList = [
    { label: "Home", link: "/accountDashboard" },
    {
      label: "Bills",
      link: "/bill",
    },
    {
      label: "Bill Details",
      link: "",
    },
  ];

  const actionsMenuList = [];

  if (showEditButton && editable && activeTab === Tab.SUMMARY) {
    actionsMenuList.push({
      value: "edit",
      label: "Edit",
    });
  }

  if (activeTab == Tab.PRODUCT) {
    actionsMenuList.push(
      {
        label: "Update To Product",
        value: "Update To Product",
      },
      {
        label: "Update To Account Product",
        value: "Update To Account Product",
      }
    );
  }

  actionsMenuList.push({
    value: "Delete",
    label: "Delete",
  });


  const handleSyncAccountProduct = async () => {
    try {
      await dispatch(
        PurchaseProductService.syncToAccountProduct(
          billData?.purchase_id,
          productIds?.productIds ? productIds?.productIds : [],
          {
            pagination: true,
            sort: Url.GetParam("sort"),
            sortDir: Url.GetParam("sortDir"),
            search: Url.GetParam("search") || "",
            page: Url.GetParam("page"),
            pageSize: Url.GetParam("pageSize"),
            purchaseId: billData?.purchase_id,
            vendorId: billData?.account_id,
          }, (res) => {
            if (res) {
              getBillDetail()
            }
          }
        )
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleSyncToProduct = async () => {
    try {
      await dispatch(
        PurchaseProductService.syncToProduct(
          billData?.purchase_id,
          productIds?.productIds ? productIds?.productIds : [],
          {
            pagination: true,
            sort: Url.GetParam("sort"),
            sortDir: Url.GetParam("sortDir"),
            search: Url.GetParam("search") || "",
            page: Url.GetParam("page"),
            pageSize: Url.GetParam("pageSize"),
            purchaseId: billData?.purchase_id,
            vendorId: billData?.account_id,
          }
        )
      );
    } catch (err) {
      console.log(err);
    }
  };

  const billDelete = async () => {
    dispatch(await BillService.delete(id, history));
    history.push("/bill");
  };

  const handleActionChange = (e) => {
    if (e == "edit") {
      setEditable(false);
    }
    if (e == "Delete") {
      setDeleteModal(true);
    }
    if (e == "Update To Product") {
      handleSyncToProduct();
    }

    if (e == "Update To Account Product") {
      handleSyncAccountProduct();
    }
  };

  const onBulkSelect = (ids) => {
    setProductIds({ productIds: ids });
  };

  const toggles = () => {
    setIsOpen(!isOpen);
  };

  const toggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  const handleImageUpload = async (values) => {
    if (paymentId) {
      let mediaParams={
        selectedFile: selectedFile && selectedFile,
        objectId: paymentId,
        ObjectName: ObjectName.PAYMENT,
        values: values,
        
      }

      await MediaUpload.uploadFile(mediaParams,()=>{
        setEditable(true);
      }
      );
    }
  };

  const handleImageBillTaxUpload = async (values) => {
    if (id) {
      let mediaParams={
        selectedFile: selectedFile && selectedFile,
        objectId: id,
        ObjectName: ObjectName.BILL_TAX,
        values: values,
        
      }

      await MediaUpload.uploadFile(mediaParams,()=>{
        setEditable(true);
      });
    }
  };

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
  };

  if (isLoading) {
    return <Spinner />;
  }

  const handleStatusChange = async (values) => {
    let data = new FormData();
    data.append("gst_status", values && Number.Get(values?.value));
    dispatch(await BillService.updateGstStatus(id, data, {}));
    setEditable(true);
  };

  let gstStatusValue =
    gstStatus && gstStatus.find((data) => data.value === billData?.gst_status);

  let gstInitialValue = {
    gst_status: gstStatusValue ? gstStatusValue : "",
  };

  const summaryTabChange = () => {
    tabToggle(Tab.SUMMARY);
  };

  const taxTabChange = () => {
    tabToggle(Tab.TAX);
  }
  
  const paymentTabChange = () => {
    tabToggle(Tab.PAYMENT);
  }

  const productTabChange = () => {
    tabToggle(Tab.PRODUCT);
  }

  const purchaseTabChange = () => {
    tabToggle(Tab.PURCHASE);
  }

  const commentTabChange = () => {
    tabToggle(Tab.COMMENTS);
  }

  const historyTabChange = () => {
    tabToggle(Tab.HISTORY);
  }

  const NavTabList = [
    {
      label: Tab.SUMMARY,
      onClick: summaryTabChange,
      className: classNames({ active: activeTab === Tab.SUMMARY }),
    },
    {
      label: Tab.TAX,
      onClick: taxTabChange,
      className: classNames({
        active: activeTab === Tab.TAX,
      }),
    },
    {
      label: Tab.PAYMENT,
      onClick: paymentTabChange,
      className: classNames({ active: activeTab === Tab.PAYMENT }),
    },
    {
      label: Tab.PRODUCT,
      onClick: productTabChange,
      className: classNames({ active: activeTab === Tab.PRODUCT }),
    },
    {
      label: Tab.PURCHASE,
      onClick: purchaseTabChange,
      className: classNames({ active: activeTab === Tab.PURCHASE }),
    },
    {
      label: Tab.COMMENTS,
      onClick: commentTabChange,
      className: classNames({ active: activeTab === Tab.COMMENTS }),
    },
    (showHistory ? {
      label: Tab.HISTORY,
      onClick: historyTabChange,
      className: classNames({
        active: activeTab === Tab.HISTORY,
      }),
    }:{}),
  ];

  return (
    <>
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Bill"
        id={id}
        label={id}
        deleteFunction={billDelete}
      />
      <DeleteModal
        isOpen={openDeleteModal}
        label={rowValue?.id}
        toggle={closeDeleteModal}
        title="Delete Payment"
        deleteFunction={paymentsDelete}
      />

      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between">
        <div>
          <PageTitle label={`Bill #${billData.bill_number}`} />
        </div>

        <div className="d-flex align-items-center">
          {(activeTab === Tab.PAYMENT || activeTab === Tab.TAX) && (
            <AddButton
              label={activeTab === Tab.PAYMENT ? "Add Payment" : "Add Tax"}
              onClick={(e) => {
                toggles();
              }}
            />
          )}
          <div className="ms-2 d-flex">
            <StatusComponent
              objectName={ObjectName.BILL}
              handleChange={onStatusChange}
              buttonLabel={billData?.status_name}
              currentStatusId={billData?.status_id}
              color={billData?.colorCode}
              />
            <div className="ms-2">
              <Action
                buttonLabel="Actions"
                dropdownLinks={actionsMenuList}
                handleChange={handleActionChange}
              />
            </div>
          </div>
        </div>
      </div>

      {vendorList && (
        <div
          className="shadow mx-0 my-3 "
          style={{ borderRadius: "10px", backgroundColor: "yellow" }}
        >
          {vendorList?.payment_terms && (
            <pre className="py-2 px-2 text-red fs-6">
              {vendorList?.payment_terms ? vendorList?.payment_terms : null}
            </pre>
          )}
          {vendorList?.return_terms && (
            <pre className="py-2 px-2 text-red fs-6">
              {vendorList?.return_terms ? vendorList?.return_terms : null}
            </pre>
          )}
        </div>
      )}

      <div className="mt-3">
        {billData && billData?.invoiceNotMatchedStatus !== "" && (
          <Banner className="bg-red" text={billData?.invoiceNotMatchedStatus} />
        )}
        {billData && billData?.invoiceMatchedStatus !== "" && (
          <Banner text={billData?.invoiceMatchedStatus} />
        )}

        {billData && billData?.productAmountNotMatchedStatus !== "" && (
          <Banner
            className="bg-red"
            text={billData?.productAmountNotMatchedStatus}
          />
        )}
        {billData && billData?.productAmountMatchedStatus !== "" && (
          <Banner text={billData?.productAmountMatchedStatus} />
        )}
        {billData && billData?.productCount == 0 && (
          <Banner className="bg-red" text="No Product(s) Added" />
        )}
        {billData && billData.marginMistachedText && (
          <Banner
            className="bg-red"
            text={billData && billData.marginMistachedText}
          />
        )}
        <div>
          {billData && billData.marginMatchedText && (
            <Banner text={billData && billData.marginMatchedText} />
          )}
        </div>
      </div>
      <NavTab list={NavTabList} />
      <div className="card-body card mb-5">
        <TabContent activeTab={activeTab}>
          {/* Detail Tab Start*/}
          {activeTab == Tab.SUMMARY && (
            <TabPane tabId={Tab.SUMMARY} className="w-100">
              <div className="row">
                <div className="col-sm-7">
                  <BillForm
                    history={history}
                    setEditable={setEditable}
                    setEditorState={setEditorState}
                    setIsSubmit={setIsSubmitting}
                    billData={billData}
                    editorState={editorState}
                    accountData={accountData}
                    accountList={setVendorList}
                    handleEditorChange={handleEditorChange}
                    showBilldetails
                    owner_id={billData?.owner_id ? billData?.owner_id : null}
                    editable={editable}
                    showAccountDetailsPageLink={
                      billData?.account?.id ? true : false
                    }
                    accountId={billData?.account?.id}
                    showUserDetailsPageLink={billData?.owner_id ? true : false}
                    userId={billData?.owner_id}
                    billId={id}
                    isEditPage={true}
                   getBillDetail={getBillDetail}
                  />
                </div>
                <div className="col-sm-5">
                  <MediaCarousel
                    showCarasoul
                    modalTitle="Upload File"
                    title="Bill"
                    objectName={ObjectName.BILL}
                    objectId={id}
                    history={history}
                    billView={true}
                    attachmentsList={true}
                    modalOpen={isModelOpen}
                    toggle={toggle}
                    setIsModelOpen={setIsModelOpen}
                    Attachments={"Attachments"}
                    handleImageUpload={handleImageUpload}
                    selectedFileValue={setSelectedFile}
                    editable={editable}
                  />
                </div>
              </div>
            </TabPane>
          )}
          {activeTab == Tab.TAX && (
            <TabPane tabId={Tab.TAX} className="w-100">
              <div className="row">
                {/* Left Section: statusSelect and taxList */}
                <div className="col-sm-7">
                  <Form initialValues={gstInitialValue} enableReinitialize>
                    <div className="statusSelect">
                      <StatusSelect
                        name="gst_status"
                        label="GST Status"
                        placeholder="Select GST Status"
                        objectName={ObjectName.BILL_GST_STATUS}
                        handleStatusChange={handleStatusChange}
                      />
                    </div>
                  </Form>
                  <div className="taxList">
                    <TaxList
                      toggles={toggles}
                      isOpen={isOpen}
                      id={props.match.params.id}
                      history={history}
                    />
                  </div>
                </div>

                {/* Right Section: mediaCarousel */}
                <div className="col-lg-5 col-sm-5">
                  <MediaCarousel
                    showCarasoul
                    modalTitle="Upload File"
                    title="Tax"
                    objectName={ObjectName.BILL_TAX}
                    objectId={id}
                    history={history}
                    billView={true}
                    attachmentsList={true}
                    modalOpen={isModelOpen}
                    toggle={toggle}
                    setIsModelOpen={setIsModelOpen}
                    Attachments={"Attachments"}
                    handleImageUpload={handleImageBillTaxUpload}
                    selectedFileValue={setSelectedFile}
                  />
                </div>
              </div>
            </TabPane>
          )}
          {activeTab == Tab.PAYMENT && (
            <TabPane tabId={Tab.PAYMENT}>
              {/* Image List Table Component */}
              <PaymentList
                setIsSubmitting={paymentSetSubmitting}
                isSubmitting={paymentSubmitting}
                paymentTab
                toggles={toggles}
                isOpen={isOpen}
                handleCloseModal={handleCloseModal}
                params={{
                  bill_id: props?.match?.params?.id,
                  showTotal:true
                }}
                billData={billData}
                setRowValue={setRowValue}
                rowValue={rowValue}
                detail={billData}
                showLoggedInUser
                history={history}
                getBillDetail={getBillDetail}
                setOpenDeleteModal={setOpenDeleteModal}
              />
            </TabPane>
          )}
          {activeTab == Tab.PURCHASE && (
            <TabPane tabId={Tab.PURCHASE}>
              <PurchaseList
                onBulkSelect={onBulkSelect}
                id="purchaseList"
                billId={
                  props?.match?.params?.id ? props?.match?.params?.id : ""
                }
                disableHeader={true}
                history={history}
              />
            </TabPane>
          )}

          {activeTab == Tab.PRODUCT && (
            <TabPane tabId={Tab.PRODUCT}>
              <ProductForm
                vendorId={billData?.account?.id}
                purchaseId={billData?.purchase_id}
                history={history}
                billId={id}
                DropdownWithCheckbox={false}
                onBulkSelect={onBulkSelect}
                bulkSelect
                totalRejectedProductAmount
                totalNotReceivedProductAmount
              />
            </TabPane>
          )}
          {/* History Tab Start*/}
          {showHistory && activeTab == Tab.HISTORY && (
            <TabPane tabId={Tab.HISTORY} className="w-100">
              <ActivityList
                id={id}
                objectId={id}
                object_name={ObjectName.BILL}
                history={history}
              />
            </TabPane>
          )}
{activeTab == Tab.COMMENTS && 
          <TabPane tabId={Tab.COMMENTS} className="w-100">
            <Comment
              objectId={id}
              objectName={ObjectName.BILL}
              maxHeight="100vh"
              owner_id={billData?.owner_id ? billData?.owner_id : null}
            />
          </TabPane>
}
        </TabContent>
      </div>
    </>
  );
};
export default BillDetail;
