import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { DropdownItem } from "reactstrap";

// Components
import Drawer from "../../../components/Drawer";
import Text from "../../../components/Text";
import Select from "../../../components/Select";
import SaveButton from "../../../components/SaveButton";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import DeleteModal from "../../../components/DeleteModal";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";

// Services
import accountTypeService from "../../../services/AccountTypeService";

// Helpers
import Account from "../../../helpers/Account";
import { typeOption } from "../../../helpers/Vendor";

// API
import { endpoints } from "../../../api/endPoints";

// Lib
import Url from "../../../lib/Url";
import SingleCheckbox from "../../../components/SingleCheckbox";

const AccountType = (props) => {
  const { _toggle, closeToggle, isOpen, history, setCurrentData, currentData } = props;
  const dispatch = useDispatch();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const statusOptions = [
    {
      label: Account.STATUS_ACTIVE,
      value: Account.ACTIVE,
    },
    {
      label: Account.STATUS_INACTIVE,
      value: Account.INACTIVE,
    },
  ];

  const sortByOption = [
    {
      value: "name:ASC",
      label: "Name",
    },
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  const checkboxOptions = [
    { name: "show_product", label: "Show Products" },
    { name: "allow_purchase", label: "Allow Purchases" },
    { name: "show_purchase_order", label: "Show Purchase Orders" },
    { name: "show_bill", label: "Show Bills" },
    { name: "show_payment", label: "Show Payments" },
    { name: "show_loyalty", label: "Show Loyalty" },
    { name: "show_rating", label: "Show Rating" },
    { name: "show_file", label: "Show Files" },
    { name: "show_addresses", label: "Show Address" },
    { name: "show_contact", label: "Show Contact" },
    { name: "show_agreement", label: "Show Agreement" },
    { name: "show_custom_field", label: "Show Custom Field" },
    { name: "show_settings", label: "Show Settings" },
    { name: "show_payment_preference", label: "Show Payment Preference" },
  ];
  
  const AddAccountTypeForm = (
    <>
      <Text name="name" label="Name" placeholder="Enter Name..." required />
      <Select
        fullWidth={true}
        label="Category"
        name="category"
        isClearable
        options={typeOption}
        required
      />
      <Select
        fullWidth={true}
        label="Status"
        name="status"
        isClearable
        options={statusOptions}
        required
      />
      <div className="d-inline-block">
      {checkboxOptions.map((option) => (
        <div key={option.name}>
          <SingleCheckbox
            name={option.name}
            label={option.label}
          />
        </div>
      ))}
    </div>
    </>
  );

  const accountTypeFooter = (
    <SaveButton
      type="submit"
      label={currentData?.id ? "Save" : "Add"}
    />
  );

  const handleSubmit = async (values) => {
    let data = new Object();
    data.name = values.name;
    data.status = values.status;
    data.category = values && values.category && values.category.value;
    data.show_product = values?.show_product ? values?.show_product : false;
    data.allow_purchase = values?.allow_purchase ? values?.allow_purchase : false;
    data.show_purchase_order = values?.show_purchase_order ? values?.show_purchase_order : false;
    data.show_bill = values?.show_bill ? values?.show_bill : false;
    data.show_loyalty = values?.show_loyalty ? values?.show_loyalty : false;
    data.show_rating = values?.show_rating ? values?.show_rating : false;
    data.show_file = values?.show_file ? values?.show_file : false;
    data.show_payment = values?.show_payment ? values?.show_payment : false;
    data.show_addresses = values?.show_addresses ? values?.show_addresses : false;
    data.show_agreement = values?.show_agreement ? values?.show_agreement : false;
    data.show_contact = values?.show_contact ? values?.show_contact : false;
    data.show_custom_field = values?.show_custom_field ? values?.show_custom_field : false;
    data.show_settings = values?.show_settings ? values?.show_settings : false;
    data.show_payment_preference = values?.show_payment_preference ? values?.show_payment_preference : false;

    let params = {
      status: Url.GetParam("status"),
      search: Url.GetParam("search"),
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
    };
    if (currentData?.id) {
      await accountTypeService.update(
        currentData?.id,
        data,
        params,
        _toggle,
        dispatch
      );
      setCurrentData("");
    } else {
      dispatch(await accountTypeService.create(data, params, _toggle));
      setCurrentData("");
    }
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
    setCurrentData("");
  };


  const handleDelete = async (id) => {
    let params = {
      status: Url.GetParam("status"),
      search: Url.GetParam("search"),
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
    };
    dispatch(await accountTypeService.delete(id, params));
  };

  return (
    <div>
      <DeleteModal
        isOpen={openDeleteModal}
        toggle={closeDeleteModal}
        title="Delete Account Type"
        deleteFunction={() => {
          handleDelete(currentData?.id);
        }}
        label={currentData?.name}
        id={currentData?.id}
      />
      <Drawer
        handleOpenModal={_toggle}
        handleCloseModal={closeToggle}
        handleDrawerClose={closeToggle}
        isModalOpen={isOpen}
        initialValues={{
          name: currentData?.id ? currentData?.name : "",
          status: currentData?.id
            ? statusOptions.find((data) => currentData?.status == data.value)
            : "",
          category: currentData?.category
            ? typeOption.find((data) => currentData?.category == data.value)
            : "",
          show_product: currentData?.show_product,
          allow_purchase: currentData?.allow_purchase,
          show_purchase_order: currentData?.show_purchase_order,
          show_bill: currentData?.show_bill,
          show_loyalty: currentData?.show_loyalty,
          show_rating: currentData?.show_rating,
          show_file: currentData?.show_file,
          show_payment: currentData?.show_payment,
          show_addresses: currentData?.show_addresses,
          show_contact: currentData?.show_contact,
          show_agreement: currentData?.show_agreement,
          show_custom_field: currentData?.show_custom_field,
          show_settings: currentData?.show_settings,
          show_payment_preference: currentData?.show_payment_preference,
        }}
        enableReinitialize
        DrawerBody={AddAccountTypeForm}
        DrawerFooter={accountTypeFooter}
        modelTitle={currentData?.id ? "Edit Type " : "Add Type"}
        onSubmit={async (values) => {
          handleSubmit(values);
        }}
      />
      <div className="mt-4">
        <ReduxTable
          id="AccountType"
          showHeader
          searchPlaceholder="Search"
          apiURL={`${endpoints().accountTypeAPI}/search`}
          newTableHeading
          history={history}
          paramsToUrl={true}
          sortByOptions={sortByOption}
          showStatusFilter
          customStatusOption={statusOptions}
          onRowClick={(row) => {
            setCurrentData(row);
            _toggle();
          }}
        >
          <ReduxColumn
            type="link"
            field="name"
            sortBy="name"
            isClickable="true"
          >
            Name
          </ReduxColumn>
          <ReduxColumn
            field="status"
            sortBy="status"
            renderField={(row) => (
              <div
                className={`status-input text-center rounded text-white text-uppercase my-3 mx-auto ${row.status == 1
                  ? "bg-success"
                  : row.status === 0
                    ? "bg-dark bg-opacity-50"
                    : "bg-dark bg-opacity-50"
                  }`}
              >
                <p>{row.status == 1 ? "Active" : "InActive"}</p>
              </div>
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn
            field="Action"
            disableOnClick
            width="70px"
            renderField={(row) => (
              <>
                <div className="text-center action-group-dropdown">
                  <MoreDropdown>
                    <DropdownItem
                      onClick={() => {
                        setCurrentData(row);
                        _toggle();
                      }}
                    >
                      Quick View
                    </DropdownItem>
                    <DropdownItem
                      className={"text-danger"}
                      onClick={() => {
                        setOpenDeleteModal(true);
                        setCurrentData(row);
                      }}
                    >
                      Delete
                    </DropdownItem>
                  </MoreDropdown>
                </div>
              </>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    </div >
  );
};
export default AccountType;