import React, { useState } from "react";

import PageTitle from "../../components/PageTitle";

// Components
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";

//Config
import { endpoints } from "../../api/endPoints";

import ProductCard from "../product/components/productCard";
import { Link } from "react-router-dom";
import Action from "../../components/Action";
import Drawer from "../../components/Drawer";
import UserSelect from "../../components/UserSelect";
import SaveButton from "../../components/SaveButton";
import ReplenishService from "../../services/ReplenishService";
import { useDispatch } from "react-redux";
import Url from "../../lib/Url";
import { Status } from "../../helpers/Product";
import Toast from "../../components/Toast";
import UserCard from "../../components/UserCard";

const ReplenishmentProduct = (props) => {
  const [isOpen, setIsOpen] = useState(false);

  const [selectedOwner, setSelectedOwner] = useState("");

  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);

  const dispatch = useDispatch();

  const onBulkSelect = (value) => {
    setSelectedProducts(value);
    setSelectedCheckBox(true);
  };

  const handleChanges = (value) => {
    if (value == "Assign Owner") {
      if (selectedProducts && selectedProducts.length > 0) {
        setIsOpen(true);
      }
      else {
        Toast.error("Select Replenishment Products");
      }
    }
  };

  const handleChange = (selectedUser) => {
    if (selectedUser) {
      setSelectedOwner(selectedUser.id);
    }
  };

  const _toggle = () => {
    setIsOpen(false);
    setSelectedCheckBox(false);
    setSelectedProducts([]);
    setSelectedCheckBox(true);
  };

  const addOwnerForm = (
    <UserSelect
      name="owner"
      label="Owner"
      handleUserChange={handleChange}
      required
    />
  );

  const editFooter = <SaveButton label="Add" />;

  const addReplenishProductOwner = async () => {
    if (selectedOwner && selectedProducts && selectedProducts.length > 0) {
      let bodyObject = {
        selectedOwner: selectedOwner,
        selectedProducts: selectedProducts,
      };
      let params = {
        brand: Url.GetParam("brand"),
        category: Url.GetParam("category"),
        user: Url.GetParam("user"),
        search: Url.GetParam("search"),
        sort: Url.GetParam("sort"),
        sortDir: Url.GetParam("sortDir"),
        status: Url.GetParam("status"),
      };
      dispatch(ReplenishService.updateOwner(bodyObject, params, _toggle));
    } else {
      Toast.error("Please Select Atleast One Item");
    }
  };
  let statusOption = [
    { label: "Completed", value: 2 },
    { label: "Pending", value: 1 },
  ];

  return (
    <div>
      <div className="d-flex flex-row justify-content-between">
        <PageTitle label="Replenishment Products" className={"pt-3"} />

        <div className="pt-2 mt-1">
          <Action
            dropdownLinks={[{ label: "Assign Owner", value: "Assign Owner" }]}
            handleChange={(e) => handleChanges(e)}
            buttonLabel="Action"
          />
        </div>
        <Drawer
          modelTitle="Assign Owner"
          DrawerBody={addOwnerForm}
          DrawerFooter={editFooter}
          onSubmit={(values) => {
            addReplenishProductOwner();
          }}
          initialValues={{ owner: "" }}
          handleOpenModal={_toggle}
          handleCloseModal={_toggle}
          handleDrawerClose={_toggle}
          isModalOpen={isOpen}
        />
      </div>

      <div className="mt-4">
        <ReduxTable
          id="ReplenishList"
          showHeader
          searchPlaceholder="Search"
          apiURL={`${endpoints().replenishmentAPI}/search`}
          newTableHeading
          history={props.history}
          paramsToUrl={true}
          params={{
            sort: Url.GetParam("sort"),
            sortDir: Url.GetParam("sortDir"),
            search: Url.GetParam("search"),
            page: Url.GetParam("page"),
            pageSize: Url.GetParam("pageSize"),
            status: Url.GetParam("status"),
            user: Url.GetParam("user"),
          }}
          sortByDropdown={true}
          showCategoryFilter={true}
          showBrandFilter={true}
          onBulkSelect={onBulkSelect}
          showUserFilter
          showStatusFilter
          customStatusOption={statusOption}
          bulkSelect
          selectedCheckBox={selectedCheckBox}
        >
          <ReduxColumn
            field="name"
            type="link"
            isClickable="true"
            sortBy="product_name"
            renderField={(row) => (
              <div>
                <Link
                  target="_blank"
                  to={`/replenish?search=${row.barcode}&productId=${row.id}&replenishBy=stock`}
                  className="link-opacity-75"
                >
                  <ProductCard
                    id={row.id}
                    productImageIcon
                    square
                    productName={row.name}
                    brandName={row.brand}
                    size={row.size != "null" ? row.size : ""}
                    unit={row.unit != "null" ? row.unit : ""}
                    salePrice={row.sale_price != "null" ? row.sale_price : ""}
                    mrp={row.mrp != "null" ? row.mrp : ""}
                    url={row.image}
                    status={row.status}
                    packSize={row.pack_size}
                    brand_id={row.brand_id}
                    disableLink
                  />
                </Link>
              </div>
            )}
          >
            Product
          </ReduxColumn>
          <ReduxColumn
            className="text-center"
            field="quantity"
            sortBy="quantity"
            minWidth="170px"
          >
            Quantity
          </ReduxColumn>
          <ReduxColumn
            className="text-center"
            field="store_count"
            sortBy="store_count"
            minWidth="170px"
          >
            Location Count
          </ReduxColumn>

          <ReduxColumn
            className="text-center"
            field="distributionCenterQuantity"
            sortBy="distribution_center_quantity"
            minWidth="170px"
          >
            Distribution Center Quantity
          </ReduxColumn>
          <ReduxColumn
            className="text-center"
            field="ownerName"
            sortBy="owner_id"
            minWidth="170px"
            disableOnClick
            renderField={(row) => (
              <>
                <UserCard
                  customSize={parseInt(50, 10)}
                  firstName={row.userName}
                  url={row.userAvatarUrl}
                  lastName={row.userLastName}
                />
              </>
            )}
          >
            Owner
          </ReduxColumn>
          <ReduxColumn
            field="status"
            sortBy="status"
            width="120px"
            minWidth="120px"
            maxWidth="120px"
            renderField={(row) => (
              <div
                className={`status-input text-center rounded text-white text-uppercase  my-3 mx-auto ${row.status && row.status === Status.COMPLETED_TEXT
                    ? "bg-success"
                    : row.status === Status.PENDING_TEXT
                      ? "bg-dark bg-opacity-50"
                      : ""
                  }`}
              >
                <p>{row.status}</p>
              </div>
            )}
          >
            Status
          </ReduxColumn>
        </ReduxTable>
      </div>
    </div>
  );
};

export default ReplenishmentProduct;
