import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import React, { useEffect, useState } from "react";

// Assets
import { faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Components
import UserSelect from "../components/UserSelect";
import DeleteModal from "./DeleteModal";
import DraftEditor from "./Draft";
import Drawer from "./Drawer";
import Link from "./Link";
import NoRecordsFound from "./NoRecordsFound";
import SaveButton from "./SaveButton";
import UserCard from "./UserCard";

// Lib
import ArrayList from "../lib/ArrayList";
import Number from "../lib/Number";

// Services
import CommentService from "../services/CommentService";

const Comment = (props) => {
  let { objectId, objectName, maxHeight } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState("");
  const [comments, setComments] = useState([]);
  const [userId, setUserId] = useState();
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [messageId, setMessageId] = useState(null);
  const [name, setName] = useState(null);
  const [isSubmit, setIsSubmit] = useState(true);
  const [userData, setUserData] = useState();
  const [userList, setUserList] = useState([]);
  const [users, setUsers] = useState([]);
  const [editorError, setEditorError] = useState(false);

  useEffect(() => {
    getComments();
  }, [objectId]);

  const Toggle = () => {
    setIsOpen(!isOpen);
    setIsSubmit(true);
  };

  const closeToggle = () => {
    setIsOpen(!isOpen);
    setName("");
    setEditorState("");
    setMessageId(null);
    setIsSubmit(true);
  };

  const getComments = async () => {
    try {
      const response = await CommentService.search({ objectId, objectName });
      if (response && response.data) {
        setComments(response.data.data);
        setUserId(response.data.loggedInUserId);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const AddMessage = async (values) => {
    try {
      setIsSubmit(true);
      let id = objectId;
      let rawComment;
      if (name || editorState) {
        rawComment = name ? convertToRaw(name.getCurrentContent()) : convertToRaw(editorState.getCurrentContent());
      }
      // Check if the editor content is empty
      if (rawComment.blocks[0].text === "") {
        setEditorError(true);
        return;
      } else {
        setEditorError(false);
      }
      let userIds =
        values && values?.user && values?.user.map((data) => data?.id);
      values.message = JSON.stringify(rawComment);
      values.objectName = objectName;
      values.user = userIds;
      if (!messageId) {
        await CommentService.add(id, values, (res) => {
          if (res) {
            setEditorState("");
            setMessageId(null);
            getComments();
            closeToggle();
            setName("");
          }
        });
      } else {
        CommentService.update(messageId, messageId, values);
        getComments();
        closeToggle();
        setName("");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDelete = async (messageId) => {
    let id = props.objectId;
    let data = {
      commentId: messageId,
      objectName: objectName,
    };
    await CommentService.delete(id, JSON.stringify(data));
    getComments();
  };

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
    setName(null)
  };

  const handleUserChange = (e) => {
    setUserData(e);
  };

  const modelBody = (
    <>
      <UserSelect
        name="user"
        label="User"
        userList={setUserList}
        handleUserChange={handleUserChange}
        isMulti
      />
      <DraftEditor
        List={true}
        placeholder={"Enter Message"}
        name="comment"
        editorState={name ? name : editorState}
        onChange={handleEditorChange}
        error={editorError}
        errorMessage="Comment is required."
        objectId={props.objectId}
      />
    </>
  );

  const modelFooter = (
    <SaveButton
      type="submit"
      label={messageId ? "Save" : "Add"}
      loading={isSubmit == false}
    />
  );
  let UsersValue = [];

  if (ArrayList.isArray(users) && ArrayList.isArray(userList)) {
    UsersValue = userList
      .filter((data) => users?.map((value) => value?.id).includes(data.id)) || []
  };

  return (
    <>
      <Drawer
        modelTitle={messageId ? "Edit Comment" : "Add Comment"}
        DrawerBody={modelBody}
        DrawerFooter={modelFooter}
        onSubmit={(values) => {
          AddMessage(values);
        }}
        initialValues={{
          comment: "",
          user: (Number.isNotNull(messageId) && ArrayList.isArray(UsersValue))
            ? UsersValue
            : (Number.isNotNull(props?.assignee_id) || Number.isNotNull(userList && userList.find((data) => data?.id == props?.assignee_id)))
              ? [userList && userList.find((data) => data?.id == props?.assignee_id)]
              : (Number.isNotNull(props?.reviewer_id) || Number.isNotNull(userList && userList.find((data) => data?.id == props?.reviewer_id)))
                ? [userList && userList.find((data) => data?.id == props?.reviewer_id)]
                : props?.ticketDetails
                  ? [
                    userId == props?.assignee_id
                      ? userList &&
                      userList.find((data) => data?.id == props?.reviewer_id)
                      : userList &&
                      userList.find((data) => data?.id == props?.assignee_id),
                  ]
                  : props?.owner_id
                    ? [userList && userList.find((data) => data?.id == props?.owner_id)]
                    : [
                      userList &&
                      userList.find((data) => data?.isLogedInUser == true),
                    ],
        }}
        handleOpenModal={Toggle}
        handleCloseModal={closeToggle}
        handleDrawerClose={closeToggle}
        isModalOpen={isOpen}
        enableReinitialize
      />

      <DeleteModal
        isOpen={openDeleteModal}
        toggle={() => {
          setOpenDeleteModal(false);
        }}
        title="Delete Message"
        deleteFunction={() => {
          handleDelete(deleteMessage.id);
        }}
        text="the comment"
        id={deleteMessage.id}
      />

      <div className="d-flex justify-content-end">
        <Link
          to="#"
          onClick={(e) => {
            e.preventDefault();
            Toggle();
          }}
          className="link-opacity-75 text-decoration-none"
          text="Add Comment"
        />
      </div>

      <div
        style={{
          maxHeight: "none",
          height: "auto",
          overflow: "visible",
        }}
      >
        {Array.isArray(comments) && comments.length > 0 ? (
          comments.map((comment, index) => (
            <div className="card p-2" key={index}>
              <div>
                <div className="flex-row">
                  <div className="fw-bold">
                    <UserCard
                      id="avatar"
                      customSize={parseInt(35, 10)}
                      url={comment?.media_url}
                      firstName={comment?.first_name}
                      lastName={comment?.last_name}
                      timestamp={comment?.timestamp}
                    />
                  </div>
                </div>
                <div
                  className="row d-flex flex-row"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  {comment &&
                    comment?.users.length > 0 &&
                    comment?.users.map((user) => (
                      <div className="flex-grow-0 fw-bold col">
                        <UserCard
                          id="avatar"
                          customSize={parseInt(20, 10)}
                          url={user?.media_url}
                          firstName={user?.first_name}
                          lastName={user?.last_name}
                          fontSize="12px"
                          minWidth="max-content"
                        />
                      </div>
                    ))}
                </div>
                <div className="d-flex justify-content-between">
                  <div className="w-100">
                    <DraftEditor
                      List={true}
                      readOnly
                      hideOutLine
                      objectId={objectId}
                      placeholder={"Enter Message..."}
                      name="comment"
                      editorState={
                        comment?.comment &&
                        EditorState.createWithContent(
                          convertFromRaw(JSON.parse(comment?.comment))
                        )
                      }
                      height
                    />
                  </div>
                  {comment?.userId == userId && (
                    <div className="d-flex ">
                      <div
                        className="cursor-pointer mx-2"
                        onClick={() => {
                          setMessageId(comment?.id);
                          setUsers(comment?.users);
                          setName(
                            comment?.comment &&
                            EditorState.createWithContent(
                              convertFromRaw(JSON.parse(comment?.comment))
                            )
                          );
                          Toggle();
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faEdit}
                          size="sm"
                          color="text-dark"
                        />
                      </div>
                      <div
                        className="cursor-pointer mx-2"
                        onClick={() => {
                          setOpenDeleteModal(true);
                          setDeleteMessage(comment);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          size="sm"
                          color="text-dark"
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <>
            <NoRecordsFound showMessage boldMessage="No comments added yet" />
          </>
        )}
      </div>
    </>
  );
};

export default Comment;
