import React, { useState, useEffect } from "react";
import Select from "./Select";
import BrandService from "../services/BrandService";
import ArrayList from "../lib/ArrayList";
import Url from "../lib/Url";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareArrowUpRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const BrandSelect = (props) => {
  const {
    name,
    handleBrandChange,
    brandOption,
    label,
    isDisabled,
    AccountProductBrandList,
    showBrandDetailsPageLink,
    brandId
  } = props;
  const [brandList, setBrandList] = useState([]);

  useEffect(() => {
    if (ArrayList.isEmpty(brandList)) {
      if (AccountProductBrandList) {
        getBrandList();
      } else {
        getBrands();
      }
    }
  }, [brandList, AccountProductBrandList]);

  const getBrands = async () => {
    const response = await BrandService.getBrandOption();
    setBrandList(response);
    brandOption(response);
  };

  const getBrandList = async () => {
    let params = {
      account_id: Url.GetParam("accountId"),
      pagination: false,
    };
    const response = await BrandService.search(params);
    const brands = [];

    if (response && response.data && response.data.length > 0) {
      response.data.forEach((brand) => {
        brands.push({
          id: brand.id,
          value: brand.id,
          label: brand.name,
        });
      });
    }

    setBrandList(brands);
    brandOption(brands);
  };

  return (
    <div className="d-flex w-100">
      <Select
        name={name ? name : "brand"}
        label={label}
        placeholder="Select Brand"
        options={brandList}
        handleChange={handleBrandChange}
        isDisabled={isDisabled}
      />
      {showBrandDetailsPageLink && (
        <div className="flex-shrink-1 bd-highlight ms-1">
          <Link target="_blank" to={`/brands/${brandId}`}>
            <FontAwesomeIcon
              className="text-body-secondary p-1"
              icon={faSquareArrowUpRight}
              style={{
                fontSize: "40px",
              }}
            />
          </Link>
        </div>
      )}
    </div>
  );
};

export default BrandSelect;
