import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { TabContent, TabPane } from "reactstrap";

// API
import { endpoints } from "../../../api/endPoints";

// Components
import ActivityList from "../../../components/ActivityList";
import Form from "../../../components/Form";
import NavTab from "../../../components/NavTab";
import SaveButton from "../../../components/SaveButton";
import Select from "../../../components/Select";
import TicketList from "../../../components/TicketList";
import UserSelect from "../../../components/UserSelect";
import CreateTicketModel from "../../../components/createTicketModel";
import ShiftSelect from "../../../components/ShiftSelect";
import StoreSelector from "../../location/components/storeSelector";
import WeekdaysCheckbox from "../../../components/WeekdaysCheckbox";
import HorizontalSpace from "../../../components/HorizontalSpace";
import CancelButton from "../../../components/CancelButton";
import SingleCheckbox from "../../../components/SingleCheckbox";

// Helpers
import ObjectName from "../../../helpers/ObjectName";
import {
  dateOption,
  monthOption,
  RecurringOption,
  typeOptions,
} from "../../../helpers/recurringTask";

// Lib
import Url from "../../../lib/Url";
import ArrayList from "../../../lib/ArrayList";
import Number from "../../../lib/Number";
import String from "../../../lib/String";

// Services
import ActivityTypeService from "../../../services/ActivityTypeService";
import RecurringActivityService from "../../../services/RecurringActivityService";
import UserRoleService from "../../../services/UserRoleService";
import Text from "../../../components/Text";

const General = (props) => {
  const {
    details,
    recurringActiviteTab,
    NavTabList,
    activeTab,
    setEditable,
    editable,
    getDetails,
  } = props;

  const [dateOptions, setDataOption] = useState([]);
  const [userList, setUserList] = useState([]);
  const [type, setType] = useState(null);
  const [selectedDays, setSelectedDays] = useState([]);
  const [assignee, setAssignee] = useState("");
  const [rowValue, setRowValue] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [activityTypeList, setActivityTypeList] = useState([]);
  const [name, setName] = useState();
  const [activityType, setActivityType] = useState(null);
  const [roleValue, setRoleValue] = useState(null);
  const [userRole, setUserRole] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState();
  const [selectedDate, setSelectedDate] = useState();
  const [storeList, setStoreList] = useState([]);
  const [locationId, setLocationId] = useState(null);
  const [shiftValue, setShiftValue] = useState(null);
  const [shiftList, setShiftList] = useState([]);
  const [addOnCheckIn, setAddOnCheckIn] = useState(false);
  const [activityNameValue, setActivityNameValue] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    getDateOptions();
    if (details && details?.day) {
      setSelectedDays(details && details?.day);
    }
    getActivityTypeList();
    getUserRoleList();
  }, []);

  const getUserRoleList = async () => {
    const roleData = await UserRoleService.list();
    setUserRole(roleData);
  };

  const getActivityTypeList = async () => {
    let response = await ActivityTypeService.search();
    let data = response && response?.data && response?.data?.data;
    let list = [];
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        const value = data[i];
        list.push({
          label: value?.name,
          value: value?.id,
        });
      }
    }
    setActivityTypeList(list);
  };

  const handleCloseModal = () => {
    setRowValue(null);
    setName("");
  };

  const getDateOptions = async () => {
    let dateOptions = dateOption();
    setDataOption(dateOptions);
  };

  let dayValue = [];
  if (details?.day) {
    details?.day.forEach((result) => {
      dayValue.push({
        value: result.id,
        label: result.name,
      });
    });
  }

  let weekValue = [];

  if (details?.week) {
    details?.week.forEach((result) => {
      weekValue.push({
        value: result.id,
        label: result.name,
      });
    });
  }

  let getArrayIds = (arrayValue, key) => {
    if (ArrayList.isArray(arrayValue)) {
      return arrayValue.map((value) => value[key]).join(",");
    } else {
      return "";
    }
  };

  const updateData = (data) => {
    if (data.taskType) {
      data.taskType = data.taskType.value;
    }

    data.day =
      selectedDays && selectedDays.length > 0
        ? JSON.stringify(selectedDays)
        : [];

    if (data.month) {
      data.month = data.month.value;
    }

    if (data.activity_name) {
      data.activity_name = data.activity_name;
    }

    if (data.date) {
      data.date = data.date.value;
    }
    if (data.assignee) {
      data.assignee = data.assignee.id;
    }

    if (data.week) {
      data.week = data.week;
    }
    if (data.activityType) {
      data.activityType = data?.activityType?.value;
    }
    data.role_id = getArrayIds(data?.role_id, "value");

    data.location_id = getArrayIds(data?.location, "id");

    if (shiftValue) {
      data.shift_id = shiftValue ? shiftValue : "";
    } else {
      data.shift_id = data.shift_id?.id ? data.shift_id?.id : "";
    }

    data.add_on_check_in = data.add_on_check_in;

    dispatch(
      RecurringActivityService.update(details?.id, data, (res) => {
        if (res) {
          setEditable(true);
          if (getDetails) {
            getDetails();
          }
        }
      })
    );
  };

  const handleUserChange = (values) => {
    setAssignee(values);
  };

  const handleCheckboxChange = (day, checked) => {
    if (checked) {
      setSelectedDays([...selectedDays, day]);
    } else {
      setSelectedDays(selectedDays.filter((d) => d !== day));
    }
  };

  const handleAddOnCheckinChange = (values) => {
    setAddOnCheckIn(values.target.checked);
  };

  const handleActivityNameChange = (e) => {
    let value = e?.target?.value;
    setActivityNameValue(value);
  };

  const returnMultiValue = (arrayValue, rowIds, key) => {
    if (
      Number.isNotNull(rowIds) &&
      ArrayList.isArray(arrayValue) &&
      ArrayList.isArray(rowIds?.split(","))
    ) {
      return arrayValue.filter((data) =>
        rowIds?.split(",")?.includes(data[key]?.toString())
      );
    } else {
      return "";
    }
  };

  // initialValues
  const initialValues = {
    role_id: roleValue
      ? roleValue
      : returnMultiValue(userRole, details?.role_id, "value"),
    date: selectedDate
      ? selectedDate
      : details?.date
      ? dateOptions.find((data) => data?.value == details?.date)
      : "",
    day: dayValue,
    month: selectedMonth
      ? selectedMonth
      : monthOption.find((data) => data.value == details?.month) || null,
    taskType: type
      ? typeOptions.find((data) => data.label == type)
      : typeOptions.find((data) => data.label == details?.type) || null,
    assignee: assignee
      ? assignee
      : userList && userList.find((data) => data.id == details?.assignee_id),
    activityType: activityType
      ? activityTypeList &&
        activityTypeList.find((data) => data?.value == activityType)
      : activityTypeList.find((data) => data?.value == details?.activityType),
    location: locationId
      ? locationId
      : returnMultiValue(storeList, details?.location_id, "id"),
    add_on_check_in: addOnCheckIn || details?.add_on_check_in,
    location: locationId
      ? locationId
      : returnMultiValue(storeList, details?.location_id, "id"),
    shift_id: shiftValue
      ? ArrayList.isNotEmpty(shiftList) &&
        shiftList.find((data) => data.id == shiftValue)
      : ArrayList.isNotEmpty(shiftList) &&
        shiftList.find((data) => data.id == details?.shift_id),
    activity_name: activityNameValue ? activityNameValue : details?.activity,
  };

  const handleTypeChange = async (e) => {
    setType(e.label);
  };

  const handleMonthChange = async (e) => {
    setSelectedMonth(e);
  };

  const handleOpenModal = () => {
    setModalOpen(!isModalOpen);
  };

  const handleActivityTypeChange = async (e) => {
    setActivityType(e && e?.value);
  };

  const handleRoleChange = (e) => {
    setRoleValue(e);
  };

  const handleDateChange = (e) => {
    setSelectedDate(e);
  };

  const onLocationChange = ({ values: { location } }) => {
    setLocationId(location ? location : "");
  };

  const handleShiftChange = (values) => {
    setShiftValue(values?.id ? values?.id : "");
  };

  return (
    <>
      <NavTab list={NavTabList} />
      <TabContent activeTab={activeTab}>
        {activeTab == recurringActiviteTab.GENERAL && (
          <TabPane tabId={recurringActiviteTab.GENERAL}>
            <div className="card p-3">
              <div className="field-wrapper mb-0 form-wrapper">
                <Form
                  initialValues={initialValues}
                  enableReinitialize={true}
                  onSubmit={(values) => {
                    updateData(values);
                  }}
                >
                  <UserSelect
                    label="Assignee"
                    name="assignee"
                    placeholder={"Select Assignee"}
                    handleUserChange={handleUserChange}
                    showUserDetailsPageLink={
                      details?.assignee_id ? true : false
                    }
                    userId={details?.assignee_id}
                    userList={setUserList}
                    isDisabled={editable}
                  />
                  <Select
                    name="role_id"
                    label="Role"
                    placeholder="Select Role"
                    options={userRole}
                    handleChange={handleRoleChange}
                    isMulti
                    isDisabled={editable}
                  />
                  <Select
                    name="activityType"
                    label="Activity Type"
                    placeholder="Select Activity Type"
                    options={activityTypeList}
                    handleChange={handleActivityTypeChange}
                    isDisabled={editable}
                  />
                  <Text
                    name="activity_name"
                    label="Activity Name"
                    placeholder="Activity Name"
                    onChange={handleActivityNameChange}
                    disabled={editable}
                  />
                  <Select
                    name="taskType"
                    label="Recurring Type"
                    placeholder="Select Task"
                    options={typeOptions}
                    handleChange={handleTypeChange}
                    isDisabled={editable}
                  />
                  {type == "Weekly" || (details?.type == "Weekly" && !type) ? (
                    <div>
                      <WeekdaysCheckbox
                        selectedDays={selectedDays}
                        handleCheckboxChange={handleCheckboxChange}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  {type == "Annually" ||
                  (details?.type == "Annually" && !type) ? (
                    <>
                      <Select
                        name="month"
                        label="Month"
                        options={monthOption}
                        handleChange={handleMonthChange}
                        isDisabled={editable}
                      />
                    </>
                  ) : (
                    ""
                  )}
                  {type == "Monthly" ||
                  type == "Annually" ||
                  (details?.type == "Monthly" && !type) ||
                  (details?.type == "Annually" && !type) ? (
                    <>
                      <Select
                        name="date"
                        label="Date"
                        options={dateOptions}
                        handleChange={handleDateChange}
                        isDisabled={editable}
                      />
                    </>
                  ) : (
                    ""
                  )}

                  <ShiftSelect
                    handleShiftChange={handleShiftChange}
                    name="shift_id"
                    label="Shift"
                    defaultValue={details?.shift_id}
                    shiftOption={setShiftList}
                    isDisabled={editable}
                  />
                  <StoreSelector
                    setStoreList={setStoreList}
                    onInputChange={onLocationChange}
                    disabled={editable}
                  />
                  <SingleCheckbox
                    handleFeatureChange={handleAddOnCheckinChange}
                    label={String.createLabel(RecurringOption.ADD_ON_CHECK_IN)}
                    name={RecurringOption.ADD_ON_CHECK_IN}
                    disabled={editable}
                  />
                  {!editable && (
                    <div>
                      <HorizontalSpace bottom="5">
                        <SaveButton label="Save" />
                        <CancelButton
                          onClick={() => {
                            props.history.push("/recurringTask");
                          }}
                        />
                      </HorizontalSpace>
                    </div>
                  )}
                </Form>
              </div>
            </div>
          </TabPane>
        )}
        {activeTab == recurringActiviteTab.TICKET && (
          <TabPane tabId={recurringActiviteTab.TICKET} className="w-100">
            <div className=" d-flex">
              <div className="mx-2">
                <CreateTicketModel
                  buttonLabel="Add New"
                  rowValue={rowValue}
                  name={name}
                  isModalOpen={isModalOpen}
                  setModalOpen={setModalOpen}
                  handleCloseModal={handleCloseModal}
                  recurring_task_id={details?.id}
                />
              </div>
            </div>
            <TicketList
              props={props}
              history={props.history}
              apiUrl={`${endpoints().ticketAPI}/search`}
              recurring_task_id={details?.id}
              setRowValue={setRowValue}
              handleOpenModal={handleOpenModal}
              setName={setName}
              isModalOpen={isModalOpen}
              name={name}
              startDateFilter={Url.GetParam("startDate")}
              endDateFilter={Url.GetParam("endDate")}
            />
          </TabPane>
        )}
        {activeTab == recurringActiviteTab.HISTORY && (
          <TabPane tabId={recurringActiviteTab.HISTORY} className="w-100">
            <ActivityList
              id={details?.id}
              objectId={details?.id}
              object_name={ObjectName.RECURRING_ACTIVITE}
              history={props.history}
            />
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

export default General;
