import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";

// Components
import DeleteModal from "../../components/DeleteModal";
import Drawer from "../../components/Drawer";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import TagSelect from "../../components/TagSelect";
import Text from "../../components/Text";
import TextArea from "../../components/TextArea";
import MoreDropdown from "../../components/authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import DraftEditor from "../../components/Draft";

// Helpers
import Cookie from "../../helpers/Cookie";
import { TagTypeName } from "../../helpers/Tag";

// Lib
import Cookies from "../../lib/Helper";
import Url from "../../lib/Url";

// Services
import TicketTestCaseService from "../../services/TicketTestCaseService";

// Actions
import { fetchList } from "../../actions/table";

// API
import { endpoints } from "../../api/endPoints";

const TicketTestCaseListPage = (props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowValue, setRowValue] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [editorState, setEditorState] = useState(() => {
    EditorState.createEmpty();
  });

  let dispatch = useDispatch();

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
  };

  const modelBody = (
    <>
      <Text name="name" label="Name" />
      <TagSelect name="moduleTag" label="Module Tag" params={{
        type: TagTypeName.MODULE
      }} required />
      <TextArea name="description" label="Description" />
      <TextArea name="testSteps" label="Test Steps" />
      <TextArea name="prerequisite" label="Prerequisite" />
      <TextArea name="testData" label="Test Data" />
      <TextArea name="expectedResult" label="Expected Result" />
      <DraftEditor
        name="comments"
        label={"Comments"}
        editorState={
          editorState && editorState !== undefined
            ? editorState
            : rowValue?.comments
              ? EditorState.createWithContent(convertFromRaw(JSON.parse(rowValue?.comments)))
              : ""
        }
        onChange={handleEditorChange}
      />
    </>
  );

  let modelFooter = (
    <>
      <SaveButton label={rowValue ? "Update" : "Add"} />
    </>
  );

  let initialValues = {
    name: rowValue ? rowValue?.name : "",
    description: rowValue ? rowValue?.description : "",
    testSteps: rowValue ? rowValue?.testSteps : "",
    prerequisite: rowValue ? rowValue?.prerequisite : "",
    testData: rowValue ? rowValue?.testData : "",
    expectedResult: rowValue ? rowValue?.expectedResult : "",
    moduleTag: rowValue ? {
      label: rowValue?.moduleTagName,
      value: rowValue?.moduleTag
    } : "",
  };

  const OpenToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const CloseToggle = () => {
    setRowValue(null)
    setEditorState("");
    setIsModalOpen(!isModalOpen);
  };

  const handleSubmit = async (values) => {
    let data = new FormData();
    data.append("name", values && values?.name ? values?.name : "");
    data.append(
      "description",
      values && values?.description ? values?.description : ""
    );
    data.append(
      "expectedResult",
      values && values?.expectedResult ? values?.expectedResult : ""
    );
    data.append(
      "prerequisite",
      values && values?.prerequisite ? values?.prerequisite : ""
    );
    data.append("testData", values && values?.testData ? values?.testData : "");
    data.append("moduleTag", values && values?.moduleTag ? values?.moduleTag?.value : null);
    data.append(
      "testSteps",
      values && values?.testSteps ? values?.testSteps : ""
    );
    data.append("project_id", Cookies.get(Cookie.PROJECT_ID));

    let rawComment;
    if (editorState) {
      rawComment = JSON.stringify(
        convertToRaw(editorState.getCurrentContent())
      );
    }

    data.append("comments", rawComment || "");

    if (rowValue) {
      dispatch(
        await TicketTestCaseService.update(rowValue?.id, data, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "ticketTestCase",
                `${endpoints().TicketTestCaseAPI}/search`,
                Url.GetParam("page") ? Url.GetParam("page") : 1,
                Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                {
                  project_id: Cookies.get(Cookie.PROJECT_ID),
                  sort: Url.GetParam("sort"),
                  sortDir: Url.GetParam("sortDir"),
                  page: Url.GetParam("page"),
                  pageSize: Url.GetParam("pageSize"),
                  search: Url.GetParam("search"),
                }
              )
            );
            CloseToggle()
          }
        })
      );

    } else {
      dispatch(
        await TicketTestCaseService.create(data, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "ticketTestCase",
                `${endpoints().TicketTestCaseAPI}/search`,
                Url.GetParam("page") ? Url.GetParam("page") : 1,
                Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                {
                  project_id: Cookies.get(Cookie.PROJECT_ID),
                  sort: Url.GetParam("sort"),
                  sortDir: Url.GetParam("sortDir"),
                  page: Url.GetParam("page"),
                  pageSize: Url.GetParam("pageSize"),
                  search: Url.GetParam("search"),
                }
              )
            );
            CloseToggle()
          }
        })
      );
    }
  };

  const closeDeleteModal = () => {
    setRowValue(null)
    setOpenDeleteModal(!openDeleteModal);
  };

  const deleteFunction = async () => {
    dispatch(await TicketTestCaseService.delete(rowValue?.id, (res) => {
      if (res) {
        dispatch(
          fetchList(
            "ticketTestCase",
            `${endpoints().TicketTestCaseAPI}/search`,
            Url.GetParam("page") ? Url.GetParam("page") : 1,
            Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
            {
              project_id: Cookies.get(Cookie.PROJECT_ID),
              sort: Url.GetParam("sort"),
              sortDir: Url.GetParam("sortDir"),
              page: Url.GetParam("page"),
              pageSize: Url.GetParam("pageSize"),
              search: Url.GetParam("search"),
            }
          )
        );
        closeDeleteModal()
      }
    }))
  }

  return (
    <>
      <PageTitle
        label="Ticket Test Case"
        buttonHandler={() => {
          OpenToggle();
        }}
        buttonLabel="Add"
        className="mb-3"
      />
      <DeleteModal
        isOpen={openDeleteModal}
        label={rowValue?.id}
        toggle={closeDeleteModal}
        title="Delete Ticket Test Case"
        deleteFunction={deleteFunction}
      />
      <Drawer
        DrawerBody={modelBody}
        initialValues={initialValues}
        handleOpenModal={OpenToggle}
        handleCloseModal={CloseToggle}
        handleDrawerClose={CloseToggle}
        isModalOpen={isModalOpen}
        enableReinitialize
        modelTitle={rowValue ? "Update Ticket Test Case" : "Add Ticket Test Case"}
        DrawerFooter={modelFooter}
        onSubmit={handleSubmit}
      />
      <ReduxTable
        id="ticketTestCase"
        showHeader
        newTableHeading
        searchPlaceholder="Search"
        apiURL={`${endpoints().TicketTestCaseAPI}/search`}
        history={props.history}
        paramsToUrl={true}
        projectId={Cookies.get(Cookie.PROJECT_ID)}
        sortByDropdown
        params={{ project_id: Cookies.get(Cookie.PROJECT_ID) }}
        showPageSize
      >
        <ReduxColumn
          className="text-center text-decoration-none"
          field="test_case_id"
          sortBy="test_case_id"
          isClickable="true"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
          type="link"
          renderField={(row) => (
            <Link to={`/ticketTestCase/${row?.id}`} className="text-decoration-none">
              {row?.test_case_id}
            </Link>
          )}>
          Ticket Test Case#
        </ReduxColumn>
        <ReduxColumn
          field="name"
          sortBy="name"
          width="190px"
          minWidth="190px"
          maxWidth="190px"
        >
          Name
        </ReduxColumn>
        <ReduxColumn
          field="description"
          sortBy="description"
          width="190px"
          minWidth="190px"
          maxWidth="190px"
        >
          Description
        </ReduxColumn>
        <ReduxColumn
          field="moduleTagName"
          sortBy="module_tag_id"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
        >
          Module Tag
        </ReduxColumn>
        <ReduxColumn
          field="expectedResult"
          sortBy="expected_result"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
        >
          Expected Result
        </ReduxColumn>
        <ReduxColumn
          field="prerequisite"
          sortBy="prerequisite"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
        >
          Prerequisite
        </ReduxColumn>
        <ReduxColumn
          field="testData"
          sortBy="test_data"
          width="110px"
          minWidth="110px"
          maxWidth="110px"
        >
          Test Data
        </ReduxColumn>
        <ReduxColumn
          field="testSteps"
          sortBy="test_steps"
          width="110px"
          disableOnClick
          minWidth="110px"
          maxWidth="110px"
        >
          Test Steps
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          disableOnClick
          renderField={(row) => (
            <div className="text-center action-group-dropdown">
              <MoreDropdown>
                <DropdownItem
                  onClick={() => {
                    setRowValue(row)
                    OpenToggle()
                  }}>
                  Quick View
                </DropdownItem>
                <DropdownItem
                  className="text-danger"
                  onClick={() => {
                    setRowValue(row)
                    setOpenDeleteModal(true)
                  }}>
                  Delete
                </DropdownItem>
              </MoreDropdown>
            </div>
          )}>
          Action
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default TicketTestCaseListPage;
