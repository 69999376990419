// API Client
import { apiClient } from "../apiClient";
import { endpoints } from "../api/endPoints";
import Toast from "../components/Toast";
import { isBadRequest, SUCCESS_RESPONSE } from "../lib/Http";
import {
  jobDeleteError,
  JobsCreateError,
  JobUpdateError,
  receiveAddJob,
  requestAddJob,
  requestDeleteJob,
  requestUpdateJob,
} from "../actions/jobs";
import { fetchList } from "../actions/table";
import ArrayList from "../lib/ArrayList";
import Url from "../lib/Url";
class JobService {
  static async get(id) {
    try {
      if (id) {
        const response = await apiClient.get(`${endpoints().jobAPI}/${id}`);
        return response.data;
      }
    } catch (err) {
      console.log(err);
    }
  }

  static async search(params) {
    try {
      let queryString = await ArrayList.toQueryString(params);
      let response = await Url.get(`${endpoints().JobApi}/list`, queryString);
  
      if (response && response.data) {
        return response; // Return the response if successful
      }
  
    } catch (error) {
      // If error occurs, dispatch the error action
      
      // Handle specific error cases
      if (isBadRequest(error)) {
        let errorMessage ;
        if (error.response && error.response.request && error.response.request.response) {
          const errorRequest = error.response.request;
          errorMessage = JSON.parse(errorRequest.response).message || errorMessage;
        }
        Toast.error(errorMessage);
      }
      
      // Return null or handle any default response as needed
      return null;
    }
  }
  
  static async delete(id, params, dispatch, callback) {
    try {
      apiClient
        .delete(`${endpoints().JobApi}/${id}`)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            return callback && callback(response);
          }
        })
        .then(() => {
          dispatch(
            fetchList("Job", `${endpoints().JobApi}/search`, 1, 25, params)
          );
        })
        .catch((error) => {
          dispatch(jobDeleteError(error));
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }

  static async create(data, params, toggle, dispatch) {
    try {
      return apiClient
        .post(`${endpoints().JobApi}`, data)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
          }
          toggle && toggle(true);
        })
        .then(() => {
          dispatch(
            fetchList("job", `${endpoints().JobApi}/list`, 1, 25, params)
          );
          dispatch(receiveAddJob());
        })
        .catch((error) => {
          dispatch(JobsCreateError(error));

          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
            console.error(errorMessage);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }

  static async update(id, data, params, dispatch, toggle) {
    try {
      apiClient
        .put(`${endpoints().JobApi}/${id}`, data)
        .then((response) => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            toggle && toggle(true)
          }
        })
        .then(() => {
          dispatch(
            fetchList("job", `${endpoints().JobApi}/list`, 1, 25, params)
          );
          dispatch(receiveAddJob());
        })
        .catch((error) => {
          dispatch(JobUpdateError(error));

          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }

  static updateOrder = async (data) => {
    apiClient.put(`${endpoints().JobApi}/sortOrder`, data)
      .then((res) => {
        if (res.status == SUCCESS_RESPONSE) {
          Toast.success(res?.data?.message);
        }
      })
      .catch((err) => {
        if (isBadRequest(err)) {
          let errorMessage;
          const errorRequest = err.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(errorMessage);
        }
      });
  }

  static clone(id,data,callback) {
      
    apiClient
      .put(`${endpoints().JobApi}/clone/${id}`,data)
      .then((response) => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          Toast.success(successMessage);
          return callback && callback(successMessage)
        }
      })
      .catch((error) => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(errorMessage);
        }
      });
  }


}
export default JobService;
