import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Nav, TabContent, TabPane } from "reactstrap";

// Components
import Action from "../../components/Action";
import BreadCrumb from "../../components/Breadcrumb";
import DeleteModal from "../../components/DeleteModal";
import Form from "../../components/Form";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import Spinner from "../../components/Spinner";
import Tab from "../../components/Tab";
import JobForm from "./components/jobForm";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import StatusText from "../../components/StatusText";
import AddModal from "../../components/Modal";
import Text from "../../components/Text";
import CancelButton from "../../components/CancelButton";
import AvatarCard from "../../components/AvatarCard";

// Services
import {
  default as Jobs,
  default as JobService,
} from "../../services/JobService";

// API
import { endpoints } from "../../api/endPoints";
import { apiClient } from "../../apiClient";

// Lib
import { RawURLDecode } from "../../lib/Url";
import {
  convertTextToDraftFormat,
  isValidDraftFormat,
} from "../../lib/validation";
import Currency from "../../lib/Currency";
import Drawer from "../../components/Drawer";


const Tabs = {
  SUMMARY: "Summary",
  CANDIDATES: "Candidates",
};

const JobDetails = (props) => {
  const [jobDetails, setJobDetails] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const [isloading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(Tabs.SUMMARY);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [editorResponsibilityState, setEditorResponsibilityState] = useState(
    EditorState.createEmpty()
  );
  const [editorDescriptionState, setEditorDescriptionState] = useState(
    EditorState.createEmpty()
  );
  const [editorEditorBenefitsState, setEditorEditorBenefitsState] = useState(
    EditorState.createEmpty()
  );
  const [jobCategoryList, setJobCategoryList] = useState();
  const [isModelOpen, setIsModelOpen] = useState(false);

  const dispatch = useDispatch();

  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
    {
      value: "first_name:ASC",
      label: "Name",
    },
  ];

  const statusOptions = [
    {
      value: "Active",

      label: "Active",
    },

    {
      value: "InActive",

      label: "InActive",
    },
  ];

  const jobTypeOptions = [
    {
      value: "Full Time",
      label: "Full Time",
    },
    {
      value: "parttime",
      label: "part time",
    },
  ];

  useEffect(() => {
    getJobDetail();
  }, [props]);

  const getJobDetail = async () => {
    setIsLoading(true);
    const data = await Jobs.get(props.match.params.id);

    if (data?.requirements) {
      let jobRequirements = isValidDraftFormat(RawURLDecode(data?.requirements))
        ? RawURLDecode(data?.requirements)
        : convertTextToDraftFormat(data?.requirements);
      setEditorState(
        jobRequirements
          ? EditorState.createWithContent(
            convertFromRaw(JSON.parse(jobRequirements))
          )
          : ""
      );
    }

    if (data?.benefits) {
      let jobBenefits = isValidDraftFormat(RawURLDecode(data?.benefits))
        ? RawURLDecode(data?.benefits)
        : convertTextToDraftFormat(data?.benefits);
      setEditorEditorBenefitsState(
        jobBenefits
          ? EditorState.createWithContent(
            convertFromRaw(JSON.parse(jobBenefits))
          )
          : ""
      );
    }

    if (data?.responsibilities) {
      let jobResponsibilities = isValidDraftFormat(
        RawURLDecode(data?.responsibilities)
      )
        ? RawURLDecode(data?.responsibilities)
        : convertTextToDraftFormat(data?.responsibilities);
      setEditorResponsibilityState(
        jobResponsibilities
          ? EditorState.createWithContent(
            convertFromRaw(JSON.parse(jobResponsibilities))
          )
          : ""
      );
    }

    if (data?.jobDescription) {
      let jobResponsibilities = isValidDraftFormat(
        RawURLDecode(data?.jobDescription)
      )
        ? RawURLDecode(data?.jobDescription)
        : convertTextToDraftFormat(data?.jobDescription);
      setEditorDescriptionState(
        jobResponsibilities
          ? EditorState.createWithContent(
            convertFromRaw(JSON.parse(jobResponsibilities))
          )
          : ""
      );
    }
    setJobDetails(data);
    console.log(jobDetails);
    setIsLoading(false);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const CloseToggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  useEffect(() => {
    getJobCategoryList();
  }, []);

  const getJobCategoryList = async () => {
    try {
      const response = await apiClient.get(`${endpoints().tagApi}/list`);
      const tagDetails = response.data.data;

      const tagList = tagDetails
        .filter((tag) => tag.type === "Job Category")
        .map((tag) => ({
          label: tag.name,
          value: tag.id,
          id: tag.id,
          status: tag.status,
        }));

      setJobCategoryList(tagList);
    } catch (err) {
      console.log(err);
    }
  };

  const _handleSubmit = (values, id) => {
    const data = new FormData();
    if (values && values.category !== undefined) {
      data.append(
        "category",
        values && values.category && values.category.value
          ? values.category.value
          : ""
      );
    }
    if (values && values.job_title !== undefined) {
      data.append(
        "job_title",
        values && values.job_title ? values.job_title : ""
      );
    }
    if (values && values.slug !== undefined) {
      data.append("slug", values && values.slug ? values.slug : "");
    }
    if (values && values.sort !== undefined) {
      data.append("sort", values && values.sort ? values.sort : "");
    }
    if (values && values.status !== undefined) {
      data.append(
        "status",
        values && values.status && values.status.value
          ? values.status.value
          : ""
      );
    }
    if (values && values.job_type !== undefined) {
      data.append(
        "job_type",
        values && values.job_type && values.job_type.value
          ? values.job_type.value
          : ""
      );
    }
    if (values && values.location !== undefined) {
      data.append("location", values && values.location ? values.location : "");
    }
    if (values && values.experience !== undefined) {
      data.append(
        "experience",
        values && values.experience ? values.experience : ""
      );
    }

    if (values && values.mandatory_skills !== undefined) {
      data.append(
        "mandatory_skills",
        values && values.mandatory_skills && values.mandatory_skills
          ? values.mandatory_skills
          : ""
      );
    }

    if (values && values.responsibilities !== undefined) {
      data.append(
        "responsibilities",
        values && values.responsibilities ? values.responsibilities : ""
      );
    }
    if (editorState) {
      let rawComment = convertToRaw(editorState.getCurrentContent());
      data.append(
        "requirements",
        JSON.stringify(rawComment) ? JSON.stringify(rawComment) : ""
      );
    }
    if (editorResponsibilityState) {
      let rawComment = convertToRaw(
        editorResponsibilityState.getCurrentContent()
      );
      data.append(
        "responsibilities",
        JSON.stringify(rawComment) ? JSON.stringify(rawComment) : ""
      );
    }
    if (editorDescriptionState) {
      let rawComment = convertToRaw(editorDescriptionState.getCurrentContent());
      data.append(
        "job_description",
        JSON.stringify(rawComment) ? JSON.stringify(rawComment) : ""
      );
    }

    if (editorEditorBenefitsState) {
      let rawComment = convertToRaw(
        editorEditorBenefitsState.getCurrentContent()
      );
      data.append(
        "benefits",
        JSON.stringify(rawComment) ? JSON.stringify(rawComment) : ""
      );
    }

    if (values && values.minimum_experience !== undefined) {
      data.append(
        "minimum_experience",
        values && values.minimum_experience ? values.minimum_experience : ""
      );
    }
    if (values && values.maximum_experience !== undefined) {
      data.append(
        "maximum_experience",
        values && values.maximum_experience ? values.maximum_experience : ""
      );
    }
    if (values && values.maximum_salary !== undefined) {
      data.append(
        "maximum_salary",
        values && values.maximum_salary ? values.maximum_salary : ""
      );
    }
    if (values && values.show_current_address !== undefined) {
      data.append(
        "show_current_address",
        values && values.show_current_address === true ? true : false
      );
    }
    if (values && values.show_employment_eligibility !== undefined) {
      data.append(
        "show_employment_eligibility",
        values && values.show_employment_eligibility === true ? true : false
      );
    }
    if (values && values.show_skills !== undefined) {
      data.append("show_skills", values && values.show_skills);
    }
    if (values && values.show_home_town_address !== undefined) {
      data.append(
        "show_home_town_address",
        values && values.show_home_town_address === true ? true : false
      );
    }
    if (values && values.show_expected_salary !== undefined) {
      data.append(
        "show_expected_salary",
        values && values.show_expected_salary === true ? true : false
      );
    }
    if (values && values.show_current_salary !== undefined) {
      data.append(
        "show_current_salary",
        values && values.show_current_salary === true ? true : false
      );
    }
    if (values && values.show_employment !== undefined) {
      data.append(
        "show_employment",
        values && values.show_employment === true ? true : false
      );
    }
    if (values && values.email_address !== undefined) {
      data.append(
        "email_address",
        values && values.email_address === true ? true : false
      );
    }
    if (values && values.current_address !== undefined) {
      data.append(
        "current_address",
        values && values.current_address === true ? true : false
      );
    }
    if (values && values.permanent_address !== undefined) {
      data.append(
        "permanent_address",
        values && values.permanent_address === true ? true : false
      );
    }
    if (values && values.staying_in !== undefined) {
      data.append(
        "staying_in",
        values && values.staying_in === true ? true : false
      );
    }
    if (values && values.highest_education !== undefined) {
      data.append(
        "highest_education",
        values && values.highest_education === true ? true : false
      );
    }
    if (values && values.resume !== undefined) {
      data.append("resume", values && values.resume === true ? true : false);
    }
    if (values && values.age !== undefined) {
      data.append("age", values && values.age === true ? true : false);
    }
    if (values && values.expected_salary !== undefined) {
      data.append(
        "expected_salary",
        values && values.expected_salary === true ? true : false
      );
    }
    if (values && values.first_name !== undefined) {
      data.append(
        "first_name",
        values && values.first_name === true ? true : false
      );
    }
    if (values && values.gender !== undefined) {
      data.append("gender", values && values.gender === true ? true : false);
    }
    if (values && values.last_name !== undefined) {
      data.append(
        "last_name",
        values && values.last_name === true ? true : false
      );
    }
    if (values && values.marital_status !== undefined) {
      data.append(
        "marital_status",
        values && values.marital_status === true ? true : false
      );
    }
    if (values && values.message !== undefined) {
      data.append("message", values && values.message === true ? true : false);
    }
    if (values && values.mobile_number !== undefined) {
      data.append(
        "mobile_number",
        values && values.mobile_number === true ? true : false
      );
    }
    if (values && values.position !== undefined) {
      data.append(
        "position",
        values && values.position === true ? true : false
      );
    }
    if (values && values.profile_picture !== undefined) {
      data.append(
        "profile_picture",
        values && values.profile_picture === true ? true : false
      );
    }
    if (values && values.current_address_area !== undefined) {
      data.append(
        "current_address_area",
        values && values.current_address_area === true ? true : false
      );
    }
    if (values && values.current_address_city !== undefined) {
      data.append(
        "current_address_city",
        values && values.current_address_city === true ? true : false
      );
    }
    if (values && values.current_address_state !== undefined) {
      data.append(
        "current_address_state",
        values && values.current_address_state === true ? true : false
      );
    }
    if (values && values.current_address_pincode !== undefined) {
      data.append(
        "current_address_pincode",
        values && values.current_address_pincode === true ? true : false
      );
    }
    if (values && values.permanent_address_area !== undefined) {
      data.append(
        "permanent_address_area",
        values && values.permanent_address_area === true ? true : false
      );
    }
    if (values && values.permanent_address_city !== undefined) {
      data.append(
        "permanent_address_city",
        values && values.permanent_address_city === true ? true : false
      );
    }
    if (values && values.permanent_address_state !== undefined) {
      data.append(
        "permanent_address_state",
        values && values.permanent_address_state === true ? true : false
      );
    }
    if (values && values.permanent_address_pincode !== undefined) {
      data.append(
        "permanent_address_pincode",
        values && values.permanent_address_pincode === true ? true : false
      );
    }
    if (values && values.educational_qualifiction_course !== undefined) {
      data.append(
        "educational_qualifiction_course",
        values && values.educational_qualifiction_course === true ? true : false
      );
    }
    if (values && values.educational_qualifiction_department !== undefined) {
      data.append(
        "educational_qualifiction_department",
        values && values.educational_qualifiction_department === true
          ? true
          : false
      );
    }
    if (values && values.educational_qualifiction_year !== undefined) {
      data.append(
        "educational_qualifiction_year",
        values && values.educational_qualifiction_year === true ? true : false
      );
    }
    if (values && values?.languages_known !== undefined) {
      data.append(
        "languages_known",
        values && values?.languages_known === true ? true : false
      );
    }
    dispatch(JobService.update(id, data, {}, dispatch));
  };

  const handleCheckBoxValue = async (values) => {
    let objectKeys = Object.keys(values);
    let objectValues = Object.values(values);

    let data = new FormData();

    let id = props.match.params.id;

    let arrayValue = [];
    for (let i = 0; i < objectValues.length; i++) {
      const value = objectValues[i];
      if (value == true) {
        arrayValue.push(objectKeys[i]);
      }
    }
    data.append("checkBoxValue", arrayValue);

    dispatch(JobService.update(id, data, {}, dispatch));
  };

  // Bread crumb list
  const breadcrumbList = [
    { label: "Home", link: "/job/dashboard" },
    { label: "Jobs", link: "/jobs" },
    { label: jobDetails.jobTitle, link: "" },
  ];

  const initialValues = {
    job_title: jobDetails && jobDetails.jobTitle,
    slug: jobDetails && jobDetails.slug,
    category:
      jobDetails && jobDetails.category
        ? jobCategoryList?.find((data) => data.value == jobDetails.category)
        : " ",
    job_type:
      jobDetails && jobDetails.jobType
        ? jobTypeOptions.find((data) => data.value == jobDetails.jobType)
        : " ",
    location: jobDetails && jobDetails.location,
    experience: jobDetails && jobDetails.experience,
    sort: jobDetails && jobDetails.sort,
    minimum_experience: jobDetails && jobDetails.minimumExperience,
    maximum_experience: jobDetails && jobDetails.maximumExperience,
    maximum_salary: jobDetails && jobDetails.maximumSalary,
    show_current_address: jobDetails ? jobDetails.showCurrentAddress : false,
    show_employment_eligibility:
      jobDetails &&
      jobDetails.showEmploymentEligibility &&
      jobDetails.showEmploymentEligibility,

    show_home_town_address:
      jobDetails &&
      jobDetails.showHomeTownAddress &&
      jobDetails.showHomeTownAddress,
    show_skills: jobDetails ? jobDetails.showSkills : false,
    show_expected_salary:
      jobDetails.showExpectedSalary && jobDetails.showExpectedSalary,
    show_current_salary: jobDetails ? jobDetails.showCurrentSalary : false,
    show_employment: jobDetails ? jobDetails.showEmployment : false,
    status:
      jobDetails && jobDetails.status
        ? statusOptions.find((data) => jobDetails.status == data.value)
        : "",
    mandatory_skills: jobDetails && jobDetails.mandatorySkills,
    email_address: jobDetails ? jobDetails.email_address : false,
    age: jobDetails ? jobDetails.age : false,
    current_address: jobDetails ? jobDetails.current_address : false,
    expected_salary: jobDetails ? jobDetails.expected_salary : false,
    first_name: jobDetails ? jobDetails.first_name : false,
    gender: jobDetails ? jobDetails.gender : false,
    highest_education: jobDetails ? jobDetails.highest_education : false,
    last_name: jobDetails ? jobDetails.last_name : false,
    marital_status: jobDetails ? jobDetails.marital_status : false,
    mobile_number: jobDetails ? jobDetails.mobile_number : false,
    message: jobDetails ? jobDetails.message : false,
    permanent_address: jobDetails ? jobDetails.permanent_address : false,
    position: jobDetails ? jobDetails.position : false,
    profile_picture: jobDetails ? jobDetails.profile_picture : false,
    resume: jobDetails ? jobDetails.resume : false,
    staying_in: jobDetails ? jobDetails.staying_in : false,
    current_address_area: jobDetails ? jobDetails.current_address_area : false,
    current_address_city: jobDetails ? jobDetails.current_address_city : false,
    current_address_state: jobDetails
      ? jobDetails.current_address_state
      : false,
    current_address_pincode: jobDetails
      ? jobDetails.current_address_pincode
      : false,
    permanent_address_area: jobDetails
      ? jobDetails.permanent_address_area
      : false,
    permanent_address_city: jobDetails
      ? jobDetails.permanent_address_city
      : false,
    permanent_address_state: jobDetails
      ? jobDetails.permanent_address_state
      : false,
    permanent_address_pincode: jobDetails
      ? jobDetails.permanent_address_pincode
      : false,
    educational_qualifiction_course: jobDetails
      ? jobDetails.educational_qualifiction_course
      : false,
    educational_qualifiction_department: jobDetails
      ? jobDetails.educational_qualifiction_department
      : false,
    educational_qualifiction_year: jobDetails
      ? jobDetails.educational_qualifiction_year
      : false,
    languages_known: jobDetails ? jobDetails?.languages_known : false,
  };

  const jobDelete = () => {
    let id = props.match.params.id;
    dispatch(
      JobService.delete(id, {}, dispatch, (response) => {
        if (response) {
          props.history.push("/jobs");
        }
      })
    );
  };

  const handleRoleClone = async (values) => {
    let id = props.match.params.id;
    let data = {
      jobName: values?.jobName,
    };
    dispatch(
      await JobService.clone(id, data, (res) => {
        if (res) {
          setIsModelOpen(false);
        }
      })
    );
  };

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
  };

  const handleEditorResponsibilityChange = (editorState) => {
    setEditorResponsibilityState(editorState);
  };

  const handleEditorDescriptionChange = (editorState) => {
    setEditorDescriptionState(editorState);
  };

  const handleEditorBenefitsChange = (editorState) => {
    setEditorEditorBenefitsState(editorState);
  };

  const actionsMenuList = [
    {
      value: "clone",
      label: "Clone",
    },
    {
      value: "delete",
      label: "Delete",
    },
  ];

  const handleActionChange = (e) => {
    if (e == "delete") {
      setDeleteModal(true);
    }
    if (e == "clone") {
      setIsModelOpen(!isModelOpen);
    }
  };

  let modelBody = (
    <>
      <Text name="jobName" label="Job Name" required />
    </>
  );

  let modelFooter = (
    <>
      <SaveButton label="Clone" />
    </>
  );

  let initialValue = {
    jobName: "",
  };

  if (isloading) {
    return <Spinner />;
  }

  return (
    <div className="mb-4 pb-4 mb-sm-2 pb-sm-2">
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Job "
        id={jobDetails.id}
        label={jobDetails.jobTitle}
        deleteFunction={jobDelete}
      />
      <Drawer
        modelTitle={"Clone"}
        DrawerBody={modelBody}
        DrawerFooter={modelFooter}
        onSubmit={(values) => {
          handleRoleClone(values);
        }}
        initialValues={initialValue}
        handleCloseModal={CloseToggle}
        handleDrawerClose={CloseToggle}
        isModalOpen={isModelOpen}
        enableReinitialize={true}
      />
      {/* Breadd Crumb Section */}
      <BreadCrumb list={breadcrumbList} />
      <div className="d-flex justify-content-between mb-3">
        <PageTitle label="Job Details" />
        <Action
          dropdownLinks={actionsMenuList}
          handleChange={handleActionChange}
        />
      </div>
      <Nav tabs className="admin-tabs">
        <Tab
          name={Tabs.SUMMARY}
          active={activeTab}
          handleChange={(e) => toggle(e)}
          toggle={toggle}
        />
        <Tab
          name={Tabs.CANDIDATES}
          active={activeTab}
          handleChange={(e) => toggle(e)}
          toggle={toggle}
        />
      </Nav>
      <Form
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values) => {
          _handleSubmit(values, props.match.params.id);
        }}
      >
        {/* General Tab */}
        <TabContent activeTab={activeTab}>
          <TabPane tabId={Tabs.SUMMARY}>
            <>
              <div className="card bg-white col-lg-12">
                <div className="card-body">
                  <JobForm
                    StatusOptions={statusOptions}
                    jobTypeOptions={jobTypeOptions}
                    editorDescriptionState={editorDescriptionState}
                    handleEditorDescriptionChange={
                      handleEditorDescriptionChange
                    }
                    editorResponsibilityState={editorResponsibilityState}
                    handleEditorResponsibilityChange={
                      handleEditorResponsibilityChange
                    }
                    editorState={editorState}
                    handleEditorChange={handleEditorChange}
                    handleEditorBenefitsChange={handleEditorBenefitsChange}
                    editorBenefitsState={editorEditorBenefitsState}
                  />
                </div>
              </div>
            </>
            <div className="btn-wrapper pull-left mt-3 mb-3">
              <SaveButton />
              <CancelButton
                onClick={() => props.history.push("/jobs")}
              />
            </div>
          </TabPane>
          {/* Candidates Tab */}
          <TabPane tabId={Tabs.CANDIDATES}>
            <Form enableReinitialize={true}>
              <div className="card col-lg-12">
                <div className="card-body">
                  <ReduxTable
                    id="Candidate"
                    showHeader
                    searchPlaceholder="Search"
                    apiURL={`${endpoints().candidate}/list`}
                    newTableHeading
                    history={history}
                    paramsToUrl={true}
                    sortByOptions={sortByOption}
                    params={{ position_id: jobDetails.id }}
                  >
                    <ReduxColumn
                      field="userName"
                      sortBy="first_name"
                      width="190px"
                      minWidth="190px"
                      maxWidth="190px"
                      renderField={(row) => (
                        <>
                          <Link
                            to={`/jobs/candidate/${row.candidateId}`}
                            className="link-opacity-75 text-decoration-none"
                          >
                            <div className="d-flex text-break">
                              <AvatarCard
                                id="avatar"
                                firstName={row.firstName}
                                lastName={row.lastName}
                                url={row.candidate_url}
                                mobileNumber={row.phone}
                                email={row.email}
                              />
                            </div>
                          </Link>
                        </>
                      )}
                    >
                      Name
                    </ReduxColumn>
                    <ReduxColumn
                      field="email"
                      sortBy="email"
                      width="190px"
                      minWidth="190px"
                      maxWidth="190px "
                    >
                      Email
                    </ReduxColumn>
                    <ReduxColumn
                      field="gender"
                      sortBy="gender"
                      width="110px"
                      minWidth="110px"
                      maxWidth="110px"
                      className="text-center"
                    >
                      Gender
                    </ReduxColumn>
                    <ReduxColumn
                      field="age"
                      sortBy="age"
                      width="150px"
                      minWidth="150px"
                      maxWidth="150px"
                      className="text-center"
                    >
                      Age
                    </ReduxColumn>
                    <ReduxColumn
                      field="maritalStatus"
                      sortBy="marital_status"
                      width="140px"
                      minWidth="140px"
                      maxWidth="140px"
                      className="text-center"
                    >
                      Marital Status
                    </ReduxColumn>
                    <ReduxColumn
                      field="position"
                      sortBy="position"
                      width="170px"
                      minWidth="170px"
                      maxWidth="170px"
                      className="text-center"
                    >
                      Position
                    </ReduxColumn>
                    <ReduxColumn
                      field="phone"
                      sortBy="phone"
                      width="130px"
                      minWidth="130px"
                      maxWidth="130px"
                      className="text-center"
                    >
                      Phone
                    </ReduxColumn>
                    <ReduxColumn
                      field="department"
                      sortBy="department"
                      width="150px"
                      minWidth="150px"
                      maxWidth="150px"
                    >
                      Department
                    </ReduxColumn>
                    <ReduxColumn
                      field="yearOfPassing"
                      sortBy="year_of_passing"
                      width="150px"
                      minWidth="150px"
                      maxWidth="150px"
                      className="text-center"
                    >
                      Year Of Passing
                    </ReduxColumn>
                    <ReduxColumn
                      field="qualification"
                      sortBy="qualification"
                      width="150px"
                      minWidth="150px"
                      maxWidth="150px"
                    >
                      Qualification
                    </ReduxColumn>
                    <ReduxColumn
                      field="expectedSalary"
                      sortBy="expected_salary"
                      width="150px"
                      minWidth="150px"
                      maxWidth="150px"
                      renderField={(row) => (
                        <span>{Currency.Format(row?.expectedSalary)}</span>
                      )}
                      className="text-end"
                    >
                      Expected Salary
                    </ReduxColumn>
                    <ReduxColumn field="currentCity" sortBy="current_city">
                      Current City
                    </ReduxColumn>
                    <ReduxColumn field="currentState" sortBy="current_state">
                      Current State
                    </ReduxColumn>
                    <ReduxColumn field="permanentCity" sortBy="permanent_city">
                      Permanent City
                    </ReduxColumn>
                    <ReduxColumn
                      field="permanentState"
                      sortBy="permanent_state"
                    >
                      Permanent State
                    </ReduxColumn>
                    <ReduxColumn field="stayingIn" sortBy="staying_in">
                      Staying In
                    </ReduxColumn>
                    <ReduxColumn field="message" sortBy="message">
                      Message
                    </ReduxColumn>
                    <ReduxColumn
                      field="createdAt"
                      sortBy="created_at"
                      className="text-center"
                      width="150px"
                      minWidth="110px"
                      maxWidth="150px"
                      renderField={(row) => <span>{row.createdAt}</span>}
                    >
                      Submitted At
                    </ReduxColumn>
                    <ReduxColumn
                      field="statusText"
                      sortBy="statusText"
                      width="130px"
                      minWidth="130px"
                      maxWidth="130px"
                      renderField={(row) => (
                        <StatusText
                          backgroundColor={row.statusColor}
                          status={row.statusText}
                        />
                      )}
                    >
                      Status
                    </ReduxColumn>
                  </ReduxTable>
                </div>
              </div>
            </Form>
          </TabPane>
        </TabContent>
      </Form>
    </div>
  );
};

export default JobDetails;
