import {
    paymentCreateError,
    receivePaymentAddPortal,
    requestAddPayment,
  } from "../actions/payment";
  import { fetchList } from "../actions/table";
  import { endpoints } from "../api/endPoints";
  import { apiClient } from "../apiClient";
  import Toast from "../components/Toast";
  import ArrayList from "../lib/ArrayList";
  import { isBadRequest } from "../lib/Http";
  import Url from "../lib/Url";
  
  class PaymentPreferenceService {
    static async create(data, params, closeToggle, callback, errorCallback) {
      try {
        if (data) {
          return (dispatch) => {
            dispatch(requestAddPayment());
            apiClient
              .post(`${endpoints().accountPaymentPreferenceAPI}`, data)
              .then((response) => {
                let successMessage;
                if (response && response.data) {
                  successMessage = response.data.message;
                  closeToggle();
                  Toast.success(successMessage);
                  callback && callback(response);
                }
              })
              .then(() => {
                dispatch(
                  fetchList(
                    "accountPaymentPreference",
                    `${endpoints().accountPaymentPreferenceAPI}/search`,
                    1,
                    25,
                    params
                  )
                );
                dispatch(receivePaymentAddPortal());
              })
              .catch((err) => {
                dispatch(paymentCreateError(err));
                if (isBadRequest(err)) {
                  let errorMessage;
                  const errorRequest = err.response.request;
                  if (errorRequest && errorRequest.response) {
                    errorMessage = JSON.parse(errorRequest.response).message;
                  }
                  Toast.error(errorMessage);
                  callback && callback(errorMessage);
                }
              });
          };
        }
      } catch (err) {
        console.log(err);
      }
    }
  
    static async get(id) {
      try {
        if (id) {
          const response = await apiClient.get(
            `${endpoints().accountPaymentPreferenceAPI}/${id}`
          );
          const data = response.data.data;
          return data;
        }
      } catch (err) {
        console.log(err);
      }
    }
  
    static update(id, data, setIsSubmitting, cb) {
      try {
        if (id && data) {
          apiClient
            .put(`${endpoints().accountPaymentPreferenceAPI}/update/${id}`, data)
            .then((response) => {
              let successMessage;
              if (response && response.data) {
                successMessage = response.data.message;
                Toast.success(successMessage);
                return cb(successMessage);
              }
            })
            .catch((err) => {
              if (isBadRequest(err)) {
                let errorMessage;
                const errorRequest = err.response.request;
                if (errorRequest && errorRequest.response) {
                  errorMessage = JSON.parse(errorRequest.response).message;
                }
                Toast.error(errorMessage);
                setIsSubmitting(true);
              }
            });
        }
      } catch (err) {
        console.log(err);
      }
    }
  
    static async delete(id) {
      try {
        const response = await apiClient.delete(
          `${endpoints().accountPaymentPreferenceAPI}/delete/${id}`
        );
        Toast.success(response.data.message);
        return {
          type: "DELETE_RATING_SUCCESS",
          payload: response.data,
        };
      } catch (error) {
        const errorMessage = error.response?.data?.message;
        Toast.error(errorMessage);
        return {
          type: "DELETE_RATING_ERROR",
          error: true,
          payload: error,
        };
      }
    }
  
    static search = async (params) => {
      let queryString = await ArrayList.toQueryString(params);
  
      let response = await Url.get(
        `${endpoints().accountPaymentPreferenceAPI}/search`,
        queryString
      );
  
      return response;
    };
  }
  
  export default PaymentPreferenceService;
  