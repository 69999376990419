import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// Components
import ActivityList from "../../components/ActivityList";
import BreadCrumb from "../../components/Breadcrumb";
import DeleteModal from "../../components/DeleteModal";
import PageTitle from "../../components/PageTitle";
import PromotionForm from "./PromotionForm";
import Action from "../../components/Action";
import Spinner from "../../components/Spinner";

// Helpers
import ObjectName from "../../helpers/ObjectName";
import Permission from "../../helpers/Permission";

// Services
import { hasPermission } from "../../services/UserRolePermissionService";
import PromotionService from "../../services/PromotionService";
import AddButton from "../../components/AddButton";
import ProductSelectModal from "../../components/ProductSelectModal";
import { endpoints } from "../../api/endPoints";
import { apiClient } from "../../apiClient";
import Toast from "../../components/Toast";
import { fetchList } from "../../actions/table";
import { isBadRequest } from "../../lib/Http";
import PromotionProductList from "./PromotionProductList";

export const Tab = {
  GENERAL: "General",
  PRODUCT: "Product",
  HISTORY: "History",
};

const PromotionDetail = (props) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [detail, setDetail] = useState();
  const [isOpen, setIsOpen] = useState(false);
  const [editable, setEditable] = useState(true);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [addProductModal, setAddProductModal] = useState(false);
  const [isToggle, setIsToggle] = useState(false);
  const [MultiSelectProduct, setMultiSelectProduct] = useState([]);
  const [productIds, setProductIds] = useState([]);

  let id = props.match.params.id;
  const param = new URLSearchParams(props.history.location.search);
  const role = param.get("section");
  let showHistory = true;
    let showEditButton = hasPermission(Permission.VISITOR_EDIT);
  const [activeTab, setActiveTab] = useState(role ? role : Tab.GENERAL);

  const dispatch = useDispatch();
  const breadcrumbList = [
    { label: "Home", link: "/locationDashboard" },
    { label: "Promotion", link: "/promotion" },
    { label: "Promotion Detail", link: "" },
  ];

  const toggle = (tab) => {
    setIsOpen(!isOpen);
    setIsModelOpen(!isModelOpen);
    setActiveTab(tab || role);
  };

  useEffect(() => {
    getdetail();
    getproductId();
  }, []);

  const multiselect = (values) => {
    setMultiSelectProduct(values);
  };
  const _handleTabChange = (tab) => {
    props.history.push(`?tab=${tab}`);
  };

  const handleDelete = async (id, params) => {
    dispatch(
      await PromotionService.delete(id, (res) => {
        if (res) {
          props.history.push(`/promotion`);
        }
      })
    );
  };

  const getdetail = async () => {
    setIsLoading(true);
    let id = props.match.params.id;
    await PromotionService.get(id, (res) => {
      if (res) {
        setDetail(res);
        setIsLoading(false);
      }
    });
  };

  const actionsMenuList = [
    {
      value: "delete",
      label: "Delete",
    },
  ];

    if (showEditButton && editable) {
      actionsMenuList.unshift({
        value: "Edit",
        label: "Edit",
      });
    }

  const handleActionChange = (e) => {
    if (e == "Edit") {
      setEditable(false);
    }
    if (e == "delete") {
      setDeleteModal(true);
    }
  };

  if (isLoading) {
    <Spinner />;
  }
  const _toggle = (id) => {
    setIsToggle(!isToggle);
  };
  const handleSubmit = async (values) => {
    const createData = new FormData();
    createData.append("promotion_id", detail?.id);
    createData.append("productIds", MultiSelectProduct);
    createData.append("promotion_name", detail?.name);
    createData.append("account_type", detail?.account_type);
    createData.append("rewards_points", detail?.reward_points);
    createData.append("start_date", detail?.start_date);
    createData.append("end_date", detail?.end_date);

    try {
      const response = await apiClient.post(
        `${endpoints().promotionProductAPI}`,
        createData
      );
      if (response) {
        Toast.success(response.data.message);
        setAddProductModal(false);
        setMultiSelectProduct("");
        getproductId();
      }
      dispatch(
        fetchList(
          "promotionProduct",
          `${endpoints().promotionProductAPI}`,
          1,
          25,
          {
            promotionId: detail?.id,
          }
        )
      );
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        Toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  };
  // Add store modal toggling
  const addProductToggle = () => {
    setAddProductModal(false);
  };
  const getproductId = async () => {
    const response = await apiClient.get(
      `${endpoints().promotionProductAPI}?pagination=false`
    );
    const data = response.data.data;
    let productsId = [];
    for (let i = 0; i < data.length; i++) {
      let { product_id } = data[i];
      productsId.push(product_id);
    }
    setProductIds(productsId);
  };
  return (
    <div>
      <BreadCrumb list={breadcrumbList} />

      <div className="d-flex justify-content-between">
        <PageTitle label={detail?.name} />
        <div className="d-flex">
          {activeTab == Tab.PRODUCT ? (
            <AddButton
              label={"Add Product"}
              onClick={() => {
                setAddProductModal(true);
              }}
            />
          ) : (
            ""
          )}
          <div className="ps-2 d-flex">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        </div>
      </div>
      <ProductSelectModal
        modalOpen={addProductModal}
        toggle={_toggle}
        toggleModalClose={addProductToggle}
        handleSubmit={handleSubmit}
        BulkSelect={multiselect}
        history={props.history}
        apiURL={`${endpoints().productAPI}/search`}
        params={{
          promotion_id: detail?.id,
          excludeIds: productIds,
        }}
      />
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Promotion"
        id={props.match.params.id}
        label={detail?.name}
        deleteFunction={handleDelete}
      />

      <Nav tabs className="admin-tabs mt-2">
        {/* General Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Tab.GENERAL,
            })}
            onClick={() => {
              toggle(Tab.GENERAL);
              _handleTabChange(Tab.GENERAL);
            }}
          >
            {Tab.GENERAL}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Tab.PRODUCT,
            })}
            onClick={() => {
              toggle(Tab.PRODUCT);
              _handleTabChange(Tab.PRODUCT);
            }}
          >
            {Tab.PRODUCT}
          </NavLink>
        </NavItem>
        {showHistory && (
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === Tab.HISTORY,
              })}
              onClick={() => {
                toggle(Tab.HISTORY);
                _handleTabChange(Tab.HISTORY);
              }}
            >
              {Tab.HISTORY}
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent>
        {activeTab === Tab.GENERAL && (
          <TabPane>
            <div className="row card-body px-2 me-0 pe-0 pb-5 mb-4">
              <div className="col-lg-8 col-sm-8 col-md-8 card card-body">
                <PromotionForm getdetail={getdetail} rowValue={detail} form="edit" history={props.history} editable={editable} setEditable={setEditable} />
              </div>
            </div>
          </TabPane>
        )}
        {activeTab === Tab.PRODUCT && (
          <TabPane>
            <div className="row card-body px-2 me-0 pe-0 pb-5 mb-4">
              <div className="col-lg-8 col-sm-8 col-md-8 card card-body">
                <PromotionProductList
                  promotionId={detail?.id}
                  rowValue={detail}
                  selectedIds={MultiSelectProduct}
                  form="edit"
                />
              </div>
            </div>
          </TabPane>
        )}
        {showHistory && activeTab === Tab.HISTORY && (
          <TabPane>
            <ActivityList
              id={id}
              objectId={id}
              object_name={ObjectName.PROMOTION}
            />
          </TabPane>
        )}
      </TabContent>
    </div>
  );
};

export default PromotionDetail;
