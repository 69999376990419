import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

// Components
import Action from "../../components/Action";
import PageTitle from "../../components/PageTitle";
import TicketList from "../../components/TicketList";
import CreateTicketModel from "../../components/createTicketModel";
import DeleteModal from "../../components/DeleteModal";

// Helpers
import Cookie from "../../helpers/Cookie";
import * as ticketcolumns from "../../helpers/Ticket";

// Lib
import Cookies, { setCookie } from "../../lib/Helper";
import Url from "../../lib/Url";

// Services
import TicketService from "../../services/TicketService";

// Actions
import { fetchList } from "../../actions/table";

// API
import { endpoints } from "../../api/endPoints";

const Ticket = (props) => {
  const [array, setArray] = useState([]);
  const [rowValue, setRowValue] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [dueDatePermission, setDueDatePermission] = useState(true);
  const [selectedIds, setSelectedIds] = useState([]);
  const [ModalOpen, setModalsOpen] = useState(false);
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);
  const [isDeleteModel, setIsDeleteModel] = useState(false);

  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setModalOpen(!isModalOpen);
  };

  const handleCloseModal = () => {
    setRowValue(null);
    setName("");
    setDueDatePermission(true);
    setModalsOpen(false);
    setSelectedCheckBox(false);
    setSelectedCheckBox(true);
  };

  const FieldLabel = [
    {
      value: ticketcolumns?.FieldLabel?.CREATEDAT,
      label: ticketcolumns?.FieldLabel?.CREATEDAT,
    },
    {
      value: ticketcolumns?.FieldLabel?.REPORTER,
      label: ticketcolumns?.FieldLabel?.REPORTER,
    },
    {
      value: ticketcolumns?.FieldLabel?.REVIEWER,
      label: ticketcolumns?.FieldLabel?.REVIEWER,
    },
    {
      value: ticketcolumns?.FieldLabel?.PROJECT,
      label: ticketcolumns?.FieldLabel?.PROJECT,
    },
    {
      value: ticketcolumns?.FieldLabel?.SPRINT,
      label: ticketcolumns?.FieldLabel?.SPRINT,
    },
  ];

  const actionsMenuList = [
    { value: "Bulk Update", label: "Bulk Update" },
    { value: "Delete", label: "Delete" },
  ];

  const handleColumnChange = (e) => {
    const array = e;
    const arrayList = JSON.stringify(array);
    setCookie(Cookie.TICKET, arrayList);
    setArray(array);
  };

  useEffect(() => {
    const checkedList = Cookies.get(Cookie.TICKET);
    const checkedLists = checkedList ? JSON.parse(checkedList) : checkedList;
    if (checkedLists) {
      setArray(checkedLists);
    }
  }, []);



  const openToggle = () => {
    setModalsOpen(!ModalOpen);
  };

  const handleActionChange = (e) => {
    if (e === "Bulk Update") {
      if (selectedIds && selectedIds.length > 0) {
        openToggle();
      }
      else {
        toast.error("Select Atleast One Item");
      }
    }
    if (e == "Delete") {
      if (selectedIds && selectedIds.length > 0) {
        setIsDeleteModel(true);
      }
      else {
        toast.error("Select Atleast One Item");
      }
    }
  };

  const ticketDelete = async (selectedIds) => {
    let ids = { selectedId: selectedIds };

    if (selectedIds && selectedIds.length > 0) {
      await TicketService.bulkDelete(ids, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "ticket",
              `${endpoints().ticketAPI}/search`,
              Url.GetParam("page") ? Url.GetParam("page") : 1,
              Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
              {
                ...Url.GetAllParams(),
              }
            )
          );
          setIsDeleteModel(false);
          setSelectedIds([]);
          setSelectedCheckBox(false);
          setSelectedCheckBox(true);
        }
      });
    }
  };

  return (
    <>
      <DeleteModal
        isOpen={isDeleteModel}
        toggle={() => {
          setIsDeleteModel(false);
        }}
        title="Ticket Delete"
        id={selectedIds?.id}
        label={"Delete"}
        deleteFunction={() => {
          ticketDelete(selectedIds);
        }}
      />
      <div className="d-flex justify-content-between">
        <PageTitle label="Tickets" />
        <div className="d-flex">
          <div className="mx-2">
            <CreateTicketModel
              buttonLabel="Add New"
              showAddButton
              rowValue={rowValue}
              name={name}
              isModalOpen={isModalOpen}
              setModalOpen={setModalOpen}
              handleCloseModal={handleCloseModal}
              isDueDateDisabled={!dueDatePermission}
            />
          </div>
          <div className="ms-2">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        </div>
      </div>
      <TicketList
        history={props.history}
        array={array}
        showCustomDateFilter
        FieldLabel={FieldLabel}
        handleColumnChange={handleColumnChange}
        showSprintFilter
        showStatusFilter
        showUserFilter
        showReviewerFilter
        showReporterFilter
        newTableHeading
        isMultiSelect
        showStatusGroupFilter
        startDateFilter={Url.GetParam("startDate")}
        endDateFilter={Url.GetParam("endDate")}
        projectId={
          Cookies.get(Cookie.PROJECT_ID) || Url.GetParam("projectId") || ""
        }
        setRowValue={setRowValue}
        handleOpenModal={handleOpenModal}
        setName={setName}
        name={name}
        showProjectFilter
        setDueDatePermission={setDueDatePermission}
        DropdownWithCheckbox
        showTicketTypeFilter
        showTicketComponentFilter
        selectedCheckBox={selectedCheckBox}
        ModalOpen={ModalOpen}
        setModalsOpen={setModalsOpen}
        setSelectedIds={setSelectedIds}
        selectedIds={selectedIds}
      />
    </>
  );
};

export default Ticket;
