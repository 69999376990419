/**
 * Is data Empty
 * @param data
 */
export const isEmpty = (string) => {
  if (string == 0) {
    return true;
  } else return null;
};

/**
 * Is data Empty
 * @param data
 */
export const isNotEmpty = (string) => {
  if (string != 0) {
    return true;
  } else return null;
};

class String {
  static Get = (data) => {
    let formatData = data ? (data) : "";
    return formatData;
  }

  static replace = (data) => {
    if (data) {
      return data.replace(/[^\w\s]/gi, "")
    }
  }

  static isNotNull(value) {
    if (value && value !== "undefined" && value !== undefined && value !=="" && value !=="null") {
      return true;
    }
    return false;
  }
  static convertPropertiesToJSON(obj) {

    for (var prop in obj) {

      if (obj.hasOwnProperty(prop) && obj[prop] && obj[prop].value) {

        if (typeof obj[prop] === "object") {
          obj[prop] = JSON.stringify(obj[prop]);
        }
      }
    }

    return obj;
  }

  static concatName(firstName, lastName){
		const name = [];

		if (firstName) {
			name.push(firstName);
		}

		if (lastName) {
			name.push(lastName);
		}

		if (name) {
			return name.join(" ");
		}

		return "";
	};

  static toCamelCase(str) {
    if(str){
      return str.toLowerCase().replace(/^\w/, (c) => c.toUpperCase());
    }
  }

  static calculateExperienceFromWorkedDays(workedDays) {
		if (workedDays) {
			const daysInYear = 365;
			const experienceInYears = (workedDays / daysInYear).toFixed(2);
			return parseFloat(experienceInYears);
		} else {
			return ""
		}
	};
  static createLabel(str) {
    try{
      if (!str || typeof str !== "string") {
        return "";
      }
    
    return str
        .split("_") 
        .map(word => word.charAt(0).toUpperCase() + word.slice(1)) 
        .join(" "); 
    }catch(err){
      console.log(err);
    }
  }

  static isObjectEmpty(obj) {
    if(obj){
      return Object.values(obj).every(value => value === "" || value == null);
    }else{
      return false
    }
}
};

export default String;
