import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchList } from "../../actions/table";

// Components
import AddButton from "../../components/AddButton";
import PageTitle from "../../components/PageTitle";
import FineList from "./components/FineList";
import Drawer from "../../components/Drawer";
import DateSelector from "../../components/Date";
import Select from "../../components/Select";
import UserSelect from "../../components/UserSelect";
import TagSelect from "../../components/TagSelect";
import Currency from "../../components/Currency";
import SaveButton from "../../components/SaveButton";
import TextArea from "../../components/TextArea";
import Action from "../../components/Action";
import DeleteModal from "../../components/DeleteModal";

// Lib
import DateTime from "../../lib/DateTime";
import Numbers from "../../lib/Number";
import Url from "../../lib/Url";
import String from "../../lib/String";

import fineService from "../../services/FineService";
import StatusService from "../../services/StatusService";

// API
import { endpoints } from "../../api/endPoints";

// Helpers
import ObjectName from "../../helpers/ObjectName";
import { toast } from "react-toastify";

const Fines = (props) => {
  const { history, apiUrls, showFineList, hideDashBoardpage, bulkSelect } = props;
  let pathName = props?.history?.location?.pathname?.replace(/\/\d+$/, "");

  
  const dispatch = useDispatch();
  const [rowValue, setRowValue] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [ModalOpen, setModalOpen] = useState(false);
  const [notesChange, setNotesChange] = useState();
  const [reviewer, setReviewer] = useState();
  const [defaultAmount, setDefaultAmount] = useState(null);
  const [type, setType] = useState();
  const [dueDate, setDueDate] = useState();
  const [date, setDate] = useState();
  const [amount, setAmount] = useState(null);
  const [user, setUser] = useState(null);
  const [statusList, setStatusList] = useState([]);
  const [statusDetail, setStatusDetail] = useState("");
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);
  const [isDeleteModel, setIsDeleteModel] = useState(false);

  useEffect(() => {
    getStatusList();
  }, []);

  const Toggle = () => {
    setIsOpen(!isOpen);
  };

  const openToggle = () => {
    setModalOpen(!ModalOpen);
  };

  const closeToggle = () => {
    setModalOpen(false);
    setReviewer("");
    setDueDate("");
    setType("");
    setDate("");
    setDueDate("");
    setDefaultAmount(null);
    setStatusDetail("");
    setAmount(null);
    setUser(null);
    setNotesChange("");
    setSelectedCheckBox(false);
    setSelectedCheckBox(true);
  };

  const onBulkSelect = (ids) => {
    setSelectedIds(ids);
  };

  const handleTagChange = (values) => {
    let value = values;
    setAmount(value?.default_value ? value?.default_value : "");
    setDefaultAmount(value);
  };

  const handleStatusChange = (values) => {
    setStatusDetail(values?.values?.status ? values?.values?.status : "");
  };

  const handleNotesChange = (e) => {
    let value = e?.target?.value ? e?.target?.value : "";
    setNotesChange(value);
  };

  const handleDueDateChange = (values) => {
    setDueDate(values);
  };

  const handleDateChange = (values) => {
    setDate(values);
  };

  const handleAmountChange = (values) => {
    let value = values.target.value;
    setAmount(value);
  };

  const handleUserChange = (e) => {
    let value = e;
    setUser(value);
  };

  const handleReviewerChange = (e) => {
    let value = e;
    setReviewer(value);
  };

  const getStatusList = async () => {
    const status = await StatusService.getOption(ObjectName.FINE);
    setStatusList(status);
  };

  const initialValues = {
    date: date ? DateTime.getTodayDateByUserTimeZone(date) : "",
    user: user ? user : "",
    type: defaultAmount
      ? {
          label: defaultAmount?.label,
          value: defaultAmount?.value,
        }
      : "",
    notes: notesChange ? notesChange : "",
    reviewer: reviewer ? reviewer : "",
    status: statusDetail ? statusDetail : "",
    amount: amount ? amount : "",
    due_date: dueDate ? DateTime.getTodayDateByUserTimeZone(dueDate) : "",
  };

  const actionsMenuList = [
    {
      value: "Bulk Update",
      label: "Bulk Update",
    },
    {
      value: "Bulk Delete",
      label: "Bulk Delete",
    },
  ];

  const handleActionChange = (e) => {
    if (e == "Bulk Update") {
      if (selectedIds && selectedIds.length > 0) {
        openToggle();
      } else {
        toast.error("Select Atleast One Item");
      }
    }
    if (e == "Bulk Delete") {
      if (selectedIds && selectedIds.length > 0) {
        setIsDeleteModel(true);
      } else {
        toast.error("Select Atleast One Item");
      }
    }
  };

  const fineBulkUpdateForm = (
    <div>
      <div className="row">
        <div className="col-sm-6">
          <DateSelector
            name="date"
            label="Date"
            placeholder="Date"
            minWidth="160px"
            onChange={handleDateChange}
          />
        </div>
        <div className="col-sm-6">
          <Select
            name="status"
            label="Status"
            placeholder="Select Status"
            onInputChange={handleStatusChange}
            options={statusList}
          />
        </div>
      </div>
      <UserSelect label="User" handleUserChange={handleUserChange} />
      <TagSelect
        name="type"
        label="Type"
        placeholder="Type"
        params={{ type: "FineType" }}
        handleTagChange={handleTagChange}
      />
      <Currency
        name="amount"
        label="Amount"
        placeholder="Amount"
        onChange={handleAmountChange}
      />
      <DateSelector
        name="due_date"
        label="Due Date"
        placeholder="Date"
        minWidth="160px"
        onChange={handleDueDateChange}
      />
      <UserSelect
        label="Reviewer"
        name="reviewer"
        handleUserChange={handleReviewerChange}
      />
      <TextArea
        name="notes"
        label="Notes"
        placeholder="Enter Notes..."
        onChange={handleNotesChange}
      />
    </div>
  );

  const fineFooter = (
    <div>
      <SaveButton type="submit" label={"Save"} />
    </div>
  );

  let params = {
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    search: Url.GetParam("search"),
    status: Url.GetParam("status"),
    type: Url.GetParam("type"),
    user: Url.GetParam("user"),
    startDate: Url.GetParam("startDate"),
    endDate: Url.GetParam("endDate"),
    isFineType: true,
    date: Url.GetParam("date"),
    showTotalAmount:true
  };

  const fineBulkDelete = async (selectedIds) => {
    let ids = { selectedId: selectedIds };

    if (selectedIds && selectedIds.length > 0) {
      await fineService.bulkDelete(ids, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "fines",
              `${endpoints().fineApi}/search`,
              Url.GetParam("page") ? Url.GetParam("page") : 1,
              Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
              {
                ...params,
              }
            )
          );
          setIsDeleteModel(false);
          setSelectedIds([]);
          setSelectedCheckBox(false);
          setSelectedCheckBox(true);
        }
      });
    }
  };

  const handleSubmit = async (values) => {
    try {
      const data = new FormData();
      data.append("date", values.date);
      data.append("due_date", values && values.due_date);
      data.append("user", Numbers.Get(values?.user?.id));
      data.append("type", Numbers.Get(values?.type?.value));
      data.append("amount", values?.amount ? values?.amount : "");
      data.append("status", String.Get(values?.status.value));
      data.append("due_date", values?.due_date ? values?.due_date : "");
      data.append("notes", String.Get(values?.notes));
      data.append("reviewer", Numbers.Get(values?.reviewer?.id));
      data.append("fineIds", selectedIds);

      if (selectedIds && selectedIds.length > 0) {
        await fineService.bulkUpdate(data, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "fines",
                `${endpoints().fineApi}/search`,
                Url.GetParam("page") ? Url.GetParam("page") : 1,
                Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
                {
                  ...params,
                }
              )
            );
            closeToggle();
            setSelectedIds([]);
            setSelectedCheckBox(false);
            setSelectedCheckBox(true);
          }
        });
      }
      closeToggle();
    } catch (err) {
      console.log(err);
    } finally {
    }
  };

  return (
    <div>
      <DeleteModal
        isOpen={isDeleteModel}
        toggle={() => {
          setIsDeleteModel(false);
        }}
        title="Fine"
        id={selectedIds?.id}
        label={"Bulk Delete"}
        deleteFunction={() => {
          fineBulkDelete(selectedIds);
        }}
      />
      <Drawer
        modelTitle={"Bulk Update"}
        DrawerBody={fineBulkUpdateForm}
        DrawerFooter={fineFooter}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        initialValues={initialValues}
        enableReinitialize
        handleOpenModal={openToggle}
        handleCloseModal={closeToggle}
        handleDrawerClose={closeToggle}
        isModalOpen={ModalOpen}
      />
      {!showFineList && (
        <div className="d-flex justify-content-between pb-3">
          <PageTitle label={"Fines"} />
          <div className="d-flex">
            <AddButton
              className="mt-0 mt-sm-1"
              label="Add New"
              onClick={() => {
                Toggle();
                setRowValue("");
              }}
            />
            <div className="ms-2">
              <Action
                dropdownLinks={actionsMenuList}
                handleChange={handleActionChange}
              />
            </div>
          </div>
        </div>
      )}
      <FineList
        setRowValue={setRowValue}
        rowValue={rowValue}
        Toggle={Toggle}
        setIsOpen={setIsOpen}
        onBulkSelect={onBulkSelect}
        isOpen={isOpen}
        history={history}
        selectedCheckBox={selectedCheckBox}
        pathName={pathName}
        param={{ isFineType: true }}
        apiURL={endpoints().fineApi}
        service={fineService}
        apiUrls={apiUrls}
        objectName={ObjectName.FINE}
        showDropdownCheckBox={hideDashBoardpage ? false : true}
        bulkSelect={bulkSelect}
      />
    </div>
  );
};

export default Fines;
