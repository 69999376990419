
// API
import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";

// Components
import Toast from "../components/Toast";

// Helpers
import { HttpStatus } from "../helpers/HttpStatus";

// Lib
import { isBadRequest, SUCCESS_RESPONSE } from "../lib/Http";

class BonusService {
    // Update Purchase Order

    static update = async (id, data, callback) => {
        apiClient
            .put(`${endpoints().bonusApi}/${id}`, data)
            .then((res) => {
                if (res.status == SUCCESS_RESPONSE) {
                    Toast.success(res?.data?.message);
                    return callback(res.data.message);
                }
            })
            .catch((err) => {
                if (isBadRequest(err)) {
                    let errorMessage;
                    const errorRequest = err.response.request;
                    if (errorRequest && errorRequest.response) {
                        errorMessage = JSON.parse(errorRequest.response).message;
                    }
                    Toast.error(errorMessage);
                }
            });
        return data;
    };

    static async get(id) {
        try {
            if (id) {
                let response = await apiClient.get(`${endpoints().bonusApi}/${id}`);

                return response;
            }
        } catch (err) {
            console.log(err);
            return null;
        }
    }

    static bulkUpdate = async (data, callback) => {
        apiClient
            .put(`${endpoints().bonusApi}/bulkUpdate`, data)
            .then((res) => {
                if (res.status == SUCCESS_RESPONSE) {
                    Toast.success(res?.data?.message);
                    return callback(res.data.message);
                }
            })
            .catch((err) => {
                if (isBadRequest(err)) {
                    let errorMessage;
                    const errorRequest = err.response.request;
                    if (errorRequest && errorRequest.response) {
                        errorMessage = JSON.parse(errorRequest.response).message;
                    }
                    Toast.error(errorMessage);
                }
            });
        return data;
    };

    // Add Bonus
    static add = (data, params, callback) => {
        return (dispatch) => {

            apiClient
                .post(endpoints().bonusApi, data)
                .then((response) => {
                    let successMessage;
                    if (response && response.data) {
                        successMessage = response.data.message;
                        Toast.success(successMessage);

                        return callback(response.data.id);
                    }
                })
                .catch((error) => {

                    if (
                        error.response &&
                        error.response.status >= HttpStatus.BAD_REQUEST
                    ) {
                        let errorMessage;
                        const errorRequest = error.response.request;
                        if (errorRequest && errorRequest.response) {
                            errorMessage = JSON.parse(errorRequest.response).message;
                        }

                        Toast.error(errorMessage);
                        // toast.error(errorMessage);
                        console.error(errorMessage);
                    }
                });
        };
    };

    static updateStatus = (id, data, callback) => {
        try {
            apiClient
                .put(`${endpoints().bonusApi}/status/${id}`, data)
                .then((response) => {
                    let successMessage;
                    if (response && response.data) {
                        successMessage = response.data.message;
                        Toast.success(successMessage);
                        return callback && callback(successMessage)
                    }
                })
                .catch((error) => {
                    if (isBadRequest(error)) {
                        let errorMessage;
                        const errorRequest = error.response.request;
                        if (errorRequest && errorRequest.response) {
                            errorMessage = JSON.parse(errorRequest.response).message;
                        }
                        Toast.error(error.response.data.message);
                        console.error(errorMessage);
                    }
                });
        } catch (err) {
            console.log(err);
        }
    };

    static delete = (id, callback) => {
        return new Promise((resolve, reject) => {
            try {
                apiClient
                    .delete(`${endpoints().bonusApi}/${id}`)
                    .then((response) => {
                        if (response && response.data) {
                            const successMessage = response.data.message;
                            Toast.success(successMessage);
                            return callback && callback(successMessage);
                        } else {
                            reject("No response data received");
                        }
                    })
                    .catch((error) => {
                        reject(error);
                        if (isBadRequest(error)) {
                            let errorMessage;
                            const errorRequest = error.response.request;
                            if (errorRequest && errorRequest.response) {
                                errorMessage = JSON.parse(errorRequest.response).message;
                            }
                            Toast.error(errorMessage);
                        }
                    });
            } catch (error) {
                reject(error);
                console.error(error);
            }
        });
    };

    static bulkDelete = async (selectedId, callback) => {
        try {
            const response = await apiClient.delete(`${endpoints().bonusApi}/bulkDelete`, {
                data: selectedId,
            });
            if (response.status === SUCCESS_RESPONSE) {
                Toast.success(response?.data?.message);
                if (callback) {
                    return callback(response.data.message);
                }
            }
        } catch (error) {
            if (isBadRequest(error)) {
                let errorMessage;
                const errorRequest = error.response.request;
                if (errorRequest && errorRequest.response) {
                    errorMessage = JSON.parse(errorRequest.response).message;
                }
                Toast.error(errorMessage);
            }
        }
    };

}

export default BonusService;
