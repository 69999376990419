import React, { useState } from "react";
import AvatarCard from "../../components/AvatarCard";
import BreadCrumb from "../../components/Breadcrumb";
import PageTitle from "../../components/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import Currency from "../../lib/Currency";
import ProductCard from "../../views/product/components/productCard";
import DailySummaryReportFilter from "./components/Filter";

const DailySummaryReport = (props) => {
    let { history } = props;

    const [data, setData] = useState([]);

    const breadcrumbList = [
        { label: "Home", link: "/locationDashboard" },
        {
            label: "Reports",
            link: "/report",
        },
        {
            label: "Daily Summary Report ",
            link: "",
        },
    ];

    return (
        <div>
            <BreadCrumb list={breadcrumbList} />
            <PageTitle label="Daily Summary Report" />
            <DailySummaryReportFilter history={history} setData={setData} />

            {/* ✴----No Order--✴ */}
            <ReduxTable
                id="noOrder"
                apiURL={[]}
                paramsToUrl={true}
                dataList={data?.noOrderData}
            >
                <ReduxColumn field="locationName" colspan={2} disableOnClick>
                    {`No Order (${data?.cardData?.noOrderDataCount || 0})`}
                </ReduxColumn>
                <ReduxColumn
                    field="differenceHours"
                    showHeader={false}
                    className="text-center"
                >
                    Name
                </ReduxColumn>
            </ReduxTable>

            {/* ✴---Top 10 Orders---✴ */}
            <ReduxTable
                id="top10Orders"
                apiURL={[]}
                paramsToUrl={true}
                dataList={data?.getTop10OrderData}
            >
                <ReduxColumn
                    field="locationName"
                    colspan={2}
                    disableOnClick
                    renderField={(row) => {
                        return (
                            <div className="d-flex">
                                <div>
                                    <div>{row?.date}</div>
                                    <div>{row?.locationName}</div>
                                </div>
                            </div>
                        );
                    }}
                >
                    Top 10 Orders
                </ReduxColumn>
                <ReduxColumn
                    field="total_amount"
                    className="text-end"
                    showHeader={false}
                ></ReduxColumn>
            </ReduxTable>

            {/* ✴---Top 10 Order Products---✴ */}
            <ReduxTable
                id="top10OrderProducts"
                apiURL={[]}
                paramsToUrl={true}
                dataList={data?.getTop10OrderProductData}
            >
                <ReduxColumn
                    field="locationName"
                    disableOnClick
                    renderField={(row) => {
                        return (
                            <ProductCard
                                productImageIcon
                                square
                                productName={row?.product_name}
                                url={row?.media_url}
                                brandName={row?.brand_name}
                                size={row?.size}
                                unit={row?.unit}
                                salePrice={row?.sale_price}
                                id={row?.product_id}
                                brand_id={row?.brand_id}
                            />
                        );
                    }}
                >
                    Top 10 Order Products
                </ReduxColumn>
            </ReduxTable>

            {/* ✴---Replenishment---✴ */}
            <ReduxTable
                id="replenishment"
                apiURL={[]}
                paramsToUrl={true}
                dataList={data?.replenishData}
            >
                <ReduxColumn
                    field="locationName"
                    colspan={2}
                    disableOnClick
                    renderField={(row) => {
                        return <AvatarCard firstName={row.userName} url={row.image} />;
                    }}
                >
                    {`Replenishment (${data?.cardData?.totalReplenishCount || 0})`}
                </ReduxColumn>
                <ReduxColumn
                    field="product_count"
                    className="text-center"
                    showHeader={false}
                ></ReduxColumn>
            </ReduxTable>

            {/* ✴---Cancelled Orders---✴ */}
            <ReduxTable
                id="cancelledOrders"
                apiURL={[]}
                paramsToUrl={true}
                dataList={data?.cancelOrderData}
            >
                <ReduxColumn
                    field="locationName"
                    colspan={2}
                    disableOnClick
                    renderField={(row) => {
                        return (
                            <AvatarCard
                                firstName={row?.userName}
                                url={row?.image}
                                locationName={row?.locationName}
                            />
                        );
                    }}
                >
                    {`Cancelled Orders (${data?.cardData?.cancelOrderCount || 0})`}
                </ReduxColumn>
                <ReduxColumn
                    field="count"
                    className="text-center"
                    showHeader={false}
                ></ReduxColumn>
            </ReduxTable>


            {/* ✴---Cancelled Order Products---✴ */}
            <ReduxTable
                id="cancelledOrderProduct"
                apiURL={[]}
                paramsToUrl={true}
                dataList={data?.cancelledOrderProductData}
            >
                <ReduxColumn
                    field="locationName"
                    colspan={3}
                    disableOnClick
                    renderField={(row) => {
                        return (
                            <AvatarCard
                                firstName={row?.first_name}
                                lastName={row?.last_name}
                                url={row?.media_url}
                            />
                        );
                    }}
                >
                    {`Cancelled Order Products (${data?.cardData?.cancelledOrderProductCount || 0})`}
                </ReduxColumn>
                <ReduxColumn
                    field="total_quantity"
                    className="text-center"
                    showHeader={false}
                ></ReduxColumn>
                 <ReduxColumn
                    field="total_price"
                    className="text-center"
                    showHeader={false}
                ></ReduxColumn>
            </ReduxTable>

            {/* ✴---Draft Orders---✴ */}
            <ReduxTable
                id="draftOrders"
                apiURL={[]}
                paramsToUrl={true}
                dataList={data?.draftOrderData}
            >
                <ReduxColumn
                    field="locationName"
                    colspan={2}
                    disableOnClick
                    renderField={(row) => {
                        return (
                            <AvatarCard
                                firstName={row?.userName}
                                url={row?.image}
                                locationName={row?.locationName}
                            />
                        );
                    }}
                >
                    {`Draft Orders (${data?.cardData?.draftOrderCount || 0})`}
                </ReduxColumn>
                <ReduxColumn
                    field="count"
                    className="text-center"
                    showHeader={false}
                ></ReduxColumn>
            </ReduxTable>

            {/* ✴---Visitors---✴ */}
            <ReduxTable
                id="visitors"
                apiURL={[]}
                paramsToUrl={true}
                dataList={data?.visitorData}
            >
                <ReduxColumn
                    colspan={2}
                    disableOnClick
                    renderField={(row) => {
                        return (
                            <div>
                                <AvatarCard
                                    firstName={row?.name}
                                    url={row?.media_url}
                                    dateTime={row?.dateTime}
                                    typeName={row?.typeName}
                                    purpose={row?.purpose}
                                />
                            </div>
                        );
                    }}
                >
                    {`Visitors (${data?.cardData?.visitorDataCount || 0})`}
                </ReduxColumn>
                <ReduxColumn field="count" showHeader={false}></ReduxColumn>
            </ReduxTable>

            {/* ✴---SaleSettleMent Missing---✴ */}
            <ReduxTable
                id="saleSettlementMissing"
                apiURL={[]}
                paramsToUrl={true}
                dataList={data?.saleSettlementData}
            >
                <ReduxColumn
                    field="locationName"
                    colspan={2}
                    disableOnClick
                    renderField={(row) => {
                        return (
                            <div className="d-flex">
                                <div>
                                    <div>{row?.location}</div>
                                    <div>{row?.shiftName}</div>
                                </div>
                            </div>
                        );
                    }}
                >
                    {`Sales Settlement Missing (${data?.cardData?.saleSettlementDataCount || 0})`}
                </ReduxColumn>
                <ReduxColumn field="count" showHeader={false}></ReduxColumn>
            </ReduxTable>

            {/* ✴---Cash In Location---✴ */}
            <ReduxTable
                id="cashInLocation"
                apiURL={[]}
                paramsToUrl={true}
                dataList={data?.cashInLocationData}
            >
                <ReduxColumn field="location" disableOnClick colspan={2}>
                    Cash In Location - Mismatch
                </ReduxColumn>
                <ReduxColumn
                    field="count"
                    showHeader={false}
                    className="text-end"
                    renderField={(row) => {
                        return (
                            <div
                                className={
                                    parseInt(row?.minimumCashInStore) >
                                        parseInt(row?.cashInLocation)
                                        ? "text-danger"
                                        : "text-primary"
                                }
                            >
                                {Currency.Format(row?.cashInLocation)}
                            </div>
                        );
                    }}
                ></ReduxColumn>
            </ReduxTable>

            {/* ✴---Attendance - Additional Shift---✴ */}
            <ReduxTable
                id="additionalShift"
                apiURL={[]}
                paramsToUrl={true}
                dataList={data?.getAdditionalDayAndShiftData?.data}
            >
                <ReduxColumn
                    colspan={2}
                    disableOnClick
                    renderField={(row) => {
                        return (
                            <div>
                                <AvatarCard
                                    firstName={`${row?.userName} : ${row?.locationName} - (${row?.shiftName})`}
                                    url={row?.image}
                                />
                            </div>
                        );
                    }}
                >
                    {`Attendance - Additional Shift (${data?.cardData?.getAdditionalDayAndShiftCount || 0})`}
                </ReduxColumn>
            </ReduxTable>

            {/* ✴---Orders---✴ */}
            <ReduxTable
                id="orders"
                apiURL={[]}
                paramsToUrl={true}
                dataList={data?.orderData}
            >
                <ReduxColumn
                    field="locationName"
                    colspan={2}
                    disableOnClick
                    renderField={(row) => {
                        return (
                            <div>
                                <div>
                                    <div>{row?.locationName}</div>
                                    <div>{row?.totalOrders}</div>
                                </div>
                            </div>
                        );
                    }}
                >
                    {`Orders (${data?.cardData?.orderCount || 0})`}
                </ReduxColumn>
                <ReduxColumn
                    field="totalAmount"
                    showHeader={false}
                    className="text-end"
                ></ReduxColumn>
            </ReduxTable>
        </div>
    );
};

export default DailySummaryReport;
