import React, { useEffect, useState } from "react";
import Select from "./Select";
import ArrayList from "../lib/ArrayList";
import AccountService from "../services/AccountService";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareArrowUpRight } from "@fortawesome/free-solid-svg-icons";

const AccountSelect = (props) => {
  let {
    name,
    handleVendorChange,
    vendorList,
    required,
    label,
    placeholder,
    isDisabled,
    accountCategory,
    customOption,
    accountId,
    showAccountDetailsPageLink,
    defaultValue=null,
    accountSelectParams={}
  } = props;

  const [vendorOption, setVendorOption] = useState([]);


  useEffect(() => {
    getVendor()
  }, [defaultValue])
  
  const getVendor = async () => {
      
        let params = { accountCategory: accountCategory, defaultValue: defaultValue, ...accountSelectParams };
      const list = await AccountService.getOption(params);
      setVendorOption(list);
      vendorList && vendorList(list);
  };

  return (
    <>
      <div className="d-flex">
        <Select
          name={name ? name : "vendor"}
          placeholder={placeholder ? placeholder : "Select Account"}
          options={customOption ? customOption : vendorOption}
          handleChange={handleVendorChange}
          required={required}
          label={label}
          isDisabled={isDisabled}
          autoFocus={getVendor}
        />
        {showAccountDetailsPageLink && (
          <div className="flex-shrink-1 bd-highlight mt-4 pt-1">
            <Link target="_blank" to={`/accounts/${accountId}`}>
              <FontAwesomeIcon
                className="text-body-secondary p-1"
                icon={faSquareArrowUpRight}
                style={{
                  fontSize: "40px",
                }}
              />
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default AccountSelect;
