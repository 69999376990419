import classNames from "classnames";
import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabPane } from "reactstrap";
import PageTitle from "../../components/PageTitle";
import DragAndDropTable from "../../components/StatusTable/StatusDragAndDropTable";
import ObjectName from "../../helpers/ObjectName";
import { Tabs } from "../../helpers/Setting";
import SettingTab from "./Components/SettingTab";
import AddButton from "../../components/AddButton";
import Url from "../../lib/Url";

const Bills = (props) => {
  let { history } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(Url.GetParam("tab") ? Url.GetParam("tab") :Tabs.STATUS);
  const [row, setRow] = useState();
  const _toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    props.history.push(`?tab=${tab}`);
  };

  const handleAddButtonClick = () => {
    _toggle();
    setRow("");
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <PageTitle label="Bill" />
        {activeTab === Tabs.STATUS && (
          <AddButton
            className="ms-2 me-1"
            label="Add"
            onClick={handleAddButtonClick}
          />
        )}
      </div>
      <Nav tabs className="admin-tabs mb-3">
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tabs.STATUS,
            })}
            onClick={() => {
              handleTabChange(Tabs.STATUS);
            }}
          >
            {Tabs.STATUS}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeTab === Tabs.SETTING,
            })}
            onClick={() => {
              handleTabChange(Tabs.SETTING);
            }}
          >
            {Tabs.SETTING}
          </NavLink>
        </NavItem>
      </Nav>
      {activeTab === Tabs.STATUS && (
        <TabPane>
          <DragAndDropTable
            history={history}
            objectName={ObjectName.BILL}
            showUrl
            _toggle={_toggle}
            isOpen={isOpen}
            row={row}
            setRow={setRow}
          />
        </TabPane>
      )}
      {activeTab === Tabs.SETTING && (
        <TabPane>
          <SettingTab />
        </TabPane>
      )}
    </>
  );
};

export default Bills;
