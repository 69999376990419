import React, { useEffect, useState } from "react";
import "./style.scss";
import NoImage from "../../assets/img/noImage.png";
import Cookie from "../../lib/Helper";
import { COOKIE_SESSION_TOKEN } from "../../lib/Cookie";

const index = ({
  src,
  alt,
  size,
  className,
  minWidth,
  maxWidth,
  maxHeight,
  minHeight,
  queryParams,
}) => {
  const [imageSrc, setImageSrc] = useState(NoImage);

  useEffect(() => {
    const fetchImage = async () => {
      if (!src) return;

      try {
        const urlWithQuery = buildSrcWithQuery(src, queryParams);

        const response = await fetch(urlWithQuery, {
          headers: {
            Authorization: Cookie.get(COOKIE_SESSION_TOKEN) || "",
          },
        });

        if (!response.ok) {
          setImageSrc(urlWithQuery);
          return;
        }

        const blob = await response?.blob();
        setImageSrc(URL.createObjectURL(blob));
      } catch (error) {
        console.error("Error fetching image:", error.message);
        setImageSrc(NoImage);
      }
    };

    const isFetchRequired = shouldFetchImage(src);
    if (isFetchRequired) {
      fetchImage();
    } else {
      setImageSrc(buildSrcWithQuery(src, queryParams));
    }
  }, [src, queryParams]);

  const buildSrcWithQuery = (baseSrc, params) => {
    if (!params) return baseSrc;
    const queryString = new URLSearchParams(params).toString();
    return `${baseSrc}?${queryString}`;
  };

  const shouldFetchImage = (url) => {
    const domainsToAllow = ["amazonaws.com"];

    try {
      const parsedUrl = new URL(url);

      const shouldFetch = !domainsToAllow.some((domain) =>
        parsedUrl?.hostname?.toLowerCase()?.endsWith(domain)
      );

      return shouldFetch;
    } catch (error) {
      console.error("Invalid URL passed to shouldFetchImage:", error.message);
      return false; 
    }
  };

  const originalImageUrl = src ? buildSrcWithQuery(src, queryParams) : NoImage;

  const handleClick = (e) => {
    e.preventDefault();
  };

  return (
    <div className="d-flex justify-content-center">
      <a href={originalImageUrl} target="_blank" rel="noopener noreferrer" onClick={handleClick}>
        <img
          src={imageSrc}
          alt={alt || "No Image"}
          className={`${size} ${className}`}
          style={{
            minWidth: minWidth,
            maxWidth: maxWidth,
            minHeight: minHeight,
            maxHeight: maxHeight,
          }}
        />
      </a>
    </div>
  );
};

export default index;
