import moment from "moment";

import Cookies from "../lib/Helper";
import { COOKIE_TIME_ZONE } from "../lib/Cookie";
import Number from "./Number";

const currentDate = new Date(); // Get the current date
const currentYear = currentDate.getFullYear(); // Get the current year
const currentMonth = currentDate.getMonth();

class DateTime {

  // To avoid the TimeZone issue with toISOString()
  static toISOString(date) {
    return moment(date).format("YYYY-MM-DDT00:00:00.000") + "Z";
  }

  static toISOStringDate(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  static toISOStrings(date) {
    return moment(date).format("YYYY-MM-DDT23:59:59.000") + "Z";
  }
  static formatDate(date) {
    return moment(date).format("YYYY-MM-DD");
  }
  static getDate(date) {
    if(date){
    return moment(date).format("DD-MMM-YYYY");
    }
    else{
      return null
    }
  }
  static currentDate() {
    return moment().format("YYYY-MM-DD");
  }

  static subtract(value) {
    if (value !== undefined) {
      const newDate = new Date();

      const weeklyDate = new Date(newDate.getTime() - value * 24 * 60 * 60 * 1000);

      const date = weeklyDate.toISOString().split('T')[0];

      return date;
    }
  }


  /**
 * convert UTC time to local time
 *
 * @param date
 * @returns {null|*}
 */
  static UTCtoLocalTime(date) {
    if (!date) {
      return null;
    }
    let gmtDateTime = moment.utc(date);

    return gmtDateTime.local().format("DD-MM-YY hh:mm A");
  }

  static LocalTime(date) {
    if (!date) {
      return null;
    }
    let gmtDateTime = moment.utc(date);

    return gmtDateTime.local().format("hh:mm A");
  }

  /**
   * convert UTC time to local time with seconds
   *
   * @param date
   * @returns {null|*}
   */
  static DateAndTime(date) {
    if (!date) {
      return null
    }
    let gmtDateTime = moment.utc(date);

    return gmtDateTime.local().format("DD-MM-YYYY hh:mm:ss A");
  }

  // Get DateTime Function
  static Get = (date) => {
    if (!date) {
      return null;
    }
    let gmtDateTime = moment.utc(date);

    return gmtDateTime.local().format("DD-MM-YYYY hh:mm:ss A");
  };

  // UTC To Local Time And Mmm Format
  static UTCtoLocalTimeAndMmmFormat(date) {
    if (!date) {
      return null;
    }
    let gmtDateTime = moment.utc(date);

    return gmtDateTime.local().format("DD-MMM-YY hh:mm A");
  }

  static getSchedulerTime(duration) {
    let Interval = [];
    const IntervalInHour = [];
    const IntervalInMin = [
      { value: 1, label: "1 Min" },
      { value: 5, label: "5 Min" },
      { value: 15, label: "15 Min" },
      { value: 30, label: "30 Min" },
    ];
    for (let i = 1; i <= (duration ? duration : 24); i++) {
      IntervalInHour.push({
        value: 60 * i,
        label: `${i} Hour`,
      });
    }
    Interval = IntervalInMin.concat(IntervalInHour);

    return Interval;
  }

  static HoursAndMinutes(totalMinutes) {
    if(totalMinutes>0){
      const minutes = totalMinutes % 60;
      const hours = Math.floor(totalMinutes / 60);
      return `${hours} hr${hours > 1 ? "s" : ""} ${minutes} min${minutes > 1 ? "s" : ""
        }`;
    }else{
      return ""
    }
  }

  static convertToISO(dateString) {
    // Split the date string by "/" to get an array of date components
    const dateComponents = dateString.split("/");

    // Get the current year and use it to create a full year string (e.g. "2021")
    const currentYear = new Date().getFullYear().toString();
    const fullYear = currentYear.slice(0, 2) + dateComponents[2];

    // Use the date components to create a new Date object
    const date = new Date(fullYear, dateComponents[1] - 1, dateComponents[0]);

    // Use the toISOString() method to get the ISO string representation of the date
    return date.toISOString();
  }

  static getToday() {
    return this.formatDate(this.getDateTimeByUserProfileTimezone(new Date()));
  }

  static TimeNow(date) {
    if (!date) {
      return null;
    }
    return moment(date).fromNow();
  }

  static currentMonthStartDate() {

    let currentDate = new Date(currentYear, currentMonth, 1)

    return this.formatDate(currentDate)
  }

  static getDateAndTime(inputDate) {
    if (inputDate) {
      const [date, time] = inputDate.split("T");
      if (time === '00:00:00.000Z') {
        return this.getDateTimeByUserProfileTimezone(date, "DD-MMM-YYYY");
      } else {
        return this.getDateTimeByUserProfileTimezone(inputDate);
      }
    } else {
      return null;
    }
  }

  static getTime(date) {

    const loginTime = new Date(date);
    const timeString = loginTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false });

    return timeString

  }

  static getTimeZones() {

    let timeZones = Intl.supportedValuesOf('timeZone')

    return timeZones
  }


  static getTimeZoneDateTime(dateTime, format) {

    let timeZone = Cookies.get(COOKIE_TIME_ZONE);

    if (dateTime && timeZone) {
      let dateValue = new Date(dateTime).toLocaleString("en-US", {
        timeZone: timeZone
      });

      return moment(dateValue).format(format)
    }
    return dateTime ? moment(dateTime).format(format) : ""
  }

  static getDateTimeByUserProfileTimezone(date, format = "DD-MMM-YY hh:mm A") {

    let dateTime = this.getTimeZoneDateTime(date, format)

    if (dateTime) {
      return dateTime;
    }

    return this.UTCtoLocalTimeAndMmmFormat(date);
  }

  static getDateByUserProfileTimezone(date, format = "YYYY-MM-DD") {

    let dateTime = this.getTimeZoneDateTime(date, format)

    if (dateTime) {
      return dateTime;
    }

    return this.UTCtoLocalTimeAndMmmFormat(date);
  }

  static getUserTimeZoneTime(time, format = "hh:mm A") {

    let convertedTime = this.getTimeZoneDateTime(time, format)

    if (convertedTime) {
      return convertedTime;
    }

    return time ? moment(time).format(format) : "";
  }

  static getTodayDateByUserTimeZone(date = new Date()) {

    let convertedDate = this.getTimeZoneDateTime(date, "YYYY-MM-DD")

    if (convertedDate) {
      return convertedDate;
    }

    return date
  }

  static getDateByUserProfileTimeZoneFrontEndFormat(date) {

    let convertedDate = this.getTimeZoneDateTime(date, "DD-MMM-YYYY")

    if (convertedDate) {
      return convertedDate;
    }

    return date
  }

  static timeToDateConversion(time) {

    if (time) {
      const dateObj = new Date();

      const timeObject = moment(time, "HH:mm:ss.SSS");

      dateObj.setHours(timeObject.get('hour'), timeObject.get('minute'));

      return dateObj;
    }

  }
  static getHours(difference) {

    const totalHours = 7 * 24; // Total hours in one week (168 hours)
    const numElements = totalHours / difference;

    let hoursSet = new Set([1, 3, 6]);

    for (let i = 1; i <= numElements; i++) {
      hoursSet.add(i * difference);
    }

    let hoursList = Array.from(hoursSet)
      .sort((a, b) => a - b)
      .map((hour) => ({
        label: `${hour} hour${hour > 1 ? 's' : ''}`, 
        value: hour,
      }));

    return hoursList;
  }

  static getYears() {

    const yearOption = [];
    for (let i = 2010; i <= 2030; i++) {
      yearOption.push({
        label: i,
        value: i,
      });
    }
    return yearOption;
  }

  static CurrentStartMonth() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const startDate = new Date(currentYear, currentMonth, 1);

    return startDate;
  }

  static CurrentEndMonth() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    const currentYear = currentDate.getFullYear();

    const endDate = new Date(currentYear, currentMonth + 1, 0);

    return endDate;
  }

  static formatMilliseconds(milliseconds) {
    if(Number.isNotNull(milliseconds)){
    const totalSeconds = Math.floor(milliseconds / 1000); // Convert to seconds
    const hrs = Math.floor(totalSeconds / 3600); // Calculate hours
    const mins = Math.floor((totalSeconds % 3600) / 60); // Calculate minutes
    const secs = totalSeconds % 60; // Calculate seconds
    const formattedDuration = [
      hrs > 0 ? `${hrs} hr${hrs > 1 ? "s" : ""}` : null,
      mins > 0 ? `${mins} min${mins > 1 ? "s" : ""}` : null,
      `${secs} sec${secs > 1 ? "s" : ""}`,
    ]
      .filter(Boolean) // Remove null values for cleaner output
      .join(" "); // Join all components with space

    return formattedDuration;
  }
  }

  static formatNetworkUsage(networkBytes) {
    if(Number.isNotNull(networkBytes)){
      const formatBytes = (bytes) => {
        const units = ["B", "KB", "MB", "GB", "TB"];
        let unitIndex = 0;
        let readableBytes = bytes;
    
        while (readableBytes >= 1024 && unitIndex < units.length - 1) {
          readableBytes /= 1024;
          unitIndex++;
        }
    
        return `${readableBytes?.toFixed(2)} ${units[unitIndex]}`;
      };
    
      return formatBytes(networkBytes);
    }
  }

}

export default DateTime;
