import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Currency from "../../../components/Currency";
import DateSelector from "../../../components/Date";
import Drawer from "../../../components/Drawer";
import SaveButton from "../../../components/SaveButton";
import SingleCheckbox from "../../../components/SingleCheckbox";
import Text from "../../../components/Text";
import ProductPriceService from "../../../services/ProductPriceService";
import Percentage from "../../../components/Percentage";
import StatusSelect from "../../../components/SelectStatus";
import ObjectName from "../../../helpers/ObjectName";
import StatusService from "../../../services/StatusService";
import Url from "../../../lib/Url";

const ProductPriceEditModal = (props) => {
  const [priceStatusValue, setPriceStatusValue] = useState();
  const {
    isOpen,
    toggle,
    productId,
    priceData,
    setIsSubmit,
    isSubmit,
    selectedDate,
    showProductPriceEdit,
    isClone,
    handleMrpChange,
    handleDiscountPercentChange,
    salePriceValue,
    mrpValue,
    discountValue,
    handleBarCode,
    handleStatusChange,
    handleCostPrice,
    handleIsDefault,
    defaultValue,
    costPriceValue,
    barCodeValue,
    priceStatus,
    handleDate,
    dateValue,
    handleSalePrice,
  } = props;

  useEffect(() => {
    getStatus();
  }, []);

  const getStatus = async () => {
    const status = await StatusService.getOption(ObjectName.PRODUCT_PRICE);
    setPriceStatusValue(status);
  };

  const dispatch = useDispatch();
  const initialValues = {
    mrp: mrpValue ? mrpValue : priceData ? priceData.mrp : "",
    salePrice: salePriceValue
      ? salePriceValue
      : priceData
      ? priceData.salePrice
      : "",
    costPrice: costPriceValue
      ? costPriceValue
      : priceData
      ? priceData.costPrice
      : "",
    barCode: barCodeValue ? barCodeValue : priceData ? priceData.barCode : "",
    isDefault: defaultValue
      ? defaultValue
      : priceData
      ? priceData.isDefault
      : false,
    date: dateValue
      ? dateValue
      : priceData?.date
      ? priceData?.date
      : !priceData
      ? selectedDate
      : "",
    discount_percentage: discountValue
      ? discountValue
      : priceData
      ? priceData.discount_percentage
      : "",
    status: priceStatus
      ? priceStatus
      : priceStatusValue &&
        priceStatusValue.find((data) => data.value === priceData?.status),
  };

  const handleSubmit = async (data) => {
    try {
      data.productId = productId;
      setIsSubmit(true);

      if (priceData && !isClone) {
        dispatch(
          await ProductPriceService.update(
            priceData.id,
            data,
            Url.GetAllParams(),
            toggle
          )
        );
      } else {
        dispatch(
          await ProductPriceService.create(
            data,
            {
              product_id: productId,
              sort: "id",
              sortDir: "DESC",
            },
            toggle,
            setIsSubmit
          )
        );
        setIsSubmit(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsSubmit(false);
    }
  };

  const bulkUpdateBody = (
    <>
      <DateSelector label="Date" name="date" onChange={handleDate} />
      <Currency
        name="mrp"
        label="MRP"
        required
        onInputChange={handleMrpChange}
      />
      <Percentage
        name="discount_percentage"
        label="Discount %"
        onInputChange={handleDiscountPercentChange}
      />
      <Currency
        name="salePrice"
        label="Sale Price"
        onInputChange={handleSalePrice}
        required
      />
      <Currency
        name="costPrice"
        label="Cost Price"
        onInputChange={handleCostPrice}
      />
      <Text name="barCode" label="Barcode" required onChange={handleBarCode} />
   {(priceData && !isClone) &&  <StatusSelect
        label="Status"
        name="status"
        objectName={ObjectName.PRODUCT_PRICE}
        handleStatusChange={handleStatusChange}
      />}
      <SingleCheckbox
        name="isDefault"
        label="Set as Default Price"
        className="py-1"
        handleOnChangeSubmit={handleIsDefault}
      />
    </>
  );

  const editModelFooter = (
    <SaveButton
      type="submit"
      label={priceData && !isClone ? "Update" : "Add"}
      loading={isSubmit == false}
    />
  );

  return (
    <>
      <Drawer
        DrawerBody={bulkUpdateBody}
        DrawerFooter={
          (showProductPriceEdit && priceData) || !priceData
            ? editModelFooter
            : ""
        }
        modelTitle={
          isClone ? "Clone Price" : priceData?.id ? "Edit Price" : "Add Price"
        }
        onSubmit={handleSubmit}
        initialValues={initialValues}
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggle}
        isModalOpen={isOpen}
        enableReinitialize={true}
      />
    </>
  );
};

export default ProductPriceEditModal;
