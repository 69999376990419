import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// Components
import AccountSelect from "../../components/AccountSelect";
import Action from "../../components/Action";
import ActivityList from "../../components/ActivityList";
import BreadCrumb from "../../components/Breadcrumb";
import CancelButton from "../../components/CancelButton";
import Currency from "../../components/Currency";
import DateSelector from "../../components/Date";
import DeleteModal from "../../components/DeleteModal";
import Form from "../../components/Form";
import MediaCarousel from "../../components/MediaCarousel";
import PageTitle from "../../components/PageTitle";
import PaymentAccountSelect from "../../components/PaymentAccountSelect";
import SaveButton from "../../components/SaveButton";
import Status from "../../components/Status";
import Text from "../../components/Text";
import TextArea from "../../components/TextArea";
import UserSelect from "../../components/UserSelect";
import Spinner from "../../components/Spinner";

// Helpers
import ObjectName from "../../helpers/ObjectName";
import Payment from "../../helpers/Payment";
import Permission from "../../helpers/Permission";

// Services
import PaymentService from "../../services/PaymentService";
import { hasPermission } from "../../services/UserRolePermissionService";
import { AccountService } from "../../services/AccountService";

// Lib
import DateTime from "../../lib/DateTime";
import Url from "../../lib/Url";
import ArrayList from "../../lib/ArrayList";

const PaymentDetail = (props) => {
  const { history } = props;
  //   constant Name
  const Tab = { SUMMARY: "Summary", PRODUCTS: "Products", HISTORY: "History" };
  const Param = new URLSearchParams(props.history.location.search);
  const [detail, setDetail] = useState();
  const [userList, setUserList] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const id = props.match.params.id;
  const dispatch = useDispatch();
  const tab = Param.get("tab");
  const [activeTab, setActiveTab] = useState(Url.GetParam("tab") ? Url.GetParam("tab") : Tab.SUMMARY);
  const [accountId, setAccountId] = useState();
  const [vendor, setVendor] = useState();
  const [paymentAccountName, setpaymentAccountName] = useState([]);
  const [DateChange, setonDateChange] = useState();
  const [dueDate, setDueDate] = useState();
  const [invoiceChange, setInvoiceChange] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentAccount, setPaymentAccount] = useState("");
  const [amount, setAmount] = useState("");
  const [notes, setNotes] = useState("");
  const [editable, setEditable] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null)

  let showHistory = hasPermission(Permission.PAYMENT_HISTORY_VIEW);

  let editPermission = hasPermission(Permission.PAYMENT_EDIT);

  useEffect(() => {
    getDetails();
  }, []);

 

  const options = [
    {
      label: Payment.STATUS_NEW_TEXT,
      value: Payment.STATUS_NEW_TEXT,
    },
    {
      label: Payment.STATUS_PAID_TEXT,
      value: Payment.STATUS_PAID_TEXT,
    },
  ];

  const initialValues = {
    date: DateTime.getTodayDateByUserTimeZone(detail?.date),
    status: options.find((data) => data.value == detail?.status),
    amount:detail?.amount ? detail?.amount : "",
    payment_account: paymentAccountName.find((data) => data.value == detail?.paymentAccount),
    account:accountList.find((data) => data.value == detail?.account),
    owner_id: selectedUser ? selectedUser : ArrayList.isArray(userList) ? userList.find((data)=>data?.id == detail?.owner_id): "",
    notes:detail?.notes ? detail?.notes : "",
    description: detail?.description ? detail?.description : "",
    due_date: detail?.due_date ? detail?.due_date : "",
    invoice_number:  detail?.invoice_number
      ? detail?.invoice_number
      : "",
  };

  const getDetails = async () => {
    const data = await PaymentService.get(id);
    setDetail(data);
  };

  const account_Id = detail && detail.account;

  // Toggle change function
  const tabToggle = (tab) => {
    setActiveTab(tab);
  };

  const onStatusChange = (value) => {
    if (value) {
      handleSubmits(value);
    }
  };

  const handleSubmit = async (values) => {
    const data = new FormData();

    data.append("date", values?.date);
    data.append("amount", values && values?.amount);
    data.append(
      "owner_id",
      values && values?.owner_id?.id ? values?.owner_id?.id : ""
    );
    data.append("account", values && values.account.value);
    data.append(
      "payment_account",
      values?.payment_account?.value ? values?.payment_account?.value : ""
    );
    data.append("notes", values.notes ? values.notes : "");

    data.append("due_date", values && values?.due_date ? values?.due_date : "");
    data.append(
      "invoice_number",
      values?.invoice_number ? values?.invoice_number : ""
    );

    await PaymentService.update(id, data, null, (res) => {
      if (res) {
        setEditable(true);
        setIsLoading(true);
        getDetails();
        setIsLoading(false);
      }
    });
  };

  const handleSubmits = async (values) => {
    const data = new FormData();

    data.append("status", values);

    await PaymentService.update(id, data, null, (res) => {
      if (res) {
        setEditable(true);
        setIsLoading(true);
        getDetails();
        setIsLoading(false);
      }
      setEditable(true);
    });
  };

  const breadcrumbList = [
    { label: "Home", link: "/accountDashboard" },
    {
      label: "Payments",
      link: "/payment",
    },
    {
      label: "Payment Details",
      link: "",
    },
  ];

  const actionsMenuList = [
    {
      value: "delete",
      label: "Delete",
    },
  ];

  if (editPermission && editable) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  const handleActionChange = (e) => {
    if (e == "edit") {
      setEditable(false);
    }
    if (e == "delete") {
      setDeleteModal(true);
    }
  };

  const paymentsDelete = async () => {
    dispatch(await PaymentService.delete(id, history));
  };

  const handleVendorChange = (e) => {
    setVendor(e);
    setAccountId(e.paymentAccount);
  };

  const handlePaymenyAccount = (e) => {
    let value = e;
    setPaymentAccount(value);
    if (value == null) {
      setAccountId("");
    }
  };

  const onDateChange = (e) => {
    setonDateChange(e ? e : "");
  };

  const onDueDateChange = (e) => {
    setDueDate(e ? e : "");
  };

  const onInvoiceChange = (e) => {
    let value = e?.target?.value;
    setInvoiceChange(value);
  };

  const handleAmountChange = (e) => {
    let value = parseFloat(e.values?.amount ? e.values?.amount : 0) || 0;
    setAmount(value);
  };

  const handleNotesChange = (e) => {
    let value = e?.target?.value ? e?.target?.value : "";
    setNotes(value);
  };

  if (isLoading) {
    return <Spinner />;
  }

  function getSavedActiveTab() {
    const savedTab = localStorage.getItem("activeTab");
    return savedTab ? savedTab : "Summary";
  }

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    props.history.push(`?tab=${tab}`);
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Payment"
        id={id}
        label={id}
        deleteFunction={paymentsDelete}
      />
      <BreadCrumb list={breadcrumbList} />

      <div className="d-flex justify-content-between">
        <div>
          <PageTitle label={`Payment #${id}`} />
        </div>
        <div className="d-flex">
          <Status
            objectName={ObjectName.PAYMENT}
            handleChange={onStatusChange}
            buttonLabel={detail?.statusName}
            currentStatusId={detail?.status}
            disabled={editable}
          />
          <div className="ps-2">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        </div>
      </div>

      <div>
        <Nav tabs className="admin-tabs">
          {/* Detail Tab */}
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === Tab.SUMMARY,
              })}
              onClick={() => {
                tabToggle(Tab.SUMMARY);
                // _handleTabChange(Tab.DETAIL);
                handleTabChange(Tab.SUMMARY);
              }}
            >
              {Tab.SUMMARY}
            </NavLink>
          </NavItem>
          {/* History Tab */}
          <NavItem>
            {showHistory && (
              <NavLink
                className={classNames({
                  active: activeTab === Tab.HISTORY,
                })}
                onClick={() => {
                  tabToggle(Tab.HISTORY);
                  handleTabChange(Tab.HISTORY);
                }}
              >
                {Tab.HISTORY}
              </NavLink>
            )}
          </NavItem>
        </Nav>
      </div>

      <TabContent activeTab={activeTab}>
        {/* Detail Tab Start*/}
        {activeTab == Tab.SUMMARY && (
          <TabPane tabId={Tab.SUMMARY} className="w-100 card card-body">
            <Form
              enableReinitialize
              initialValues={initialValues}
              onSubmit={handleSubmit}
            >
              <div className="row">
                <div className="col-lg-7">
                  <div className="">
                    <DateSelector
                      label="Date"
                      name="date"
                      format="dd-MMM-yyyy"
                      disabled={editable}
                      required
                      onChange={onDateChange}
                    />
                    <div className="d-flex bd-highlight">
                      <div className="w-100 bd-highlight">
                        <AccountSelect
                          name="account"
                          label="Account"
                          placeholder="Select Account"
                          required
                          isDisabled={editable}
                          handleVendorChange={handleVendorChange}
                          showIcon
                          to={`/accounts/${account_Id}`}
                          showAccountDetailsPageLink={account_Id ? true : false}
                          accountId={account_Id}
                          defaultValue={account_Id}
                          vendorList={setAccountList}
                        />
                      </div>
                    </div>
                    <PaymentAccountSelect
                      isDisabled={editable}
                      setpaymentAccountName={setpaymentAccountName}
                      handleChange={handlePaymenyAccount}
                      name={"payment_account"}
                      label={"Payment Account"}
                      required
                    />
                    <Text
                      label="Invoice Number"
                      name="invoice_number"
                      disabled={editable}
                      required
                      onChange={onInvoiceChange}
                    />

                    <Currency
                      label="Amount"
                      name="amount"
                      onInputChange={handleAmountChange}
                      required
                      disabled={editable}
                    />

                    <div className="row">
                      <div className="col-6">
                        <UserSelect
                          name="owner_id"
                          label="Owner"
                          isDisabled={editable}
                          showUserDetailsPageLink={
                            detail?.owner_id ? true : false
                          }
                          userId={detail?.owner_id}
                          userList={setUserList}
                          handleUserChange={(e)=>setSelectedUser(e)}
                        />
                      </div>

                      <div className="col-6">
                        <DateSelector
                          label="Due Date"
                          name="due_date"
                          onChange={onDueDateChange}
                          disabled={editable}
                          // isClearable
                        />
                      </div>
                    </div>

                    <TextArea
                      name="notes"
                      label="Notes"
                      placeholder="Enter Notes..."
                      error=""
                      onChange={handleNotesChange}
                      fontBolded
                      disabled={editable}
                    />
                    {editable == false && (
                      <>
                        <SaveButton label="Save" />
                        <CancelButton
                          onClick={() => props.history.push("/payment")}
                        />
                      </>
                      )}
                  </div>
                </div>
                <div className="col-lg-5">
                  <MediaCarousel
                    showCarasoul
                    objectName={ObjectName.PAYMENT}
                    objectId={id}
                    history={history}
                    attachmentsList={true}
                    Attachments={"Attachments"}
                    editable={editable}
                  />
                </div>
              </div>
            </Form>
          </TabPane>
        )}

        {/* History Tab Start*/}
        {showHistory && activeTab == Tab.HISTORY && (
          <TabPane tabId={Tab.HISTORY} className="w-100">
            <ActivityList
              id={id}
              objectId={id}
              object_name={ObjectName.PAYMENT}
              history={history}
            />
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

export default PaymentDetail;
