import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem, Nav, NavItem, NavLink, TabPane } from "reactstrap";

// Components
import MoreDropdown from "../../components/authentication/moreDropdown";
import DeleteModal from "../../components/DeleteModal";
import Drawer from "../../components/Drawer";
import PageTitle from "../../components/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import SaveButton from "../../components/SaveButton";
import SettingTab from "./components/SettingTab";
import DetailPage from "./detailPage";
import DragAndDropTable from "../../components/StatusTable/StatusDragAndDropTable";
import AddButton from "../../components/AddButton";
import AttendanceTypeForm from "./components/AttendanceTypeForm";

// API
import { endpoints } from "../../api/endPoints";

// Services
import AttendanceTypeService from "../../services/AttendanceTypeService";

// Helpers
import {
  Status
} from "../../helpers/AttendanceType";
import { Tabs } from "../../helpers/Setting";
import { Type } from "../../helpers/AttendanceType";
import ObjectName from "../../helpers/ObjectName";
import { Attendance } from "../../helpers/Attendance";

// Actions
import { fetchList } from "../../actions/table";

// Lib
import ArrayList from "../../lib/ArrayList";
import Number from "../../lib/Number";
import Url from "../../lib/Url";

// Services
import UserRoleService from "../../services/UserRoleService";

const AttendanceType = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [rowValue, setRowValue] = useState(null);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(Url.GetParam("tab") ? Url.GetParam("tab") : Tabs.TYPES);
  const [row, setRow] = useState();

  const [category, setCategory] = useState();
  const [userRole, setUserRole] = useState([]);
  const [statusOption, setStatusOption] = useState([]);
  const [hourOption, setHourOption] = useState([])
  const dispatch = useDispatch();
  const [isTypeModelOpen, setIsTypeModelOpen] = useState(false);

  const selectedId = props.match && props.match.params && props.match.params.id;

  useEffect(() => {
    getUserRoleList()
  }, [])

  const _toggle = () => {
    setIsTypeModelOpen(!isTypeModelOpen);
  };

  const handleAddButtonClick = () => {
    Toggle();
    setRow("");
  };

  const Toggle = () => {
    setIsOpen(!isOpen);
  }

  const ToggleTypeClose = () => {
    setIsTypeModelOpen(!isTypeModelOpen);
    setRowValue(null);
  };

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    props.history.push(`?tab=${tab}`);
  };

  const getUserRoleList = async () => {
    const roleData = await UserRoleService.list();
    setUserRole(roleData);
  };

  const sortByOption = [
    {
      value: "name:ASC",
      label: "Name",
    },
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  const categoryList = [
    {
      value: Type.WORKING_DAY,
      label: Type.WORKING_DAY_TEXT,
    },
    {
      value: Type.LEAVE,
      label: Type.LEAVE_TEXT,
    },
  ]

  const statusOptions = [
    {
      value: Attendance.STATUS_ACTIVE,
      label: Attendance.STATUS_ACTIVE_TEXT,
    },
    {
      value: Attendance.STATUS_INACTIVE,
      label: Attendance.STATUS_INACTIVE_TEXT,
    },
  ];

  let daysArray = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]

  let params = {
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    status: Url.GetParam("status"),
    search: Url.GetParam("search"),
  };

  const handleAttendanceType = async (values) => {
    let allowedRoleIds = ArrayList.isArray(values?.allowed_roles) ? values?.allowed_roles?.map((data => data?.value)) : [];

    let data = new FormData();
    data.append("name", values && values?.name ? values?.name : "");
    data.append(
      "status",
      values && values?.status ? values?.status?.value : ""
    );
    data.append(
      "days_count",
      values && values?.days_count ? values?.days_count : ""
    );
    data.append(
      "maximum_allowed",
      values && values?.maximum_allowed
        ? values?.maximum_allowed
        : ""
    );
    data.append(
      "cutoff_time",
      values && values?.cutoff_time ? values?.cutoff_time?.value : ""
    );
    data.append(
      "category",
      values && values?.category ? values?.category?.value : ""
    );
    data.append(
      "default_status",
      values && values?.default_status ? values?.default_status?.value : ""
    );
    data.append(
      "date",
      values && values?.date ? values?.date : ""
    );
    let checkBoxs = ["allow_late_checkin", "is_additional_shift",];

    for (let i = 0; i < checkBoxs.length; i++) {
      const key = checkBoxs[i];
      data.append(key, values[key] ? values[key] : false);
    }

    let days = [];
    let keys = Object.keys(values);
    daysArray.forEach((key) => {
      if (keys?.includes(key) && values[key]) {
        days.push(key);
      }
    });

    data.append("days", ArrayList.isArray(days) ? days.join(",") : "");
    data.append("allowed_role_ids", allowedRoleIds);


    if (rowValue) {
      dispatch(
        await AttendanceTypeService.update(rowValue.id, data, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "attendanceType",
                `${endpoints().attendanceTypeAPI}/search`,
                params.page ? params.page : 1,
                params.pageSize ? params.pageSize : 25,
                params
              )
            );
            ToggleTypeClose();
          }
        })
      );
    } else {
      dispatch(
        await AttendanceTypeService.create(data, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "attendanceType",
                `${endpoints().attendanceTypeAPI}/search`,
                params.page ? params.page : 1,
                params.pageSize ? params.pageSize : 25,
                params
              )
            );
            ToggleTypeClose();
          }
        })
      );
    }
  };

  const handleCategoryChange = (value) => {
    setCategory(value.values && value.values.category.value)
  }

  const FormBody = (
    <AttendanceTypeForm
      daysArray={daysArray}
      categoryList={categoryList}
      handleCategoryChange={handleCategoryChange}
      roleList={userRole}
      statusOption={setStatusOption}
      hourOption={setHourOption}
    />
  );

  const FormFooter = (
    <>
      {" "}
      <SaveButton type="submit" label={rowValue ? "Update" : "Add"} />
    </>
  );

  const deleteToggle = () => {
    setDeleteIsOpen(!deleteIsOpen);
    setRowValue(null);
  };

  const deleteFunction = async () => {
    dispatch(
      await AttendanceTypeService.delete(rowValue.id, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "attendanceType",
              `${endpoints().attendanceTypeAPI}/search`,
              1,
              25,
              {
                sort: Url.GetParam("sort"),
                sortDir: Url.GetParam("sortDir"),
                page: Url.GetParam("page"),
                pageSize: Url.GetParam("pageSize"),
                status: Url.GetParam("status"),
                search: Url.GetParam("search"),
              }
            )
          );
          deleteToggle();
        }
      })
    );
  };

  const checkBoxInitialValues = (values) => {
    let obj = {}
    let checkBoxs = ["allow_late_checkin", "is_additional_shift",];
    for (let i = 0; i < checkBoxs.length; i++) {
      const key = checkBoxs[i];
      if (Number.isNotNull(values) && Number.isNotNull(values[key])) {
        obj[key] = values[key];
      } else {
        obj[key] = ""
      }
    }
    return obj
  }

  const daysCheckBoxInitialValues = (values) => {
    let obj = {}
    let splitKey = values?.allowed_days?.split(",") || []
    for (let i = 0; i < daysArray.length; i++) {
      const key = daysArray[i];
      if (Number.isNotNull(values) && Number.isNotNull(values?.allowed_days) && splitKey.includes(key)) {
        obj[key] = true;
      } else {
        obj[key] = false
      }
    }
    return obj
  }

  let allowedRoleValue = []

  let splitRoleIds = rowValue && rowValue?.allowed_roles?.split(",") || [];
  ArrayList.isArray(splitRoleIds) && splitRoleIds.forEach((values) => {

    let isRecord = ArrayList.isArray(userRole) && userRole.find((data) => data?.value == values) || null;
    if (isRecord) {
      allowedRoleValue.push(isRecord)
    }
  });

  let initialValue = {
    name: rowValue ? rowValue?.name : "",
    days_count: rowValue ? rowValue?.days_count : "",
    status: rowValue ? rowValue?.status : "",
    category: rowValue ? categoryList.find((data) => data.value === Number.Get(rowValue?.category)) : categoryList.find((data) => data.value === Number.Get(category)),
    cutoff_time: rowValue && hourOption ? hourOption.find((data) => data.value == rowValue?.cutoff_time) : null,
    maximum_allowed: rowValue ? rowValue?.maximum_allowed : "",
    ...checkBoxInitialValues(rowValue),
    ...daysCheckBoxInitialValues(rowValue),
    allowed_roles: ArrayList.isArray(allowedRoleValue) ? allowedRoleValue : "",
    default_status: (rowValue && ArrayList.isArray(statusOption)) ? statusOption.find((data) => data?.value === rowValue?.default_status) : "",
    date: rowValue ? rowValue?.date : "",
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteIsOpen}
        toggle={deleteToggle}
        title="Delete"
        label={rowValue?.id}
        deleteFunction={deleteFunction}
      />
      <Drawer
        DrawerBody={FormBody}
        DrawerFooter={FormFooter}
        modelTitle={rowValue ? "Edit Attendance Type" : "Add Attendance Type"}
        onSubmit={(values) => {
          handleAttendanceType(values);
        }}
        initialValues={initialValue}
        handleOpenModal={_toggle}
        handleCloseModal={ToggleTypeClose}
        handleDrawerClose={ToggleTypeClose}
        isModalOpen={isTypeModelOpen}
        enableReinitialize
        disableSubmitOnEnter={true}
      />
      {!selectedId ? (
        <>
          <div className="d-flex justify-content-between">
            <PageTitle
              label="Attendance"
            />
            {activeTab === Tabs.TYPES && (
              <AddButton
                className="ms-2 me-1"
                label="Add Type"
                onClick={() => _toggle()}
              />
            )}
            {activeTab === Tabs.STATUS && (
              <AddButton
                className="ms-2 me-1"
                label="Add Status"
                onClick={handleAddButtonClick}
              />
            )}
          </div>

          <Nav tabs className="admin-tabs mb-3">
            <NavItem>
              <NavLink
                className={classNames({
                  active: activeTab === Tabs.TYPES,
                })}
                onClick={() => {
                  handleTabChange(Tabs.TYPES);
                }}
              >
                {Tabs.TYPES}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({
                  active: activeTab === Tabs.STATUS,
                })}
                onClick={() => {
                  handleTabChange(Tabs.STATUS);
                }}
              >
                {Tabs.STATUS}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({
                  active: activeTab === Tabs.SETTING,
                })}
                onClick={() => {
                  handleTabChange(Tabs.SETTING);
                }}
              >
                {Tabs.SETTING}
              </NavLink>
            </NavItem>
          </Nav>
          {activeTab === Tabs.TYPES && (
            <TabPane>
              <ReduxTable
                id="attendanceType"
                showHeader
                searchPlaceholder="Search"
                paramsToUrl={true}
                history={props.history}
                apiURL={`${endpoints().attendanceTypeAPI}/search`}
                newTableHeading
                sortByOptions={sortByOption}
                showStatusFilter
                customStatusOption={statusOptions}
                params={{ status: Url.GetParam("status") ? Url.GetParam("status") : Attendance.STATUS_ACTIVE }}
              >
                <ReduxColumn
                  field="name"
                  sortBy="name"
                  renderField={(row) => (
                    <Link
                      to={`/admin/settings/attendanceType/${row.id}`}
                      className="link-opacity-75 text-decoration-none"
                    >
                      {row.name}
                    </Link>
                  )}
                >
                  Name
                </ReduxColumn>
                <ReduxColumn
                  field="days_count"
                  sortBy="days_count"
                  className="text-center"
                >
                  Days Count
                </ReduxColumn>
                <ReduxColumn
                  field="category"
                  sortBy="category"
                  className="text-center"
                  renderField={(row) => (
                    <>
                      {row.category === Type.WORKING_DAY
                        ? Type.WORKING_DAY_TEXT
                        : row.category === Type.LEAVE
                          ? Type.LEAVE_TEXT
                          : ''
                      }
                    </>
                  )}
                >
                  Category
                </ReduxColumn>
                <ReduxColumn
                  field="maximum_allowed"
                  sortBy="maximum_allowed"
                  className="text-center"
                >
                  Max Limit
                </ReduxColumn>
                <ReduxColumn
                  field="cutoff_time"
                  sortBy="cutoff_time"
                  className="text-center"
                  renderField={(row) => {
                    return (
                      <>
                        {row.cutoff_time ? `${row.cutoff_time} Hours` : ''}
                      </>
                    );
                  }}
                >
                  Cut-Off Time
                </ReduxColumn>
                <ReduxColumn
                  field="status"
                  sortBy="status"
                  width={"120px"}
                  minWidth="120px"
                  maxWidth="120px"
                  className="column-status"
                  renderField={(row) => (
                    <div
                      className={`status-input text-center rounded text-white text-uppercase ${row.status?.value &&
                        row.status?.value === Status.STATUS_ACTIVE
                        ? "bg-success"
                        : row.status?.value === Status.STATUS_INACTIVE
                          ? "bg-dark bg-opacity-50"
                          : ""
                        }`}
                    >
                      <p>{row.status?.label}</p>
                    </div>
                  )}
                >
                  Status
                </ReduxColumn>
                <ReduxColumn
                  field="Action"
                  disableOnClick
                  width="70px"
                  renderField={(row) => (
                    <>
                      <div className="text-center action-group-dropdown">
                        <MoreDropdown>
                          <DropdownItem
                            onClick={() => {
                              setRowValue(row);
                              _toggle();
                            }}
                          >
                            Quick View
                          </DropdownItem>
                          <DropdownItem
                            className="text-danger"
                            onClick={() => {
                              setRowValue(row);
                              setDeleteIsOpen(true);
                            }}
                          >
                            Delete
                          </DropdownItem>
                        </MoreDropdown>
                      </div>
                    </>
                  )}
                >
                  Action
                </ReduxColumn>
              </ReduxTable>
            </TabPane>
          )}
          {activeTab === Tabs.STATUS && (
            <TabPane>
              <DragAndDropTable
                history={history}
                objectName={ObjectName.ATTENDANCE}
                showUrl
                _toggle={Toggle}
                isOpen={isOpen}
                row={row}
                setRow={setRow}
              />
            </TabPane>
          )}
          {activeTab == Tabs.SETTING && <SettingTab />}
        </>
      ) : (
        <DetailPage {...props} />
      )}
    </>
  );
};

export default AttendanceType;
