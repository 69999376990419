import React, { useState } from 'react'
import PageTitle from '../../../components/PageTitle';
import LateCheckInAnalysisReportFilter from './components/Filter';
import Graph from './components/Graph';
import DateTime from '../../../lib/DateTime';
import BreadCrumb from '../../../components/Breadcrumb';

const lateCheckInAnalysisReport = (props) => {
  const [graphData, setGraphData] = useState([])
  const [isLoading, setIsLoading] = useState(false);

  const breadcrumbList = [
    { label: "Home", link: "/attendanceSummaryReport" },
    {
      label: "Reports",
      link: "/Reports",
    },
    {
      label: "Attendance Late Checkin Analysis Report",
      link: "",
    },
  ];

  return (
    <div>
      <BreadCrumb list={breadcrumbList} />
      <PageTitle label="Attendance Late Checkin Analysis Report" />
      <LateCheckInAnalysisReportFilter
        setGraphData={setGraphData}
        history={props.history}
        setIsLoading={setIsLoading}
      />
      <Graph title={`Total Late Hours: ${DateTime.HoursAndMinutes(graphData?.totalLateHours)}`} isLoading={isLoading} history={props?.history} graphArrayData={graphData?.data}  />

    </div>
  )
}

export default lateCheckInAnalysisReport;
