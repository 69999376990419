import { endpoints } from "../api/endPoints";
import Toast from "../components/Toast";
import ArrayList from "../lib/ArrayList";
import { isBadRequest } from "../lib/Http";
import Url from "../lib/Url";





class LocationPerformanceReportService {

    static async getReport(params) {
        try{
        let queryString = await ArrayList.toQueryString(params);
        let response = await Url.get(`${endpoints().LocationPerformanceReportApi}`, queryString);
        if (response && response.data) {
            return response.data;
          }
        }catch(error){
            if (isBadRequest(error)) {
                let errorMessage;
                const errorRequest = error.response.request;
                if (errorRequest && errorRequest.response) {
                  errorMessage = JSON.parse(errorRequest.response).message;
                }
                Toast.error(errorMessage);
              }
        }
    }
}
export default LocationPerformanceReportService;