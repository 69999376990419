export const MenuLocations = {
    HEADER_TEXT: "Header",
    HEADER_VALUE: 1,
    FOOTER_TEXT: "Footer",
    FOOTER_VALUE: 2,
}

export const MenuLocationOptions = [
    {
        label: MenuLocations.HEADER_TEXT,
        value: MenuLocations.HEADER_VALUE
    },
    {
        label: MenuLocations.FOOTER_TEXT,
        value: MenuLocations.FOOTER_VALUE,
    }
]