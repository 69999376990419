import { endpoints } from "../api/endPoints";
import { apiClient } from "../apiClient";
import Toast from "../components/Toast";
import { isBadRequest, SUCCESS_RESPONSE } from "../lib/Http";

class AppMenuService {

    // Create the App Menu
    static create(data, callback) {
        apiClient
            .post(`${endpoints().appMenuAPI}`, data)
            .then((response) => {
                let successMessage;
                if (response && response.data) {
                    successMessage = response.data.message;
                    Toast.success(successMessage);
                }
                return callback(response && response.data);
            })
            .catch((error) => {
                if (isBadRequest(error)) {
                    let errorMessage;
                    const errorRequest = error.response.request;
                    if (errorRequest && errorRequest.response) {
                        errorMessage = JSON.parse(errorRequest.response).message;
                    }
                    Toast.error(errorMessage);
                    console.error(errorMessage);
                }
            });
    }

    // Update the App Menu
    static update(data, callback) {
        apiClient
            .put(`${endpoints().appMenuAPI}`, data)
            .then((response) => {
                let successMessage;
                if (response && response.data) {
                    successMessage = response.data.message;
                    Toast.success(successMessage);
                }
                return callback(response && response.data);
            })
            .catch((error) => {
                if (isBadRequest(error)) {
                    let errorMessage;
                    const errorRequest = error.response.request;
                    if (errorRequest && errorRequest.response) {
                        errorMessage = JSON.parse(errorRequest.response).message;
                    }
                    Toast.error(errorMessage);
                    console.error(errorMessage);
                }
            });
    }

    static updateStatus(id, data, callback) {
        apiClient
            .put(`${endpoints().appMenuAPI}/status/${id}`, data)
            .then((response) => {
                let successMessage;
                if (response && response.data) {
                    successMessage = response.data.message;
                    Toast.success(successMessage);
                }
                return callback(response && response.data);
            })
            .catch((error) => {
                if (isBadRequest(error)) {
                    let errorMessage;
                    const errorRequest = error.response.request;
                    if (errorRequest && errorRequest.response) {
                        errorMessage = JSON.parse(errorRequest.response).message;
                    }
                    Toast.error(errorMessage);
                    console.error(errorMessage);
                }
            });
    }

    // Delete the App Menu
    static delete(id, callback) {
        apiClient
            .delete(`${endpoints().appMenuAPI}/${id}`)
            .then((response) => {
                let successMessage;
                if (response && response.data) {
                    successMessage = response.data.message;
                    Toast.success(successMessage);
                }
                return callback(response && response.data);
            })
            .catch((error) => {
                if (isBadRequest(error)) {
                    let errorMessage;
                    const errorRequest = error.response.request;
                    if (errorRequest && errorRequest.response) {
                        errorMessage = JSON.parse(errorRequest.response).message;
                    }
                    Toast.error(errorMessage);
                    console.error(errorMessage);
                }
            });
    }

    static updateOrder = async (data) => {
        apiClient.put(`${endpoints().appMenuAPI}/order`, data)
            .then((res) => {
                if (res.status == SUCCESS_RESPONSE) {
                    Toast.success(res?.data?.message);
                }
            })
            .catch((err) => {
                if (isBadRequest(err)) {
                    let errorMessage;
                    const errorRequest = err.response.request;
                    if (errorRequest && errorRequest.response) {
                        errorMessage = JSON.parse(errorRequest.response).message;
                    }
                    Toast.error(errorMessage);
                }
            });
    }

    static get(data, callback) {
        apiClient
            .get(`${endpoints().appAPI}`, data)
            .then((response) => {
                return callback(response && response.data);
            })
    }

    // Search for App Menus
    static search(params, callback) {
        apiClient
            .get(`${endpoints().appMenuAPI}/search`, { params })
            .then((response) => {
                if (response && response?.data) {
                    callback(response.data); 
                } else {
                    callback(null);
                }
            })
            .catch((error) => {
                if (isBadRequest(error)) {
                    let errorMessage = "An error occurred while fetching the data.";
                    const errorRequest = error.response?.request;
                    if (errorRequest?.response) {
                        try {
                            errorMessage = JSON.parse(errorRequest.response)?.message || errorMessage;
                        } catch (error) {
                            console.error(error);
                        }
                    }
                    console.error(errorMessage);
                } else {
                    console.error(error);
                }
            });
    }
}

export default AppMenuService;