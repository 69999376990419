import classnames from "classnames";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// Lib
import { default as Cookie, default as Cookies } from "../../lib/Helper";
import Url from "../../lib/Url";
import ArrayList from "../../lib/ArrayList";

// Components
import Action from "../../components/Action";
import AddButton from "../../components/AddButton";
import BreadCrumb from "../../components/Breadcrumb";
import DeleteModal from "../../components/DeleteModal";
import Drawer from "../../components/Drawer";
import NumberComponent from "../../components/Number";
import PageTitle from "../../components/PageTitle";
import SaveButton from "../../components/SaveButton";
import Select from "../../components/Select";
import Spinner from "../../components/Spinner";
import StoryPointSelect from "../../components/StoryPointSelect";
import Text from "../../components/Text";
import General from "./components/general";
import ProjectComponent from "./components/ProjectComponent";
import ProjectUsers from "./components/projectUsers";
import SettingsTab from "./components/settingsTab";
import TicketType from "./components/ticketType";
import AvatarCard from "../../components/AvatarCard";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import Toast from "../../components/Toast";
import Button from "../../components/Button";

// Services
import ProjectComponentService from "../../services/ProjectComponentService";
import ProjectService from "../../services/ProjectService";
import ProjectTicketTypeService from "../../services/ProjectTicketTypeService";
import ProjectUserService from "../../services/ProjectUserService";
import { hasPermission } from "../../services/UserRolePermissionService";
import CompanyUserService from "../../services/UserService";

// Helpers
import Permission from "../../helpers/Permission";
import { Projects } from "../../helpers/Project";
import { fetchList } from "../../actions/table";
import { endpoints } from "../../api/endPoints";
import ProjectUser from "../../helpers/ProjectUser";
import TagSelect from "../../components/TagSelect";
import { TagTypeName } from "../../helpers/Tag";

const Tab = {
  GENERAL: "General",
  TICKET_TYPE: "Ticket Types",
  SETTINGS: "Settings",
  USERS: "Users",
  COMPONENTS: "Components",
};

const ProjectDetail = (props) => {
  const { history, match } = props;
  const [activeTab, setActiveTab] = useState(
    Url.GetParam("tab") ? Url.GetParam("tab") : Tab.GENERAL
  );
  const [projectData, setProjectData] = useState("");
  const [deleteModal, setDeleteModal] = useState();
  const [isDeleteModel, setIsDeleteModel] = useState("");
  const [ticketTypeIsDeleteModel, setTicketTypeIsDeleteModel] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [row, setRow] = useState("");
  const [statusIsOpen, setStatusIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState();
  const [isComponentModalOpen, setIsComponentModalOpen] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [editable, setEditable] = useState(true);
  const [currentData, setCurrentData] = useState("");
  const [ticketTypeCurrentData, setTicketTypeCurrentData] = useState("");
  const [userList, setUserList] = useState();
  const [statusValue, setStatusValue] = useState("");
  const [defaultStoryPoint, setDefauleStory] = useState("");
  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [sortChange, setSortChange] = useState();
  const [fineTypeList, setFineTypeList] = useState([]);
  const [fineType, setFineType] = useState("");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      Url.UpdateUrl(
        {
          projectId: match?.params?.id
            ? match?.params?.id
            : Url.GetParam("projectId"),
          isLoading: false,
          tab: tab,
        },
        props
      );
    }
  };

  let showEditButton = hasPermission(Permission.PROJECT_EDIT);

  const _toggle = () => {
    setStatusIsOpen(!statusIsOpen);
  };

  let dispatch = useDispatch();

  useEffect(() => {
    getData(
      match?.params?.id
        ? match?.params?.id
        : Cookies.get(Cookie.PROJECT_ID)
        ? Cookies.get(Cookie.PROJECT_ID)
        : props.match.params.id
    );
  }, [Cookies.get(Cookie.PROJECT_ID), Url.GetParam("isLoading")]);

  useEffect(() => {
    getData(
      match?.params?.id
        ? match?.params?.id
        : Cookies.get(Cookie.PROJECT_ID)
        ? Cookies.get(Cookie.PROJECT_ID)
        : Url.GetParam("projectId")
    );
    getUsers();
  }, []);

  const getUsers = async () => {
    const users = await CompanyUserService.getOption({});
    setUserList(users);
  };

  const handleAddButtonClick = () => {
    _toggle();
    setRow("");
  };

  const statusOption = [
    {
      label: Projects.STATUS_ACTIVE,
      value: Projects.STATUS_ACTIVE_VALUE,
    },
    {
      label: Projects.STATUS_INACTIVE,
      value: Projects.STATUS_INACTIVE_VALUE,
    },
  ];

  const breadcrumbList = [
    { label: "Home", link: "/admin/dashboard" },
    {
      label: "Project",
      link: "/project",
    },
    {
      label: "Project Details",
      link: "",
    },
  ];

  const getData = async (id) => {
    const response = await ProjectService.getProject(
      match?.params?.id ? match?.params?.id : Url.GetParam("projectId")
    );
    setProjectData(response);
    if (Url.GetParam("isLoading") === "true") {
      Url.UpdateUrl(
        {
          projectId: match?.params?.id
            ? match?.params?.id
            : Url.GetParam("projectId"),
          startDate: Url.GetParam("startDate"),
          endDate: Url.GetParam("endDate"),
          sort: Url.GetParam("sort"),
          sortDir: Url.GetParam("sortDir"),
          isLoading: false,
        },
        props
      );
    }
  };

  const projectDelete = (id) => {
    dispatch(ProjectService.deleteProject(id));
    history.push("/project");
  };

  const addtoggle = () => {
    setIsOpen(!isOpen);
  };

  const closeTicketTypeToggle = () => {
    setTicketTypeCurrentData("");
    setSortChange("");
    setIsOpen(!isOpen);
  };

  const openUserToggle = () => {
    setIsModalOpen(!isModalOpen);
  };

  const closeUserToggle = () => {
    setIsModalOpen(!isModalOpen);
    setSelectedUserIds([]);
    history.push();
  };

  const addComponentToggle = () => {
    setIsComponentModalOpen(!isComponentModalOpen);
  };

  const closeComponentToggle = () => {
    setIsComponentModalOpen(!isComponentModalOpen);
    setCurrentData("");
  };

  const closeTicktTypeDeleteModal = () => {
    setTicketTypeIsDeleteModel(false);
    setTicketTypeCurrentData("");
  };

  const closeProjectComponentDeleteModal = () => {
    setIsDeleteModel(false);
    setCurrentData("");
  };

  const handleStatusChange = (values) => {
    setStatusValue(values?.values?.status);
  };

  const handleSortChange = (values) => {
    let data = values && values?.values && values?.values?.sort;
    setSortChange(data);
  };

  const handleStoryChange = (e) => {
    setDefauleStory(e.values.story_points);
  };

  const handleFineTypeChange = (e) => {
    let value = e.values.fine_type
    setFineType(value)
  }

  const addProjectTicketTypeForm = (
    <>
      <Text
        name="name"
        label=" Name"
        placeholder=" Name"
        error=""
        required={true}
      />
      <Select
        name="status"
        label="Status"
        options={statusOption}
        onInputChange={handleStatusChange}
      />
      <NumberComponent
        label="Sort"
        name="sort"
        onInputChange={handleSortChange}
      />

      <StoryPointSelect
        name="story_points"
        label="Story Points"
        placeholder="Select Story Points"
        onChange={handleStoryChange}
      />
      <TagSelect
        name={"fine_type"}
        label="Fine Type"
        params={{ type: TagTypeName.FINE_TYPE }}
        TagList={setFineTypeList}
        onChange={handleFineTypeChange}
      />
    </>
  );

  const addComponentForm = (
    <>
      <Text
        name="name"
        label=" Name"
        placeholder=" Name"
        error=""
        required={true}
      />
      <Select
        label="Status"
        name="status"
        placeholder="Select Status"
        options={statusOption}
        required={true}
      />
    </>
  );

  const projectTicketTypeFooter = (
    <SaveButton label={ticketTypeCurrentData?.id ? "Update" : "Add"} />
  );

  let params = {
    search: Url.GetParam("search"),
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    projectId: match?.params?.id
      ? match?.params?.id
      : Url.GetParam("projectId")
      ? Url.GetParam("projectId")
      : "",
  };

  const projectTicketTypeCreate = (data) => {
    try {
      const Data = {
        name: data?.name,
        status: statusValue?.value,
        sort: data && data?.sort ? data?.sort : "",
        default_story_point: data?.story_points?.value,
        default_assignee: data?.user?.id,
        fine_type: data && data.fine_type,
        projectId: match?.params?.id
          ? match?.params?.id
          : Url.GetParam("projectId")
          ? Url.GetParam("projectId")
          : "",
      };
      let id = ticketTypeCurrentData?.id;
      if (ticketTypeCurrentData?.id) {
        dispatch(
          ProjectTicketTypeService.updateProjectTicketType(id, Data, (res) => {
            if (res) {
              setIsLoading(true);
              closeTicketTypeToggle();
              setIsLoading(false);
            }
          })
        );
      }
      dispatch(ProjectTicketTypeService.addProjectTicketType(Data, params));
      addtoggle();
    } catch (error) {
      console(error);
    }
  };

  const projectComponentSubmit = async (data) => {
    try {
      setIsLoading(true);
      data.name = data?.name;
      data.status = data?.status?.value;
      data.projectId = match?.params?.id
        ? match?.params?.id
        : Url.GetParam("projectId")
        ? Url.GetParam("projectId")
        : "";

      if (currentData && currentData?.id) {
        // Update logic
        await ProjectComponentService.updateProjectComponent(
          currentData?.id,
          data,
          async (res) => {
            if (res) {
              await dispatch(
                fetchList(
                  "projectComponents",
                  `${endpoints().projectComponentAPI}/search`,
                  params.page ? params.page : 1,
                  params.pageSize ? params.pageSize : 25,
                  {
                    params,
                  }
                )
              );
              addComponentToggle();
            }
          }
        );
        addComponentToggle();
        setCurrentData("");
      } else {
        await dispatch(
          ProjectComponentService.addProjectComponent(data, params)
        );
        addComponentToggle();
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const projectComponentDelete = async (id) => {
    let componentId = currentData && currentData?.id;
    let projectId = match?.params?.id
      ? match?.params?.id
      : Url.GetParam("projectId")
      ? Url.GetParam("projectId")
      : "";
    dispatch(
      await ProjectComponentService.deleteProjectTicketType(componentId, {
        sort: Url.GetParam("sort"),
        sortDir: Url.GetParam("sortDir"),
        search: Url.GetParam("search"),
        page: Url.GetParam("page"),
        pageSize: Url.GetParam("pageSize"),
        projectId: projectId,
      })
    );
    setCurrentData("");
  };

  const projectTicketTypeDelete = async () => {
    const id = ticketTypeCurrentData?.id;
    let projectId = match?.params?.id
      ? match?.params?.id
      : Url.GetParam("projectId")
      ? Url.GetParam("projectId")
      : "";
    dispatch(
      ProjectTicketTypeService.deleteProjectTicketType(id, {
        ...params,
      })
    );
    setTicketTypeCurrentData("");
  };

  const projectUserCreate = async (data) => {
    if (ArrayList.isArray(selectedUserIds)) {
      data.userId = selectedUserIds;
      data.projectId = match?.params?.id
        ? match?.params?.id
        : Url.GetParam("projectId")
        ? Url.GetParam("projectId")
        : null;
      data.status = ProjectUser.STATUS_ACTIVE_TEXT;
      dispatch(
        await ProjectUserService.addProjectUser(data, (res) => {
          if (res) {
            dispatch(
              fetchList(
                "projectUser",
                `${endpoints().ProjectUserApi}/search`,
                params.page ? params.page : 1,
                params.pageSize ? params.pageSize : 25,
                params
              )
            );
            closeUserToggle();
            setSelectedUserIds([]);
          }
        })
      );
    } else {
      Toast.error("Select Users");
    }
  };

  const actionsMenuList = [
    {
      value: "delete",
      label: "Delete",
    },
  ];

  if (
    (activeTab == Tab.SETTINGS || activeTab == Tab.GENERAL) &&
    showEditButton &&
    editable
  ) {
    actionsMenuList.unshift({
      value: "edit",
      label: "Edit",
    });
  }

  const handleActionChange = (e) => {
    if (e == "edit") {
      setEditable(false);
    }
    if (e == "delete") {
      setDeleteModal(true);
    }
  };

  let BulkSelect = (e) => {
    setSelectedUserIds(e);
  };

  const modalBody = (
    <ReduxTable
      id={"user"}
      showHeader
      newTableHeading
      sortByDropdown
      showStatusOptions={false}
      apiURL={`${endpoints().ProjectUserApi}/userList`}
      searchPlaceholder="Search"
      onBulkSelect={BulkSelect}
      params={{
        projectId: match?.params?.id,
      }}
      bulkSelect
      history={history}
      paramsToUrl={true}
      showScroll
    >
      <ReduxColumn
        field="user"
        renderField={(row) => (
          <>
            <div className="d-flex text-break">
              <AvatarCard
                id="avatar"
                firstName={row.first_name}
                lastName={row.last_name}
                url={row.media_url}
              />
            </div>
          </>
        )}
      >
        User
      </ReduxColumn>
    </ReduxTable>
  );

  const productFooter = (
    <Button
      label={"Add Users"}
      onClick={() => {
        projectUserCreate({});
      }}
    />
  );

  if (Url.GetParam("isLoading") === "true" || isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <DeleteModal
        isOpen={isDeleteModel}
        toggle={closeProjectComponentDeleteModal}
        title=" Project Component"
        label={currentData?.name}
        deleteFunction={projectComponentDelete}
      />
      <DeleteModal
        isOpen={ticketTypeIsDeleteModel}
        toggle={closeTicktTypeDeleteModal}
        title=" Project Ticket type"
        label={ticketTypeCurrentData?.name}
        deleteFunction={projectTicketTypeDelete}
      />
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Project"
        id={projectData && projectData?.projectData?.id}
        label={projectData && projectData?.projectData?.name}
        deleteFunction={() =>
          projectDelete(projectData && projectData?.projectData?.id)
        }
      />
      <BreadCrumb list={breadcrumbList} />
      <div className="mb-3 d-flex justify-content-between">
        <PageTitle label={projectData && projectData?.projectData?.name} />

        <div className="float-end d-flex">
          {activeTab == Tab.TICKET_TYPE && (
            <AddButton
              label={"Add New"}
              // onClick={() => }
              onClick={addtoggle}
              className="mx-2"
            />
          )}
          {activeTab == Tab.USERS && (
            <AddButton
              label={"Add New"}
              // onClick={() => }
              onClick={openUserToggle}
              className="mx-2"
            />
          )}
          {activeTab == Tab.STATUS && (
            <AddButton
              label={"Add"}
              onClick={handleAddButtonClick}
              className="mx-2"
            />
          )}
          {activeTab == Tab.COMPONENTS && (
            <AddButton
              label={"Add New"}
              onClick={addComponentToggle}
              className="mx-2"
            />
          )}
          <div className="ps-2">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
        </div>
      </div>
      <Drawer
        hideAddButton
        DrawerBody={addProjectTicketTypeForm}
        DrawerFooter={projectTicketTypeFooter}
        initialValues={{
          name:
            ticketTypeCurrentData && ticketTypeCurrentData?.name
              ? ticketTypeCurrentData?.name
              : "",
          status:
            ticketTypeCurrentData && ticketTypeCurrentData?.status
              ? statusOption.find(
                  (status) => status.label == ticketTypeCurrentData?.status
                )
              : "",
          sort:
            sortChange === ""
              ? ""
              : sortChange
              ? sortChange
              : ticketTypeCurrentData && ticketTypeCurrentData?.sort
              ? ticketTypeCurrentData?.sort
              : "",
          story_points:
            defaultStoryPoint === null
              ? null
              : defaultStoryPoint
              ? defaultStoryPoint
              : ticketTypeCurrentData &&
                ticketTypeCurrentData?.default_story_point
              ? {
                  label: ticketTypeCurrentData?.default_story_point,
                  value: ticketTypeCurrentData?.default_story_point,
                }
              : "",
          fine_type:fineType ? {
                value: fineType?.value,
                label: fineType?.label,
              } : ArrayList.isArray(fineTypeList) && fineTypeList.find((data)=> data?.value == ticketTypeCurrentData?.fine_type)    
        }}
        onSubmit={(values) => {
          projectTicketTypeCreate(values);
        }}
        handleOpenModal={addtoggle}
        handleCloseModal={closeTicketTypeToggle}
        handleDrawerClose={closeTicketTypeToggle}
        isModalOpen={isOpen}
        buttonLabel={true}
        showButton={true}
        modelTitle={ticketTypeCurrentData?.id ? "Edit Type" : "New Type"}
      />
      <Drawer
        modelTitle={currentData ? "Edit Components" : "New Components"}
        DrawerBody={addComponentForm}
        enableReinitialize
        DrawerFooter={projectTicketTypeFooter}
        initialValues={{
          name: currentData?.name ? currentData?.name : "",
          status:
            currentData &&
            statusOption.find((data) => data.value == currentData.statusId),
        }}
        onSubmit={(values) => {
          projectComponentSubmit(values);
        }}
        handleOpenModal={addComponentToggle}
        handleCloseModal={closeComponentToggle}
        handleDrawerClose={closeComponentToggle}
        isModalOpen={isComponentModalOpen}
        buttonLabel={true}
        showButton={true}
        hideAddButton
      />

      <Drawer
        modelTitle="Select User"
        DrawerBody={modalBody}
        DrawerFooter={productFooter}
        initialValues={{
          user: "",
          status:
            {
              value: "Active",

              label: "Active",
            } || "",
        }}
        handleOpenModal={openUserToggle}
        handleCloseModal={closeUserToggle}
        handleDrawerClose={closeUserToggle}
        isModalOpen={isModalOpen}
        enableReinitialize
      />

      <Nav tabs className="admin-tabs">
        {/* Detail Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Tab.GENERAL,
            })}
            onClick={() => {
              toggle(Tab.GENERAL);
            }}
          >
            {Tab.GENERAL}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Tab.TICKET_TYPE,
            })}
            onClick={() => {
              toggle(Tab.TICKET_TYPE);
            }}
          >
            {Tab.TICKET_TYPE}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Tab.USERS,
            })}
            onClick={() => {
              toggle(Tab.USERS);
            }}
          >
            {Tab.USERS}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Tab.COMPONENTS,
            })}
            onClick={() => {
              toggle(Tab.COMPONENTS);
            }}
          >
            {Tab.COMPONENTS}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === Tab.SETTINGS,
            })}
            onClick={() => {
              toggle(Tab.SETTINGS);
            }}
          >
            {Tab.SETTINGS}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        {/* Detail Tab Start*/}
        {activeTab == Tab.GENERAL && (
          <TabPane tabId={Tab.GENERAL} className="w-100">
            <General
              match={match}
              history={history}
              projectData={projectData}
              editable={editable}
              setEditable={setEditable}
            />
          </TabPane>
        )}
        {activeTab == Tab.TICKET_TYPE && (
          <TabPane tabId={Tab.TICKET_TYPE} className="w-100">
            <TicketType
              projectId={
                match?.params?.id
                  ? match?.params?.id
                  : Url.GetParam("projectId")
              }
              history={history}
              handleOpenModal={addtoggle}
              setCurrentData={setTicketTypeCurrentData}
              setIsDeleteModel={setTicketTypeIsDeleteModel}
            />
          </TabPane>
        )}
        {activeTab == Tab.SETTINGS && (
          <TabPane tabId={Tab.SETTINGS} className="w-100">
            <SettingsTab
              history={history}
              projectId={
                match?.params?.id
                  ? match?.params?.id
                  : Url.GetParam("projectId")
                  ? Url.GetParam("projectId")
                  : ""
              }
              editable={editable}
              setEditable={setEditable}
            />
          </TabPane>
        )}
        {activeTab == Tab.USERS && (
          <TabPane tabId={Tab.USERS} className="w-100">
            <ProjectUsers history={history} />
          </TabPane>
        )}
        {activeTab == Tab.COMPONENTS && (
          <TabPane tabId={Tab.COMPONENTS} className="w-100">
            <ProjectComponent
              history={history}
              projectId={
                match?.params?.id
                  ? match?.params?.id
                  : Url.GetParam("projectId")
                  ? Url.GetParam("projectId")
                  : ""
              }
              handleOpenModal={addComponentToggle}
              setCurrentData={setCurrentData}
              setIsDeleteModel={setIsDeleteModel}
            />
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

export default ProjectDetail;
