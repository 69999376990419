import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import Button from "../../../components/Button";
import DeleteModal from "../../../components/DeleteModal";
import Drawer from "../../../components/Drawer";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { DropdownItem } from "reactstrap";
import { fetchList } from "../../../actions/table";
import { endpoints } from "../../../api/endPoints";
import MoreDropdown from "../../../components/authentication/moreDropdown";
import Phone from "../../../components/Phone";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import Text from "../../../components/Text";
import Url from "../../../lib/Url";
import TextArea from "../../../components/TextArea";
import CallRegisterService from "../../../services/CallRegisterService";
import CallRegisterFilter from "./Filter";
import TagSelect from "../../../components/TagSelect";
import Number from "../../../lib/Number";
import TagService from "../../../services/TagService";
import DateTime from "../../../lib/DateTime";

const CallRegisterList = (props) => {
  let { isModalOpen, addCallRegisterToggle } = props;

  const [rowValue, setRowValue] = useState(null);
  const [isCallRegisterDeleteModel, setDeleteCallRegisterModal] =
    useState(false);
  const [purposeChange, setPurposeChange] = useState(null);
  const [purposeTagList, setPurposeTagList] = useState();
  const [phoneValue, setPhoneValue] = useState(null);
  const [nameChange, setNameChange] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    getTagList();
  }, []);

  const { history } = props;

  const handleTagChange = (values) => {
    let value = values;
    setPurposeChange(value);
  };

  const handlePhoneChange = (e) => {
    setPhoneValue(e);
  };

  const handleNameChange = (e) => {
    let value = e.target.value;
    setNameChange(value);
  };

  const getTagList = async () => {
    let purposeTagList = await TagService.getOption();
    setPurposeTagList(purposeTagList);
  };

  const addCallRegisterForm = (
    <div>
      <Text
        label="Name"
        name="name"
        className="fw-bold-text"
        placeholder="Enter Name"
        required
        error=""
        onChange={handleNameChange}
      />
      <Phone
        label="Mobile Number"
        name="phone"
        placeholder="Enter Mobile Number"
        error=""
        onChange={handlePhoneChange}
      />
      <TagSelect
        name="purpose"
        label="Purpose"
        placeholder="Select Purpose"
        params={{ type: "Call Register Purpose" }}
        handleTagChange={handleTagChange}
      />
      <TextArea name="notes" label="Notes" placeholder="Enter Notes..." />
    </div>
  );

  const addCallRegisterFooter = (
    <Button
      type="submit"
      label={rowValue?.id ? "Edit" : "Add"}
      showButtonLabelName
    ></Button>
  );

  const closeDeleteModal = () => {
    setDeleteCallRegisterModal(false);
    setRowValue(null);
  };

  const closeCallRegisterToggle = () => {
    props.setIsModalOpen && props.setIsModalOpen(false);
    setRowValue(null);
    setPurposeChange(null);
    setNameChange("");
    setPhoneValue("");
  };

  const handleCallRegisterDelete = async () => {
    let params = {
      ...Url.GetAllParams(),
      date: Url.GetParam("date"),
    };
    dispatch(
      await CallRegisterService.del(rowValue?.id, () => {
        dispatch(
          fetchList(
            "callRegister",
            `${endpoints().callRegisterAPI}/search`,
            Url.GetParam("page") ? Url.GetParam("page") : 1,
            Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
            params
          )
        );
        closeDeleteModal();
      })
    );
  };

  const handleCallRegister = async (values) => {
    const createData = new FormData();
    let id = rowValue?.id;
    createData.append("name", values.name ? values.name : "");
    createData.append("phone", values.phone ? values.phone : "");
    createData.append("purpose", Number.Get(values?.purpose?.value));
    createData.append("notes", values.notes ? values.notes : "");

    let params = {
      ...Url.GetAllParams(),
      date: Url.GetParam("date"),
    };

    if (rowValue?.id) {
      createData.append("id", rowValue?.id);

      dispatch(
        await CallRegisterService.update(id, createData, (res) => {
          dispatch(
            fetchList(
              "callRegister",
              `${endpoints().callRegisterAPI}/search`,
              Url.GetParam("page") ? Url.GetParam("page") : 1,
              Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
              params
            )
          );
          closeCallRegisterToggle();
        })
      );
    } else {
      dispatch(
        await CallRegisterService.create(createData, (response) => {
          dispatch(
            fetchList(
              "callRegister",
              `${endpoints().callRegisterAPI}/search`,
              Url.GetParam("page") ? Url.GetParam("page") : 1,
              Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
              params
            )
          );
          closeCallRegisterToggle("");
        })
      );
    }
  };

  const initialValue = {
    name: nameChange ? nameChange : rowValue?.name ? rowValue?.name : "",
    notes: rowValue?.notes ? rowValue?.notes : "",
    phone: phoneValue ? phoneValue : rowValue?.phone ? rowValue?.phone : "",
    purpose: purposeChange
      ? {
          label: purposeChange?.label,
          value: purposeChange?.value,
        }
      : purposeTagList &&
        purposeTagList.find((fine) => fine.value === rowValue?.purposeId),
  };

  return (
    <>
      <DeleteModal
        isOpen={isCallRegisterDeleteModel}
        title="Delete Call Register"
        label={rowValue?.name}
        toggle={closeDeleteModal}
        deleteFunction={handleCallRegisterDelete}
      />
      <Drawer
        modelTitle={rowValue?.id ? "Edit Call Register" : "Add Call Register"}
        DrawerBody={addCallRegisterForm}
        DrawerFooter={addCallRegisterFooter}
        onSubmit={(values) => handleCallRegister(values)}
        initialValues={initialValue}
        handleOpenModal={addCallRegisterToggle}
        handleCloseModal={closeCallRegisterToggle}
        handleDrawerClose={closeCallRegisterToggle}
        isModalOpen={isModalOpen}
        enableReinitialize={true}
      />

      <CallRegisterFilter history={history} />

      <ReduxTable
        id="callRegister"
        disableHeader
        newTableHeading
        searchPlaceholder="Search"
        apiURL={`${endpoints().callRegisterAPI}/search`}
        history={props?.history}
        paramsToUrl={true}
        sortByDropdown
        className="lh-lg"
        params={{}}
        showPageSize
      >
        <ReduxColumn
          field="name"
          sortBy="name"
          width="210px"
          minWidth="210px"
          maxWidth="210px"
          className="text-center"
          renderField={(row) => (
            <Link
              to={`/callRegister/${row.id}`}
              className="link-opacity-75 text-decoration-none"
            >
              {row?.id}
            </Link>
          )}
        >
          Call Register#
        </ReduxColumn>
        <ReduxColumn
          field="dateTime"
          sortBy="created_at"
          width="180px"
          minWidth="180px"
          maxWidth="180px"
          className="text-center"
          renderField={(row) => <>{DateTime.getDateAndTime(row?.dateTime)}</>}
        >
          Date
        </ReduxColumn>
        <ReduxColumn
          field="name"
          sortBy="name"
          width="210px"
          minWidth="210px"
          maxWidth="210px"
        >
          Name
        </ReduxColumn>
        <ReduxColumn
          field="phone"
          sortBy="phone"
          width="180px"
          minWidth="180px"
          maxWidth="180px"
          className="text-center"
        >
          Phone
        </ReduxColumn>
        <ReduxColumn
          field="type"
          sortBy="type"
          width="210px"
          minWidth="210px"
          maxWidth="210px"
          className="text-center"
        >
          Call Type
        </ReduxColumn>
        <ReduxColumn
          field="duration"
          sortBy="duration"
          width="210px"
          minWidth="210px"
          maxWidth="210px"
          className="text-center"
        >
          Duration
        </ReduxColumn>
        <ReduxColumn
          field="purpose"
          sortBy="purpose"
          width="210px"
          minWidth="210px"
          maxWidth="210px"
          className="text-center"
        >
          Purpose
        </ReduxColumn>
        <ReduxColumn
          field="owner"
          sortBy="owner"
          width="210px"
          minWidth="210px"
          maxWidth="210px"
          className="text-center"
        >
          Owner
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          disableOnClick
          renderField={(row) => (
            <div className="text-center action-group-dropdown">
              <MoreDropdown>
                <DropdownItem
                  onClick={() => {
                    setRowValue(row);
                    props.setIsModalOpen && props.setIsModalOpen(true);
                  }}
                >
                  Quick View
                </DropdownItem>
                <DropdownItem
                  className="text-danger"
                  onClick={() => {
                    setRowValue(row);
                    setDeleteCallRegisterModal(true);
                  }}
                >
                  Delete
                </DropdownItem>
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default CallRegisterList;
