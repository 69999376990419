import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Form from '../../components/Form';
import SaveButton from '../../components/SaveButton';
import Select from '../../components/Select';
import { getCompanySettings, saveSetting } from "../../services/SettingService";
import { getKeyValueByObject } from "../../lib/Helper";
import { Setting } from "../../helpers/Setting";
import MultiSelect from '../../components/Multiselect';
import CompanyService from '../../services/CompanyService';
import { getUserRole } from "../../services/UserSettingService";
import StoreSelector from '../location/components/storeSelector';
import AccountTypeSelect from '../../components/AccountTypeSelect';
import ArrayList from '../../lib/ArrayList';
import orderTypeService from '../../services/OrderTypeService';

const SettingPage = (props) => {
    const [companyList, setCompanyList] = useState([]);
    const [company, setCompany] = useState([])
    const [userRoleList, setUserRoleList] = useState([]);
    const [userRole, setUserRole] = useState([])
    const [orderTypeValue, setOrderTypeValue] = useState(null);
    const [accountTypeValue, setAccountTypeValue] = useState(null);
    const [locationValue, setLocationValue] = useState(null);
    const [storeList, setStoreList] = useState([]);
    const [orderTypes, setOrderTypes] = useState([]);
    const [accountTypes, setAccountType] = useState([]);
    const [settings, setSettings] = useState([]);

    let dispatch = useDispatch();

    useEffect(() => {
        getSettings()
        getCompanyList();
        getUserRoleValue();
        getOrderTypes()
    }, [props]);

    const getOrderTypes = async () => {
        let response = await orderTypeService.list();
        let data = response && response?.data;
        setOrderTypes(data);
    };
    const getSettings = async () => {
        const settingData = await getCompanySettings();
        let company = getKeyValueByObject(settingData, Setting.ONLINE_SALE_COMPANY);
        setCompany(company)
        let role = getKeyValueByObject(settingData, Setting.ONLINE_SALE_USER_ROLE)
        setUserRole(role)
        setSettings(settingData)
    };
    const getUserRoleValue = async () => {
        const roleData = await getUserRole();
        setUserRoleList(roleData);
    };

    const getCompanyList = async () => {
        const data = await CompanyService.search();
        let list = [];
        data && data.length > 0 && data.forEach((companyList) => {
            list.push({
                value: companyList.id,
                label: companyList.company_name,
                id: companyList.id,
            });
        });
        setCompanyList(list)
    };
    const accountType =
        settings &&
            ArrayList.isNotEmpty(settings) &&
            getKeyValueByObject(settings, Setting.ONLINE_ACCOUNT_TYPE)
            ? getKeyValueByObject(settings, Setting.ONLINE_ACCOUNT_TYPE)
            : null;
    let orderTypesValue = getKeyValueByObject(
        settings,
        Setting.ONLINE_ORDER_TYPE
    );
    let orderTypesArray = [];

    if (orderTypesValue) {
        let orderTypeData = orderTypesValue && orderTypesValue.split(",");

        orderTypeData.forEach((value) => {
            let data = orderTypes && orderTypes.find((data) => data?.value == value);
            orderTypesArray.push(data);
        });
    }

    let allowedLocation = getKeyValueByObject(
        settings,
        Setting.ONLINE_LOCATION
    );
    let locationArray = [];
    if (allowedLocation) {
        let locationData = allowedLocation && allowedLocation.split(",");
        locationData.forEach((value) => {
            let data = storeList && storeList.find((data) => data?.id == value);
            locationArray.push(data);
        });
    }


    const handleUpdate = async (values) => {
        let data = new FormData();

        data.append(
            Setting.ONLINE_SALE_COMPANY,
            values && values?.online_sale_company?.value
        );
        data.append(
            Setting.ONLINE_SALE_USER_ROLE,
            values && values?.online_sale_user_role?.value
        );

        let allowedOrder = [];
        for (let i = 0; i < values.online_order_type.length; i++) {
            const data = values.online_order_type[i];
            allowedOrder.push(data?.value);
        }
        let allowedLocation = [];
        for (let i = 0; i < values.online_location.length; i++) {
            const data = values.online_location[i];
            allowedLocation.push(data?.id);
        }

        data.append(
            Setting.ONLINE_ORDER_TYPE,
            allowedOrder && allowedOrder.length > 0 ? allowedOrder.join(",") : ""
        );
        data.append(
            Setting.ONLINE_ACCOUNT_TYPE,
            values?.online_account_type && values?.online_account_type?.value
                ? values?.online_account_type?.value
                : ""
        );
        data.append(
            Setting.ONLINE_LOCATION,
            allowedLocation && allowedLocation.length > 0
                ? allowedLocation.join(",")
                : ""
        );

        saveSetting(data, null, (res) => {
            if (res) {
            }
        });
    };

    const handleAllowedOrderTypeChange = (value) => {
        let data = value?.online_order_type;
        setOrderTypeValue(data);
    }

    const handleAccountTypeChange = (value) => {
        let data = value && value?.values?.online_account_type;
        setAccountTypeValue(data);
    }

    const handleAllowedLocationChange = (value) => {
        let data = value?.online_location;
        setLocationValue(data);
    }


    return (
        <div>
            <Form
                enableReinitialize={true}
                initialValues={{
                    online_sale_company: companyList && companyList.length > 0 && companyList.find((data) => data?.value == company),
                    online_sale_user_role: userRoleList && userRoleList.length > 0 && userRoleList.find((data) => data?.value == userRole),
                    online_account_type: accountTypeValue ? accountTypeValue :
                        accountTypes && accountTypes.find((value) => value.value == accountType),
                    online_order_type: orderTypeValue ? orderTypeValue : orderTypesArray,
                    online_location: locationValue ? locationValue : locationArray,
                }}
                onSubmit={(values) => {
                    handleUpdate(values);
                }}
            >
                <div className='card bg-white mb-3'>
                    <div className='card-body'>
                        <Select
                            name={Setting.ONLINE_SALE_COMPANY}
                            label='Company'
                            options={companyList}
                            handleOnChangeSubmit={(values) => {
                                setCompany(values?.online_sale_company?.value)
                            }}
                        />
                        <Select
                            name={Setting.ONLINE_SALE_USER_ROLE}
                            label='Role'
                            options={userRoleList}
                            handleOnChangeSubmit={(values) => {
                                setUserRole(values?.online_sale_user_role?.value)
                            }}
                        />
                        <div className="col">
                            <MultiSelect
                                label="Order Types "
                                name={Setting.ONLINE_ORDER_TYPE}
                                options={orderTypes ? orderTypes : []}
                                handleOnChangeSubmit={handleAllowedOrderTypeChange}
                            />
                        </div>
                        <div className="col">
                            <AccountTypeSelect
                                label="Account Type"
                                name={Setting.ONLINE_ACCOUNT_TYPE}
                                accountTypeOption={setAccountType}
                                onChange={handleAccountTypeChange}
                            />
                        </div>
                        <div className="col">
                            <StoreSelector
                                label="Location"
                                name={Setting.ONLINE_LOCATION}
                                setStoreList={setStoreList}
                                handleOnChangeSubmit={handleAllowedLocationChange}
                            />
                        </div>

                        <div className='mb-4'>
                            <div>
                                <SaveButton />
                            </div>
                        </div>
                    </div>
                </div>
            </Form>
        </div>
    );
};
export default SettingPage;