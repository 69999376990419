import React, { useState } from "react";
import "../../scss/_custom.scss";
import Select from "../../components/Select";

const HomePage = (props) => {

  const { pagesList } = props;

  const [nameToggle, setNameToggle] = useState(true);

  return (
    <>
      <div className="accordion mt-3" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className={`accordion-button ${nameToggle ? "" : "collapsed"}`}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded={nameToggle ? "true" : "false"}
              aria-controls="collapseOne"
              onClick={() => setNameToggle(!nameToggle)}
            >
              <h5 className="text-primary">Home Page</h5>
            </button>
          </h2>
          <div
            id="collapseOne"
            className={`accordion-collapse collapse ${nameToggle ? "show" : ""}`}
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-3">
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <Select
                    name="home_page"
                    label="Home Page"
                    options={pagesList}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
