export const CandidateStatus = {
    STATUS_NEW: "NEW",
};

export const FieldLabel = {
    AGE: "Age",
    CURRENT_CITY: "Current City",
    CURRENT_STATE: "Current State",
    DEPARTMENT: "Department",
    EMAIL: "Email",
    EXPECTED_SALARY: "Expected Salary",
    GENDER: "Gender",
    MARITAL_STATUS: "Marital Status",
    MESSAGE: "Message",
    PERMANENT_CITY: "Permanent City",
    PERMANENT_STATE: "Permanent State",
    PHONE: "Phone",
    POSITION: "Position",
    QUALIFICATION: "Qualification",
    STAYING_IN: "Staying In",
    YEAR_OF_PASSING: "Year Of Passing",
  };




 const LANGUAGE_ENGLISH_TEXT = "English"
 const LANGUAGE_HINDI_TEXT = "Hindi"
 const LANGUAGE_KANNADA_TEXT = "Kannada"
 const LANGUAGE_MALAYALAM_TEXT = "Malayalam"
 const LANGUAGE_TAMIL_TEXT = "Tamil"
 const LANGUAGE_TELUGU_TEXT = "Telugu"


 const LANGUAGE_ENGLISH_VALUE = 1
 const LANGUAGE_HINDI_VALUE = 2
 const LANGUAGE_KANNADA_VALUE = 3
 const LANGUAGE_MALAYALAM_VALUE = 4
 const LANGUAGE_TAMIL_VALUE = 5
 const LANGUAGE_TELUGU_VALUE = 6



 export const LanguageKnown = [
    { label: LANGUAGE_ENGLISH_TEXT, value: LANGUAGE_ENGLISH_VALUE },
    { label: LANGUAGE_HINDI_TEXT, value: LANGUAGE_HINDI_VALUE},
    { label: LANGUAGE_KANNADA_TEXT, value: LANGUAGE_KANNADA_VALUE },
    { label: LANGUAGE_MALAYALAM_TEXT, value: LANGUAGE_MALAYALAM_VALUE },
    { label: LANGUAGE_TAMIL_TEXT, value: LANGUAGE_TAMIL_VALUE },
    { label: LANGUAGE_TELUGU_TEXT, value: LANGUAGE_TELUGU_VALUE }
]

