import React from "react";
import Filter from "../../../components/Filter";
import { useState } from "react";
import Url from "../../../lib/Url";
import Text from "../../../components/Text";
import NumberHelper from "../../../lib/Number";
import Number from "../../../components/Number";
import ArrayList from "../../../lib/ArrayList";
import { useDispatch } from "react-redux";
import { fetchList } from "../../../actions/table";
import { endpoints } from "../../../api/endPoints";
import TagSelect from "../../../components/TagSelect";
import DateSelect from "../../../components/DateSelect";
import { DateOptions } from "../../../helpers/Date";

const CallRegisterFilter = (props) => {
  let { history } = props;

  const [pageSize, setPageSize] = useState(Url.GetParam("pageSize"));
  const [searchItem, setSearch] = useState(Url.GetParam("search"));
  const [spinValue, setSpin] = useState(false);
  const [allParams, setAllParams] = useState({ ...Url.GetAllParams() });
  const [TagList, setTagList] = useState();

  let dispatch = useDispatch();
  const UpdateUrl = (params) => {
    const currentPage = window.location.pathname;
    let queryString = "";
    const queryStringArray = Object.entries(params);

    if (queryStringArray.length > 0) {
      queryString = "?";
      queryStringArray.forEach(async (queryParam) => {
        if (queryParam[1] !== "")
          queryString = `${queryString}&${queryParam[0]}=${queryParam[1]}`;
      });
    }
    if (history) {
      history.push(`${currentPage}${queryString}`);
    }
  };

  let filterForm = (
    <>
      <div className="row d-block px-0 py-0">
        <div className="col">
          <Text name="name" placeholder="Enter Name" />
        </div>
        <div className="col">
          <Number name="phone" placeholder="Enter Phone Number" />
        </div>
        <div className="col">
          <TagSelect
            name="purpose"
            placeholder="Select Purpose"
            params={{ type: "Call Register Purpose" }}
            TagList={setTagList}
          />
        </div>
        <div className="col">
          <DateSelect name="date" placeholder="Select Date"  />
        </div>
      </div>
    </>
  );

  let getInitialValue = (key) => {
    if (NumberHelper.isNotNull(allParams[key])) {
      return allParams[key];
    } else {
      return "";
    }
  };
  let filterInitialValues = {
    name: getInitialValue("name"),
    phone: getInitialValue("phone"),
    purpose:
      TagList &&
      TagList.find((fine) => fine.value == getInitialValue("purpose")),
    date:
      DateOptions &&
      DateOptions.find((fine) => fine.value == getInitialValue("date")),
  };

  const handleSearchTermChange = (e) => {
    const searchValue = e.target.value;
    setSearch(searchValue);
    setAllParams((preValue) => ({
      ...preValue,
      search: searchValue,
    }));
  };

  const onSearchKeyUp = async (event) => {
    if (event.charCode === 0) {
      let params = {
        ...allParams,
        search: searchItem,
      };

      dispatch(
        fetchList(
          "callRegister",
          `${endpoints().callRegisterAPI}/search`,
          Url.GetParam("page") ? Url.GetParam("page") : 1,
          Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
          params
        )
      );
      UpdateUrl(params);
    }
  };

  const handlePageSize = async (value) => {
    let params = {
      ...allParams,
      pageSize: value,
    };
    setPageSize(value);
    dispatch(
      fetchList(
        "callRegister",
        `${endpoints().callRegisterAPI}/search`,
        Url.GetParam("page") ? Url.GetParam("page") : 1,
        value,
        params
      )
    );
    UpdateUrl(params);
    setAllParams((preValue) => ({
      ...preValue,
      pageSize: value,
    }));
  };

  const refreshButtonOnClick = async () => {
    const filteredValues = Object.fromEntries(
      Object.entries(allParams)
        .filter(([key, _]) => !isNaN(key))
        .map(([_, value]) => {
          const [prop, val] = value?.split("=");
          return [prop, val];
        })
    );
    setSpin(true);
    dispatch(
      fetchList(
        "callRegister",
        `${endpoints().callRegisterAPI}/search`,
        Url.GetParam("page") ? Url.GetParam("page") : 1,
        Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
        { ...filteredValues, ...allParams }
      )
    );
    setSpin(false);
  };

  const handleFilter = (values) => {
    values.purpose = values?.purpose ? values?.purpose?.value : "";
    values.date = values?.date ? values?.date?.value : "";
    let filterParams = ArrayList.toQueryString(values);
    const filteredValues = Object.fromEntries(
      Object.entries(filterParams)
        .filter(([key, _]) => !isNaN(key))
        .map(([_, value]) => {
          const [prop, val] = value?.split("=");
          return [prop, val];
        })
    );
    dispatch(
      fetchList(
        "callRegister",
        `${endpoints().callRegisterAPI}/search`,
        Url.GetParam("page") ? Url.GetParam("page") : 1,
        Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
        filteredValues
      )
    );
    UpdateUrl(filteredValues);
    setAllParams(filteredValues);
  };

  const handleDeleteFilter = async (removedFilter) => {
    let params = {
      ...allParams,
      ...removedFilter,
    };
    dispatch(
      fetchList(
        "callRegister",
        `${endpoints().callRegisterAPI}/search`,
        Url.GetParam("page") ? Url.GetParam("page") : 1,
        Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
        params
      )
    );
    UpdateUrl(params);
    setAllParams(params);
  };

  return (
    <div>
      <Filter
        showHeader
        newTableHeading
        pageSearchOnChange={(e) => {
          handleSearchTermChange(e);
        }}
        sortByDropdown
        pageSearchValue={searchItem}
        searchPlaceholder="Search"
        getPageSizeByOptions={(e) => handlePageSize(e)}
        selectedPageSize={pageSize && pageSize}
        refreshButtonOnClick={refreshButtonOnClick}
        refreshValue={spinValue}
        showPageSize
        handleDeleteFilter={handleDeleteFilter}
        initialValues={filterInitialValues}
        handleFilter={handleFilter}
        onKeyDown={onSearchKeyUp}
        customChild={filterForm}
      />
    </div>
  );
};

export default CallRegisterFilter;
