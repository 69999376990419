import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";
import { fetchList } from "../../actions/table.js";
import { endpoints } from "../../api/endPoints";
import MoreDropdown from "../../components/authentication/moreDropdown.js";
import AvatarCard from "../../components/AvatarCard";
import Button from "../../components/Button";
import DeleteModal from "../../components/DeleteModal.js";
import Drawer from "../../components/Drawer.js";
import PageTitle from "../../components/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/reduxTable";
import StatusText from "../../components/StatusText.js";
import * as Constants from "../../helpers/Candidate.js";
import Cookie from "../../helpers/Cookie.js";
import { Jobs } from "../../helpers/Job.js";
import ObjectName from "../../helpers/ObjectName";
import Currency from "../../lib/Currency.js";
import Cookies, { setCookie } from "../../lib/Helper.js";
import Url from "../../lib/Url.js";
import CandidateService from "../../services/CandidateService.js";
import JobService from "../../services/JobService.js";
import StatusService from "../../services/StatusService";
import CandidateFilter from "./components/Filter.js";
import CandidateForm from "./components/form";
import { toast } from "react-toastify";
import MessageInput from "../../components/MessageInput.js";

const Candidate = (props) => {
  const [isOpen, setIsOpen] = useState(0);
  const [arrays, setArray] = useState([]);
  const [arrayList, setArrayList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [rowValue, setRowValue] = useState(null);
  const [jobsDetail, setJobsDetail] = useState([]);
  const [bulkSelectIds, setBulkSelectIds] = useState([]);
  const [selectedCheckBox, setSelectedCheckBox] = useState(true);
  const [isBulkModelOpen, setIsBulkModelOpen] = useState(false);
  const [subject, setSubject] = useState('');
  const { history } = props;

  const dispatch = useDispatch();
  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
    {
      value: "first_name:ASC",
      label: "Name",
    },
  ];

  const genderOptions = [
    {
      value: "Male",
      label: "Male",
    },
    {
      value: "Female",
      label: "Female",
    },
  ];

  const maritialStatusOptions = [
    {
      value: "Single",
      label: "Single",
    },
    {
      value: "Married",
      label: "Married",
    },
  ];

  useEffect(() => {
    getJobDetails();
  }, []);

  const toggle = () => {
    setIsOpen(!isOpen);
    setRowValue("");
  };

  // Use Effect
  useEffect(() => {
    const checkedList = Cookies.get(Cookie.CANDIDATE);
    const checkedLists = checkedList ? JSON.parse(checkedList) : checkedList;
    if (checkedLists) {
      setArrayList(checkedLists);
      setArray(checkedLists);
    }
  }, []);

  const handleColumnChange = async (e) => {
    const array = e;
    let arrayList = [];
    arrayList = JSON.stringify(array);
    setCookie(Cookie.CANDIDATE, arrayList);
    setArray(array);
    setArrayList(array);
  };

  const closeDeleteModal = () => {
    setOpenDeleteModal(!openDeleteModal);
    setRowValue("");
  };

  const getStatusList = async (currentStatusId, allowed_statuses) => {
    if (currentStatusId) {
      const data = await StatusService.nextStatusSearch(
        ObjectName.CANDIDATE,
        currentStatusId,
        "",
        allowed_statuses
      );

      if (data && data.length > 0) {
        setStatusList(data);
      }
    }
  };

  const onStatusChange = async (value, id) => {
    const data = {};
    data.status = value;
    dispatch(
      await CandidateService.updateStatus(id, data, (res) => {
        if (res) {
          dispatch(
            fetchList(
              "Candidate",
              `${endpoints().candidate}/list`,
              Url.GetParam("page") ? Url.GetParam("page") : 1,
              Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
              {
                ...Url.GetAllParams(),
              }
            )
          );
        }
      })
    );
  };

  const handleDelete = async (id) => {
    dispatch(
      await CandidateService.delete(id, () => {
        dispatch(
          fetchList(
            "Candidate",
            `${endpoints().candidate}/list`,
            Url.GetParam("page") ? Url.GetParam("page") : 1,
            Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
            {
              ...Url.GetAllParams(),
            }
          )
        );
        closeDeleteModal();
        setOpenDeleteModal(false);
        setRowValue(null);
      })
    );
  };

  const FieldLabel = [
    {
      value: Constants.FieldLabel.AGE,
      label: Constants.FieldLabel.AGE,
    },
    {
      value: Constants.FieldLabel.CURRENT_CITY,
      label: Constants.FieldLabel.CURRENT_CITY,
    },
    {
      value: Constants.FieldLabel.CURRENT_STATE,
      label: Constants.FieldLabel.CURRENT_STATE,
    },
    {
      value: Constants.FieldLabel.DEPARTMENT,
      label: Constants.FieldLabel.DEPARTMENT,
    },
    {
      value: Constants.FieldLabel.EMAIL,
      label: Constants.FieldLabel.EMAIL,
    },
    {
      value: Constants.FieldLabel.EXPECTED_SALARY,
      label: Constants.FieldLabel.EXPECTED_SALARY,
    },
    {
      value: Constants.FieldLabel.GENDER,
      label: Constants.FieldLabel.GENDER,
    },
    {
      value: Constants.FieldLabel.MARITAL_STATUS,
      label: Constants.FieldLabel.MARITAL_STATUS,
    },
    {
      value: Constants.FieldLabel.MESSAGE,
      label: Constants.FieldLabel.MESSAGE,
    },
    {
      value: Constants.FieldLabel.PERMANENT_CITY,
      label: Constants.FieldLabel.PERMANENT_CITY,
    },
    {
      value: Constants.FieldLabel.PERMANENT_STATE,
      label: Constants.FieldLabel.PERMANENT_STATE,
    },
    {
      value: Constants.FieldLabel.PHONE,
      label: Constants.FieldLabel.PHONE,
    },
    {
      value: Constants.FieldLabel.POSITION,
      label: Constants.FieldLabel.POSITION,
    },
    {
      value: Constants.FieldLabel.QUALIFICATION,
      label: Constants.FieldLabel.QUALIFICATION,
    },
    {
      value: Constants.FieldLabel.STAYING_IN,
      label: Constants.FieldLabel.STAYING_IN,
    },
    {
      value: Constants.FieldLabel.YEAR_OF_PASSING,
      label: Constants.FieldLabel.YEAR_OF_PASSING,
    },
  ];

  function getKeyByValue(object, value) {
    let isSelected = false;
    for (const key in object) {
      if (key == value) {
        isSelected = object[key] == true ? true : false;
      }
    }
    return isSelected;
  }

  const email =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.EMAIL) ? true : false;
  const gender =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.GENDER) ? true : false;
  const age =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.AGE) ? true : false;
  const marital_status =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.MARITAL_STATUS)
      ? true
      : false;
  const position =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.POSITION)
      ? true
      : false;
  const Phone =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.PHONE) ? true : false;
  const department =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.DEPARTMENT)
      ? true
      : false;
  const year_of_passing =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.YEAR_OF_PASSING)
      ? true
      : false;
  const qualification =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.QUALIFICATION)
      ? true
      : false;
  const expected_salary =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.EXPECTED_SALARY)
      ? true
      : false;
  const current_city =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.CURRENT_CITY)
      ? true
      : false;
  const current_state =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.CURRENT_STATE)
      ? true
      : false;

  const permanent_city =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.PERMANENT_CITY)
      ? true
      : false;
  const permanent_state =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.PERMANENT_STATE)
      ? true
      : false;
  const staying_with =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.STAYING_IN)
      ? true
      : false;
  const message =
    arrays && getKeyByValue(arrays, Constants.FieldLabel.MESSAGE)
      ? true
      : false;

  const handleSubmit = async (values) => {
    values.maritalStatus = values?.maritalStatus?.value;
    values.gender = values?.gender?.value;
    values.skills = JSON.stringify(values.skills);
    values.position_id = values?.position.value;
    values.position = values?.position.label;
    let params = {
      sort: Url.GetParam("sort"),
      sortDir: Url.GetParam("sortDir"),
      page: Url.GetParam("page"),
      pageSize: Url.GetParam("pageSize"),
      search: Url.GetParam("search"),
    };
    if (rowValue && rowValue?.candidateId) {
      await CandidateService.update(rowValue?.candidateId, values, (res) => {
        dispatch(
          fetchList(
            "Candidate",
            `${endpoints().candidate}/list`,
            Url.GetParam("page") ? Url.GetParam("page") : 1,
            Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
            {
              ...Url.GetAllParams(),
            }
          )
        );
        toggle(false);
        setRowValue(null);
      });
    } else {
      dispatch(CandidateService.add(values, params, toggle));
    }
  };

  const getJobDetails = async () => {
    let response = await JobService.search({
      status: Jobs.STATUS_ACTIVE,
    });
    let data = response && response?.data && response?.data?.data;
    if (response && response?.data && response?.data?.data) {
      let list = [];
      for (let i = 0; i < data.length; i++) {
        const { job_title, id, slug } = data[i];
        list.push({
          label: job_title,
          id: id,
          value: id,
          slug: slug,
        });
      }

      setJobsDetail(list);
    } else {
      setJobs([]);
    }
  };
  const initialValues = {
    firstName: rowValue?.firstName ? rowValue?.firstName : "",
    lastName: rowValue?.lastName ? rowValue?.lastName : "",
    email: rowValue?.email ? rowValue?.email : "",
    gender: rowValue?.gender
      ? genderOptions.find((data) => rowValue?.gender == data.value)
      : "",
    skills: "",
    interviewDate: "",
    phone: rowValue?.phone || "",
    maritalStatus: rowValue?.maritalStatus
      ? maritialStatusOptions.find(
          (data) => rowValue?.maritalStatus == data.value
        )
      : "",
    age: rowValue?.age ? rowValue?.age : "",
    position: jobsDetail.find((data) => data.id == rowValue?.position_id),
    qualification: rowValue?.qualification ? rowValue?.qualification : "",
    currentAddress: "",
    currentArea: "",
    currentCountry: "",
    currentCity: rowValue?.currentCity ? rowValue?.currentCity : "",
    currentState: rowValue?.currentState ? rowValue?.currentState : "",
    currentPincode: "",
    permanentAddress: "",
    permanentArea: "",
    permanentCountry: "",
    permanentCity: rowValue?.permanentCity ? rowValue?.permanentCity : "",
    permanentState: rowValue?.permanentState ? rowValue?.permanentState : "",
    permanentPincode: "",
    department: rowValue?.department ? rowValue?.department : "",
    yearOfPassing: rowValue?.yearOfPassing ? rowValue?.yearOfPassing : "",
    over_all_experience: "",
    projectTitle: "",
    projectPeriod: "",
    projectDescription: "",
    courseName: "",
    coursePeriod: "",
    courseInstitution: "",
    currentSalary: "",
    expected_salary: rowValue?.expectedSalary ? rowValue?.expectedSalary : "",
    message: rowValue?.message ? rowValue?.message : "",
    percentage: "",
    positionType: "",
    dob: "",
    relevantExperience: "",
    expectedCostPerHour: "",
    jobReferenceType: "",
    jobReferenceName: "",
    willingToWorkInShift: "",
    stayingIn: rowValue?.stayingIn ? rowValue?.stayingIn : "",
    tenthPercentage: "",
    tenthYearOfPassing: "",
    twelvethPercentage: "",
    twelvethYearOfPassing: "",
    degreeArrear: "",
    didCourse: "",
    didProject: "",
    linkedinProfileName: "",
    facebookProfileName: "",
    jobTitle: "",
    joinedMonth: "",
    joinedYear: "",
    companyName: "",
    knownLanguages: "",
    employmentEligibility: "",
    didVaccineStatus: "",
  };

  const candidateForm = <CandidateForm />;

  const candidateFooter = (
    <div className="container-fluid">
      <div className="col-sm-12 text-center">
        <Button
          type="submit"
          label={rowValue?.candidateId ? "Save" : "Add"}
          showButtonLabelName
        />
      </div>
    </div>
  );
  const actionsMenuList = [
    {
      value: "Send Message",
      label: "Send Message",
    },
  ];
  const handleBulkSelect = (e) => {
    setBulkSelectIds(e);
  };
  const handleActionChange = (e) => {
    if (e === "Send Message") {
      if (bulkSelectIds && bulkSelectIds.length > 0) {
        setIsBulkModelOpen(true);
      }else{
        toast.error("Select Atleast One Item")
      }
    }
  };
  const sendMessage = async (values) => {
    let body = { candidateId: bulkSelectIds,message:subject};

    await CandidateService.sendMessage(body, (res) => {
      dispatch(
        fetchList(
          "Candidate",
          `${endpoints().candidate}/list`,
          Url.GetParam("page") ? Url.GetParam("page") : 1,
          Url.GetParam("pageSize") ? Url.GetParam("pageSize") : 25,
          {
            ...Url.GetAllParams(),
          }
        )
      );
      bulkCloseToggle(false);
      setSelectedCheckBox(false);
      setSelectedCheckBox(true);
      setBulkSelectIds([])
      setSubject()
    });
  };
  const bulkUpdateForm = (
    <>
     <MessageInput text={subject} setText={setSubject} title="Message"/>
    </>
  );
  const bulkCloseToggle = () => {
    setIsBulkModelOpen(!isBulkModelOpen);
    setSelectedCheckBox(false);
    setSelectedCheckBox(true);
  };
  let bulkUpdateFooter = (
    <div className="container-fluid">
      <div className="col-sm-12 text-center">
        <Button
          type="submit"
          label="Send"
          showButtonLabelName
        />
      </div>
    </div>
  );
  const initialValue = {
    subject:"",
    message:""
  }

  return (
    <>
      <DeleteModal
        isOpen={openDeleteModal}
        toggle={closeDeleteModal}
        title="Delete Candidate"
        deleteFunction={() => {
          handleDelete(rowValue?.candidateId);
        }}
        label={`${rowValue?.firstName} ${rowValue?.lastName}`}
        id={rowValue?.candidateId}
      />
      <Drawer
        handleOpenModal={bulkCloseToggle}
        handleCloseModal={bulkCloseToggle}
        handleDrawerClose={bulkCloseToggle}
        isModalOpen={isBulkModelOpen}
        modelTitle="Send Message"
        DrawerBody={bulkUpdateForm}
        DrawerFooter={bulkUpdateFooter}
        initialValues={initialValue}
        onSubmit={(values) => {
          sendMessage(values);
        }}
        hideDefaultButtons
      />
      <PageTitle
        label="Candidates"
        buttonHandler={() => {
          toggle();
        }}
        buttonLabel="Add New"
        dropdownLinks={actionsMenuList}
        handleChange={handleActionChange}
      />
        
      <Drawer
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggle}
        isModalOpen={isOpen}
        initialValues={initialValues}
        enableReinitialize
        DrawerBody={candidateForm}
        DrawerFooter={candidateFooter}
        modelTitle={rowValue?.candidateId ? "Edit Candidate" : "Add Candidate"}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
      />

      <CandidateFilter
        history={history}
        genderOptions={genderOptions}
        maritialStatusOptions={maritialStatusOptions}
        handleColumnChange={handleColumnChange}
        FieldLabel={FieldLabel}
        arrayList={arrayList}
      />

      <div className="mt-3">
        <ReduxTable
          id="Candidate"
          disableHeader
          apiURL={`${endpoints().candidate}/list`}
          newTableHeading
          history={history}
          paramsToUrl={true}
          sortByOptions={sortByOption}
          bulkSelect
          onBulkSelect={handleBulkSelect}
          selectedCheckBox={selectedCheckBox}
          params={{
            ...Url.GetAllParams(),
          }}
        >
          <ReduxColumn
            field="userName"
            sortBy="first_name"
            width="190px"
            minWidth="190px"
            maxWidth="190px"
            renderField={(row) => (
              <>
                <Link
                  to={`/jobs/candidate/${row.candidateId}`}
                  className="link-opacity-75 text-decoration-none"
                >
                  <div className="d-flex text-break">
                    <AvatarCard
                      id="avatar"
                      firstName={row.firstName}
                      lastName={row.lastName}
                      url={row.candidate_url}
                      mobileNumber={row.phone}
                      email={row.email}
                    />
                  </div>
                </Link>
              </>
            )}
          >
            Name
          </ReduxColumn>
          {email && email == true && (
            <ReduxColumn
              field="email"
              sortBy="email"
              width="190px"
              minWidth="190px"
              maxWidth="190px "
            >
              Email
            </ReduxColumn>
          )}
          {gender && gender == true && (
            <ReduxColumn
              field="gender"
              sortBy="gender"
              width="110px"
              minWidth="110px"
              maxWidth="110px"
              className="text-center"
            >
              Gender
            </ReduxColumn>
          )}
          {age && age == true && (
            <ReduxColumn
              field="age"
              sortBy="age"
              width="150px"
              minWidth="150px"
              maxWidth="150px"
              className="text-center"
            >
              Age
            </ReduxColumn>
          )}
          {marital_status && marital_status == true && (
            <ReduxColumn
              field="maritalStatus"
              sortBy="marital_status"
              width="140px"
              minWidth="140px"
              maxWidth="140px"
              className="text-center"
            >
              Marital Status
            </ReduxColumn>
          )}
          {position && position == true && (
            <ReduxColumn
              field="position"
              sortBy="position"
              width="170px"
              minWidth="170px"
              maxWidth="170px"
              className="text-center"
            >
              Position
            </ReduxColumn>
          )}
          {Phone && Phone == true && (
            <ReduxColumn
              field="phone"
              sortBy="phone"
              width="130px"
              minWidth="130px"
              maxWidth="130px"
              className="text-center"
            >
              Phone
            </ReduxColumn>
          )}
          {department && department == true && (
            <ReduxColumn
              field="department"
              sortBy="department"
              width="150px"
              minWidth="150px"
              maxWidth="150px"
            >
              Department
            </ReduxColumn>
          )}
          {year_of_passing && year_of_passing == true && (
            <ReduxColumn
              field="yearOfPassing"
              sortBy="year_of_passing"
              width="150px"
              minWidth="150px"
              maxWidth="150px"
              className="text-center"
            >
              Year Of Passing
            </ReduxColumn>
          )}
          {qualification && qualification == true && (
            <ReduxColumn
              field="qualification"
              sortBy="qualification"
              width="150px"
              minWidth="150px"
              maxWidth="150px"
            >
              Qualification
            </ReduxColumn>
          )}
          {expected_salary && expected_salary == true && (
            <ReduxColumn
              field="expectedSalary"
              sortBy="expected_salary"
              width="150px"
              minWidth="150px"
              maxWidth="150px"
              renderField={(row) => (
                <span>{Currency.Format(row?.expectedSalary)}</span>
              )}
              className="text-end"
            >
              Expected Salary
            </ReduxColumn>
          )}
          {current_city && current_city == true && (
            <ReduxColumn field="currentCity" sortBy="current_city">
              Current City
            </ReduxColumn>
          )}
          {current_state && current_state == true && (
            <ReduxColumn field="currentState" sortBy="current_state">
              Current State
            </ReduxColumn>
          )}
          {permanent_city && permanent_city == true && (
            <ReduxColumn field="permanentCity" sortBy="permanent_city">
              Permanent City
            </ReduxColumn>
          )}
          {permanent_state && permanent_state == true && (
            <ReduxColumn field="permanentState" sortBy="permanent_state">
              Permanent State
            </ReduxColumn>
          )}
          {staying_with && staying_with == true && (
            <ReduxColumn field="stayingIn" sortBy="staying_in">
              Staying In
            </ReduxColumn>
          )}
          {message && message == true && (
            <ReduxColumn field="message" sortBy="message">
              Message
            </ReduxColumn>
          )}
          <ReduxColumn
            field="createdAt"
            sortBy="created_at"
            className="text-center"
            width="150px"
            minWidth="110px"
            maxWidth="150px"
            renderField={(row) => <span>{row.createdAt}</span>}
          >
            Submitted At
          </ReduxColumn>
          <ReduxColumn
            field="statusText"
            sortBy="statusText"
            width="130px"
            minWidth="130px"
            maxWidth="130px"
            renderField={(row) => (
              <StatusText
                backgroundColor={row.statusColor}
                status={row.statusText}
              />
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn
            field="Action"
            disableOnClick
            width="120px"
            minWidth="80px"
            maxWidth="80px"
            renderField={(row) => (
              <div className=" text-center action-group-dropdown">
                <MoreDropdown
                  onClick={() => {
                    setStatusList([]);
                    getStatusList(row.status, row.allowed_statuses);
                  }}
                >
                  <DropdownItem
                    onClick={() => {
                      toggle();
                      setRowValue(row);
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  {statusList &&
                    statusList.length > 0 &&
                    statusList.map((data) => {
                      return (
                        <DropdownItem
                          onClick={() => {
                            onStatusChange(data?.value, row?.candidateId);
                          }}
                        >
                          {data?.label}
                        </DropdownItem>
                      );
                    })}
                  <DropdownItem
                    className={"text-danger"}
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setRowValue(row);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    </>
  );
};

export default Candidate;
