import React, { useEffect, useState } from "react";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import { useDispatch } from "react-redux";

// Components
import BreadCrumb from "../../components/Breadcrumb";
import Form from "../../components/Form";
import DraftEditor from "../../components/Draft";
import SaveButton from "../../components/SaveButton";
import CancelButton from "../../components/CancelButton";
import Toast from "../../components/Toast";
import DeleteModal from "../../components/DeleteModal";
import Action from "../../components/Action";
import PageTitle from "../../components/PageTitle";
import StatusComponent from "../../components/Status";
import Select from "../../components/Select";
import Spinner from "../../components/Spinner";

// API
import { apiClient } from "../../apiClient";
import { endpoints } from "../../api/endPoints";

// Lib
import { isBadRequest } from "../../lib/Http";

// Services
import PageService from "../../services/PageService";
import ObjectName from "../../helpers/ObjectName";

const PageDetail = (props) => {
  // Use States
  const [pageDetails, setPageDetails] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editorState, setEditorState] = useState(() => EditorState.createEmpty());
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");

  const dispatch = useDispatch();

  // Use Effect
  useEffect(() => {
    getPageDetails();
  }, []);

  // Bread Crumb list
  const breadcrumbList = [
    { label: "Page", link: "/pages/pagelist" },
    { label: pageDetails.name, link: "" },
  ];

  const actionsMenuList = [
    {
      value: "Delete",
      label: "Delete",
    },
  ];

  const handleActionChange = (e) => {
    if (e === "Delete") {
      setDeleteModal(true);
    }
  };

  // Getting the page id
  const id = window.location.pathname.split("/")[4];

  // Getting the Page Details
  const getPageDetails = async () => {
    try {
      const response = await apiClient.get(`${endpoints().pageAPI}/${id}`);
      let data = response.data.data;
      setPageDetails(data);
      setEditorState(
        EditorState.createWithContent(convertFromRaw(JSON.parse(data.content)))
      );
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  const getTemplates = async () => {
    try {
      let response = await PageService.getTemplateList();

      const templatesData = response.templates;

      setTemplates(templatesData);
    } catch (error) {
      console.error(error);
    }
  };

  // Handle change Editor state
  const handleTemplateChange = (selectedOption) => {
    setSelectedTemplate(selectedOption);
  };

  useEffect(() => {
    getPageDetails();
    getTemplates();
  }, []);

  const handleEditorChange = (editorState) => {
    setEditorState(editorState);
  };

  //   Page delete
  const pageDelete = async () => {
    dispatch(
      await PageService.delete(id, (res) => {
        setDeleteModal(false);
        props.history.push("/pages/pagelist");
      })
    );
  };

  // Updating the Page Details
  const updatePageDetails = async (values) => {
    let rawComment;
    if (editorState) {
      rawComment = convertToRaw(editorState.getCurrentContent());
    }
    values.content = JSON.stringify(rawComment);

    if (values?.template_id?.id) {
      values.template_id = values?.template_id?.id;
    }

    try {
      const response = await apiClient.put(
        `${endpoints().pageAPI}/${id}`,
        values
      );
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        Toast.success(successMessage);
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        Toast.error(errorMessage);
        console.error(errorMessage);
      }
    }
  };

  const onStatusChange = async (status) => {
    const data = new FormData();
    data.append("status", status ? status : "");
    if (status) {
      dispatch(
        await PageService.statusUpdate(id, data, (res) => {
          if (res) {
            setIsLoading(true);
            getPageDetails();
            setIsLoading(false);
          }
        })
      );
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      {/* Breadcrumb section */}
      <div className="row">
        <BreadCrumb list={breadcrumbList} />
      </div>

      {/* PageTitle Section */}
      <div className="d-flex justify-content-between mb-3">
        <PageTitle label={pageDetails.name} />
        <div className="d-flex justify-content-end">
          <div className="me-3">
            <Action
              dropdownLinks={actionsMenuList}
              handleChange={handleActionChange}
            />
          </div>
          <StatusComponent
            objectName={ObjectName.PAGE}
            handleChange={onStatusChange}
            buttonLabel={pageDetails?.status_name}
            currentStatusId={pageDetails?.status}
          />
        </div>
      </div>
      <DeleteModal
        isOpen={deleteModal}
        toggle={() => {
          setDeleteModal(false);
        }}
        title="Delete Page"
        label={pageDetails.name}
        deleteFunction={() => {
          pageDelete();
        }}
      />

      {/* Form section */}
      <Form
        enableReinitialize={true}
        initialValues={{ content: "", template_id: pageDetails && pageDetails?.template_id ? templates.find((template) => template.id === pageDetails?.template_id) : "" }}
        onSubmit={(values) => {
          updatePageDetails(values);
        }}
      >
        <div className="mt-3">
          <div className="mt-1 mb-3 pb">
            <Select
              name="template_id"
              label="Select Template"
              placeholder="Select Template..."
              options={templates}
              onChange={handleTemplateChange}
              value={selectedTemplate}
            />
          </div>

          {/* Draft JS - Rich Text Editor */}
          <DraftEditor
            name="content"
            editorState={editorState}
            onChange={handleEditorChange}
          />

          {/* Save Button */}
          <div className="mt-2">
            <SaveButton />
            <CancelButton
              onClick={() => props.history.push("/pages/pagelist")}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default PageDetail;