import React, { useEffect, useState } from "react";
import Filter from "../../../../components/Filter";
import { DateOptions } from "../../../../helpers/Date";
import ArrayList from "../../../../lib/ArrayList";
import DateTime from "../../../../lib/DateTime";
import NumberHelper from "../../../../lib/Number";
import Url from "../../../../lib/Url";
import LateCheckInAnalysisReportService from "../../../../services/LateCheckInAnalysisReportService";

const LateCheckInAnalysisReportFilter = (props) => {
    let { history, setGraphData } = props;

    const [searchItem, setSearch] = useState(Url.GetParam("search"));
    const [spinValue, setSpin] = useState(false);
    const [allParams, setAllParams] = useState({ ...Url.GetAllParams() });
    const [userList, setuserList] = useState([]);
    const [roleList, setRoleList] = useState([]);

    useEffect(() => {
        getGraphData(allParams);
    }, [])



    let getGraphData = async (params) => {
        try {
            props.setIsLoading && props.setIsLoading(true)
            let data  = await LateCheckInAnalysisReportService.getReport(params);
            setGraphData && setGraphData(data);
            props.setIsLoading && props.setIsLoading(false)
        } catch (err) {
            props.setIsLoading && props.setIsLoading(false)
        }
    }



    const UpdateUrl = (params) => {
        const currentPage = window.location.pathname;
        let queryString = "";
        const queryStringArray = Object.entries(params);

        if (queryStringArray.length > 0) {
            queryString = "?";
            queryStringArray.forEach(async (queryParam) => {
                if (queryParam[1] !== "")
                    queryString = `${queryString}&${queryParam[0]}=${queryParam[1]}`;
            });
        }
        history.push(`${currentPage}${queryString}`);
    };


    let getInitialValue = (key) => {
        if (NumberHelper.isNotNull(allParams[key])) {
            return allParams[key];
        } else {
            return "";
        }
    };

    let filterInitialValues = {
        startDate: Url.GetParam("startDate"),
        endDate: Url.GetParam("endDate"),
        date:
            DateOptions &&
            DateOptions.find((fine) => fine.value == getInitialValue("date")),
        role: ArrayList.isArray(roleList) ? roleList.find((data) => data?.value == Url.GetParam("role")) : "",
        user: ArrayList.isArray(userList) ? userList.find((data) => data?.id == Url.GetParam("user")) : ""
    };

    const handleSearchTermChange = (e) => {
        const searchValue = e.target.value;
        setSearch(searchValue);
        setAllParams((preValue) => ({
            ...preValue,
            search: searchValue,
        }));
    };

    const onSearchKeyUp = async (event) => {
        if (event.charCode === 13) {
            let params = {
                ...allParams,
                search: searchItem,
            };
            getGraphData(params)
            UpdateUrl(params);
        }
    };

    const refreshButtonOnClick = async () => {
        setSpin(true);
        getGraphData(allParams)
        setSpin(false);
    };

    const handleFilter = (values) => {
        values.date = values?.date ? values?.date?.value : "";
        values.user = values?.user ? values?.user?.id : "";
        values.role = values?.role ? values?.role?.value : "";
        values.startDate = values?.startDate ? DateTime.getDate(values?.startDate) : "";
        values.endDate = values?.endDate ? DateTime.getDate(values?.endDate) : "";
        let filterParams = ArrayList.toQueryString(values);
        const filteredValues = Object.fromEntries(
            Object.entries(filterParams)
                .filter(([key, _]) => !isNaN(key))
                .map(([_, value]) => {
                    const [prop, val] = value?.split("=");
                    return [prop, val];
                })
        );
        UpdateUrl(filteredValues);
        setAllParams(filteredValues);
        getGraphData(filteredValues)
    };

    const handleDeleteFilter = async (removedFilter) => {
        let params = {
            ...allParams,
            ...removedFilter,
        };
        getGraphData(params)
        UpdateUrl(params);
        setAllParams(params);
    };

    return (
        <div>
            <Filter
                showHeader
                newTableHeading
                pageSearchOnChange={(e) => {
                    handleSearchTermChange(e);
                }}
                sortByDropdown
                pageSearchValue={searchItem}
                searchPlaceholder="Search"
                refreshButtonOnClick={refreshButtonOnClick}
                refreshValue={spinValue}
                handleDeleteFilter={handleDeleteFilter}
                initialValues={filterInitialValues}
                handleFilter={handleFilter}
                onKeyPress={onSearchKeyUp}
                showCustomDateFilter
                showRoleFilter
                showUserFilter
                setRoleList={setRoleList}
                userList={setuserList}
            />
        </div>
    );
};

export default LateCheckInAnalysisReportFilter;
