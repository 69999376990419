import React from 'react'
import ArrayList from '../lib/ArrayList';
import Url from '../lib/Url';
import { endpoints } from '../api/endPoints';
import { isBadRequest } from '../lib/Http';
import Toast from '../components/Toast';

class LateCheckInAnalysisReportService {
    static async getReport(params) {
        try{
            let queryString = await ArrayList.toQueryString(params);
            let response = await Url.get(`${endpoints().attendanceAPI}/getAnalysisReport`, queryString);
            if (response && response.data) {
                return response.data;
              }
            }catch(error){
                if (isBadRequest(error)) {
                    let errorMessage;
                    const errorRequest = error.response.request;
                    if (errorRequest && errorRequest.response) {
                      errorMessage = JSON.parse(errorRequest.response).message;
                    }
                    Toast.error(errorMessage);
                  }
            }
    }
}

export default LateCheckInAnalysisReportService
