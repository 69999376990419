import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";

// Actions
import { fetchList } from "../../../actions/table";

// API
import { endpoints } from "../../../api/endPoints";

// Components
import MoreDropdown from "../../../components/authentication/moreDropdown";
import Drawer from "../../../components/Drawer";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import SaveButton from "../../../components/SaveButton";
import Select from "../../../components/Select";
import Spinner from "../../../components/Spinner";

// Lib
import Url from "../../../lib/Url";

// Service
import Number from "../../../components/Number";
import Text from "../../../components/Text";
import PaymentPreferenceService from "../../../services/PaymentPreferenceService";
import PaymentPreference from "../../../helpers/AccountPaymentPreference";

const buttonLabel = true;

const PaymentPreferenceList = (props) => {
  const {
    history,
    paymentTab,
    isOpen,
    toggles,
    handleOpenModal,
    handleCloseModal,
    setRowValue,
    rowValue = null,
    setDetail,
    setIsSubmitting,
    isSubmitting,
    setOpenDeleteModal,
    apiUrl,
    vendorDetails,
  } = props;

  const paymentAccounts = [
    {
      value: "Saving",
      label: "Saving",
    },
    {
      value: "Current",
      label: "Current",
    },
    {
      value: "Overdraft",
      label: "Overdraft",
    },
    {
      value: "Cash Credit",
      label: "Cash Credit",
    },
    {
      value: "Loan Account",
      label: "Loan Account",
    },
    {
      value: "NRE",
      label: "NRE",
    },
  ];

  const statusOptions = [
    {
      label: PaymentPreference.STATUS_ACTIVE,
      value: PaymentPreference.ACTIVE,
    },
    {
      label: PaymentPreference.STATUS_INACTIVE,
      value: PaymentPreference.INACTIVE,
    },
  ];

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const sortByOption = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  useEffect(() => {
    getDetails();
  }, [rowValue]);

  const getDetails = async () => {
    const data = await PaymentPreferenceService.get(rowValue?.id);
    if (data) {
      setDetail(data);
    }
  };

  let vendorId = vendorDetails?.data?.vendorId;

  const _toggle = () => {
    setRowValue("");
    handleCloseModal();
    setDetail("");
  };

  const initialValues = {
    bank_name: rowValue?.bank_name ? rowValue?.bank_name : "",
    bank_account_number: rowValue?.bank_account_number
      ? rowValue?.bank_account_number
      : "",
    bank_account_name: rowValue?.bank_account_name
      ? rowValue?.bank_account_name
      : "",
    type: rowValue?.type
      ? paymentAccounts.find((value) => value.label == rowValue?.type)
      : "",
    ifsc_code: rowValue?.ifsc_code ? rowValue?.ifsc_code : "",
    status: rowValue?.id
      ? statusOptions.find((data) => rowValue?.status == data.value)
      : "",
  };
  const handleSubmit = async (values) => {
    try {
      setIsSubmitting(false);
      const data = new FormData();
      let param = {
        ...Url.GetAllParams(),
      };
      if (rowValue && rowValue?.id) {
        data.append("bank_name", values && values?.bank_name);
        data.append(
          "bank_account_number",
          values && values?.bank_account_number
        );
        data.append("bank_account_name", values && values?.bank_account_name);
        data.append("type", values && values?.type?.value);
        data.append("ifsc_code", values && values?.ifsc_code);
        data.append("status", values && values?.status?.value);
        data.append("vendorId", vendorId);

        setIsLoading(true);
        await PaymentPreferenceService.update(
          rowValue?.id,
          data,
          setIsSubmitting,
          (res) => {
            if (res) {
              handleCloseModal();
              dispatch(
                fetchList(
                  "accountPaymentPreference",
                  `${endpoints().accountPaymentPreferenceAPI}/search`,
                  1,
                  25,
                  param
                )
              );
            }
          }
        );
        setIsLoading(false);
        setDetail("");
      } else {
        data.append("bank_name", values && values?.bank_name);
        data.append(
          "bank_account_number",
          values && values?.bank_account_number
        );
        data.append("bank_account_name", values && values?.bank_account_name);
        data.append("type", values && values?.type?.value);
        data.append("ifsc_code", values && values?.ifsc_code);
        data.append("status", values && values?.status?.value);
        data.append("vendorId", vendorId);

        dispatch(
          await PaymentPreferenceService.create(
            data,
            param,
            toggles,
            (response) => {
              setIsLoading(true);
              if (response) {
                setIsLoading(false);
                setIsSubmitting(true);
                setDetail("");
                handleCloseModal();
                _toggle();
                // Update the list after successful creation
                dispatch(
                  fetchList(
                    "accountPaymentPreference",
                    `${endpoints().accountPaymentPreferenceAPI}/search`,
                    1,
                    25,
                    param
                  )
                );
              }
            }
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const paymentPreferenceForm = (
    <>
      <Text label="Bank Name" name="bank_name" required />
      <Number label="Account Number" name="bank_account_number" required />
      <Text label="Account Name" name="bank_account_name" required />
      <Select label=" Type" name="type" required options={paymentAccounts} />
      <Text label="IFSC" name="ifsc_code" required />
      <Select
        fullWidth={true}
        label="Status"
        name="status"
        isClearable
        options={statusOptions}
        required
      />
    </>
  );

  const paymentPreferenceFooter = (
    <SaveButton type="submit" loading={isSubmitting == false} />
  );

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Drawer
        modelTitle={
          rowValue?.id
            ? "Edit Account Payment Preference "
            : "Add Account Payment Preference"
        }
        DrawerBody={paymentPreferenceForm}
        DrawerFooter={paymentPreferenceFooter}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        initialValues={initialValues}
        handleOpenModal={handleOpenModal}
        handleCloseModal={_toggle}
        handleDrawerClose={_toggle}
        isModalOpen={isOpen}
        buttonLabel={buttonLabel}
        enableReinitialize
      />

      <ReduxTable
        id="accountPaymentPreference"
        showHeader
        newTableHeading
        searchPlaceholder="Search"
        apiURL={`${endpoints().accountPaymentPreferenceAPI}/search`}
        history={history}
        params={{ account_id: vendorId }}
        sortByOptions={sortByOption}
        disableHeader={paymentTab ? true : false}
        paramsToUrl={true}
      >
        <ReduxColumn
          field="bank_name"
          sortBy="id"
          className="text-center"
          width="300px"
          minWidth="300px"
          maxWidth="300px"
          renderField={(row) => (
            <Link
              to={`/paymentPreferenceDetail/detail/${row.id}?tab=${Url.GetParam(
                "tab"
              )}`}
              className="link-opacity-75 text-decoration-none"
            >
              {row.bank_name}
            </Link>
          )}
        >
          Bank Name
        </ReduxColumn>

        <ReduxColumn
          field="bank_account_number"
          sortBy="bank_account_number"
          className="text-center"
          width="300px"
          minWidth="300px"
          maxWidth="300px"
        >
          Account Number
        </ReduxColumn>
        <ReduxColumn
          field="bank_account_name"
          sortBy="bank_account_name"
          className="text-center"
          width="300px"
          minWidth="300px"
          maxWidth="300px"
        >
          Account Name
        </ReduxColumn>
        <ReduxColumn
          field="type"
          sortBy="type"
          className="text-center"
          width="300px"
          minWidth="300px"
          maxWidth="300px"
        >
          Account Type
        </ReduxColumn>
        <ReduxColumn
          field="ifsc_code"
          sortBy="ifsc_code"
          className="text-center"
          width="300px"
          minWidth="300px"
          maxWidth="300px"
        >
          IFSC
        </ReduxColumn>

        <ReduxColumn
          field="status"
          sortBy="status"
          renderField={(row) => (
            <div
              className={`status-input text-center rounded text-white text-uppercase my-3 mx-auto ${
                row.status == 0
                  ? "bg-success"
                  : row.status === 1
                  ? "bg-dark bg-opacity-50"
                  : "bg-dark bg-opacity-50"
              }`}
            >
              <p>{row.status == 0 ? "Active" : "InActive"}</p>
            </div>
          )}
        >
          Status
        </ReduxColumn>

        <ReduxColumn
          field="Action"
          disableOnClick
          renderField={(row) => (
            <>
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      setRowValue && setRowValue(row);
                      toggles();
                      setIsLoading(true);
                      getDetails();
                      setIsLoading(false);
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  <DropdownItem
                    className=" text-danger cursor-pointer"
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setRowValue(row);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            </>
          )}
        >
          Actions
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default PaymentPreferenceList;
