import React from "react";
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable";
import { endpoints } from "../../../api/endPoints";
import UserCard from "../../../components/UserCard";
import DateTime from "../../../lib/DateTime";
import PageTitle from "../../../components/PageTitle";
import DeviceUsageActivity from "../../../helpers/DeviceUsage";

const DeviceUsage = (props) => {

    const sortByOption = [
        {
            value: "id:DESC",
            label: "Most Recent",
        },
    ];

    let customTypeOption = [
        {
            label: DeviceUsageActivity.CHECKIN_LABEL,
            value: DeviceUsageActivity.CHECKIN_VALUE,
        },
        {
            label: DeviceUsageActivity.CHECKOUT_LABEL,
            value: DeviceUsageActivity.CHECKOUT_VALUE,
        },
    ]

    return (
        <>
            <PageTitle label="Device Usage" />
            <ReduxTable
                id="deviceUsage"
                showHeader
                newTableHeading
                params={{}}
                searchPlaceholder="Search"
                apiURL={`${endpoints().deviceUsageAPI}/search`}
                history={props.history}
                paramsToUrl={true}
                showCustomDateFilter
                sortByOptions={sortByOption}
                showUserFilter
                showTypeFilter
                customTypeOption={customTypeOption}
            >
                <ReduxColumn
                    field="owner"
                    sortBy="owner"
                    renderField={(row) => (
                        <>
                            <UserCard
                                customSize={parseInt(50, 10)}
                                firstName={row.first_name}
                                lastName={row.last_name}
                                url={row.media_url}
                            />
                        </>
                    )}
                >
                    User
                </ReduxColumn>
                <ReduxColumn field="app_name" sortBy="app_name" className="text-center">
                    App Name
                </ReduxColumn>
                <ReduxColumn field="app_usage" sortBy="app_usage" className="text-center"
                    renderField={(row) => {
                        return <>{DateTime.formatMilliseconds(row?.app_usage)}</>
                    }}
                >
                    App Usage
                </ReduxColumn>
                <ReduxColumn field="network_usage" sortBy="network_usage" className="text-center"
                    renderField={(row) => {
                        return <>{DateTime.formatNetworkUsage(row?.network_usage)}</>
                    }}
                >
                    Network Usage
                </ReduxColumn>
                <ReduxColumn field="activity" sortBy="activity" className="text-center"
                >
                    Activity
                </ReduxColumn>
            </ReduxTable>
        </>

    );
};
export default DeviceUsage;
