import React, { useState } from 'react'
import { endpoints } from "../../../api/endPoints"
import Drawer from "../../../components/Drawer"
import Quantity from "../../../components/Quantity"
import SaveButton from "../../../components/SaveButton"
import TagSelect from "../../../components/TagSelect"
import ReduxTable, { ReduxColumn } from "../../../components/reduxTable"
import ObjectName from "../../../helpers/ObjectName"
import { useDispatch } from "react-redux"
import CurrencyDenominationService from "../../../services/CurrencyDenominationService"
import Currency from "../../../lib/Currency"
import { fetchList } from "../../../actions/table"
import MoreDropdown from "../../../components/authentication/moreDropdown"
import { DropdownItem } from "reactstrap"
import DeleteModal from "../../../components/DeleteModal"

const CurrencyDenominationTab = (props) => {
  let { isModelOpen, setIsModelOpen, id, history } = props;
  const [tagList, setTagList] = useState([]);
  const [rowValue, setRowValue] = useState(null);
  const [isOpen, setIsOpen] = useState(false)

  let dispatch = useDispatch();


  const openToggle = () => {
    setIsModelOpen(!isModelOpen)
  }

  const closeToggle = () => {
    setIsModelOpen(!isModelOpen)
  }
  let modelBody = (
    <>
      <TagSelect
        name="denomination"
        label="Denomination"
        placeholder="Denomination"
        params={{ type: "Currency Denomination" }}
        TagList={setTagList}
        required
        isDisabled
      />
      <Quantity
        name="count"
        label="Count"
        placeholder="Count"
        required
      />
    </>
  );

  let modelFooter = (
    <>
      <SaveButton
        type="submit"
        label={"Edit"}
      />
    </>
  );

  let denominationInitialValues = {
    count: rowValue ? {
      label: rowValue?.count,
      value: rowValue?.count
    } : "",
    denomination: rowValue ? {
      label: rowValue?.denomination,
      value: rowValue?.denomination
    } : ""
  };


  const handleSubmit = async (values) => {
    let data = new FormData();
    data.append("denomination", values?.denomination ? values?.denomination?.label : "")
    data.append("count", values?.count ? values?.count?.value : "")

    dispatch(await CurrencyDenominationService.update(rowValue?.id, data, (res) => {
      dispatch(
        fetchList("CurrencyDenomination", `${endpoints().CurrencyDenominationAPI}/search`, 1, 25, { object_id: id, object_name: ObjectName.SALE_SETTLEMENT })
      );
      closeToggle()
      setRowValue(null)
    }))
  }

  const handleDeleteFunction = async () => {

    dispatch(await CurrencyDenominationService.delete(rowValue?.id, (res) => {
      dispatch(
        fetchList("CurrencyDenomination", `${endpoints().CurrencyDenominationAPI}/search`, 1, 25, { object_id: id, object_name: ObjectName.SALE_SETTLEMENT })
      );
      setIsOpen(false)
      setRowValue(null)
    }))
  }


  return (
    <div>
      <DeleteModal
        id={rowValue?.id}
        label={rowValue?.id}
        isOpen={isOpen}
        toggle={() => {
          setIsOpen(false);
        }}
        title="Delete Denomination"
        deleteFunction={handleDeleteFunction}
      />

      <Drawer
        isModalOpen={isModelOpen}
        handleOpenModal={openToggle}
        handleDrawerClose={closeToggle}
        handleCloseModal={closeToggle}
        modelTitle={"Edit"}
        DrawerBody={modelBody}
        DrawerFooter={modelFooter}
        initialValues={denominationInitialValues}
        enableReinitialize={true}
        onSubmit={(values) => {
          handleSubmit(values);
        }}
        hideDefaultButtons
      />
      <ReduxTable
        id="CurrencyDenomination"
        showHeader
        newTableHeading
        searchPlaceholder="Search"
        apiURL={`${endpoints().CurrencyDenominationAPI}/search`}
        history={history}
        paramsToUrl={true}
        sortByDropdown
        params={{ object_id: id, object_name: ObjectName.SALE_SETTLEMENT }}
        showPageSize
        totalAmount
      >
        <ReduxColumn
          field="denomination"
          sortBy="denomination"
          className="text-center"
        >
          Denomination
        </ReduxColumn>
        <ReduxColumn
          field="count"
          sortBy="count"
          className="text-center"
        >
          Count
        </ReduxColumn>
        <ReduxColumn
          field="amount"
          sortBy="amount"
          className="text-end"
          renderField={(row) => <span>{Currency.Format(row.amount)}</span>}
        >
          Amount
        </ReduxColumn>
        <ReduxColumn
          field="Action"
          disableOnClick
          width="70px"
          renderField={(row) => (
            <>
              <div className="text-center action-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      setRowValue(row);
                      openToggle();
                    }}
                  >
                    Quick View
                  </DropdownItem>
                  <DropdownItem
                    className="text-danger"
                    onClick={() => {
                      setRowValue(row);
                      setIsOpen(true);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            </>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </div>
  )
}

export default CurrencyDenominationTab;
