import {
    BarElement,
    CategoryScale,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from "chart.js";
import Chart from "chart.js/auto";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from "react";
import Spinner from "../../../../components/Spinner";
import DateTime from "../../../../lib/DateTime";
import { getRandomColor } from "../../../../lib/Helper";
import Number from "../../../../lib/Number";
import String from "../../../../lib/String";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const Graph = (props) => {
    let { graphArrayData = [], key1, key2, totalAmount = 0, title, isLoading, isQuantityWise = false } = props;
    const [colorList, setColorList] = useState([]);


    useEffect(() => {
        getRandomColors();
    }, [isLoading]);



    const getRandomColors = () => {
        let color = getRandomColor(graphArrayData.length);
        setColorList(color);
    };

    const graphBarData = graphArrayData && graphArrayData.length > 0 && graphArrayData.map((value, index) => [String.concatName(value["first_name"], value["last_name"]), value[key2] !== "" ? Number.Get(value["total_late_hours"]) : 0]) || [];

    if (isLoading) {
        return <Spinner />;
    }

    const isMobileView = window.innerWidth <= 768;

    return (
        <>
            <div className="App">
                <h3 className="text-center">
                    {`${title}`}
                </h3>
            </div>

            <div className="overflow-x-auto" style={{ width: '100%' }} id="scroll-view-v1">
                <HighchartsReact
                    highcharts={Highcharts}
                    options={{
                        chart: {
                            type: 'column',
                            scrollablePlotArea: {
                                minWidth: 700,
                            },
                            ...(graphBarData && graphBarData.length > 30 ? { width: 10000 } : {}),
                            marginBottom: 50,
                            marginLeft: isMobileView ? 60 : 80,
                        },
                        credits: {
                            enabled: false,
                        },
                        title: {
                            text: " ",
                        },
                        xAxis: {
                            type: 'category',
                            labels: {
                                autoRotation: [-45, -90],
                                style: {
                                    fontSize: '13px',
                                    fontFamily: 'Verdana, sans-serif',
                                },
                            },
                        },
                        yAxis: {
                            min: 0,
                            title: {
                                text: ' ',
                            },
                            labels: {
                                formatter: function () {
                                    return DateTime.HoursAndMinutes(this.value)
                                },
                                style: {
                                    fontSize: '13px',
                                    fontFamily: 'Verdana, sans-serif',
                                    zIndex: 2
                                },
                                enabled: !isMobileView,
                                x: isMobileView ? 0 : 10,
                                y: 0,
                            },
                        },
                        legend: {
                            enabled: false,
                        },
                        tooltip: {
                            formatter: function () {
                                return `<b>${DateTime.HoursAndMinutes(this.y)}</b>`;
                            },
                            pointFormat: function () {
                                return `<b>${DateTime.HoursAndMinutes(this.y)}</b>`;
                            },
                        },
                        series: [
                            {
                                name: 'Population',
                                colors: colorList,
                                colorByPoint: true,
                                groupPadding: 0,
                                data: graphBarData,
                                dataLabels: {
                                    enabled: true,
                                    rotation: -40,
                                    color: 'black',
                                    align: 'center',
                                    formatter: function () {
                                        return `<b>${DateTime.HoursAndMinutes(this.y)}</b>`;
                                    }, style: {
                                        fontSize: '13px',
                                        fontFamily: 'Verdana, sans-serif',
                                    },
                                },
                            },
                        ],
                    }}
                />
            </div>
        </>
    );
};
export default Graph;
