import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { DropdownItem } from "reactstrap";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

// API
import { endpoints } from "../api/endPoints";

// Components
import MoreDropdown from "./authentication/moreDropdown";
import ReduxTable, { ReduxColumn } from "./reduxTable";
import DocumentForm from "../views/media/components/documentForm";
import Button from "./Button";
import DeleteModal from "./DeleteModal";
import ImageCarousel from "./static/imageCarousel";
import DragAndDropField from "./FileUpload";
import Drawer from "./Drawer";
import NoRecordsFound from "./NoRecordsFound";

// Actions
import * as API from "../actions/media";
import { fetchList } from "../actions/table";

// Helpers
import Media from "../helpers/Media";
import MediaUpload from "../helpers/MediaUpload";
import ObjectName from "../helpers/ObjectName";
import { Feature } from "../helpers/Product";

// Services
import MediaService from "../services/MediaService";

// Lib
import Url from "../lib/Url";

const MediaCarousel = (props) => {
  const {
    objectName,
    objectId,
    attachmentsList,
    billView,
    Attachments,
    handleImageUpload,
    selectedFileValue,
    imageUrl,
    editable,
    hideAttachments,
    width,
    height,
    showNoRecordFound,
    setMediaList,
    dragDropFieldHeight,
    className,
  } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [currentData, setCurrentData] = useState([]);
  const [status, setImageStatus] = useState();
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteRow, setDeleteRow] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [file, setFile] = useState();
  const [selectedImages, setSelectedImages] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [imageCount, setImageCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const sortOptions = [
    {
      value: "id:DESC",
      label: "Most Recent",
    },
  ];

  useEffect(() => {
    if (props?.objectId && props?.objectId !== undefined) {
      getMediaDetails();
    }
  }, [props?.objectId]);

  const getMediaDetails = async () => {
    const response = await MediaService.search(
      props?.objectName,
      props?.objectId
    );
    setSelectedImages(response);
    setImageCount(response && response?.length ? response?.length : "");
    if (response && setMediaList) {
      setMediaList(response);
    }
  };

  const params = {
    object_id: objectId,
    objectName: objectName,
    status: status,
    pagination: true,
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    page: Url.GetParam("page"),
    pageSize: Url.GetParam("pageSize"),
    search: Url.GetParam("search"),
  };

  const handleDeleteMedia = async (id) => {
    if (id) {
      setIsLoading(true);
      await MediaService.delete(id);
      await getMediaDetails();
      dispatch(
        fetchList(
          objectName ? objectName : "image",
          `${endpoints().mediaAPI}/search`,
          1,
          25,
          {
            object_id: objectId,
            objectName: objectName,
          }
        )
      );
      setIsLoading(false);
    }
  };

  const ProductMediaStatus = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE",
  };

  const statusOptions = [
    {
      value: ProductMediaStatus.ACTIVE,
      label: ProductMediaStatus.ACTIVE,
    },
    {
      value: ProductMediaStatus.INACTIVE,
      label: ProductMediaStatus.INACTIVE,
    },
  ];

  const openLightbox = () => {
    setIsOpen(true);
  };

  const onDrop = (acceptedFiles) => {
    setSelectedFile(acceptedFiles && acceptedFiles[0]);
    selectedFileValue(acceptedFiles && acceptedFiles[0]);
    setErrorMessage("");
  };

  const onDropImage = async (acceptedFiles) => {
    if (!props.onDropImage) {
      setSelectedFile(acceptedFiles && acceptedFiles[0]);

      let mediaParams={
        selectedFile: acceptedFiles && acceptedFiles[0],
        objectId: objectId,
        ObjectName: objectName,
        
      }

      await MediaUpload.uploadFile(mediaParams,()=>{
        getMediaDetails();
        dispatch(
          fetchList(
            objectName ? objectName : "image",
            `${endpoints().mediaAPI}/search`,
            1,
            25,
            {
              object_id: objectId,
              objectName: objectName,
            }
          )
        );
      });
    }
    props.onDropImage(acceptedFiles);
  };

  const handleDelete = (event) => {
    event.stopPropagation();
    setSelectedFile("");
  };

  const documentForm = (
    <DocumentForm
      onDrop={onDrop}
      selectedFile={selectedFile}
      handleDelete={handleDelete}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      file={props?.file}
      billView={billView}
      currentData={props?.currentData}
    />
  );

  const documentFooter = (
    <div className="overflow-hidden">
      <div className="col-sm-10 text-center">
        <Button type="submit" label="Add" />
      </div>
    </div>
  );

  const handleSubmit = async (values) => {
    if (!selectedFile) {
      setErrorMessage("Please select an image to upload");
      return;
    }

    let mediaParams={
      selectedFile: selectedFile && selectedFile,
      objectId: objectId,
      ObjectName: objectName,
      values: values,
    }

    await MediaUpload.uploadFile(mediaParams,()=>{
      getMediaDetails();
      dispatch(
        fetchList(
          objectName ? objectName : "image",
          `${endpoints().mediaAPI}/search`,
          params.page ? params.page : 1,
          params.pageSize ? params.pageSize : 25,
          params
        )
      );
      setSelectedFile("");
      setErrorMessage("");
      isModelOpen ? _toggle : props?.toggle()
    });
  };

  const handleUpdate = async (id, values) => {
    try {
      if (!file) {
        setErrorMessage("erroe message");
      }
      const mediaFile = selectedFile ? selectedFile : "";

      const media = selectedFile?.name;
      const name = values?.name;
      const tagId = values?.category && values?.category?.value;
      const data = new FormData();

      if (mediaFile) {
        data.append([Media.MEDIA_FILE], mediaFile ? mediaFile : "");
      }
      if (media !== undefined) {
        data.append([Media.MEDIA_NAME], media ? media : "");
      }
      data.append("object", ObjectName);

      data.append("object_id", objectId);
      data.append("name", name ? name : "");
      data.append("tag_id", tagId ? tagId : "")

      data.append([Media.MEDIA_VISIBILITY], Media.VISIBILITY_PUBLIC);
      // if(
      await MediaService.update(id, data, props?.toggle(), (response) => {
        if (response) {
          dispatch(
            fetchList(
              objectName ? objectName : "image",
              `${endpoints().mediaAPI}/search`,
              params.page ? params.page : 1,
              params.pageSize ? params.pageSize : 25,
              params
            )
          );
          setErrorMessage("");
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleOpenModal = () => {
    if (isModelOpen) {
        setIsModelOpen(prevState => !prevState);
    } else {
        props?.toggle?.() || props?.toggleClose?.();
    }
    setErrorMessage("");
};

  return (
    <div>
      <Drawer
        modelTitle={
          props?.currentData?.name
            ? "Edit File"
            : props.modalTitle
              ? props.modalTitle
              : "Add File"
        }
        DrawerBody={documentForm}
        DrawerFooter={documentFooter}
        onSubmit={(values) => {
          if (props?.currentData?.id) {
            handleUpdate(props?.currentData?.id, values);
          } else {
            handleSubmit(values);
            handleImageUpload?.(values);
          }
        }}
        initialValues={{
          name: props?.currentData?.name ? props?.currentData?.name : "",
          category: props?.currentData?.tag_id ? {
            label: props?.currentData?.typeName,
            value: props?.currentData?.tag_id
          } : ""
        }}
        handleOpenModal={() => handleOpenModal()}
        handleCloseModal={() =>
          handleOpenModal()
            
        }
        handleDrawerClose={() =>
          handleOpenModal()
         
        }
        isModalOpen={props?.modalOpen ? props?.modalOpen : isModelOpen}
        enableReinitialize={true}
      />
      <div>
        <DeleteModal
          isOpen={deleteModal}
          toggle={() => {
            setDeleteModal(false);
          }}
          title="Delete Image"
          label={deleteRow.name}
          deleteFunction={() => {
            handleDeleteMedia(deleteRow.id);
            setModalOpen(false);
          }}
        />
        {/* Image List Table */}
        {isOpen && (
          <Lightbox
            mainSrc={currentData?.url}
            onCloseRequest={() => setIsOpen(false)}
          />
        )}
        {!props.showCarasoul && (
          <ReduxTable
            id={objectName ? objectName : "image"}
            disableHeader={props?.userView || billView ? true : false}
            newTableHeading
            noRecordFoundHeight={"7vh"}
            apiURL={`${endpoints().mediaAPI}/search`}
            searchPlaceholder="Search"
            paramsToUrl={true}
            params={params}
            history={props.history}
            statusOptions={statusOptions}
            sortByOptions={sortOptions}
            onRowClick={(row) => {
              props?.setCurrentData(row);
              props?.setFile(row.file_name);
              props?.toggle(row.id);
              props?.setImageStatus(row.status);
              props?.setImageFeature(row.feature);
            }}
          >
            <ReduxColumn
              width="150px"
              minWidth="150px"
              maxWidth="150px"
              renderField={(row) => {
                if (row.url) {
                  return (
                    <card
                      className="img-fluid"
                      onClick={() => {
                        openLightbox();
                        setCurrentData(row);
                      }}
                    >
                      <div className="d-flex justify-content-around">
                        <img
                          width="50"
                          height="50"
                          src={row.url}
                          alt={row.file_name}
                          className="img-fluid img-thumbnail"
                        />
                      </div>
                    </card>
                  );
                } else
                  return (
                    <a href={row.pdf} download>
                      <button>Download PDF</button>
                    </a>
                  );
              }}
              disableOnClick
            >
              Image
            </ReduxColumn>
            {!billView && (
              <ReduxColumn
                width="150px"
                minWidth="150px"
                maxWidth="150px"
                field="name"
                sortBy="name"
                type="link"
                isClickable="true"
              >
                Name
              </ReduxColumn>
            )}
            {!billView && (
              <ReduxColumn
                className="align-center"
                width="150px"
                minWidth="150px"
                maxWidth="150px"
                field="feature"
                renderField={(row) => (
                  <div className="d-flex justify-content-around">
                    <div
                      className={`status-input text-center rounded text-white text-uppercase ${row.feature && row.feature == 1 ? "bg-success" : ""
                        }`}
                    >
                      {row.feature && row.feature == 1 ? (
                        <sapn>Feature</sapn>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                )}
                disableOnClick
              >
                Type
              </ReduxColumn>
            )}
            {!billView && (
              <ReduxColumn
                width="150px"
                minWidth="150px"
                maxWidth="150px"
                field="status"
                sortBy="status"
                renderField={(row) => (
                  <div
                    className={`status-input text-center rounded text-white text-uppercase align-middle  mx-auto ${row.status && row.status === ProductMediaStatus.ACTIVE
                      ? "bg-success"
                      : row.status === ProductMediaStatus.INACTIVE
                        ? "bg-dark bg-opacity-50"
                        : ""
                      }`}
                  >
                    <p>{row.status}</p>
                  </div>
                )}
              >
                Status
              </ReduxColumn>
            )}
            <ReduxColumn
              field="Action"
              className="action-column"
              disableOnClick
              renderField={(row) => (
                <div className="text-center action-group-dropdown">
                  <MoreDropdown>
                    {!attachmentsList ? (
                      <>
                        {row.status !== ProductMediaStatus.ACTIVE ? (
                          <DropdownItem
                            onClick={() => {
                              dispatch(
                                API.updateImageStatus(
                                  row.id,
                                  {
                                    status: Media.MEDIA_STATUS_ACTIVE,
                                    objectId: objectId,
                                    objectName: objectName,
                                  },
                                  objectName,
                                  params
                                )
                              );
                            }}
                          >
                            Make As Active
                          </DropdownItem>
                        ) : (
                          <DropdownItem
                            onClick={() => {
                              dispatch(
                                API.updateImageStatus(
                                  row.id,
                                  {
                                    status: Media.MEDIA_STATUS_INACTIVE,
                                    objectId: objectId,
                                    objectName: objectName,
                                  },
                                  objectName,
                                  params
                                )
                              );
                            }}
                          >
                            Make As InActive
                          </DropdownItem>
                        )}
                        <DropdownItem
                          onClick={async () => {
                            dispatch(
                              await API.updateImageStatus(
                                row.id,
                                {
                                  feature: Feature.FEATURE_ENABLED,
                                  objectId: objectId,
                                  objectName: objectName,
                                },
                                objectName,
                                params,
                                props.getDetails
                              )
                            );
                          }}
                        >
                          Set as a featured image
                        </DropdownItem>
                      </>
                    ) : (
                      ""
                    )}
                    <DropdownItem
                      className=" text-danger cursor-pointer"
                      onClick={() => {
                        setDeleteModal(true);
                        setDeleteRow(row);
                      }}
                    >
                      Delete
                    </DropdownItem>
                  </MoreDropdown>
                </div>
              )}
            >
              Action
            </ReduxColumn>
          </ReduxTable>
        )}
        <div className="cursor-pointer">
          {!hideAttachments && <h5>Attachments</h5>}
          {Attachments && !editable && (
            <DragAndDropField
              onDrop={onDropImage}
              width={width ? width : "100%"}
              height={dragDropFieldHeight}
            />
          )}
        </div>
        {(props.showCarasoul && selectedImages && selectedImages.length > 0) ||
          (props.showCarasoul && imageUrl && imageUrl.length > 0) ? (
          <>
            <div>
              <ImageCarousel
                height={height}
                photos={imageUrl ? imageUrl : selectedImages}
                showRemove={true}
                isLoading={props.isLoading || isLoading}
                showRemoveTop={props.showRemoveTop}
                handlePhotoDelete={(photo) => {
                  handleDeleteMedia(photo.id);
                  props.handleImageRemove(photo);
                }}
                editable={editable}
                className={className}
              />
            </div>
          </>
        ) : showNoRecordFound ? (
          <NoRecordsFound
            showMessage={true}
            hideCard={true}
            message="No Attachments Found"
            middleHeight={'180px'}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default MediaCarousel;
