import React, { useState } from "react";
import Text from "../../components/Text";
import "../../scss/_custom.scss";

const PortalName = () => {
  const [nameToggle, setNameToggle] = useState(true);

  const legalColor = nameToggle ? "#ECF5FF" : "#FFFFFF";

  return (
    <>
      <div className="accordion mt-3" id="accordionExample">
        <div className="accordion-item">
          <h2 className="accordion-header" id="headingOne">
            <button
              className={`accordion-button ${nameToggle ? "" : "collapsed"}`}
              style={{ backgroundColor: legalColor }}
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapseOne"
              aria-expanded={nameToggle ? "true" : "false"}
              aria-controls="collapseOne"
              onClick={() => setNameToggle(!nameToggle)}
            >
              <h5 className="text-primary">App Name</h5>
            </button>
          </h2>
          <div
            id="collapseOne"
            className={`accordion-collapse collapse ${nameToggle ? "show" : ""}`}
            aria-labelledby="headingOne"
            data-bs-parent="#accordionExample"
          >
            <div className="accordion-body p-3">
              <div className="row">
                <div className="col-lg-12 col-sm-12">
                  <Text
                    name="portal_name"
                    label="App Name"
                    placeholder="Enter App Name..."
                    error=""
                    required={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PortalName;
