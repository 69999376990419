import React, { useEffect, useState, useRef } from "react";
import "../style.scss";

import { Line } from "react-chartjs-2";
import Spinner from "../../../components/Spinner";
import {
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import Chart from "chart.js/auto";
import { getColorsRandom, getRandomColor } from "../../../lib/Helper";
import ChartDataLabels from "chartjs-plugin-datalabels";
import Number from "../../../lib/Number";
import Url from "../../../lib/Url";
import Currency from "../../../lib/Currency";
import { OrderProduct } from "../../../helpers/orderProduct";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);
const ActiveSales = (props) => {
    const chartRef = useRef(null);
    const [total, setTotal] = useState([]);
    const [storeList, setStoreList] = useState([]);
    const [average, setAverage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [colorList, setColorList] = useState([]);
    const [totalQuantity, setTotalQuantity] = useState([]);
    const graphData = props?.graphData;
    const defaultGraph = props?.defaultGraph;
    const [locationName, setLocation] = useState([]);
    const [storeQuantity, setStoreQuantity] = useState([]);
    const [amount, setAmount] = useState([]);
    const [totalAmount, setTotalAmount] = useState("");
    const [totalAverage, setTotalAverage] = useState("");
    const [filterOpen, setFilterOpen] = useState(false);
    const [chartWidth, setChartWidth] = useState('100%');

    useEffect(() => {
        getRandomColors();
    }, [storeList]);

    useEffect(() => {
        getStoresList();
    }, [graphData]);

    useEffect(() => {
        setFilterOpen(props.filterOpen || false);
    }, [props.filterOpen]);

    useEffect(() => {
        if (chartRef.current && chartRef.current.chart) {
            const chart = chartRef.current.chart;
            const canvas = chart.canvas;
            const containerWidth = canvas.parentElement.offsetWidth;
            const chartWidthCalc = containerWidth > 700 ? 700 : containerWidth
            setChartWidth(chartWidthCalc);
        }
    }, [storeList, locationName]);

    const getRandomColors = () => {
        let color = getRandomColor(Url.GetParam("graphData") == OrderProduct.REPORT_TYPE_AMOUNT_WISE ? amount.length : storeQuantity.length);
        setColorList(color);
    };
    const getStoresList = async () => {
        let storeData = [];
        let locationName = [];
        let orderProductQuantity = [];
        let storeQuantity = [];
        let total;
        let average;
        let storesLists = [];
        let amount = []
        let totalAmount;
        let totalAverage
        if (!defaultGraph && graphData) {
            storesLists = graphData.data;
            if (storesLists) {
                if (storesLists.length == 0) {
                    storeData.push([]);
                    orderProductQuantity.push([]);
                    setStoreList(storeData);
                    setLocation([]);
                    setStoreQuantity([]);
                    setTotalQuantity(orderProductQuantity);
                    total = graphData.totalQuantity;
                    setTotal(total);
                    setAverage(graphData.average);
                    setAmount([])
                } else {
                    for (var i = 0; i < storesLists.length > 0; i++) {
                        if (storesLists[i].date) {
                            storeData.push(storesLists[i].date);
                            amount.push(storesLists[i].price)
                            setAmount(amount)
                            orderProductQuantity.push(storesLists[i].quantity);
                            setStoreList(storeData);
                            setTotalQuantity(orderProductQuantity);
                            setLocation([]);
                            setStoreQuantity([]);
                        }

                        if (storesLists[i].name) {
                            locationName.push(storesLists[i].name);
                            storeQuantity.push(storesLists[i].quantity);
                            amount.push(storesLists[i].price)
                            setAmount(amount)
                            setLocation(locationName);
                            setStoreQuantity(storeQuantity);
                            setStoreList([]);
                            setTotalQuantity([]);
                        }

                        if (storesLists[i].brand_name) {
                            locationName.push(storesLists[i].brand_name);
                            storeQuantity.push(storesLists[i].quantity);
                            amount.push(storesLists[i].price)
                            setLocation(locationName);
                            setStoreQuantity(storeQuantity);
                            setStoreList([]);
                            setTotalQuantity([]);
                            setAmount(amount)
                        }

                        if (storesLists[i].category_name) {
                            locationName.push(storesLists[i].category_name);
                            storeQuantity.push(storesLists[i].quantity);
                            amount.push(storesLists[i].price)
                            setLocation(locationName);
                            setStoreQuantity(storeQuantity);
                            setStoreList([]);
                            setTotalQuantity([]);
                            setAmount(amount)
                        }
                        if (storesLists[i].product_name) {
                            locationName.push(storesLists[i].product_name);
                            storeQuantity.push(storesLists[i].quantity);
                            amount.push(storesLists[i].price)
                            setLocation(locationName);
                            setStoreQuantity(storeQuantity);
                            setStoreList([]);
                            setTotalQuantity([]);
                            setAmount(amount)
                        }
                    }
                    total = graphData.totalQuantity;
                    average = graphData.average;
                    totalAmount = graphData?.totalAmount;
                    totalAverage = graphData?.totalAverage;
                    setAverage(average);
                    setTotal(total);
                    setTotalAmount(totalAmount);
                    setTotalAverage(totalAverage)
                }
            }
        }
        if (storeData && storeData.length > 0) return storeData;
        if (orderProductQuantity) return orderProductQuantity;
    };
    const graphBarData = locationName && locationName.length > 0 && locationName.map((value, index) => [value, Url.GetParam("graphData") == "Amount Wise" ? amount[index] : storeQuantity[index]]);
    if (isLoading) {
        return <Spinner />;
    }

    return (
        <>
            <div className="App">
                <h3 className="text-center">
                    {Url.GetParam("graphData") == "Amount Wise" ? `Total Amount: ${Currency.GetFormatted(graphData?.totalAmount ? graphData?.totalAmount : "")} (Average: ${Currency.GetFormatted(graphData?.totalAverage ? graphData?.totalAverage : "")})${" "}` : `Total Quantity: ${graphData?.totalQuantity ? graphData?.totalQuantity : ""} (Average: ${Number.Get(graphData.average ? graphData.average : "")})${" "}`}
                </h3>
            </div>
            <div className={`chart-wrapper ${filterOpen ? 'hide-chart' : ''}`}>
                {storeList.length > 0 && totalQuantity.length > 0 && (
                    <div
                        className="chart-container"
                        style={{ width: chartWidth, whiteSpace: "nowrap" }}
                    >
                        <div>
                            <Line
                                ref={chartRef}
                                data={{
                                    labels: storeList,
                                    datasets: [
                                        {
                                            label: " ",
                                            data: Url.GetParam("graphData") == "Amount Wise" ? amount : totalQuantity,
                                            backgroundColor: colorList,
                                            borderColor: defaultGraph ? ["blue"] : colorList,
                                            borderWidth: 2,
                                            datalabels: {
                                                formatter: (value) => {
                                                    if (Url.GetParam("graphData") == "Amount Wise") {
                                                        return Currency.GetFormatted(value);
                                                    } else {
                                                        return value;
                                                    }
                                                },
                                                color: "blue",
                                                anchor: "end",
                                                align: "top",
                                                offset: -5,
                                                font: {
                                                    size: 14,
                                                },
                                            },
                                        },
                                    ],
                                }}
                                height={"550px"}
                                plugins={[ChartDataLabels]}
                                options={{
                                    animation: false,
                                    maintainAspectRatio: false,
                                    scales: {
                                        x: {
                                            ticks: {
                                                maxRotation: 90,
                                                minRotation: 90,
                                                autoSkip: true
                                            }
                                        },
                                        y: {
                                            ticks: {
                                                callback: function (value, index, values) {
                                                    if (Url.GetParam("graphData") === "Amount Wise") {
                                                        return Currency.GetFormatted(value);
                                                    } else {
                                                        return value;
                                                    }
                                                },
                                                beginAtZero: true,
                                            },
                                        },

                                    },
                                    plugins: {
                                        legend: {
                                            display: true,
                                            position: "top",
                                            align: "center",
                                            labels: {
                                                padding: 20,
                                                boxWidth: 0,
                                            },
                                        },
                                    },
                                }}
                            />
                        </div>
                    </div>
                )}
                {locationName.length > 0 && storeQuantity.length > 0 && (
                    <div className="chart-container-wrapper" style={{ overflowX: "auto" }}>
                        <div style={{ minWidth: graphBarData && graphBarData.length > 30 ? '1000px' : "100%" }}>
                            <HighchartsReact
                                highcharts={Highcharts}
                                options={{
                                    chart: {
                                        type: 'column',
                                        width: null,
                                        scrollablePlotArea: {
                                            minWidth: graphBarData && graphBarData.length > 30 ? 700 : null,
                                            scrollPositionX: 1,
                                        },
                                        ...(graphBarData && graphBarData.length > 30 ? { width: 10000 } : {}),
                                    },
                                    credits: {
                                        enabled: false,
                                    },
                                    title: {
                                        text: " ",
                                    },
                                    xAxis: {
                                        type: 'category',
                                        labels: {
                                            rotation: -75,
                                            style: {
                                                fontSize: '10px',
                                                fontFamily: 'Verdana, sans-serif',
                                            },
                                            formatter: function () {
                                                const maxLength = 15;
                                                if (this.value && this.value.length > maxLength) {
                                                    return this.value.substring(0, maxLength) + '...';
                                                }
                                                return this.value;
                                            }
                                        },
                                        scrollbar: {
                                            enabled: true
                                        },
                                        tickLength: 0,
                                    },
                                    yAxis: {
                                        min: 0,
                                        title: {
                                            text: ' ',
                                        },
                                        labels: {
                                            formatter: function () {
                                                if (Url.GetParam("graphData") == "Amount Wise") {
                                                    return Currency.GetFormatted(this.value);
                                                } else {
                                                    return this.value;
                                                }
                                            },
                                        },
                                    },
                                    legend: {
                                        enabled: false,
                                    },
                                    tooltip: {
                                        pointFormat: Url.GetParam("graphData") == "Amount Wise" ? '<b>₹{point.y}</b>' : '<b>{point.y}</b>',
                                    },
                                    series: [
                                        {
                                            name: 'Population',
                                            colors: colorList,
                                            colorByPoint: true,
                                            groupPadding: 0,
                                            data: graphBarData,
                                            dataLabels: {
                                                enabled: true,
                                                rotation: -60,
                                                color: 'black',
                                                align: 'center',
                                                format: Url.GetParam("graphData") == "Amount Wise" ? '₹{point.y}' : '{point.y}',
                                                style: {
                                                    fontSize: '13px',
                                                    fontFamily: 'Verdana, sans-serif',
                                                },
                                            },
                                        },
                                    ],
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
export default ActiveSales;
