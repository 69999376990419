import React, { useState } from "react";
import CompanyUserService from "../services/UserService";
import Select from "./Select";
import Spinner from "./Spinner";
import Url from "../lib/Url";
import { User } from "../helpers/User";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareArrowUpRight } from "@fortawesome/free-solid-svg-icons";

const ProjectUserSelect = (props) => {
  let {
    name,
    handleUserChange,
    label,
    required,
    projectId = null,
    customUserOption,
    placeholder,
    isDisabled,
    isMulti,
    defaultValue,
    showUserDetailsPageLink,
    userId,
    userLists,
    isFullList = false
  } = props;

  const [userOption, setUserOption] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const getUsers = async () => {
    let params = {
      status: User.STATUS_ACTIVE_VALUE,
      ...(isFullList ? {} : { projectId: projectId ? projectId : Url.GetParam("projectId") }),
    };
    setIsLoading(true);
    const response = await CompanyUserService.projectUserList(params);
    let data = response && response?.data;
    let userList = [];
    if (data && data.length > 0) {
      for (let i in data) {
        let { first_name, last_name, media_url, id } = data[i];

        userList.push({
          label: await CompanyUserService.getUserName(
            media_url,
            first_name,
            last_name
          ),
          id: id,
          value: first_name + "" + last_name,
        });
      }
    }
    setUserOption(userList);
    userLists && userLists(userList);
    setIsLoading(false);
  };

  const onFocus = () => {
    getUsers();
  };

  if (isLoading) {
    <Spinner />;
  }
  return (
    <>
      <div className="d-flex">
        <Select
          name={name ? name : "user"}
          placeholder={placeholder ? placeholder : "Select User"}
          options={customUserOption ? customUserOption : userOption}
          handleChange={handleUserChange}
          required={required}
          label={label}
          width={props?.width}
          isClearable={true}
          autoFocus={onFocus}
          isLoading={isLoading}
          defaultValue={defaultValue}
          isDisabled={isDisabled}
          isMulti={isMulti}
        />
        {showUserDetailsPageLink && (
          <div className="flex-shrink-1 bd-highlight mt-4 pt-1">
            <Link target="_blank" to={`/user/${userId}`}>
              <FontAwesomeIcon
                className="text-body-secondary p-1"
                icon={faSquareArrowUpRight}
                style={{
                  fontSize: "40px",
                }}
              />
            </Link>
          </div>
        )}
      </div>
    </>
  );
};

export default ProjectUserSelect;
