import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { InputGroup, InputGroupText, Input } from "reactstrap";

const PageSearch = (props) => {
  const {
    classnames,
    placeholder,
    onChange,
    value,
    width,
    id,
    onKeyDown,
    onSearchClick,
  } = props;

  return (
    <>
      <InputGroup
        className={`${classnames} align-items-stretch me-0 me-sm-2`}
        style={{ width: width ? width : "100%" }}
      >
        <Input
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          onKeyDown={(e) => onKeyDown && onKeyDown(e)}
          // value prop: can be used if want a full control over input field value
          {...((value || value === "") && { value })}
        />
        <InputGroupText
          id={id}
          addonType="prepend"
          className="input-group-text bg-dark cursor-pointer"
          onClick={() => onSearchClick && onSearchClick()}
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} className="text-white bg-dark" />
        </InputGroupText>
      </InputGroup>
    </>
  );
};

export default PageSearch;
