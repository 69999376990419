import React from "react";
import PropTypes from "prop-types";

// Checkbox component (moved above to ensure it's available)
function Checkbox({ day, checked, onChange, disabled }) {
  return (
    <label style={{ marginRight: "15px" }}>
      <input
        className="me-2 ms-2"
        type="checkbox"
        checked={checked}
        onChange={(e) => onChange(day, e.target.checked)}
        disabled={disabled}
      />
      {day}
    </label>
  );
}

// Week days Checkbox component
const WeekdaysCheckbox = ({ selectedDays, handleCheckboxChange, disabled }) => {
  // Using a Set for better performance with larger selectedDays data
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  // Convert selectedDays to a Set if it's an array for performance
  const selectedDaysSet = new Set(selectedDays);

  return (
    <div>
      {daysOfWeek.map((day) => (
        <Checkbox
          key={day}
          day={day}
          checked={selectedDaysSet.has(day)} // Use Set's has method for faster membership check
          onChange={handleCheckboxChange}
          disabled={disabled}
        />
      ))}
    </div>
  );
};

// Prop Types validation
Checkbox.propTypes = {
  day: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

WeekdaysCheckbox.propTypes = {
  selectedDays: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.instanceOf(Set),
  ]).isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default WeekdaysCheckbox;
