import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import classnames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// Components
import Text from "../../components/Text";
import PageTitle from "../../components/PageTitle";
import UserRolePermission from "./components/UserRolePermission";
import AddModal from "../../components/Modal";
import DeleteModal from "../../components/DeleteModal";
import BreadCrumb from "../../components/Breadcrumb";
import UserList from "../../components/UserList";
import Action from "../../components/Action";
import SaveButton from "../../components/SaveButton";
import ActivityList from "../../components/ActivityList";
import UserRoleDetail from "./UserRoleDetail";
import RoleSetting from "./RoleSetting";
import Toast from "../../components/Toast";

// API
import { endpoints } from "../../api/endPoints";

// Action
import * as API from "../../actions/userSetting";
import { fetchList } from "../../actions/table";

// Lib
import Url from "../../lib/Url";
import { isBadRequest } from "../../lib/Http";

// Config
import { apiClient } from "../../apiClient";

// Services
import RoleService from "../../services/RoleService";

// Helpers
import ObjectName from "../../helpers/ObjectName";
import { User } from "../../helpers/User";
import Drawer from "../../components/Drawer";

const UserRoleDetails = (props) => {
  const { history, match } = props;
  const dispatch = useDispatch();

  //Tab constants
  const TAB_GENERAL = "General";
  const TAB_PERMISSIONS = "Permissions";
  const TAB_SETTING = "Settings";
  const TAB_HISTORY = "History";
  const TAB_USERS = "Users";
  const [userRoleDetail, setUserRoleDetail] = useState();
  const [deleteModal, setDeleteModal] = useState();
  const [activeTabs, setActiveTab] = useState(
    Url.GetParam("tab") ? Url.GetParam("tab") : TAB_GENERAL
  );
  const [isModelOpen, setIsModelOpen] = useState(false);

  const handleDelete = (id, params) => {
    try {
      dispatch(API.deleteUserRole(id, params));
      props.history.push("/admin/roles");
    } catch (err) {
      console.log(err);
    }
  };

  const handleTabs = (tab) => {
    setActiveTab(tab);
    props.history.push(`?tab=${tab}`);

    const updatedParams = { tab };

    if (tab === TAB_USERS) {
      updatedParams.role = match.params.id;
    }

    Url.UpdateUrl(updatedParams);
  };

  const handleRoleClone = async (values) => {
    let data = {
      roleName: values?.roleName,
    };
    dispatch(
      await RoleService.clone(match.params.id, data, (res) => {
        if (res) {
          setIsModelOpen(false);
        }
      })
    );
  };

  useEffect(() => {
    if (match.params.id) {
      getUserRoleDetails(match.params.id);
    }
  }, [props]);

  const getUserRoleDetails = () => {
    let id = props.match.params.id;

    try {
      return apiClient
        .get(`${endpoints().userRoleAPI}/${id}`)
        .then((response) => {
          const data = response.data;
          setUserRoleDetail(data);
        })
        .catch((error) => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    } catch (error) {
      console.log(error);
    }
  };

  const breadcrumbList = [
    { label: "Home", link: "/admin/companyProfile" },
    { label: "Roles", link: "/admin/roles" },
    { label: userRoleDetail?.data?.role_name, link: "" },
  ];

  const actionsMenuList = [
    {
      value: "clone",
      label: "Clone",
    },
    {
      value: "delete",
      label: "Delete",
    },
  ];

  const handleActionChange = (e) => {
    if (e == "delete") {
      setDeleteModal(true);
    }

    if (e == "clone") {
      setIsModelOpen(!isModelOpen);
    }
  };

  let modelBody = (
    <>
      <Text name="roleName" label="Role Name" required />
    </>
  );

  let modelFooter = (
    <>
      <SaveButton label="Clone" />
    </>
  );

  let initialValues = {
    roleName: "",
  };

  const CloseToggle = () => {
    setIsModelOpen(!isModelOpen);
  };

  let values = {
    sort: Url.GetParam("sort"),
    sortDir: Url.GetParam("sortDir"),
    search: Url.GetParam("search"),
    role: Url.GetParam("role"),
    allow_leave: Url.GetParam("allow_leave"),
    status: Url.GetParam("status"),
  };

  const updateUser = async (value) => {
    let userId = value?.data?.userId;
    let data = value?.data;
    try {
      const response = await apiClient.put(
        `${endpoints().userAPI}/${userId}`,
        data
      );
      if (response && response.data) {
        let successMessage = response.data.message;
        Toast.success(successMessage);
        const updatedValues = {
          ...values,
          role: props.match.params.id,
          status: User.STATUS_ACTIVE_VALUE,
        };
        dispatch(
          fetchList(
            "roleUserList",
            `${endpoints().userAPI}/search`,
            updatedValues.page ? updatedValues.page : 1,
            updatedValues.pageSize ? updatedValues.pageSize : 25,
            updatedValues
          )
        );
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        Toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  };

  return (
    <>
      <BreadCrumb list={breadcrumbList} />

      <div className="d-flex justify-content-between">
        <PageTitle label={userRoleDetail?.data?.role_name} />

        <DeleteModal
          isOpen={deleteModal}
          title={`Delete role`}
          label={userRoleDetail?.data?.role_name}
          id={match.params.id}
          toggle={() => {
            setDeleteModal(false);
          }}
          deleteFunction={handleDelete}
        />
        <Drawer
        modelTitle={"Clone"}
        DrawerBody={modelBody}
        DrawerFooter={modelFooter}
        onSubmit={(values) => {
          handleRoleClone(values);
        }}
        initialValues={initialValues}
        handleCloseModal={CloseToggle}
        handleDrawerClose={CloseToggle}
        isModalOpen={isModelOpen}
        enableReinitialize={true}
      />
        <div className="ps-2">
          <Action
            dropdownLinks={actionsMenuList}
            handleChange={handleActionChange}
          />
        </div>
      </div>
      <div>
        {/* Nav Section for userRole detail tabs navigation */}
        <Nav tabs className="admin-tabs mt-2">
          {/* userRoleDetail Tab */}
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTabs === TAB_GENERAL,
              })}
              onClick={() => {
                handleTabs(TAB_GENERAL);
              }}
            >
              {TAB_GENERAL}
            </NavLink>
          </NavItem>

          {/* userRolepermissions Tab */}
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTabs === TAB_PERMISSIONS,
              })}
              onClick={() => {
                handleTabs(TAB_PERMISSIONS);
              }}
            >
              {TAB_PERMISSIONS}
            </NavLink>
          </NavItem>

          {/* userRolepermissions Tab */}

          <NavItem>
            <NavLink
              className={classnames({
                active: activeTabs === TAB_USERS,
              })}
              onClick={() => {
                handleTabs(TAB_USERS);
              }}
            >
              {TAB_USERS}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTabs === TAB_SETTING,
              })}
              onClick={() => {
                handleTabs(TAB_SETTING);
              }}
            >
              {TAB_SETTING}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTabs === TAB_HISTORY,
              })}
              onClick={() => {
                handleTabs(TAB_HISTORY);
              }}
            >
              {TAB_HISTORY}
            </NavLink>
          </NavItem>
        </Nav>

        {/* UserRoleTab sections */}
        <TabContent activeTab={activeTabs}>
          <TabPane tabId={TAB_GENERAL}>
            {/* Detail tab */}
            <UserRoleDetail
              history={history}
              match={match}
              activeTab={activeTabs}
            />
          </TabPane>

          {/* Permission Tab */}
          <TabPane tabId={TAB_PERMISSIONS}>
            <UserRolePermission
              history={history}
              match={match}
              activeTab={activeTabs}
            />
          </TabPane>

          {/* Settings Tab */}
          <TabPane tabId={TAB_SETTING}>
            <RoleSetting
              roleId={props.match.params.id}
              history={history}
              match={match}
              activeTab={activeTabs}
            />
          </TabPane>

          <TabPane tabId={TAB_USERS}>
            <UserList
              tableId="roleUserList"
              params={{
                role: props?.match?.params?.id,
                status: User.STATUS_ACTIVE_VALUE,
              }}
              props={props}
              history={history}
              bulkSelect={false}
              showRoleFilter={false}
              DropdownWithCheckbox={false}
              updateUser={(e) => updateUser(e)}
            />
          </TabPane>
          {activeTabs == TAB_HISTORY && (
            <TabPane tabId={TAB_HISTORY}>
              <ActivityList
                id={props?.match?.params?.id}
                objectId={props?.match?.params?.id}
                object_name={ObjectName.ROLE}
              />
            </TabPane>
          )}
        </TabContent>
      </div>
    </>
  );
};
export default UserRoleDetails;
