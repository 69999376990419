import React, { useMemo } from "react";
import Select from "./Select";

const Quantity = ({
  label = "Select Quantity",
  width = "100%",
  required = false,
  onChange,
  name = "quantity",
  isDisabled = false,
  defaultValue = null,
  list,
}) => {
  // Generate quantity list only once
  const quantityList = useMemo(() => {
    const options = Array.from({ length: 10000 }, (_, i) => ({
      label: i,
      value: i,
    }));
    if (list) list(options);
    return options;
  }, [list]);

  return (
    <Select
      fullWidth={true}
      width={width}
      label={label}
      name={name}
      placeholder={label}
      isClearable
      isSearchable
      required={required}
      options={quantityList}
      isSingleSelect={true}
      onInputChange={onChange}
      isDisabled={isDisabled}
      defaultValue={defaultValue}
      showVirtualizedMenu={true}
    />
  );
};

export default Quantity;
